import { createAction } from "redux-act";
import { createAsyncAction } from "../../lib/utils";

import * as api from "./api";
import * as mutators from "./mutators";

const openAddsectionmodal = createAction(
  `OPEN_ADD_SECTION_MODAL`,
  (payload) => payload
);

const uploadVideo = createAsyncAction(
  `UPLOAD_VIDEO`,
  payload => payload
)

const getCourseSection = createAsyncAction(
  `GET_ADDCOURSE_SECTION`,
  payload => payload
)

const getBookResourceByBookId = createAsyncAction(
  `GET_BOOKRESOURCE_BYBOOKID`,
  payload => payload
)

const addLearningList = createAsyncAction(
  `ADD_LEARNING_LIST`,
  payload => payload
)

const getLearningList = createAsyncAction(
  `GET_LEARNING_LIST`,
  payload => payload
)

const deleteLearningList = createAsyncAction(
  `DELETE_LEARNING_LIST`,
  payload => payload
)

const getVideosByBookResourceId = createAsyncAction(
  `GET_VIDEOS_BY_BOOK_RESOURCE_ID`,
  payload => payload
)

const openSingleVideoPlayer = createAction(
  `OPEN_SINGLE_VIDEO_PLAYER`,
  (payload) => payload
)

const closeSingleVideoPlayer = createAction(
  `CLOSE_SINGLE_VIDEO_PLAYER`,
  (payload) => payload
)
const setVideoJsOptions = createAction(
  `SET_VIDEO_JS_OPTIONS`,
  (payload) => payload
);
const handleSuccessAlertModalClose = createAction(
  `CLOSE_SUCCESSS_ALERT_MODAL_ADMIN`,
  (payload) => payload
);

const handleErrorAlertModalClose = createAction(
  `HANDLE_ERRORR_MODAL_CLOSE_ADMIN`,
  (payload) => payload
);
const reducer = {
  [openAddsectionmodal]: (state, payload) =>
    mutators.openAddsectionmodal(payload)(state),
  [uploadVideo.success]: (state, payload) =>
    mutators.uploadvideoSuccessAlertBox(payload)(state),
  [uploadVideo.failure]: (state, payload) =>
    mutators.uploadvideoFailureAlertBox(payload)(state),
  [getCourseSection.success]: (state, payload) =>
    mutators.getCourseSection(payload)(state),
  [getBookResourceByBookId.success]: (state, payload) =>
    mutators.getBookResourceByBookId(payload)(state),
  [addLearningList.success]: (state, payload) =>
    mutators.addLearningListSuccess(payload)(state),
  [addLearningList.failure]: (state, payload) =>
    mutators.uploadvideoFailureAlertBox(payload)(state),
  [getLearningList.success]: (state, payload) =>
    mutators.getLearningList(payload)(state),
  [deleteLearningList.success]: (state, payload) =>
    mutators.deleteLearningListSuccessAlertBox(payload)(state),
  [deleteLearningList.failure]: (state, payload) =>
    mutators.uploadvideoFailureAlertBox(payload)(state),
  [getVideosByBookResourceId.success]: (state, payload) =>
    mutators.getVideosByBookResourceId(payload)(state),
  [openSingleVideoPlayer]: (state, payload) =>
    mutators.openSingleVideoPlayer(payload)(state),
  [closeSingleVideoPlayer]: (state, payload) =>
    mutators.closeSingleVideoPlayer(payload)(state),
  [setVideoJsOptions]: (state, payload) =>
    mutators.setVideoJsOptions(payload)(state),
  [handleSuccessAlertModalClose]: (state, payload) =>
    mutators.handleSuccessAlertModalClose(payload)(state),
  [handleErrorAlertModalClose]: (state, payload) =>
    mutators.handleErrorAlertModalClose(payload)(state),
}


const restApi = {
  [uploadVideo]: api.uploadVideo(uploadVideo),
  [getCourseSection]: api.getCourseSection(getCourseSection),
  [getBookResourceByBookId]: api.getBookResourceByBookId(getBookResourceByBookId),
  [addLearningList]: api.addLearningList(addLearningList),
  [getLearningList]: api.getLearningList(getLearningList),
  [deleteLearningList]: api.deleteLearningList(deleteLearningList),
  [getVideosByBookResourceId]: api.getVideosByBookResourceId(getVideosByBookResourceId),
}
export default {
  actions: {
    openAddsectionmodal,
    uploadVideo,
    getCourseSection,
    getBookResourceByBookId,
    addLearningList,
    getLearningList,
    deleteLearningList,
    getVideosByBookResourceId,
    openSingleVideoPlayer,
    closeSingleVideoPlayer,
    setVideoJsOptions,
    handleSuccessAlertModalClose,
    handleErrorAlertModalClose,
  },
  restApi,
  reducer,
};

