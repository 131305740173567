import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import TeacherHomeInstOrg from "../teacherinstorg/TeacherHomeInstOrg.js";
import TeacherInstOrgHeader from ".././layout/teacherInstOrgHeader/TeacherInstOrgHeader";
import PublicFooter from "../layout/PublicFooter";
import AddUsers from "../../src/common/AddUsers.js";
import CreateAssessment from "./../assessmentModule/assessmentPages/createAssessment/CreateAssessment.js";
import ViewAssessments from "./../assessmentModule/assessmentPages/viewAssessment/teacher/ViewAssessments.js";
import Questionnaire from "../assessmentModule/assessmentPages/questionnaire/Questionnaire.js";
import ViewQuestionnaire from "../assessmentModule/assessmentPages/questionnaire/teacher/ViewQuestionnaire";
import ViewQuestionnairePaper from "../assessmentModule/assessmentPages/questionnaire/teacher/ViewQuestionnairePaper";
import ViewAssessmentsCourseTIO from "./../assessmentModule/assessmentPages/viewAssessment/teacher/ViewAssessmentsCourseTIO.js";
import ViewAllResultsPage from "./../assessmentModule/assessmentPages/viewAssessment/teacher/ViewAllResultsPage";
import ViewResultsByUser from "./../assessmentModule/assessmentPages/viewAssessment/common/ViewResultsByUser";
import NotFoundPage from "../common/404";
import Calendar from "../calendar.js";
import ProfileHome from "../profile/ProfileHome.js";
import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import AttendanceHome from "../attendance/AttendanceHome.js";

export default class TeacherRouterInstOrg extends Component {
  render() {
    var path = window.location.pathname;
    let noHeaderFooterRoutes = [
      "/ViewQuestionnaire",
      "/ViewQuestionnairePaper",
      "ViewQuestionnaireOnline",
    ];
    return (
      <ConnectedRouter history={createBrowserHistory()}>
        <div>
          <div className="page-body">
            <div className="page-content-teacherinstorg">
              {!noHeaderFooterRoutes.includes(path) && (
                <TeacherInstOrgHeader></TeacherInstOrgHeader>
              )}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/TeacherHomeInstOrg" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/TeacherHomeInstOrg" />
                </Route>
                <Route
                  exact
                  path="/TeacherHomeInstOrg"
                  render={(props) => <TeacherHomeInstOrg {...props} />}
                />
                <Route
                  exact
                  path="/InstOrgAddUsers"
                  render={(props) => <AddUsers {...props} />}
                />
                <Route
                  exact
                  path="/CreateAssessment"
                  render={(props) => <CreateAssessment {...props} />}
                />
                <Route
                  exact
                  path="/ViewAssessments"
                  render={(props) => <ViewAssessments {...props} />}
                />
                <Route
                  exact
                  path="/Questionnaire"
                  render={(props) => <Questionnaire {...props} />}
                />
                <Route
                  exact
                  path="/ViewQuestionnaire"
                  render={(props) => <ViewQuestionnaire {...props} />}
                />
                <Route
                  exact
                  path="/ViewQuestionnaireOnline"
                  render={(props) => <ViewQuestionnaire {...props} />}
                />
                <Route
                  exact
                  path="/ViewQuestionnairePaper"
                  render={(props) => <ViewQuestionnairePaper {...props} />}
                />
                <Route
                  exact
                  path="/ViewAssessmentsCourseTIO"
                  render={(props) => <ViewAssessmentsCourseTIO {...props} />}
                />
                <Route
                  exact
                  path="/ViewAllResults"
                  render={(props) => <ViewAllResultsPage {...props} />}
                />
                <Route
                  exact
                  path="/ViewAnswers"
                  render={(props) => <ViewResultsByUser {...props} />}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <ProfileHome onlyPersonalInfo={true} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/attendance"
                  render={(props) => (
                    <AttendanceHome {...props} />
                  )}
                />
                {/* Footer Links  */}
                <Route exact path="/Partners" component={PartnersPage} />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/Careers" component={CareersPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
          {!noHeaderFooterRoutes.includes(path) && <PublicFooter />}
        </div>
      </ConnectedRouter>
    );
  }
}
