import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import * as selectors from "../redux-orders/selector";
import * as appData from "../../appSelector";
import { List } from "immutable";
import "./CartSummary.css";
import { Alert } from 'react-bootstrap';
import InformationBanner from "../../common/InformationBanner";
import INFO_BANNER from "../../common/InfoBanner";
import '../../App.css';
class ExecutiveCartSummary extends Component {
  componentDidMount() {
    this.props.actions.getCartSummaryByUserId({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });
  }

  changeQuantity = (e, instituteIndex, bookIndex) => {
    this.props.actions.changeQuantity({
      instituteIndex,
      bookIndex,
      value: e.target.value,
    });

  };

  navigateToCart = () => {
    this.props.history.push("/Review");
  };

  subTotal = (cartData) => {
      let total = cartData.get("bookDtls", List()).reduce(
          (sub, book) => {
            sub = sub + Number(book.get('totalAmount',0))
            return sub

          },0
      )

      return total
  }

  render() {
    const { cartSummary,
       userData,
       updateCartSuccessAlert,
       updateCartFailureAlert,
       deleteCartSuccessAlert,
       deleteCartFailureAlert } = this.props;
    return (
      <div className="container mt-4">
        <div className="review-button mb-4">
          <button className="btn btn-primary" hidden = {!this.props.cartSummary.size >0 }
          onClick={this.navigateToCart}>
            Review
          </button>
        </div>
        <div hidden ={this.props.cartSummary.size >0} className="empty-content" >
         <InformationBanner isHorizontal 
      message = {INFO_BANNER.MESSAGE_CHECKOUT}/>
      <br/>
          </div>
          
        <div className="table-responsive">
          <table className="table table-bordered" hidden = {!this.props.cartSummary.size >0 }>
            <thead>
              <tr>
                <th>Institute Name</th>
                <th>Book Details</th>
                <th>Sub Total</th>
              </tr>
            </thead>
            {cartSummary.map((cartData, instituteIndex) => (
              <tr>
                <td>
                  <h2>{`${cartData.get("instName", "")}`}</h2>
                </td>
                <td>
                  {cartData.get("bookDtls", List()).map((book, bookIndex) => (
                    <div>
                      <div className="cart-img-wrapper">
                        <img
                          src={book.get("bookImage", "")}
                          alt={"book"}
                          width={"300"}
                          height={"200"}
                        />
                      </div>
                      <div className="cart-content-wrapper">
                        <p className="price"> price : 
                          <span className="rupee"></span> 
                          {`${ book.get("bookPrice", "")}`} 
                        </p>
                        <p className="total-amount"> Total Amount :  
                        <span className="rupee"></span> 
                          {`${book.get("totalAmount", "")}`}
                        </p>
                        <p className="quantity">
                          Quantity :&nbsp;
                          <input
                            type="text"
                            value={book.get("quantity", "")}
                            onChange={(e) =>
                              this.changeQuantity(e, instituteIndex, bookIndex)
                            }
                          />
                          &nbsp;&nbsp;
                          {updateCartSuccessAlert(bookIndex) && <Alert variant='success'className="p-1 m-1">{'Successfully Added'}</Alert>}
                          {updateCartFailureAlert(bookIndex) && <Alert variant='danger'className="p-1 m-1 ">{'Update failed'}</Alert>}
                          {deleteCartSuccessAlert(bookIndex) && <Alert variant='success'className="p-1 m-1">{'SuccessFully deleted'}</Alert>}
                          {deleteCartFailureAlert(bookIndex) && <Alert variant='danger'className="p-1 m-1">{'Delete failed'}</Alert>}
          
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => {
                              this.props.actions.updateCart({
                                jwt: userData.jwt,
                                cartDetailsId: book.get("cartDetailsId", ""),
                                instituteIndex,
                                bookIndex,
                                user: this.props.userData.id,
                              });
                            }}
                          >
                            Update
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-danger"
                            type="button"
                            onClick={() => {
                              this.props.actions.deleteCart({
                                jwt: userData.jwt,
                                cartDetailsId: book.get("cartDetailsId", ""),
                                instituteIndex,
                                bookIndex,
                                user: this.props.userData.id,
                              });
                            }}
                          >
                            Delete
                          </button>
                        </p>
                      </div>
                    </div>
                  ))}
                </td>
                <td>
                Sub Total : 
                <span className="rupee"></span> 
                    {` ${this.subTotal(cartData)}`}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    );
  }
}


const s = ({ state }) => ({
  userData: appData.getUserData(state),
  cartSummary: selectors.getCartSummary(state),
  updateCartSuccessAlert: selectors.getUpdateCartSuccessAlert(state),
  updateCartFailureAlert: selectors.getUpdateCartFailureAlert(state),
  deleteCartSuccessAlert: selectors.getDeleteCartSuccessAlert(state),
  deleteCartFailureAlert: selectors.getDeleteCartFailureAlert(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
    },
    d
  ),
});
export default connect(s, d)(ExecutiveCartSummary);
