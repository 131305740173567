import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '../appSelector'

class DeleteUserModal extends Component {
    constructor(props) {
        super(props);
        this.deleteUser = this.deleteUser.bind(this);
        this.calldeleteUser = this.calldeleteUser.bind(this);
    }
    deleteUser() {
        this.calldeleteUser();
        this.props.onClose();
    }
    calldeleteUser() {
        var eachUserIdBody = {
                "userId": this.props.eachrowUserData.id
            }
        this.props.actions.removeUserFromAssociation({
            eachUserId: eachUserIdBody,
            jwt: this.props.userData.jwt
        })
    }
    render() {
        return (
            <Modal show={this.props.show}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Confirm Delete User from Association</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete the user?</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.deleteUser}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const s = ({ state }) => ({
    userData: appData.getUserData(state)
  })
  const d = (d) => ({
    actions: bindActionCreators(
      {
        ...modals.actions
      },
      d
    )
  })
export default connect(s,d)(DeleteUserModal);
