import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as videoStreamingData from '../redux-videoStreaming/selectors'
import * as appData from '../../appSelector';
import './CourseVideoView.css';
import { videoStreamingConf } from '../redux-videoStreaming/index';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion'
import VideoPlayer from '../video-player/VideoPlayer';

class CourseVideoContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            srcVideoObjectArray: [],
        };
        this.createSourceOption = this.createSourceOption.bind(this);
        this.setVideoJsOptions = this.setVideoJsOptions.bind(this);
    }

    setVideoJsOptions() {
        const videoJsOptions = {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            sources: this.state.srcVideoObjectArray
        };
        this.props.actions.setVideoJsOptions(
            videoJsOptions
        );
    }

    createSourceOption(sectionVideos) {
        this.state.srcVideoObjectArray = [];
        sectionVideos.courseVideos.map(courseVideos => {
            let srcObject = {
                src: courseVideos.courseVidDestinationS3url,
                type: 'application/x-mpegURL'
            };
            this.state.srcVideoObjectArray.push(srcObject);
        })
        this.setVideoJsOptions();
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-9">
                        <div>
                            {(this.props.videoJsOptions) &&
                                (<VideoPlayer {...this.props.videoJsOptions} />)}
                        </div>
                    </div>
                    <div className="col-3">
                        {this.props.getVideosByBookResourceId.sectionList &&
                            this.props.getVideosByBookResourceId.sectionList
                                .map((sectionList, sectionListIndex) => {
                                    return (
                                        <div role="button" key={sectionListIndex} eventKey={sectionListIndex + 1}>
                                            <Accordion eventKey={sectionListIndex}>
                                                <Card key={sectionListIndex}>
                                                    <Accordion.Toggle
                                                        eventKey={sectionListIndex + 1}
                                                        className="d-flex align-items-center justify-content-between"
                                                        as={Card.Header}
                                                        variant="link" >
                                                        <div>
                                                            <h4>{sectionList.sectionName}:{sectionList.courseSectionTitle}</h4>
                                                        </div>
                                                        <i className="fa fa-angle-down"></i>
                                                    </Accordion.Toggle>
                                                    {sectionList.sectionVideos.map((sectionVideos, sectionVideosIndex) => {
                                                        return (
                                                            <div key={sectionVideosIndex}>
                                                                <Accordion.Collapse eventKey={sectionListIndex + 1}>
                                                                    <Card.Body eventKey={sectionVideosIndex}>
                                                                        <button className="btn btn-light" onClick={() => this.createSourceOption(sectionVideos)} >
                                                                            {sectionVideos.videoTitle}
                                                                        </button>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </div>)
                                                    })
                                                    }
                                                </Card>
                                            </Accordion>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getVideosByBookResourceId: videoStreamingData.getVideosByBookResourceId(state),
    videoJsOptions: videoStreamingData.getVideoJsOptions(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(CourseVideoContent);
