import { Map, List } from 'immutable'

export const getBookByOrgUser = state => state.get('execOrgAssocData', '')

export const getAssignCoursesSchoolAdminData =  state => state.get('assignedCoursesSchoolAdminData', List())

export const getQuantity = state => state.get('quantity',0)

export const getInstituteDetails = state => state.getIn(['instituteDetailsData',0], Map())


export const isCalendarShown = state => state.get('isCalendarShown', false)

export const getOrderStatusSuccessAlertExec = state => state.get('addToCartStatusSuceessExec', false)
export const getOrderStatusErrorAlertExec = state => state.get('addToCartStatusErrorExec', false)

export const getaddToCartStatusSuceessExecMsg = state => state.get('addToCartStatusSuceessExecMsg', false)
export const getaddToCartStatusErrorExecMsg = state => state.get('addToCartStatusErrorExecMsg', false)