import React, { Component } from 'react'
import PublicFooter from "../layout/PublicFooter";
import AlertBox from '../common/AlertBox';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as selectors from './selectors'
import { loginn } from './index'

class ForgotUsername extends Component {

  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      errorStatus: false,
      errorMessage: '',
      successStatus: false,
      successMessage: ''
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      ...nextProps.forgotUsername
    }
  }

  onEmailSubmit = (e) => {
    const { emailId } = this.state;
    e.preventDefault();
    this.props.actions.emailSubmit(emailId);
    // fetch(`${APICONF.FORGOT_USERNAME}/${emailId}`).then(response => response.json())
    //   .then(data => {
    //     if (data.status === 'Success') {
    //       this.setState({
    //         errorStatus: false,
    //         successStatus: true,
    //         successMessage: 'An email has been sent with the username. Please check your email.'
    //       })
    //     } else {
    //       this.setState({
    //         errorStatus: true,
    //         errorMessage: data.status,
    //       })
    //     }
    //   }).catch(error => {
    //     this.setState({
    //       errorStatus: true,
    //       errorMessage: 'Something went wrong..! Please try after some time.'
    //     })
    //   })
  }
  render() {
    const { errorStatus, errorMessage, successStatus, successMessage } = this.state;
    return (
      <div className='forgot-password-page'>
        <form onSubmit={this.onEmailSubmit}>
          <div className="position-forgot-page">
            <AlertBox successAlert={successStatus} successMsg={successMessage} errorAlert={errorStatus} errorMsg={errorMessage} />
            <h4 className="h4FontColor-signup">Forgot Username</h4>
            <input id="emailId" name="emailId" className="transparentInputs-signup" type="email" placeholder="Enter Email" required onChange={this.handleInputChange} /><br />
            <button className="btn" type="submit">Find Account</button><br />
          </div>
        </form>
        <PublicFooter />
      </div>
    )
  }
}

const s = ({ state }) => ({
  forgotUsername: selectors.getForgotUsername(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions
    },
    d
  )
})

export default connect(s, d)(ForgotUsername)
