import { teacherinstorg } from '../teacherinstorg/index'
import { studentInstOrg } from '../studentinstorg/index'
import { student } from '../student/index'
import { teacheractions } from '../teacher/index'
import { modals } from '../modals/index'
import { common } from '../common/index'
import { assessmentModule } from './../assessmentModule/redux-assessmentModule/index'
import { loginn } from '../../src/login/index'
import { adminConf } from '../admin/redux-adminconf/index'
import { instOrgAdminConf } from '../instOrgAdmin/redux-instOrgAdminconf/index'
import { guest } from '../guest/redux-guest/index'
import { publicView } from '../publicview/redux-publicView/index'
import { executive } from '../executive/redux-executive/index'
import { orders } from '../order/redux-orders/index'
import { schoolAdmin } from '../schoolAdmin/redux-schoolAdmin/index'
import { profile } from '../profile/index';
import { videoStreamingConf } from '../videoStreaming/redux-videoStreaming/index'
import { parent } from '../parent/parent-redux/index'
import { parentinstorg } from '../parentinstorg/parentinst-redux/index'
import { attendanceConf } from '../attendance/redux-attendance/index'
const config = {
    ...teacherinstorg.restApi,
    ...studentInstOrg.restApi,
    ...modals.restApi,
    ...common.restApi,
    ...assessmentModule.restApi,
    ...loginn.restApi,
    ...adminConf.restApi,
    ...instOrgAdminConf.restApi,
    ...guest.restApi,
    ...publicView.restApi,
    ...executive.restApi,
    ...orders.restApi,
    ...schoolAdmin.restApi,
    ...student.restApi,
    ...teacheractions.restApi,
    ...profile.restApi,
    ...videoStreamingConf.restApi,
    ...parent.restApi,
    ...parentinstorg.restApi,
    ...attendanceConf.restApi
}

const middleware = config => store => next => action => {
    return config[action.type]
        ? next(action) &&
        store.dispatch({ ...action, type: `${action.type}_PENDING` }) &&
        config[action.type](store, action.payload, action.meta)
        : next(action)
}

export default {
    middleware,
    config
}