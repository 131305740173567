import { createReducer } from 'redux-act'
import { Map } from 'immutable'

import { teacherinstorg } from './teacherinstorg/index'
import { studentInstOrg } from './studentinstorg/index'
import { student } from './student/index'
import { teacheractions } from './teacher/index'
import { modals } from './modals/index'
import { common } from './common/index'
import { assessmentModule } from './assessmentModule/redux-assessmentModule/index'
import { loginn } from './login/index'
import { adminConf } from './admin/redux-adminconf/index'
import { instOrgAdminConf } from './instOrgAdmin/redux-instOrgAdminconf/index'
import { guest } from './guest/redux-guest/index'
import { publicView } from './publicview/redux-publicView/index'
import { executive } from './executive/redux-executive/index'
import { orders } from './order/redux-orders/index'
import { schoolAdmin } from './schoolAdmin/redux-schoolAdmin/index'
import { profile } from './profile/index'
import { videoStreamingConf } from './videoStreaming/redux-videoStreaming/index';
import { parent } from './parent/parent-redux/index';
import { parentinstorg } from './parentinstorg/parentinst-redux/index';
import { attendanceConf } from './attendance/redux-attendance/index'
const initState = Map()

const reducers = {
  ...teacherinstorg.reducer,
  ...studentInstOrg.reducer,
  ...modals.reducer,
  ...common.reducer,
  ...assessmentModule.reducer,
  ...loginn.reducer,
  ...adminConf.reducer,
  ...instOrgAdminConf.reducer,
  ...guest.reducer,
  ...publicView.reducer,
  ...executive.reducer,
  ...orders.reducer,
  ...schoolAdmin.reducer,
  ...student.reducer,
  ...teacheractions.reducer,
  ...profile.reducer,
  ...videoStreamingConf.reducer,
  ...parent.reducer,
  ...parentinstorg.reducer,
  ...attendanceConf.reducer
}

export default createReducer(reducers,initState)