import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { parent } from './parent-redux/index'
import * as appData from '../appSelector'
import * as parentsdata from './parent-redux/selectors'
import * as execData from '../executive/redux-executive/selector'
import Calendar from "../calendar";
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap';
import { executive } from '../executive/redux-executive';

class ParentHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.callgetAssignStudentToParent = this.callgetAssignStudentToParent.bind(this);
        this.handleStudentDashboard = this.handleStudentDashboard.bind(this);
    }

    componentDidMount() {
        this.callgetAssignStudentToParent()
    }

    callgetAssignStudentToParent(items) {
        this.props.actions.getAssignStudentToParent({
            jwt: this.props.userData.jwt,
            id: this.props.userData.id,
        })
    }

    handleStudentDashboard(studentData) {
        console.log("studentData", studentData)
        localStorage.setItem('selectedStudentData', JSON.stringify( studentData))
        this.props.history.push("/student-dashboard")
       // window.open("/student-dashboard")
    }
    render() {

        return (
            <div className=' m-5 '>
                <div className={`${this.props.isCalendarShown ? 'move-left' : ''}`}>
                    <div style={{
                        paddingBottom: '5px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer'
                    }}
                        onClick={() => this.props.actions.toggleCalendar(!this.props.isCalendarShown)}
                    >
                        <img
                            src={'https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png'}
                            alt="Calendar"
                        />
                    </div>
                    {this.props.getAssignStudentToParent.map((studentData, studentIndex) =>
                        <React.Fragment key={studentIndex}>
                            <div role="button">
                                <Accordion eventKey={studentIndex}>
                                    <Card key={studentIndex} className='mt-4'>
                                        <Accordion.Toggle
                                            eventKey={studentIndex + 1}
                                            className="d-flex align-items-center justify-content-between"
                                            as={Card.Header}
                                            variant="link" >
                                            <div>
                                                <b>First Name:&nbsp;{studentData.firstName}</b>&nbsp;&nbsp;
                                                <b>Last Name:&nbsp;{studentData.lastName} </b>&nbsp;&nbsp;
                                                <b>institute Name:&nbsp;{studentData.instIds}</b>&nbsp;&nbsp;
                                            </div>
                                            <i className="fa fa-angle-down"></i>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={studentIndex + 1}>
                                            <Card.Body eventKey={studentIndex}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <b>Student Details :</b>&nbsp;&nbsp;

                                                    </div>
                                                    <div className="col-5">
                                                        <div>
                                                            <div className="mt-1">
                                                                Student Name:&nbsp;{studentData.firstName + "" + studentData.lastName}
                                                            </div>
                                                            <div className="mt-1">
                                                                Student Id:&nbsp;{studentData.studentId}
                                                            </div>
                                                            <div className="mt-1">
                                                                Parent Id : {studentData.parentId}
                                                            </div>
                                                            <div className="mt-1">
                                                                institute Name :
                                                            </div>
                                                            <div className="mt-1">
                                                                Role :
                                                            </div>
                                                            <div className="mt-1">
                                                                E-mail :
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-7">
                                                        <div className="float-right">
                                                            <button className="btn btn-sm btn-warning ml-2 m-5" onClick={(e) => this.handleStudentDashboard(studentData)}  >
                                                                <i className="fa fa-external-link" aria-hidden="true" style={{ color: 'black' }} /> Student Dashboard
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                {
                    this.props.isCalendarShown &&
                    <div className="calender-wrapper">
                        <a className="calendar-external-icon" href="/calendar" target="_blank"><i className="fa-external-link fa" aria-hidden="true" style={{ color: 'black', fontFamily: "FontAwesome" }} /></a>
                        <Calendar callApiGetAPI={true} />
                    </div>
                }
            </div>
        );
    }
}
const s = ({ state }) => ({

    getSelectedStudent: parentsdata.getSelectedStudent(state),
    getAssignStudentToParent: parentsdata.getAssignStudentToParent(state),
    userData: appData.getUserData(state),
    isCalendarShown: execData.isCalendarShown(state)
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...parent.actions,
            ...executive.actions,

        },
        d
    )
})
export default connect(s, d)(ParentHome);