import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import PublicHeader from '../layout/PublicHeader';
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from 'history';
import Login from '../login/Login.js';
import Signup from '../login/Signup.js';
import ForgotPassword from '../login/ForgotPassword';
import ForgotUsername from '../login/ForgotUsername';
import NotFoundPage from '../common/404'
import PublicHome from '../publicview/PublicHome.js';

export default class PublicRouter extends Component {
  render() {
    return (
      <ConnectedRouter history={createBrowserHistory()}>
        <div>
        <PublicHeader></PublicHeader>
          <Switch>
          <Route exact path="/Logout">
              <Redirect to="/Login"/>
          </Route>
            <Route exact path="/" component={PublicHome} />
            <Route exact path="/Login" component={Login} />
            <Route exact path="/Signup" component={Signup} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/forgot-username" component={ForgotUsername} />
            {this.props.commonRoutes()}
            {!this.props.loggedIn &&
              <Route  exact path="/">
                <Redirect to="/Login" push />
              </Route>
            }
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </ConnectedRouter>
    )
  }
}
