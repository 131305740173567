import React from 'react';
import Pagination from './pagination';
import SearchBox from './SearchBox';

const WithPagination = ({ children, totalItems, itemsPerPage, currentPage, onSearch, onPaginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <SearchBox onSearch={onSearch}></SearchBox>
      {children}
      <Pagination itemsPerPage={itemsPerPage} totalItems={totalItems} currentPage={currentPage} paginate={onPaginate} />
    </>
  );
};

export default WithPagination;