import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import * as assessmentModuleData from '../../../redux-assessmentModule/selector'
import * as appData from '../../../../appSelector'
import ViewAllResultsLinks from './ViewAllResultsLinks';
import Accordion from 'react-bootstrap/Accordion'

class ViewAllResultsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
    this.callAllResultsByTestId = this.callAllResultsByTestId.bind(this);
  }

  componentDidMount(){
    this.callAllResultsByTestId()
  }
  callAllResultsByTestId(){
    const { eachTestId } = this.props.location.state
this.props.actions.getAllResultsByTestId({
  loginUserId: this.props.userData.id,
  eachTestId: eachTestId,
  jwt: this.props.userData.jwt
})
  }
  render() {
    const { eachViewEachAssessmentData } = this.props.location.state
    var showAllResults =  this.props.allResultsByTestId && this.props.allResultsByTestId.map((eachUserResultsData,i) => {
      return (
          <ViewAllResultsLinks index={i} key={i} eachUserResultsData ={eachUserResultsData} eachViewEachAssessmentData={eachViewEachAssessmentData}/>
      )
    })
return(
  <Accordion className="view-results-accordion">
  {showAllResults}
  </Accordion>
)
  }
}

const s = ({ state }) => ({
    allResultsByTestId : assessmentModuleData.getAllResultsByTestId(state),
    userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s,d)(ViewAllResultsPage);