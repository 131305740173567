import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class RefreshTokenModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>session timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {'Do you want to continue session'}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.no}>
            No
          </Button>
          <Button variant="primary" onClick={this.props.onGenerateNewToken}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default RefreshTokenModal;
