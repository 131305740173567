import React, {Component} from 'react';
import StandardEditableCellInstOrg from './StandardEditableCellInstOrg';
import './AddStandardInstOrg.css';
class StandardTableRowInstOrg extends Component {
    render() {
      return (
          <tr>
          <StandardEditableCellInstOrg 
            onStandardsTablesUpdate={this.props.onStandardsTablesUpdate} 
            cellData={{
              "type": "standardName",
              value: this.props.standard.standardName,
              id: this.props.standard.standardId
            }}
          />
          <td >
            <button 
              type="button" 
              onClick={
                () =>{ 
                  this.props.deleteStandard({
                    standard: this.props.standard
                  })
               }
              }  
              className="col-md-12 no-btn-padding btn btn-danger"
            >Delete
            </button>
           </td>
        </tr>
      );
    }
  }
  export default StandardTableRowInstOrg;