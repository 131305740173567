import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './Signup.css';
import 'react-data-grid/dist/react-data-grid.css';
import PublicFooter from "../layout/PublicFooter";
import { Map } from 'immutable'
import {
  inputNameValidator,
  inputValidatorForUserName,
  inputEmailValidator,
  inputPasswordValidator,
  checkNumbers
} from '../lib/inputValidator'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { loginn } from './index'
import * as selectors from './selectors'
import loginTask from './loginTask'

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      phoneNumber: null,
      inipassword: '',
      confpassword: '',
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
      userNameErrorMsg: '',
      emailErrorMsg: '',
      phoneErrorMsg: '',
      passwordErrorMsg: '',
      confpasswordErrorMsg: '',
      signUpError: ''
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleUserNameChange = this.handleUserNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleIniPassChange = this.handleIniPassChange.bind(this);
    this.handleConfirmPassChange = this.handleConfirmPassChange.bind(this);
    this.handleSignupSubmit = this.handleSignupSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      initialLoadButtonDisable: true
    })
  }
  handleFirstNameChange(evt) {
    const erorMsg = inputNameValidator(evt.target.value, 'First Name')
    this.setState({
      firstName: evt.target.value,
      firstNameErrorMsg: erorMsg,
    });
  };

  handleLastNameChange(evt) {
    const erorMsg = inputNameValidator(evt.target.value, 'Last Name')
    this.setState({
      lastName: evt.target.value,
      lastNameErrorMsg: erorMsg
    });
  };

  handleUserNameChange(evt) {
    const erorMsg = inputValidatorForUserName(evt.target.value)
    this.setState({
      userName: evt.target.value,
      userNameErrorMsg: erorMsg
    });
  };

  handleEmailChange(evt) {
    const erorMsg = inputEmailValidator(evt.target.value)
    this.setState({
      email: evt.target.value,
      emailErrorMsg: erorMsg
    });
  };
  handlePhoneChange(evt) {
    const erorMsg = checkNumbers(evt.target.value)
    this.setState({
      phoneNumber: evt.target.value,
      phoneErrorMsg: erorMsg
    });
  };

  handleIniPassChange(evt) {
    const erorMsg = inputPasswordValidator(evt.target.value)
    this.setState({
      inipassword: evt.target.value,
      passwordErrorMsg: erorMsg
    });
  }
  handleConfirmPassChange(evt) {
    this.setState({
      confpassword: evt.target.value,
      confpasswordErrorMsg: this.state.inipassword !== evt.target.value ? 'Please Enter matching passowrd' : ''
    });
  }

  handleSignupSubmit(event) {
    event.preventDefault();
    this.props.actions.signup({
      "firstName": this.state.firstName,
      "lastName": this.state.lastName,
      "username": this.state.userName,
      "email": this.state.email,
      "passWord": this.state.confpassword,
      "phoneNumber": this.state.phoneNumber,
        history:this.props.history
    });
  }

  render() {
    const signUp = Map({
      inputs:Map({
        firstNameErrorMsg: Map({
          value: this.state.firstNameErrorMsg,
          eachRowData: this.state.firstName
        }),
        lastNameErrorMsg:
          Map({
            value: this.state.lastNameErrorMsg,
            eachRowData: this.state.lastName
          }),
        userNameErrorMsg:
          Map({
            value: this.state.userNameErrorMsg,
            eachRowData: this.state.userName
          }),
        emailErrorMsg:
          Map({
            value: this.state.emailErrorMsg,
            eachRowData: this.state.email
          }),
        passwordErrorMsg:
          Map({
            value: this.state.passwordErrorMsg,
            eachRowData: this.state.inipassword
          }),
        phoneErrorMsg:
          Map({
            value: this.state.phoneErrorMsg,
            eachRowData: this.state.phoneNumber
          }),
        confpasswordErrorMsg:
          Map({
            value: this.state.confpasswordErrorMsg,
            eachRowData: this.state.confpassword
          }),
      })
    })
    const disbaled = signUp.get("inputs",Map()).filter((data, index)=>{
      return data.get('value','')!==''
    })
    const initialDisbaled = signUp.get("inputs",Map()).filter((data, index)=>{
      return data.get('eachRowData','')!==''
    })
    let signUpDisabled;
    if(disbaled.size>0){
      signUpDisabled = true
    }
    var initialDisable = this.state.initialLoadButtonDisable
    if(initialDisbaled.size === signUp.get("inputs",Map()).size){
      initialDisable = false;
    }

    return (
      <div>
        <div className='container-fluid m-2 p-5'>
          <div className='row'>
            <div className='col-lg-6 p-3'>
              <div className="positionSignup">
              </div>
            </div>
            <div className='col-lg-6 p-3'>
              <form ref={this.myForm} onSubmit={this.handleSignupSubmit}>

                <div className="float-right-signup ">
                  <h4 className="h4FontColor-signup">Signup for Durchere</h4>
                  {this.props.signUpError && (
                    <div className="h4FontColor-signupError">
                      {this.props.signUpError}
                    </div>
                  )}

                  {this.state.firstNameErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.firstNameErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="text"
                    placeholder="Enter First Name"
                    onChange={this.handleFirstNameChange}
                    required
                  />
                  <br />
                  {this.state.lastNameErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.lastNameErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={this.handleLastNameChange}
                    required
                  />
                  <br />
                  {this.state.userNameErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.userNameErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="text"
                    placeholder="Enter Username"
                    onChange={this.handleUserNameChange}
                    required
                  />
                  <br />
                  {this.state.emailErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.emailErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="text"
                    placeholder="Enter Email"
                    onChange={this.handleEmailChange}
                    required
                  />
                  <br />

                  {this.state.phoneErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.phoneErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="text"
                    placeholder="Enter Phone Number"
                    onChange={this.handlePhoneChange}
                    required
                  />
                  <br />
                  {this.state.passwordErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.passwordErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="password"
                    placeholder="Enter Password"
                    onChange={this.handleIniPassChange}
                    required
                  />
                  <br />
                  {this.state.confpasswordErrorMsg && (
                    <div className="h4FontColor-signupError">
                      {this.state.confpasswordErrorMsg}
                    </div>
                  )}
                  <input
                    className="transparentInputs-signup"
                    type="password"
                    placeholder="Confirm Password"
                    onChange={this.handleConfirmPassChange}
                    required
                  />
                  <br />
                  <br />
                  <br />
                  <Button
                    disabled={signUpDisabled || initialDisable}
                    type="submit"
                    className="signUpButton"
                  >
                    <span className="fontbuttoncolor">SignUp</span>
                  </Button>
                  <br />
                </div>



              </form>
            </div>
          </div>
          <div>


          </div>
        </div>
        <PublicFooter />
      </div>
    );
  }
}
const s = ({ state }) => {
  return {
    signupError: selectors.getSignupErrorMessage(state)
  }
}
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions,
      ...loginTask.actions
    },
    d
  )
})
export default connect(s, d)(Signup);
