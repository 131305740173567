import axios from 'axios'
import APICONF from '../../config/ApiConfig.js';

export const  getGuestHomeBooks = (action) => async (
    { dispatch } ,
    { jwt }
    ) => {
    const getBooksUrl = APICONF.GET_PUBLIC_BOOKS;
    const [err, result] = await axios 
         .get(getBooksUrl, {
            headers: {
                "authorization": jwt
            }
         })
         .then(res => [null,res.data])
         .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
    }