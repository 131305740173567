import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { common } from './index'
import * as userData from '../appSelector'
import AssignParentToStudentModal from '../modals/AssignParentToStudentModal';

class AssignParentToStudent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            modalassignType: false,
            defaultSelectedBooks: []
        }
        this.openAssignParentModal = this.openAssignParentModal.bind(this);
        this.closeAssignParentModal = this.closeAssignParentModal.bind(this);
        this.defaultparentNames = this.defaultparentNames.bind(this);
    }
    closeAssignParentModal() {
        this.setState({ show: false });
    }
    openAssignParentModal() {
        this.defaultparentNames()
        this.setState({
            show: true,
            modalassignType: true
        });
    }
    defaultparentNames() {
        this.props.actions.getAssignParentToStudentByStudentId({

            jwt: this.props.userData.jwt,
            studentId: this.props.data.id,

        })

    }

    render() {
        console.log("this.props.data", this.props.data);
        return (

            (this.state.modalassignType) ? (
                !["72195", "79126", "79146", "75126"].includes(this.props.data.roleId) &&
                <div>
                    <AssignParentToStudentModal
                        eachrowUserData={this.props.data}
                        show={this.state.show}
                        onClose={this.closeAssignParentModal}
                    />
                    <button className="btn btn-secondary" onClick={() => this.openAssignParentModal()}>
                        Assign Parent
                    </button>
                </div>) : (
                !["72195", "79126", "79146", "75126"].includes(this.props.data.roleId) &&
                <button className="btn btn-secondary" onClick={() => this.openAssignParentModal()}>
                    Assign Parent
                </button>
            )

        )
    }
}

const s = ({ state }) => ({
    userData: userData.getUserData(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...common.actions
        },
        d
    )
})

export default connect(s, d)(AssignParentToStudent);