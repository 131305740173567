import axios from "axios";
import APICONF from "../../config/ApiConfig.js";

import { PAYMENT_KEY_GENERATED_FROM_DASHBOARD } from '../../config/ApiConfig'

import { List, Map } from "immutable";

import orders from './orders'

export const getCartSummaryByUserId = (action) => async (
  { dispatch },
  { userId, jwt }
) => {
  const getBooksUrl = APICONF.GET_CART_ITEMS_BY_USER_ID + "/" + userId;
  const [err, result] = await axios
    .get(getBooksUrl, {
      headers: {
        authorization: jwt,
      },
    })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
};

export const getGuestCartSummaryByUserId = (action) => async (
  { dispatch },
  { userId, jwt }
) => {
  const getBooksUrl = APICONF.GET_GUEST_CART_ITEMS_BY_USER_ID + "/" + userId;
  const [err, result] = await axios
    .get(getBooksUrl, {
      headers: {
        authorization: jwt,
      },
    })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
};

export const addToGuestCart = (action) => async (
  { dispatch },
  { data , jwt }
) => {
  const guestCart = APICONF.GUEST_CART
  const [err, result] = await axios({
    method: 'post',
    url: guestCart,
    data,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch) && new Promise((resolve, reject) => {
    setTimeout(() =>  {
        resolve(
            dispatch(orders.actions.resetAddToCartAlerts()) 
        )
    } 
    , 3000
    )
})
}

const  loadScript =  (src) =>  {
  return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {

          resolve(true);
      };
      script.onerror = () => {
          resolve(false);
      };
      document.body.appendChild(script);
  });
}

export const loadRazoPayScript = (action) => async (
  { dispatch },
  {src}
) => {
  const [err, result] = await loadScript(src)
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}




export const paymentSuccess = (action) => async (
  { dispatch },
  {responseData, userId,cartId, jwt, shippingId}
) => {
  const savePayment = APICONF.SAVE_PAYMENT
  console.log("::::", responseData)
  const [err, result] = await axios({
    method: 'post',
    url: savePayment,
    data: {
      userId,
      cartId,
      razorpayPaymentId: responseData.razorpayPaymentId,
      razorpayOrderId: responseData.razorpayOrderId,
      razorpaySignature: responseData.razorpaySignature,
      shippingId
    },
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}



export const paymentOrders = (action) => async (
  { dispatch },
  {
    userId,
    jwt,
    cartId,
    username
  }
) => {
  
  const [err, result] = await axios({
    method: 'get',
    headers: {
      authorization: jwt,
    },
    url: `${APICONF.CREATE_ORDER_BY_CART_ID}/${cartId}/${userId}`
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])

  let options = {}
  if(result) {
     options = {
      key: PAYMENT_KEY_GENERATED_FROM_DASHBOARD,
      // APICONF.PAYMENT_KEY_GENERATED_FROM_DASHBOARD, // Enter the Key ID generated from the Dashboard
      amount: result.data.amount,
      currency: "INR",
      name: username,
      description: "Test Transaction",
      image: "https://www.durchere.com/favicon.ico",
      order_id: result.data.id,
      // prefill: {
      //     name: username,
      //     email: "SoumyaDey@example.com",
      //     contact: "9999999999",
      // },
      theme: {
          color: "#61dafb",
      },
  }
  }
  const actionToDispatch = err ? action.failure(err) : action.success({...result,options, cartId})
  return dispatch(actionToDispatch)
}




export const openPaymentUI = (action) => async (
  { dispatch },
  {
    userId,
    jwt,
    cartId
  }
) => {
  
  const [err, result] = await axios({
    method: 'get',
    headers: {
      authorization: jwt,
    },
    url: `${APICONF.CREATE_ORDER_BY_CART_ID}/${cartId}/${userId}`
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const updateCart = (action) => async (
  { dispatch, getState },
  { jwt, cartDetailsId, instituteIndex, bookIndex, user }
) => {
  const updatedBooksUrl = APICONF.UPDATE_CART_ITEMS;
  const instId = getState()
    .state.getIn(["cartSummary", "data", 'instituteDtls', instituteIndex,"instId"],"")


  const userId = getState()
  .state.getIn(["cartSummary", "data", "userId"],"")

  const bookPrice = getState()
    .state.getIn(
      [
        "cartSummary",
        "data",
        'instituteDtls',
        instituteIndex,
        "bookDtls",
        bookIndex,
        "bookPrice"
      ],
      ""
      )

  const bookId = getState()
    .state.getIn(
      [
        "cartSummary",
        "data",
        'instituteDtls',
        instituteIndex,
        "bookDtls",
        bookIndex,
        "bookId"
      ],
      ""
      )

  let  quantity = getState()
    .state.getIn(
      [
        "cartSummary",
        "data",
        'instituteDtls',
        instituteIndex,
        "bookDtls",
        bookIndex,
        "quantity"
      ],
      ""
      )

      quantity = parseInt(quantity)

  const totalAmount = bookPrice * quantity

  const [err, result] = await axios({
    method: "post",
    url: updatedBooksUrl,
    headers: {
      authorization: jwt,
    },
    data: {
      cartDetailsId,
      instId,
      bookId,
      bookPrice,
      quantity,
      totalAmount,
      userId
    },
  })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success({
    ...result,
    instituteIndex,
    bookIndex
  });
  return (
    dispatch(actionToDispatch) &&
    dispatch(orders.actions.getCartSummaryByUserId({ userId: user, jwt })) &&
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(dispatch(orders.actions.resestUpdatedCartAlerts(bookIndex)));
      }, 3000);
    })
  );
};

export const deleteCart = (action) => async (
  { dispatch, getState },
  {
    jwt,
    cartDetailsId,
    instituteIndex,
    bookIndex,
    user
  }
) => {
  const updatedBooksUrl = APICONF.DELETE_CART_ITEMS;

  const [err, result] = await axios({
    method: "delete",
    url: `${updatedBooksUrl}/${cartDetailsId}`,
    headers: {
      authorization: jwt,
    },
  })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success({
      ...result,
      cartDetailsId,
      instituteIndex,
      bookIndex
  });
  if(err){
    return dispatch(actionToDispatch) &&
    new Promise((resolve, reject) => {
      setTimeout(() =>  {
          resolve(
              dispatch(orders.actions.resetDeleteCartAlerts()) 
          )
      } 
      , 3000
      )
  })
  }else{
    return dispatch(actionToDispatch) &&
    dispatch(orders.actions.getCartSummaryByUserId({userId: user, jwt})) &&
    new Promise((resolve, reject) => {
      setTimeout(() =>  {
          resolve(
              dispatch(orders.actions.resetDeleteCartAlerts()) 
          )
      } 
      , 3000
      )
  })

  }

};

export const updateGuestCart = (action) => async (
  { dispatch, getState },
  { jwt, cartDetailsId, bookIndex, user }
) => {
  const updatedBooksUrl = APICONF.UPDATE_GUEST_CART_ITEMS;
  
  const userId = getState()
    .state.getIn(["guestCartSummary", "data", "userId"], "")

  const bookPrice = getState()
    .state.getIn(
      [
        "guestCartSummary",
        "data",
        "bookDtls",
        bookIndex,
        "bookPrice"
      ],
      ""
    )

  const bookId = getState()
    .state.getIn(
      ["guestCartSummary", "data", 'bookDtls', bookIndex, "bookId"], "")

  let quantity = getState()
    .state.getIn(
      [
        "guestCartSummary",
        "data",
        "bookDtls",
        bookIndex,
        "quantity"
      ],
      ""
    )

  quantity = parseInt(quantity)

  const totalAmount = bookPrice * quantity

  const [err, result] = await axios({
    method: "post",
    url: updatedBooksUrl,
    headers: {
      authorization: jwt,
    },
    data: {
      cartDetailsId,
      bookId,
      bookPrice,
      quantity,
      totalAmount,
      userId
    },
  })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure({
    ...err,
    bookIndex
  }) : action.success({
    ...result,
    bookIndex
  });
  return dispatch(actionToDispatch) && 
  dispatch(orders.actions.getGuestCartSummaryByUserId({ userId: user, jwt })) &&
  new Promise((resolve, reject) => {
    setTimeout(() =>  {
        resolve(
            dispatch(orders.actions.resestUpdatedCartAlerts(bookIndex)) 
        )
    } 
    , 3000
    )
})
};

export const deleteGuestCart = (action) => async (
  { dispatch, getState },
  {
    jwt,
    cartDetailsId,
    instituteIndex,
    bookIndex,
    user
  }
) => {
  const deleteBooksUrl = APICONF.DELETE_GUEST_CART_ITEMS;

  const [err, result] = await axios({
    method: "delete",
    url: `${deleteBooksUrl}/${cartDetailsId}`,
    headers: {
      authorization: jwt,
    },
  })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure({
    ...err,
    bookIndex
  }) : action.success({
    ...result,
    cartDetailsId,
    bookIndex
  });
  if (err) {
    return dispatch(actionToDispatch) &&
    new Promise((resolve, reject) => {
      setTimeout(() =>  {
          resolve(
              dispatch(orders.actions.resetDeleteCartAlerts()) 
          )
      } 
      , 3000
      )
  })
  } else {
    return dispatch(actionToDispatch) &&
      dispatch(orders.actions.getGuestCartSummaryByUserId({ userId: user, jwt })) &&
      new Promise((resolve, reject) => {
        setTimeout(() =>  {
            resolve(
                dispatch(orders.actions.resetDeleteCartAlerts()) 
            )
        } 
        , 3000
        )
    })

  }

};

export const ordersBooks = (action) => async (
  { dispatch, getState },
  { jwt }
) => {
  const postOrdersRequestUrl = APICONF.ORDER_REQUEST;
  const getCartSummaryDetails = getState().state.getIn(['cartSummary','data', 'instituteDtls'],List())
  const userId = getState().state.getIn(['cartSummary','data', 'userId'],'')
  const cartId = getState().state.getIn(['cartSummary','data', 'cartId'],'')
  const instShippingDetails = getState().state.getIn(['instShipArray'],List())
  const userData = getState().state.get('userData', '');
  const roleId = userData.roleId
  const intitutesOrderData = getCartSummaryDetails.map(
    institute => {
      let indInstGrandTotal = 0
      const indInstorderData = institute.get('bookDtls',List()).map(
        book => {
          indInstGrandTotal = indInstGrandTotal + book.get('totalAmount')
          return {
              bookPrice: book.get('bookPrice',''),
              bookId:  book.get('bookId',''),
              bookName: book.get('bookTitle',''),
              eachQuantityTotal: book.get('totalAmount',''),
              quantity: book.get('quantity','')
          }
        }
      )
      let shippingId = 0
      if(["76517", "72195", "72138"].includes(roleId)){
        shippingId = Number(getState().state.get('shippingId',''))
      }else{
        let shippingInfo = instShippingDetails.filter(currentShipData=>currentShipData.get("instId","") === institute.get('instId',''))
        if(shippingInfo !== undefined){
          shippingInfo.map(shippingData=>{
            shippingId = shippingData.get("selectedShippingId","")
          })
      }
      }

      return {
        instId: institute.get('instId',''),
        instituteName: institute.get('instName',''),
        indInstorderData,
        indInstGrandTotal,
        shippingId
      }
    }
  )
  const [err, result] = await axios({
    method: 'post',
    url: postOrdersRequestUrl,
    headers: {
      authorization: jwt,
    },
    data: {
      userId,
      cartId,
      intitutesOrderData
    }
  })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
};



export const orderBooksForGuest = (action) => async (
  { dispatch, getState },
  { jwt }
) => {
  const postOrdersRequestUrl = APICONF.ORDER_BOOKS_FOR_GUEST;
  const getCartSummaryDetails = getState().state.getIn(['guestCartSummary','data'],List())
  const userId = getState().state.getIn(['guestCartSummary','data', 'userId'],'')
  const cartGuestId = getState().state.getIn(['guestCartSummary','data', 'cartGuestId'],'')
  
  const grandTotal = getState().state.getIn(['guestCartSummary','data', 'cartGrandTotal'],'')
  const shippingId = Number(getState().state.get('shippingId',''))

  const orderData = getCartSummaryDetails.get('bookDtls', List()).map(
    book => {
      return {
        bookPrice: book.get('bookPrice',''),
        bookId:  book.get('bookId',''),
        bookName: book.get('bookTitle',''),
        quantityTotal: book.get('totalAmount',''),
        quantity: book.get('quantity','')
      }
    }
  )
  const [err, result] = await axios({
    method: 'post',
    url: postOrdersRequestUrl,
    headers: {
      authorization: jwt,
    },
    data: {
      userId,
      cartGuestId,
      orderData,
      grandTotal,
      shippingId
    }
  })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
};







export const getUserAddressDetails = (action) => async (
  { dispatch },
  { userId, jwt }
) => {
  const getUserAddressUrl = APICONF.USER_ADDRESS_DETAILS + "/" + userId;
  const [err, result] = await axios
    .get(getUserAddressUrl, {
      headers: {
        authorization: jwt,
      },
    })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
};




export const getShippingAddressForInstitute = (action) => async (
  { dispatch, getState },
  { instId }
) => {
  const userId = getState().state.getIn(['userData','id'],'')
  const jwt = getState().state.getIn(['userData','jwt'],'')
  const getInstAddressUrl = `${APICONF.INST_ADDRESS_DETAILS}/${userId}/${instId}` 
  const [err, result] = await axios
    .get(getInstAddressUrl, {
      headers: {
        authorization: jwt,
      },
    })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
  const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
};



export const getAllOrderDetailsByUserId = (action) => async (
  { dispatch, getState }
) => {
  const userId = getState().state.getIn(['userData','id'],'')
  const jwt = getState().state.getIn(['userData','jwt'],'')
  const selectedYear = getState().state.get('selectedOrderHistoryYear',{value:0}).value
  const getAllOrderDetails = `${APICONF.GET_ALL_ORDER_DETAILS}/${userId}/${selectedYear}`;
  if(userId !==''){
    const [err, result] = await axios
    .get(getAllOrderDetails, {
      headers: {
        authorization: jwt,
      },
    })
    .then((res) => [null, res.data])
    .catch((err) => [err, null]);
    const actionToDispatch = err ? action.failure(err) : action.success(result);
  return dispatch(actionToDispatch);
  }
};
