import React, { Component } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../publicview/Public.css';
import { Link } from 'react-router-dom';

class OrgBooksRenderer extends Component {
    render() {
        const limitedBooks = this.props.organization.booksData.map((book, index) => {
            if (index < 4) {
                return <div key={book.bookId} className="book-card">
                    <Link to={{ pathname: '/PublicOrders', state: { bookId: book.bookId, bookData: book, role: 'public' } }} className="book-Card-link">
                        <div className="book-card-image-holder">
                            <div style={{ backgroundImage: `url(${book.imageappLocationOrg})` }} className="book-card-image" />
                        </div>
                        <div className="book-card-name">{book.bookTitle}
                        </div>
                        <div className="book-card-price">&#8377; {book.price}</div>
                    </Link>
                </div>
            }
        });
        const allBooks = this.props.organization.booksData.map((book) => {
            return <div key={book.bookId} className="book-card">
                <Link to={{ pathname: '/PublicOrders', state: { bookId: book.bookId, bookData: book, role: 'public' } }} className="book-Card-link">
                    <div className="book-card-image-holder">
                        <div style={{ backgroundImage: `url(${book.imageappLocationOrg})` }} className="book-card-image" />
                    </div>
                    <div className="book-card-name">{book.bookTitle}
                    </div>
                    <div className="book-card-price">&#8377; {book.price}</div>
                </Link>
            </div>
        });
        return (
            <div>
                <Row className="row-height">
                    <Col>
                        <div className="org-header">

                            <p><span className="org-name">{this.props.organization.organizationName}</span></p>
                            {!(this.props.showAll) ?
                                <span onClick={this.props.onSeeAll.bind(this, this.props.organization.orgId)} className="float-right see-all-link">See all
                                </span> : ''}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>{(this.props.showAll) ? allBooks : limitedBooks}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default OrgBooksRenderer;

