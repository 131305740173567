import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '.././appSelector';

class AddSectionmodal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enteredcourseSectionTitle: '',
      enteredcourseSectionName: ''
    }
    this.addSectionetails = this.addSectionetails.bind(this);
    this.callAddSectionApi = this.callAddSectionApi.bind(this);
    this.closeAddSectionmodal = this.closeAddSectionmodal.bind(this);
  }

  handleCourseSectionTitle = event => {
    this.setState({
      enteredcourseSectionTitle: event.target.value,
    })
  }

  handleCourseSectionName = event => {
    this.setState({
      enteredcourseSectionName: event.target.value,
    })
  }
  addSectionetails = () =>{
    this.callAddSectionApi();
    this.closeAddSectionmodal();
  }
   callAddSectionApi = () =>{
    var coursesectionData = {
      "courseSectionName": this.state.enteredcourseSectionName,
      "courseSectionTitle": this.state.enteredcourseSectionTitle,
      "userId": this.props.userData.id
    }
    this.props.actions.addCourseSection({
      formData: coursesectionData,
      jwt: this.props.userData.jwt
    })
  }
  closeAddSectionmodal = () =>{
    this.props.actions.closeAddSectionmodal();
  }
  
  render() {
    return (
      <div>
        <Modal show={this.props.show}>
          <Modal.Header>
            <Modal.Title>Add Section </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Section Name: </label>
              </div>
              <div className="col-md-8">
                <input type="text" onChange={this.handleCourseSectionName} placeholder="Enter Section Name" className="css-yk16xz-control" required />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Section Title: </label>
              </div>
              <div className="col-md-8">
                <input type="text" onChange={this.handleCourseSectionTitle} placeholder=" Enter Section Title" className="css-yk16xz-control" required />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger btn-sm btn-responsive" onClick={this.closeAddSectionmodal}>Close</button>
            <button className="btn btn-primary btn-sm btn-responsive" onClick={this.addSectionetails}>Add</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state)

})
const d = (d) => ({
  actions: bindActionCreators(
    {
       ...modals.actions
    },
    d
  )
})
export default connect(s, d)(AddSectionmodal);