import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import './startAssessment.css'
import * as selectors from '../../../redux-assessmentModule/selector'
import { List } from 'immutable'
import TestHeaderView from '../../viewAssessment/teacher/TestHeaderView'
import { Navbar } from "react-bootstrap";
import durchereLogoText from '../../../../assets/logo/durchere_text_logo.png';
import MathJax from 'react-mathjax-preview'
import EndAssessmentModal from '../../../../modals/EndAssessmentModal'
import EndAssessmentTimerEnd from '../../../../modals/EndAssessmentTimerEnd'
import * as appData from '../../../../appSelector'
const generateInputBaseOnType = (inputType, props) => {
  const inputTypes = {
    1: props => {
      const {
        question,
        answer,
        marksForQuestion,
        testQuestionnaireId,
        testAnswerId,
        correctOption,
        answerOption,
        setResultsOfStudent,
        getCurrentAnswer,
        setCurrentAnswer,
      } = props
      return (
        <div
          className="options-wrapper-start-assessment"
          key={question}
          onChange={() => (
            setCurrentAnswer(
              {
                testQuestionnaireId, value: answerOption
              }
            ) &&
            setResultsOfStudent({
              marksForQuestion,
              testQuestionnaireId,
              testAnswerId,
              correctOption,
              answerOption,
              inputType
            })
          )
          }
        >
          <label className="checkbox-inline">
            <input
              name={inputType}
              id={answer}
              value={answer}
              type="radio"
              checked={getCurrentAnswer(testQuestionnaireId) === question}
            />
            {question}
          </label>
        </div>
      )
    },
    0: props => {
      const {
        marksForQuestion,
        testQuestionnaireId,
        testAnswerId,
        correctOption,
        answerOption,
        setCureentInputValue,
        setCurrentAnswer,
        getCurrentAnswer,
        setResultsOfStudent
      } = props

      return (
        <div className="options-wrapper-start-assessment">
          <input
            type='text'
            placeholder='Answer'
            value={getCurrentAnswer(testQuestionnaireId)}
            onChange={(e) => (
              setCureentInputValue(e.target.value) &&
              setCurrentAnswer(
                {
                  testQuestionnaireId, value: e.target.value
                }
              ) &&
              setResultsOfStudent(
                {
                  marksForQuestion,
                  testQuestionnaireId,
                  testAnswerId,
                  correctOption,
                  answerOption,
                  inputType
                }
              )
            )
            }
          >
          </input>
        </div>
      )
    }
  }
  return inputTypes[inputType](props)
}
const InputGenerator = props => {
  const { inputType, ...restProps } = props
  return generateInputBaseOnType(inputType, restProps)
}
class StartAssessment extends Component {
  constructor(props) {
    super(props)
    this.timerId = null;
    this.timerRef = React.createRef();
    this.endAssessmentHandler = this.endAssessmentHandler.bind(this)
  }
  componentDidMount() {
    var globalTestId = localStorage.getItem('eachTestId');
    this.props.actions.startAssessment({
      jwt: this.props.userData.jwt,
      testId: globalTestId,
      userId: this.props.userData.id
    });
    var globalEachTestData = JSON.parse(localStorage.getItem('eachTestData'))
    this.startTimer(+globalEachTestData.testDuration*60);
    // this.startTimer(.05*60);
    window.onbeforeunload = function() {
      // eslint-disable-next-line no-restricted-globals
      return confirm("Your Assessment will be closed and your answers wont be changed Are you sure to leave the Assessment?");
      //this.endAssessmentHandler(); ///make sure it works
    }
    document.onkeydown = function(e) {
      if(e.key === "F12") {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && (e.key === 'i' || e.key === 'I')) {
        return false;
      }
      if(e.ctrlKey && e.shiftKey && (e.key === 'c' || e.key === 'C')) {
        return false;
      }
      if(e.ctrlKey && e.shiftKey && (e.key === 'j' || e.key === 'J')) {
        return false;
      }
      if(e.ctrlKey && (e.key === 'u' || e.key === 'U')) {
        return false;
      }
    }
    document.addEventListener('contextmenu', e => e.preventDefault());
  }


  startTimer = (duration) => {
    var timer = duration, minutes, seconds;
    this.timerId = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.timerRef.current.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            clearInterval(this.timerId);
            this.closeWindow();
        }
    }, 1000);
  }

  closeWindow(){
    this.props.actions.endAssessmentTimerEndModal()
  }
  endAssessmentHandler(){
    this.props.actions.endAssessmentModal()
  }

  render() {
    const passageIndex = this.props.getPassageIndexOfStartAssessment
    const questionsIndex = this.props.getQuestionsStartAssessmentIndex
    var globalEachTestData = JSON.parse(localStorage.getItem('eachTestData'))
    return (
      <React.Fragment>
        {
          this.props.endAssessmentModal &&
          (<EndAssessmentModal show={this.props.endAssessmentModal}/>)
        }
        {
          this.props.endAssessmentTimerEndModal &&
          (<EndAssessmentTimerEnd show={this.props.endAssessmentTimerEndModal}/>)
        }
        <Navbar expand="lg" bg="dark" className="navbar navbar-expand-md navbar-dark fixed-top">
          <Navbar.Brand href="/">
            <img alt="durchere logo" src={durchereLogoText} className="durchere-logo" />
          </Navbar.Brand>
        </Navbar>
        <div className="container top-margin-window-start-assessment-online">
          <div className="test-header-wrapper d-flex">
            <TestHeaderView eachTestData={globalEachTestData} />
            <div className="header-right">

            <div className="text-center">
              <button className="btn btn-primary m-auto" type="button" onClick={ () => (this.endAssessmentHandler())}>End Assessment</button>
              </div>
              <div className="timer">
                Time Left: <span ref={this.timerRef} id="time"></span>
              </div>
            </div>
          </div>
          <div className="start-assessment-wrapper col-md-12">
            {
              (!this.props.showQuestionsForParaGraphStartAssessment)
              && (!this.props.showregularQuestionsStartAssessment)
              && (this.props.getQuestionsStartAssessment.get('passages', List()).count() > 0)
              && (
                <div>
                  <div>
                    <b>Passage Title :</b>{this.props.getQuestionsStartAssessment.getIn(['passages', passageIndex, 'testPassage', 'passageTitle'], '')}
                  </div>
                  <div className="top-margin-start-assessment p-3">
                  <MathJax math={this.props.getQuestionsStartAssessment.getIn(['passages', passageIndex, 'testPassage', 'passageText'], '')} />
                  </div>
                  <div className="top-margin-start-assessment">
                    <button
                      className="col-md-12 no-btn-padding btn btn-success"
                      type="button"
                      onClick={this.props.actions.showQuestionsForParaGraphStartAssessment}
                    >
                      Next
              </button>
                  </div>
                </div>
              )
            }
            {
              (this.props.showQuestionsForParaGraphStartAssessment) && (!this.props.showregularQuestionsStartAssessment) && (
                <React.Fragment>
                  <div className="question-wrapper">
                  <MathJax math={this.props.getQuestionsStartAssessment
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionStartAssessmentIndex,
                              'testQuestion',
                              'questionText'
                            ], '')}/>
                    <div>
                      {
                        this.props.getQuestionsStartAssessment
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionStartAssessmentIndex,
                              'testQuestion',
                              'marksForQuestion'
                            ], '')
                      } Points
                    </div>
                  </div>
                  <div className="p-3">
                    {this.props.getQuestionsStartAssessment
                      .getIn(
                        [
                          'passages',
                          passageIndex,
                          'passagesQuestions',
                          this.props.getCurrentQuestionStartAssessmentIndex,
                          'testAnswerOptions'
                        ], List()
                      ).map(currentAnswer =>
                        <InputGenerator
                          inputType={
                            this.props.getQuestionsStartAssessment
                              .getIn(
                                [
                                  'passages',
                                  passageIndex,
                                  'passagesQuestions',
                                  this.props.getCurrentQuestionStartAssessmentIndex,
                                  'testQuestion',
                                  'questionCategory'
                                ], '')
                          }
                          marksForQuestion={
                            this.props.getQuestionsStartAssessment
                              .getIn(
                                [
                                  'passages',
                                  passageIndex,
                                  'passagesQuestions',
                                  this.props.getCurrentQuestionStartAssessmentIndex,
                                  'testQuestion',
                                  'marksForQuestion'
                                ], '')
                          }
                          correctOption={
                            currentAnswer.get('correctOption', '')
                          }
                          testQuestionnaireId={
                            currentAnswer.get('testQuestionnaireId', '')
                          }
                          testAnswerId={
                            currentAnswer.get('testAnswerId', '')
                          }
                          question={
                            currentAnswer.get('answerOption', '')
                          }
                          answer={
                            currentAnswer.get('testAnswerId', '')
                          }
                          answerOption={
                            currentAnswer.get('answerOption', '')
                          }
                          setCureentInputValue={
                            this.props.actions.setCureentInputValue
                          }
                          setCurrentAnswer={
                            this.props.actions.setCurrentAnswer
                          }
                          getCurrentAnswer={
                            this.props.getCurrentAnswer
                          }
                          setResultsOfStudent={
                            this.props.actions.setResultsOfStudent
                          }
                        />)
                    }
                    <div className="row d-flex justify-content-between">
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => this.props.actions.currentQuestionIndexStartAssessment(
                          {
                            currentIndex: this.props.getCurrentQuestionStartAssessmentIndex,
                            isDecrease: true
                          }
                        )}
                      >
                        Prev question
              </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => this.props.actions.currentQuestionIndexStartAssessment(
                          {
                            currentIndex: this.props.getCurrentQuestionStartAssessmentIndex,
                            isDecrease: false
                          }
                        )}
                      >
                        Next question
              </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            }
            {(this.props.showOnlyRegularQuestions || this.props.showregularQuestionsStartAssessment)
              && (this.props.getQuestionsStartAssessment.get('questions', List()).count() > 0)
              && (
                <React.Fragment>
                  <div className="question-wrapper">
                  <MathJax math={this.props.getQuestionsStartAssessment.getIn(['questions', questionsIndex, 'testQuestion', 'questionText'], '')} />
                    <div>
                      {this.props.getQuestionsStartAssessment
                        .getIn(
                          [
                            'questions', questionsIndex, 'testQuestion', 'marksForQuestion'
                          ], '')
                      } Points
                    </div>
                  </div>
                  <div className="p-3">
                    {
                      this.props.getQuestionsStartAssessment.getIn(
                        [
                          'questions',
                          questionsIndex,
                          'testAnswerOptions'
                        ], List()
                      ).map(
                        (currentQuestion, index) =>
                          <InputGenerator
                            inputType={
                              this.props.getQuestionsStartAssessment
                                .getIn(
                                  [
                                    'questions', questionsIndex, 'testQuestion', 'questionCategory'
                                  ], '')
                            }
                            question={
                              currentQuestion.get('answerOption', '')
                            }
                            answer={
                              currentQuestion.get('testAnswerId', '')
                            }
                            correctOption={
                              currentQuestion.get('correctOption', '')
                            }
                            testQuestionnaireId={
                              currentQuestion.get('testQuestionnaireId', '')
                            }
                            testAnswerId={
                              currentQuestion.get('testAnswerId', '')
                            }
                            marksForQuestion={
                              this.props.getQuestionsStartAssessment
                                .getIn(
                                  [
                                    'questions', questionsIndex, 'testQuestion', 'marksForQuestion'
                                  ], '')
                            }
                            index={index}
                            answerOption={
                              currentQuestion.get('answerOption', '')
                            }
                            setCureentInputValue={
                              this.props.actions.setCureentInputValue
                            }
                            setCurrentAnswer={
                              this.props.actions.setCurrentAnswer
                            }
                            getCurrentAnswer={
                              this.props.getCurrentAnswer
                            }
                            setResultsOfStudent={
                              this.props.actions.setResultsOfStudent
                            }
                          />)
                    }
                    <div className="row d-flex justify-content-between">
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => this.props.actions.questionsIndexStartAssessment(
                          {
                            regularQuestionIndex: this.props.getQuestionsStartAssessmentIndex - 1
                          }
                        )}
                      >
                        Prev question
              </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => this.props.actions.questionsIndexStartAssessment(
                          {
                            regularQuestionIndex: this.props.getQuestionsStartAssessmentIndex + 1
                          }
                        )}
                      >
                        Next question
              </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const s = ({ state }) => ({
  getQuestionsStartAssessment: selectors.questionsOfStartAssessment(state),
  getPassageIndexOfStartAssessment: selectors.passageIndexOfStartAssessment(state),
  getCurrentQuestionStartAssessmentIndex: selectors.currentQuestionStartAssessmentIndex(state),
  showQuestionsForParaGraphStartAssessment: selectors.showQuestionsForParaGraphStartAssessment(state),
  getQuestionsStartAssessmentIndex: selectors.questionsStartAssessmentIndex(state),
  showregularQuestionsStartAssessment: selectors.showregularQuestionsStartAssessment(state),
  getCurrentAnswer: selectors.getCurrentAnswer(state),
  endAssessmentModal: selectors.endAssessmentModal(state),
  endAssessmentTimerEndModal: selectors.endAssessmentTimerEndModal(state),
  userData: appData.getUserData(state),
  showOnlyRegularQuestions: selectors.showOnlyRegularQuestions(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(StartAssessment);