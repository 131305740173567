import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { schoolAdmin } from './redux-schoolAdmin/index'
import * as appData from '../appSelector'
class AssignedCoursesFromOrgLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }

  }

  render() {
    var imageFromBucket = this.props.schoolAdminAssignedCourseData &&
      this.props.schoolAdminAssignedCourseData.get('imageBucketEnable');
    var imageUrl = "";
    if (imageFromBucket) {
      imageUrl = this.props.schoolAdminAssignedCourseData.get('imageBucketLocationChanged');
    } else {
      imageUrl = this.props.schoolAdminAssignedCourseData.get('imageappLocationChanged');
    }
    return (
      <>
        <div key={this.props.schoolAdminAssignedCourseData.get('bookId')} className="books-card-wrapper col-md-12">
          <div className="card" >
            <div className="card-header">
              <b>Standard Name:&nbsp;</b>{this.props.schoolAdminAssignedCourseData.get('standardName')}&nbsp;&nbsp;
              <b>Course Name:&nbsp;</b>{this.props.schoolAdminAssignedCourseData.get('courseName')}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <img className="bookImageSize" alt={this.props.schoolAdminAssignedCourseData.bookTitle} src={imageUrl} />
                </div>
                <div className="col-4">
                  <div>
                    <div>
                      <b>Course Details:</b>
                    </div>
                    <div className="mt-1">
                      Title: {this.props.schoolAdminAssignedCourseData.get('bookTitle')}
                    </div>
                    <div className="mt-1">
                      Description: {this.props.schoolAdminAssignedCourseData.get('bookDescription')}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <ul>
                    <li>
                      <Link className="mb-3 text-white btn btn-warning"
                        to={{
                          pathname: '/Orders',
                          state: {
                            bookId: this.props.schoolAdminAssignedCourseData.get('bookId'),
                            bookData: this.props.schoolAdminAssignedCourseData.toJS()
                          }
                        }}>
                        Order
                      </Link>
                    </li>
                    <li>
                      <Link className="mb-3 text-white btn btn-info"
                        to={{
                          pathname: '/view-resources',
                          state: {
                            bookId: this.props.schoolAdminAssignedCourseData.get('bookId'),
                            bookData: this.props.schoolAdminAssignedCourseData.toJS()
                          }
                        }}>
                        View Resources
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const s = ({ state }) => ({
  getUserData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...schoolAdmin.actions
    },
    d
  )
})
export default connect(s, d)(AssignedCoursesFromOrgLinks);
