import {List, Map} from 'immutable'

export const getUserInfo = state => state.getIn(['userInfo','responseObject'], Map());
export const getAddressInfo = state => state.get('addressInfo', List())

export const getAddressModalStatus = state => state.getIn(['modals', 'address'], false)


export const isShippingAddress = state => state.getIn(['edittingAddress','isShippingAddress'], false)

export const getCurrentAddressIndex = state => state.getIn(['edittingAddress','currentAddressIndex'],0)

export const isEditingAddress = state => state.getIn(['edittingAddress','isEditingAddress'], false)

export const getAddressAlert = state => state.get('addressAlert', false)

export const getUserInfoAlert = state => state.get('userInfoAlert', false)
export const getEventAlert = state => state.get('eventData', false)

export const getUserProfiles = (state) => {
  const userProfileData = state.get("userProfileData", List());
  return userProfileData.reduce((allUsers, currentUser) => {
    return [
      ...allUsers,
      {
        OwnerText:currentUser.get("firstName", ""),
        Id: currentUser.get('id','')
      }
       
    ];
  }, []);
};


export const getEvents = (state) => {
  return state.get('allEventsData', List()).toJS()
}
console.log(getEvents)
export const getMeetingUrl = (state) => {
  return state.get('meetingUrl')
}
export const getEventData = (state) => {
  return state.get('eventData')
}