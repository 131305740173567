import React, { Component } from "react";
import "./Executive.css";
import ExecutiveLinks from "././ExecutiveLinks.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as execData from "./redux-executive/selector";
import { executive } from "./redux-executive/index";
import * as appData from ".././appSelector";
import Calendar from "../calendar";
import WithPagination from "../common/WithPagination";

class ExecutiveHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3
    }

  }
  componentDidMount() {
    this.props.actions.getExecOrgAssociatedBooks({
      userId: this.props.userData.id,
      orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
      jwt: this.props.userData.jwt,
    });
  }

  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }
  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }
  render() {
    const { currentPage, itemsPerPage, searchText } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;

    var imageFromBucket =
      this.props.bookData &&
      this.props.bookData.responseObject.imageBucketEnable;

    let currentItems = []
    this.props.bookData && this.props.bookData.responseObject.booksData.map((bookData) => {
      currentItems.push(bookData)
    })
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }
    if (currentItems) {
      var showBooks = currentItems.slice(indexOfFirst, indexOfLast).map((bookData, i) => {
        var imageUrl = "";
        if (imageFromBucket) {
          imageUrl = bookData.imageBucketLocationChanged;
        } else {
          imageUrl = bookData.imageappLocationChanged;
        }
        return (
          <ExecutiveLinks
            key={bookData.bookId}
            bookData={bookData}
            imageUrl={imageUrl}
          />
        );
      });
    }

    return (
      <div className="mt-4 ">
        <div className={`${this.props.isCalendarShown ? 'move-left' : ''}`}>
          <div style={{
            paddingBottom: '5px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer'
          }}
            onClick={() => this.props.actions.toggleCalendar(!this.props.isCalendarShown)}
          >
            <img
              src={'https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png'}
              alt="Calendar"
            />
          </div>
          <div className='mt-4' style={{ "margin-bottom": '70px' }}>
            <WithPagination
              itemsPerPage={itemsPerPage}
              totalItems={currentItems.length}
              currentPage={currentPage}
              onSearch={this.onSearch}
              onPaginate={this.onPaginate}
            >
              {showBooks}
            </WithPagination>
          </div>
        </div>
        {
          this.props.isCalendarShown &&
          <div className="calender-wrapper">
            <a className="calendar-external-icon" href="/calendar" target="_blank"><i className="fa-external-link fa" aria-hidden="true" style={{ color: 'black', fontFamily: "FontAwesome" }} /></a>
            <Calendar callApiGetAPI={true} />
          </div>
        }
      </div>
    );
  }
}
const s = ({ state }) => ({
  bookData: execData.getBookByOrgUser(state),
  userData: appData.getUserData(state),
  isCalendarShown: execData.isCalendarShown(state)
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...executive.actions,
    },
    d
  ),
});
export default connect(s, d)(ExecutiveHome);
