import React, { Component } from 'react';
import './Alert.css';


class AlertBox extends Component {

   

    componentDidUpdate(){
        setTimeout(() => this.setState({successMsg:''}), 3000);
      }

      // set alertbox success or failure false so it wont show every where 
    render() {
        var successAlert = this.props.successAlert;
        var errorAlert = this.props.errorAlert;
        var successShortMsg = this.props.successShortMsg;
        var successMsg = this.props.successMsg;
        var errorShortMsg = this.props.errorShortMsg;
        var errorMsg = this.props.errorMsg;
        console.log ("successMsg", successMsg)
          
            return (
                <div>
                    {successAlert ? (<div id="hideMeGreen" role="alert">
                    { successShortMsg !== undefined && successShortMsg !==''? (<>{successShortMsg}&nbsp;:&nbsp;{successMsg}</> ):(<>{successMsg}</>)}
                    </div>) : (errorAlert) ? (<div id="hideMeRed" role="alert">
                    { errorShortMsg !== undefined && errorShortMsg!==''?(<>{errorShortMsg}&nbsp;:&nbsp;{errorMsg}</>):(<>{errorMsg}</>)}
                    </div>) : null
                    }
                </div>
            );
        }

        
}
           
                
    
export default AlertBox;