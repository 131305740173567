import React, { Component } from 'react';

class StandardEditableCellInstOrg extends Component {
    render() {
        return (
            <td>
                <input 
                className="col-lg-11 col-md-11 col-sm-11 col-xs-11" 
                type='text' 
                name={this.props.cellData.type} 
                id={this.props.cellData.id} 
                placeholder="Enter Standard Name"
                value={this.props.cellData.value} 
                onChange={this.props.onStandardsTablesUpdate} />
            </td>
        );
    }
}


export default StandardEditableCellInstOrg;