import React, { Component } from "react";
import CourseInputTagInstOrg from "./CourseInputTagInstOrg";
import AlertModal from "../../../common/AlertModal";
import DeleteCourseModal from '../../../modals/DeleteCourseModal'

import { List } from 'immutable'

class CourseTableInstOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coursesSavingData: [],
      courseDataOnIni: [],
      successAlert: false,
      errorAlert: false,
      successMsg: "",
      errorMsg: "",
    };
    this.callSaveData = this.callSaveData.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  saveData(dataFromChild) {
    this.setState({
      coursesSavingData: dataFromChild,
    });
  }

  callSaveData() {
    this.props.addCourses({
      jwt: this.props.userProps.jwt,
      userId: this.props.userId,
      orgId: this.props.orgId,
      coursesData: this.state.coursesSavingData,
    });
  }

  handleSuccessAlertModalClose() {
    this.props.handleSuccessAlertModalClose();
    this.props.history.push("/InstOrgAddCourses");
  }
  handleErrorAlertModalClose() {
    this.props.handleErrorAlertModalClose();
  }

  render() {
    let saveDataVar = this.saveData;
    let courseDataOnIniforApi = [];
    let handleDeleteCourseModalClose = this.props.handleDeleteCourseModalClose;
    if (this.props.stdCoursesObject.count() > 0) {
      var stdCourses = this.props.stdCoursesObject.map((stdCourse, index) => {
        var coursesData = {
          standardId: stdCourse.get("standardId", ""),
          courseNames: stdCourse.get("courseNames", List()),
          removedCourses: stdCourse.get("removedCourses", List()),
        };
        courseDataOnIniforApi.push(coursesData);
        return (
          <CourseInputTagInstOrg
            courseDataOnIniforApi={courseDataOnIniforApi}
            stdCourse={stdCourse}
            key={stdCourse.get("standardId", "")}
            callbackFromParent={saveDataVar.bind(this)}
            handleDeleteCourseModalClose={handleDeleteCourseModalClose}
            currentStdCourseIndex={index}
            storingIndexesOfCurrentDeletedCourses={
              this.props.storingIndexesOfCurrentDeletedCourses
            }
            addingCourses={this.props.addingCourses}
          />
        );
      });
    }

    const {
      successAlert,
      errorAlert,
      successMsg,
      errorMsg,
      deleteCourseAlert,
      orgIdAdmRteInCrses,
      pathSeparator,
      userIdAdmRteInCrses,
    } = this.props;

    return (
      <div>
        <br />
        <AlertModal
          type="success"
          show={successAlert}
          title="Success"
          message={successMsg}
          onClose={() => this.handleSuccessAlertModalClose()}
        ></AlertModal>
        <AlertModal
          type="danger"
          show={errorAlert}
          title="Error"
          message={errorMsg}
          onClose={() => this.handleErrorAlertModalClose()}
        ></AlertModal>
        <DeleteCourseModal
          show={deleteCourseAlert}
          onCancel={() => this.props.handleDeleteCourseModalClose(false)}
          onDelete={() =>
            this.props.handleDeleteCourseModalClose(false) &&
            this.props.updateCourses({
              jwt: this.props.jwt,
              userId: this.props.userId,
              orgId: this.props.orgId,
              orgIdAdmRteInCrses,
              pathSeparator,
              userIdAdmRteInCrses,
            })
          }
          currentDeletedCourse = {this.props.currentDeletedCourse }
        />
        <br />
        <table className="table table-sm table-bordered table-striped">
          <thead className="thead-dark">
            <tr>
              <th className="fixed-col center">Standard Name</th>
              <th className="center">Course Names</th>
            </tr>
          </thead>
          <tbody>{stdCourses}</tbody>
        </table>
        <button
          className="btn btn-success btn-responsive pull-right"
          onClick={this.callSaveData}
        >
          Save
        </button>
      </div>
    );
  }
}

export default CourseTableInstOrg;
