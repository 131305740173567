import React, { Component } from 'react';
import '../layout/Footer.css';
import faceBookLogo from './../assets/icons/facebook.jpg';
import twitterLogo from './../assets/icons/twitter.jpg';
import instagramLogo from './../assets/icons/instagram.jpg';
import durchereLogo from './../assets/logo/durchere.png';
import {Link } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as appData from "../appSelector";

class PublicFooter extends Component {
    render() {
        return (
            <div className="public-footer">
                <hr className="hrTag"></hr>
                <div className="footer">
                    <div className="footer-container">
                        <div className="inline-flex">
                            <div className="vertical-line-public-footer">
                                <Link title="durchere" to="/">
                                    <img alt="durchere logo" src={durchereLogo} className="durchere-logo-footer" />
                                </Link>
                            </div>
                            <div className="footer-brand">DURCHERE
                            </div>
                            <div className="footer-motto">Be You For You
                            </div>
                            <div className="social-links">
                                <ul>
                                    <li>
                                        <a title="Join us on Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Durchere-102317981861823">
                                            <img alt="Facbook logo" src={faceBookLogo} width="27" height="27" />
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Join us on Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/durchere/">
                                            <img alt="Twitter logo" src={twitterLogo} width="27" height="27" />
                                        </a>
                                    </li>
                                    <li>
                                        <a title="Join us on Instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/durchere/">
                                            <img alt="Instagram logo" src={instagramLogo} width="27" height="27" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="social-links-767 inline-flex">
                            <ul>
                                <li>
                                    <a title="Join us on Facebook" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Durchere-102317981861823">
                                        <img alt="Facbook logo" src={faceBookLogo} width="27" height="27" />
                                    </a>
                                </li>
                                <li>
                                    <a title="Join us on Twitter" target="_blank" rel="noopener noreferrer" href="https://twitter.com/durchere/">
                                        <img alt="Twitter logo" src={twitterLogo} width="27" height="27" />
                                    </a>
                                </li>
                                <li>
                                    <a title="Join us on Instagram" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/durchere/">
                                        <img alt="Instagram logo" src={instagramLogo} width="27" height="27" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="float-section inline-block">
                            <nav className="" role="navigation" aria-label="Explore the site.">
                                <Link className="footer-link" to="/Partners">Partners</Link>
                                {/*<Link className="footer-link" to="/PublicBooks">Books</Link>*/}
                                <Link className="footer-link" to="/AboutUs">About</Link>
                                {/* <Link className="footer-link" to="/Careers">Careers</Link> */}
                                <Link className="footer-link" to="/ContactUs">Contact</Link>
                                {
                                   ( this.props.isPublicPath || this.props.userData.roleId === "76517" 
                                   || this.props.userData.roleId === "72195" || this.props.userData.roleId === "72138"
                                   || this.props.userData.roleId === "79126") &&
                                    <Link className="footer-link" to="/PublicBooks">Books</Link>
                                }
                            </nav>
                            <aside className="footer-copy-right" aria-label="The Fine Print.">
                                <span className="copy-right-text">© 2021 Durchere Inc.&nbsp;
                                        <Link className="legal-link" to="#">Legal.</Link>
                                </span>
                            </aside>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const s = ({ state }) => ({
    userData: appData.getUserData(state),
  });
  const d = (d) => ({
    actions: bindActionCreators(
      {

      },
      d
    ),
  });
export default connect(s, d)(PublicFooter);