import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { adminConf } from '../../redux-adminconf/index'
import * as adminData from '../../redux-adminconf/selector';
import { Link } from 'react-router-dom';

class BookBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.showDeleteModalMain = this.showDeleteModalMain.bind(this);
    this.showQuantityModal = this.showQuantityModal.bind(this);
  }

  showDeleteModalMain() {
    let bookData = this.props.bookData
    this.props.actions.openDeleteBookModal(
      bookData
    );
  }


  showQuantityModal() {
    let bookData = this.props.bookData
    this.props.actions.openQuantityModal(
      bookData
    );
  }
  render() {
    return (
      <div className="books-card-wrapper col-md-12">
        <div className="card" key={this.props.bookData.bookId}>
          <div className="card-header">
            Standard Name: {this.props.bookData.standardName}&nbsp;&nbsp;
            Course Name: {this.props.bookData.courseName}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <img className="bookImageSize" alt={this.props.bookData.bookTitle} src={this.props.imageUrl} />
              </div>
              <div className="col-6">
                <ul>
                  <li>
                    <Link className="mb-3 text-white btn btn-primary" to={{pathname:'/book-resourses-management', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData}}}>Manage Book Resources</Link>
                  </li>
                  <li>
                    <a className="mb-3 text-white btn btn-danger" onClick={this.showDeleteModalMain} >Remove Book</a>
                  </li>
                  <li>
                    <Link className="mb-3 text-white btn btn-warning" to={{ pathname: '/Orders', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData } }} >Order</Link>
                  </li>
                  <li>
                    <a className="mb-3 text-white btn btn-success" onClick={this.showQuantityModal}>Quantity</a>
                  </li>
                  <li>
                  <Link className="mb-3 text-white btn btn-info" to={{pathname:'/view-resources', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData}}}>View Resources</Link>
                  </li>
                </ul>
                <div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  openDeleteBookModal: adminData.openDeleteBookModal(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...adminConf.actions
    },
    d
  )
})
export default connect(s,d)(BookBox);
