import { createAction } from 'redux-act'
import {createAsyncAction} from './../../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const createAssessment = createAsyncAction(
    `CREATE_ASSESSMENT`,
    payload => payload
)

const getViewAssessments = createAsyncAction(
    `VIEW_ASSESSMENTS`,
    payload => payload
)

const addQuestionToAssessment = createAsyncAction(
    `ADD_QUESTION_TO_ASSESSMENT`,
    payload => payload
)

const getAssmentAssocParagraphs = createAsyncAction(
  `GET_ASSESSMENT_ASSOSC_PARAGRAPHS_TEST_ID`,
  payload => payload
)

const addParagraphToAssessment = createAsyncAction(
  `ADD_PARAGRAPH_TO_ASSESSMENT`,
  payload => payload
)

const endTestData = createAsyncAction(
  `END_TEST_DATA`,
  payload => payload
)

const setAnswerOptionsData = createAction(
  `ADD_ANSWER_OPTIONS_QUESTION`,
  payload => payload
)

const getViewAssessmentsByCourse = createAsyncAction(
  `VIEW_ASSESSMENTS_BY_COURSE`,
  payload => payload
)
const getQuestions = createAsyncAction(
  `GET_QUESTIONS`,
  payload => payload
)

const showQuestionsRelatedToParaGraph = createAction(
  `SHOW_QUESTIONS_RELATED_PARAGRAPHS`,
  payload => payload
)

const currentQuestionIndex = createAction(
  `SHOW_CURRENT_QUESTION_INDEX`,
  payload => payload
)

const questionsIndex = createAction(
  `GET_QUESTIONS_INDEX`,
  payload =>payload
)

const startAssessment = createAsyncAction(
  `START_ASSESSMENT`,
  payload => payload
)

const showQuestionsForParaGraphStartAssessment = createAction(
  `SHOW_QUESTIONS_FOR_PARAGRAPHS_START_ASSESSMENT`,
  payload => payload
)

const currentQuestionIndexStartAssessment = createAction(
  `SHOW_CURRENT_QUESTION_INDEX_START_ASSESSMENT`,
  payload => payload
)

const questionsIndexStartAssessment = createAction(
  `GET_QUESTIONS_INDEX_START_ASSESSMENT`,
  payload =>payload
)

const closeAlertModal = createAction(
  `CLOSE_ALERT_MODAL`,
  payload => payload
)

const showDeleteAssessmentModal = createAction(
  `SHOW_DELETE_ASSESSMENT_MODAL`,
  payload => payload
)

const currentTestId = createAction(
  `CURRENT_ASSESSMENT_ID`,
  payload => payload
)

const getAllResultsByTestId = createAsyncAction(
  `GET_ALL_RESULTS_BY_TEST_ID`,
  payload => payload
)
const setResultsOfStudent = createAction(
  `SET_RESULTS_OF_STUDENT`,
  payload => payload
)

const setCureentInputValue = createAction(
  `SET_CURRENT_INPUT_VALUE`,
  payload => payload
)

const totalMarks = createAction(
  `SET_TOTAL_MARKS`,
  payload => payload
)
const getResultsByuser = createAsyncAction(
  `GET_RESULTS_BY_USER`,
  payload => payload
)

const showQuestionsForParaGraphResultsByuser = createAction(
  `SHOW_QUESTIONS_FOR_PARAGRAPHS_RESULTS_BY_USER`,
  payload => payload
)

const currentQuestionIndexResultsByuser = createAction(
  `SHOW_CURRENT_QUESTION_INDEX_RESULTS_BY_USER`,
  payload => payload
)

const questionsIndexResultsByuser = createAction(
  `GET_QUESTIONS_INDEX_RESULTS_BY_USER`,
  payload =>payload
)

const setCurrentAnswer = createAction(
  `SET_CURRENT_ANSWER`,
  payload => payload
)
const closeQuestionnaireModal = createAction(
  `CLOSE_QUESTIONNAIRE_ALERT_MODAL`,
  payload => payload
)

const endAssessmentModal = createAction(
  `END_ASSESSMENT_MODAL`,
  payload => payload
)

const endAssessmentTimerEndModal = createAction(
  `END_ASSESSMENT_TIMER_MODAL`,
  payload => payload
)

const setStartAssessment = createAction(
  `SET_ASSESSMENT_STARTED_FLAG`,
  payload => payload
)


const reducer = {
    [createAssessment.success]: (state, payload) =>
      mutators.createAssessment(payload)(state),
    [createAssessment.failure]: (state, payload) =>
      mutators.createAssessmentError(payload)(state),
    [getViewAssessments.success]: (state, payload) =>
      mutators.getViewAssessments(payload)(state),
    [addQuestionToAssessment.success]: (state, payload) =>
      mutators.addQuestionToAssessment(payload)(state),
    [addQuestionToAssessment.failure]: (state, payload) =>
      mutators.addQuestionToAssessmentError(payload)(state),
    [getQuestions.success]: (state,payload) =>
      mutators.getQuestions(payload)(state),
    [getAssmentAssocParagraphs.success]: (state, payload) =>
      mutators.getAssmentAssocParagraphs(payload)(state),
    [addParagraphToAssessment.success]: (state, payload) =>
      mutators.addParagraphToAssessment(payload)(state),
    [addParagraphToAssessment.failure]: (state, payload) =>
      mutators.addParagraphToAssessmentError(payload)(state),
    [setAnswerOptionsData]: (state, payload) =>
      mutators.setAnswerOptionsData(payload)(state),
    [getViewAssessmentsByCourse.success]: (state, payload) =>
      mutators.getViewAssessmentsByCourse(payload)(state),
    [showQuestionsRelatedToParaGraph]: (state,payload) =>
      mutators.showQuestionsRelatedToParaGraph(payload)(state),
    [currentQuestionIndex]: (state,payload) =>
      mutators.currentQuestionIndex(payload)(state),
    [questionsIndex]: (state, payload) => 
      mutators.questionsIndex(payload)(state),
    [startAssessment.success]: (state, payload) =>
      mutators.startAssessment(payload)(state),
    [showQuestionsForParaGraphStartAssessment]: (state,payload) =>
      mutators.showQuestionsForParaGraphStartAssessment(payload)(state),
    [currentQuestionIndexStartAssessment]: (state,payload) =>
      mutators.currentQuestionIndexStartAssessment(payload)(state),
    [questionsIndexStartAssessment]: (state, payload) => 
      mutators.questionsIndexStartAssessment(payload)(state), 
    [closeAlertModal]: (state, payload) =>
      mutators.closeAlertModal(payload)(state),
    [showDeleteAssessmentModal]: (state, payload) =>
      mutators.showDeleteAssessmentModal(payload)(state),
    [currentTestId]: (state, payload) =>
      mutators.currentTestId(payload)(state), 
    [getAllResultsByTestId.success]: (state, payload) =>
      mutators.getAllResultsByTestId(payload)(state),
    [setResultsOfStudent]: (state, payload) =>
      mutators.setResultsOfStudent(payload)(state),
    [setCureentInputValue]: (state, payload) =>
      mutators.setCureentInputValue(payload)(state),
    [totalMarks]: (state, payload) =>
      mutators.totalMarks(payload)(state),
    [getResultsByuser.success]: (state, payload) =>
      mutators.getResultsByuser(payload)(state),
    [showQuestionsForParaGraphResultsByuser]: (state,payload) =>
      mutators.showQuestionsForParaGraphResultsByuser(payload)(state),
    [currentQuestionIndexResultsByuser]: (state,payload) =>
      mutators.currentQuestionIndexResultsByuser(payload)(state),
    [questionsIndexResultsByuser]: (state, payload) => 
      mutators.questionsIndexResultsByuser(payload)(state),
    [setCurrentAnswer]: (state, payload) =>
      mutators.setCurrentAnswer(payload)(state),
    [closeQuestionnaireModal]: (state, payload) =>
      mutators.closeQuestionnaireModal(payload)(state),
    [endAssessmentModal]: (state, payload) =>
      mutators.endAssessmentModal(payload)(state),
    [endAssessmentTimerEndModal]: (state, payload) =>
      mutators.endAssessmentTimerEndModal(payload)(state),

    [setStartAssessment]: (state, payload) =>
      mutators.setStartAssessment(payload)(state)
      
}

const restApi = {
   [createAssessment]: api.createAssessment(createAssessment),
   [getViewAssessments]: api.getViewAssessments(getViewAssessments),
   [addQuestionToAssessment]: api.addQuestionToAssessment(addQuestionToAssessment),
   [getAssmentAssocParagraphs]: api.getAssmentAssocParagraphs(getAssmentAssocParagraphs),
   [addParagraphToAssessment]: api.addParagraphToAssessment(addParagraphToAssessment),
   [getViewAssessmentsByCourse]: api.getViewAssessmentsByCourse(getViewAssessmentsByCourse),
   [getQuestions]: api.getQuestions(getQuestions),
   [startAssessment]: api.startAssessment(startAssessment),
   [getAllResultsByTestId]: api.getAllResultsByTestId(getAllResultsByTestId),
   [endTestData]: api.endTestData(endTestData),
   [getResultsByuser]: api.getAllResultsByTestId(getResultsByuser),
}

export default {
    actions: {
        createAssessment,
        getViewAssessments,
        addQuestionToAssessment,
        getAssmentAssocParagraphs,
        addParagraphToAssessment,
        setAnswerOptionsData,
        getViewAssessmentsByCourse,
        getQuestions,
        showQuestionsRelatedToParaGraph,
        currentQuestionIndex,
        questionsIndex,
        startAssessment,
        showQuestionsForParaGraphStartAssessment,
        currentQuestionIndexStartAssessment,
        questionsIndexStartAssessment,
        closeAlertModal,
        showDeleteAssessmentModal,
        currentTestId,
        getAllResultsByTestId,
        setResultsOfStudent,
        setCureentInputValue,
        totalMarks,
        endTestData,
        getResultsByuser,
        showQuestionsForParaGraphResultsByuser,
        currentQuestionIndexResultsByuser,
        questionsIndexResultsByuser,
        setCurrentAnswer,
        closeQuestionnaireModal,
        endAssessmentModal,
        endAssessmentTimerEndModal,
        setStartAssessment
    },
    restApi,
    reducer
}