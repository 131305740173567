import React, { Component } from 'react';
import './404.css'
class NotFoundPage extends Component {
  render() {
    return (
      <div className="not-found-wrapper">
        <h1>404</h1>
        <p>Nothing to see in <br />here.</p>
      </div>
    )
  }
}
export default NotFoundPage;
