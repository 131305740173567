import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import * as appData from "../../appSelector";
import './OrderHistory.css'
import { List } from "immutable";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";
import { common } from '../../common/index'

class OrderInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.popoverHoverFocus = this.popoverHoverFocus.bind(this);
        this.openupdateOrderStatusModalMain = this.openupdateOrderStatusModalMain.bind(this);
    }

    openupdateOrderStatusModalMain() {
        let orderId = this.props.ordersInfo.get('orderId', '')
        this.props.actions.openupdateOrderStatusModal(
            orderId
        );
      }
    popoverHoverFocus(props) {
        return (
            <Popover {...props} id="popover-trigger-hover-focus" title="Popover bottom">
                {this.props.ordersInfo.get('street1', '')},
                {this.props.ordersInfo.get('street2', '')},
                {this.props.ordersInfo.get('cityName', '')},
                {this.props.ordersInfo.get('landmark', '')},
                {this.props.ordersInfo.get('district', '')},
                {this.props.ordersInfo.get('state', '')},
                {this.props.ordersInfo.get('country', '')},
                {this.props.ordersInfo.get('zipCode', '')}
            </Popover>
        )
    }

    render() {

        const {
            orderIndex,
            actions
        } = this.props

        return (
          <>
            <div className="books-card-wrapper col-md-12">
              <div className="card">
                <div className="card-header order-history-header">
                  <div className="date">
                    Order Placed <br />{" "}
                    <span>
                      {this.props.ordersInfo.get("orderPlacedDate", "")}
                    </span>
                  </div>
                  <div className="price">
                    Total <br />{" "}
                    <span>
                      {" "}
                      {"\u20B9"} {this.props.ordersInfo.get("grandTotal", 0)}
                    </span>
                  </div>
                  <div className="ship">
                    Shipped to <br /> <span> </span>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="bottom"
                      overlay={this.popoverHoverFocus}
                    >
                      <Button>
                        {this.props.ordersInfo.get("fullName", "")}
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <div>
                    Order Status{" "}
                    <div>
                    <span>{this.props.ordersInfo.get("orderStatus", "")}</span>
                    </div>
                  </div>
                  <div>
                    Service Provider{" "}
                    <span>
                      {this.props.ordersInfo.get("postalService", "")}{" "}
                    </span>
                  </div>
                  <div>
                    Tracking Number{" "}
                    <span>
                      {this.props.ordersInfo.get("trackingNumber", "")}{" "}
                    </span>
                  </div>
                  {this.props.userData.roleId === "78129" && (
                    <div>
                      <Button onClick={
                          () =>{
                            this.openupdateOrderStatusModalMain() 
                            actions.setCurrentOrderIndex(orderIndex)
                          }
                          
                          }>
                        Update Order Status
                      </Button>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  {this.props.ordersInfo
                    .get("booksData", List())
                    .map((book, bookIndex) => (
                      <div className="cart-data-wrapper" key={bookIndex}>
                        <div className="cart-img-wrapper">
                          <img
                            src={book.get("imageBucketLocationChanged", "")}
                            alt={"book"}
                            width={"300"}
                            height={"200"}
                          />
                        </div>
                        <div className="cart-content-wrapper">
                          <p>{book.get("bookDescription", "")}</p>
                          <p className="price">
                            {`Price: 	\u20B9 ${book.get("price", "")}`}
                          </p>
                          <p className="quantity">
                            Quantity:&nbsp; {book.get("quantity", 0)}
                          </p>
                          <p className="total-amount">
                            {`Total Amount: 	\u20B9 ${book.get(
                              "totalAmount",
                              ""
                            )}`}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        );
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state)
});
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...orders.actions,
            ...common.actions,
        },
        d
    ),
});
export default connect(s, d)(OrderInfo);
