import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { modals } from '../modals'
import { common } from '../common/index'
import * as modalsData from '../modals/selector'
import * as commonData from '../common/selector'
import * as appData from '../appSelector'
import Select from 'react-select';

import './CreateInstitute.css'
import AlertModal from '../common/AlertModal';

class CreateInstitute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instituteName: '',
      street1: '',
      street2: '',
      cityName: '',
      landmark: '',
      district: '',
      state: '',
      country: '',
      countryCode: '',
      zipCode: '',
      imageBucketEnabled: 'y',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: ''
    };
    this.closeInstituteAlertModal = this.closeInstituteAlertModal.bind(this)
  }

  closeInstituteAlertModal(payload) {
    this.props.actions.closeInstituteAlertModal(payload);
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  handleStateInfo = event => {
    let stateName = event.label;
    this.setState({
      state: stateName,
    })
  }

  handleCountryInfo = event => {
    let countryName = event.label;
    let countryValue = event.value;
    this.setState({
      country: countryName,
      countryCode: countryValue
    })
  }
  onCreateInstSubmit = (e) => {
    e.preventDefault();

    let instituteObject = {
      instituteName: this.state.instituteName,
      street1: this.state.street1,
      street2: this.state.street2,
      cityName: this.state.cityName,
      landmark: this.state.landmark,
      district: this.state.district,
      state: this.state.state,
      country: this.state.country,
      countryCode: this.state.countryCode,
      zipCode: this.state.zipCode,
      imageBucketEnabled: this.state.imageBucketEnabled
    }
    let userObject = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      email: this.state.email
    }
    this.props.actions.addInstitute({
      jwt: this.props.userData.jwt,
      data: {
        "listOfinstituteDetails": [
          {
            "instituteDetails": instituteObject,
            "user": userObject
          }
        ],
        userId: this.props.userData.id,
        orgId: Number(Object.keys(this.props.userData.orgInfo[0]))
      }
    });
    setTimeout(() => {
      // window.close();
    }, 500);
  }

  componentDidMount() {
    this.props.actions.getStateCodeInfo({
      jwt: this.props.userData.jwt,
    })
    this.props.actions.getCountryCodeInfo({
      jwt: this.props.userData.jwt,
    })
  }

  render() {
    const {
      instituteName, street1, street2, cityName, landmark, district, zipCode, firstName, lastName,
      phoneNumber, email
    } = this.state;
    return (
      <div className='container mt-4' >
        {(this.props.successAlertModal) && (
          <AlertModal type="success" show={true} title={this.props.createInstituteSuccessShortMsg} message={this.props.createInstituteSuccessLongMsg} onClose={() => this.closeInstituteAlertModal(true)} />
        )}
        {(this.props.failureAlertModal) && (
          <AlertModal type="danger" show={true} title={this.props.createInstituteFailureShortMsg} message={this.props.createInstituteFailureLongMsg} onClose={() => this.closeInstituteAlertModal(false)} />
        )}
        <b className='d-flex justify-content-center h5'>Create Institute</b>
        <div className="top-margin-create-institute">
        </div>
        <hr />
        <div className="container institute-form">
          <form onSubmit={this.onCreateInstSubmit}>
            <h5 className=''>User Details</h5>
            <hr />
            <div className='container'>
              <div className="row mt-2">
                <div className="col-md-4">
                  <label>First Name:  </label>
                </div>
                <div className="col-md-8">
                  <input
                    name="firstName"
                    value={firstName}
                    type="text"
                    onChange={this.handleInputChange}
                    placeholder="First Name"
                    className="css-yk16xz-control"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label>Last Name:  </label>
                </div>
                <div className="col-md-8">
                  <input
                    name="lastName"
                    value={lastName}
                    type="text"
                    onChange={this.handleInputChange}
                    placeholder="Last Name"
                    className="css-yk16xz-control"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label>Phone Number:  </label>
                </div>
                <div className="col-md-8">
                  <input
                    name="phoneNumber"
                    value={phoneNumber}
                    type="number"
                    onChange={this.handleInputChange}
                    placeholder="Phone Number"
                    className="css-yk16xz-control"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label>Email:  </label>
                </div>
                <div className="col-md-8">
                  <input
                    name="email"
                    value={email}
                    type="text"
                    onChange={this.handleInputChange}
                    placeholder="Email"
                    className="css-yk16xz-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div>
              <h5>Institute Details</h5>
              <hr />
              <div className="row">
              </div>
              <div>
                <div className="row">
                  <div className="col-md-4">
                    <label>Institute Name:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="instituteName"
                      value={instituteName}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="Institute Name"
                      className="css-yk16xz-control"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>Street1:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="street1"
                      value={street1}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="Street1"
                      className="css-yk16xz-control"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>Street2:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="street2"
                      value={street2}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="Street2"
                      className="css-yk16xz-control"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>City Name:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="cityName"
                      value={cityName}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="City Name"
                      className="css-yk16xz-control"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>Landmark:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="landmark"
                      value={landmark}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="Landmark"
                      className="css-yk16xz-control"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>District:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="district"
                      value={district}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="District"
                      className="css-yk16xz-control"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>State:  </label>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <Select onChange={this.handleStateInfo} options={this.props.stateCodeInfoOptions} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>Country:  </label>
                  </div>
                  <div className="col-md-3">
                    <div>
                      <Select onChange={this.handleCountryInfo} options={this.props.countryCodeInfoOptions} />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>Zip Code:  </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      name="zipCode"
                      value={zipCode}
                      type="text"
                      onChange={this.handleInputChange}
                      placeholder="Zip Code"
                      className="css-yk16xz-control"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label>Image Bucket Enabled:  </label>
                  </div>
                  <div className="col-md-8">
                    <select
                      name="imageBucketEnabled"
                      value="imageBucketEnabled"
                      onChange={this.handleInputChange}
                      placeholder="Image Bucket Enabled"
                      className="css-yk16xz-control"
                      required
                    >
                      <option value="y">Yes</option>
                      <option value="n">No</option>
                    </select>
                  </div>
                </div>
                <hr/>
                <div>
                  <button type="submit" className="btn w-25 btn-primary btn-sm btn-responsive float-right">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

const s = ({ state }) => ({
  allInstitutes: modalsData.getAllInstitutesData(state),
  userData: appData.getUserData(state),
  stateCodeInfoOptions: commonData.getStateCodeInfo(state),
  countryCodeInfoOptions: commonData.getCountryCodeInfo(state),
  successAlertModal: modalsData.successAlertModal(state),
  failureAlertModal: modalsData.failureAlertModal(state),
  createInstituteSuccessShortMsg: modalsData.createInstituteSuccessShortMsg(state),
  createInstituteSuccessLongMsg: modalsData.createInstituteSuccessLongMsg(state),
  createInstituteFailureShortMsg: modalsData.createInstituteFailureShortMsg(state),
  createInstituteFailureLongMsg: modalsData.createInstituteFailureLongMsg(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions,
      ...common.actions
    },
    d
  )
})
export default connect(s, d)(CreateInstitute);
