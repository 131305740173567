import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import GuestHome from "../guest/GuestHome";
import GuestHeader from ".././layout/guestHeader/GuestHeader";
import PublicFooter from "../layout/PublicFooter";
import NotFoundPage from "../common/404";
import GuestCheckout from "../order/cartSummary/GuestCheckout";
import OrdersPage from "../../src/order/OrdersPage.js";
import GuestCartSummary from "../order/cartSummary/GuestCartSummary";
import ProfileHome from "../profile/ProfileHome";
import Payment from "../../src/order/cartSummary/payment";
import OrderHistory from "../order/orderHistory/OrderHistory";
import Calendar from "../calendar";

import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import PublicBooksPage from "../publicview/PublicBooksPage.js";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import PublicOrders from "../order/PublicOrders.js";

export default class GuestRouter extends Component {
  render() {
    return ( 
      <ConnectedRouter history={createBrowserHistory()}>
    <div>
        <div className="page-body">
          <div className="page-content-guest">
           
              {window.location.pathname.indexOf("/Payment") === -1 && (
                <GuestHeader></GuestHeader>
              )}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/Guest" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/Guest" />
                </Route>
                <Route
                  exact
                  path="/Guest"
                  render={(props) => <GuestHome {...props} />}
                />
                <Route
                  exact
                  path="/Orders"
                  render={(props) => <OrdersPage {...props} />}
                />
                <Route
                  exact
                  path="/GuestCheckout"
                  render={(props) => <GuestCheckout {...props} />}
                />
                <Route
                  exact
                  path="/GuestCartSummary"
                  render={(props) => <GuestCartSummary {...props} />}
                />
                <Route
                  exact
                  path="/OrderHistory"
                  render={(props) => <OrderHistory {...props} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => <ProfileHome {...props} />}
                />
                <Route
                  exact
                  path="/Payment/:id"
                  render={(props) => <Payment {...props} />}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />
                <Route exact path="/Partners" component={PartnersPage} />
                <Route exact path="/PublicBooks" component={PublicBooksPage} />
                <Route
                  exact
                  path="/PublicOrders"
                  render={(props) => <PublicOrders {...props} />}
                />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/Careers" component={CareersPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
              </div>
          </div>
        </div>
        {window.location.pathname.indexOf("/Payment") === -1 && (
                <PublicFooter />
              )}
       
        </ConnectedRouter>
     
    );
  }
}
