import axios from 'axios'
import APICONF from '../../config/ApiConfig.js';

export const  getAssignStudentToParent = (action) => async (
    { dispatch } ,
    {jwt ,id}
    ) => {
        
    const getAssignStudentToParentUrl = APICONF.GET_ASSIGN_PARENTTOSTUDENTS_BY_PARENTID  + "/" + id  ;
    const [err, result] = await axios 
         .get(getAssignStudentToParentUrl, {
            headers: {
                "authorization": jwt
            }
         })
         .then(res => [null,res.data])
         .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
    }