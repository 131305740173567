import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as appData from "../appSelector";
import { instOrgAdminConf } from "./redux-instOrgAdminconf/index";
import * as instOrgAdminconfData from ".././instOrgAdmin/redux-instOrgAdminconf/selector";

import { executive } from "../executive/redux-executive/index";
import * as execData from "../executive/redux-executive/selector";
import Calendar from "../calendar";

class InstOrgAdminHome extends Component {
  componentDidMount() {
    this.props.actions.getOrganizationDetails({
      jwt: this.props.userData.jwt,
      orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
    });
  }

  render() {
    let orgName = Object.values(this.props.userData.orgInfo[0])[0];
    return (
      <>
        <div className="mt-4 m-5">
          <div className={`${this.props.isCalendarShown ? "move-left" : ""}`}>
            <p
              style={{ textAlign: 'right', marginRight: '1rem', cursor: "pointer" }}
              onClick={() =>
                this.props.actions.toggleCalendar(!this.props.isCalendarShown)
              }
            >
              <img
                src={
                  "https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png"
                }
                alt="Calendar"
              />
            </p>


            <div className="card">
              <div className="card-header">
                <b>Organization Name: {orgName}&nbsp;&nbsp;</b>
              </div>
              <div className="card-body">
                <p>
                  <b>Organization Details</b>
                </p>
                <hr></hr>
                <br />
                <div className="org-info">
                  <p>
                    <b>Street1:</b> {this.props.getOrganizationDetailsData.street1}
                  </p>
                  <p>
                    <b>Street2:</b> {this.props.getOrganizationDetailsData.street2}
                  </p>
                  <p>
                    <b>District:</b> {this.props.getOrganizationDetailsData.street2}
                  </p>
                  <p>
                    <b>State:</b> {this.props.getOrganizationDetailsData.state}
                  </p>
                  <p>
                    <b>Country:</b> {this.props.getOrganizationDetailsData.country}
                  </p>
                  <p>
                    <b>Zip Code:</b> {this.props.getOrganizationDetailsData.zipCode}
                  </p>
                </div>
              </div>
            </div>
            {this.props.isCalendarShown && (
              <div className="calender-wrapper">
                <a
                  className="calendar-external-icon"
                  href="/calendar"
                  target="_blank"
                >
                  <i
                    className="fa-external-link fa"
                    aria-hidden="true"
                    style={{ color: "black", fontFamily: "FontAwesome" }}
                  />
                </a>
                <Calendar callApiGetAPI={true} />
              </div>

            )}
          </div></div>
      </>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  getOrganizationDetailsData:
    instOrgAdminconfData.getOrganizationDetails(state),
  isCalendarShown: execData.isCalendarShown(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...instOrgAdminConf.actions,
      ...executive.actions,
    },
    d
  ),
});
export default connect(s, d)(InstOrgAdminHome);
