import axios from 'axios'
import APICONF from '../../config/ApiConfig.js';
import APIMSGS from '../../common/ApiMsgs';

export const  getPublicBooks = (action) => async (
    { dispatch } 
    ) => {
    const getBooksUrl = APICONF.GET_PUBLIC_BOOKS
    const [err, result] = await axios 
         .get(getBooksUrl, {
         })
         .then(res => [null,res.data])
         .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
    }

    export const submitContactForm = (action) => async (
     { dispatch },
     { contactUsFormData }
   ) => {
     const contactUsUrl = APICONF.CONTACTUS
     const [err, result] = await axios({
       method: 'post', //you can set what request you want to be
       url: contactUsUrl,
       data: contactUsFormData,
     })
       .then(res => [null, res.data])
       .catch(err => [err, null])
     const actionToDispatch = err ? action.failure({...err, msg:APIMSGS.CONTACT_FORM_FAIL}) : action.success({...result, msg:APIMSGS.CONTACT_FORM_SUCCESS})
     return dispatch(actionToDispatch)
   }