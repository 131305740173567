import { fromJS, List } from 'immutable'

export const getEachOrganizationDetails = payload => state => {
  const organizationData = state.get('organizationData', List())

  let organizationIds = []

  organizationData.forEach(organization => {
    if(organization.get('orgId','')){
      organizationIds.push(organization.get('orgId',''))
    }
  })
  if(!organizationIds.includes(payload.data.orgId)) {
    return state.set('organizationData', organizationData.push(fromJS(payload.data)))
  }
  else{
    return state
  }
  }

export const getEachInstituteDetails = payload => state => {
    const instituteDetailsData = state.get('instituteDetailsData', List())

    let instituteIds = []

    instituteDetailsData.forEach((institute) => {
      if(institute.get('instId','')){
        instituteIds.push(institute.get('instId',''))
      }
    })

    if(!instituteIds.includes(payload.data.instId)){
      return state.set('instituteDetailsData', instituteDetailsData.push(fromJS(payload.data)))
    }else{
      return state
    }
}

export const getAssignedCoursesSchoolAdmin = (payload) => (state)=> {
  return state.set('assignedCoursesSchoolAdminData',fromJS(payload.data))
}