export const openAddsectionmodal = state => state.get('openAddsectionmodal', false)

export const uploadvideoSuccessShortMsg = state => state.get('manageBookResourcesSuccessShortMsg','')
export const uploadvideoSuccessLongMsg = state => state.get('manageBookResourcesSuccessLongMsg','')
export const uploadvideoFailureShortMsg = state => state.get('manageBookResourcesFailureShortMsg','')
export const uploadvideoFailureLongMsg = state => state.get('manageBookResourcesFailureLongMsg','')

export const successAlertBox = state => state.get('successAlertBox',false)
export const failureAlertBox = state => state.get('failureAlertBox',false)

export const getCourseSection = state => state.get('getCourseSection',[])

export const getBookResourceByBookId = state => state.get('allbookResourceIdData', [])

export const getExistingLearningList = state => state.get('getExistingLearningList', [])

export const getVideosByBookResourceId = state => state.get('getVideosByBookResourceId', [])

export const getOpenSingleVideoPlayer = state => state.get('openSingleVideoPlayer', false)

export const getVideoJsOptions = state => state.get('videoJsOptions', '')

export const successAlert = state => state.get('successAlert', false)
export const getErrorAlert = state => state.get('errorAlert', false)
export const successMsg = state => state.get('successMsg', '')
export const getErrorMessage = state => state.get('errorMsg', '')


export const addLearningListSuccessLongMsg = state => state.get('addLearningListSuccessLongMsg','')


export const deleteLearningListSuccessLongMsg = state => state.get('deleteLearningListSuccessLongMsg','')
