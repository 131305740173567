import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './viewAssessments.css'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import * as viewAssessmentsData from '../../../redux-assessmentModule/selector.js'
import EachViewAssessmentCourseTIO from './EachViewAssessmentCourseTIO'
import * as appData from '../../../../appSelector'

class ViewAssessmentsCourseTIO extends Component {

  constructor(props) {
    super(props);
    this.state = {}
    this.getViewAssessmentsCourseTIO = this.getViewAssessmentsCourseTIO.bind(this);
  }
  componentDidMount() {
    this.getViewAssessmentsCourseTIO();  
  }
  getViewAssessmentsCourseTIO() {
    const { eachBookId } = this.props.location.state
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.props.actions.getViewAssessmentsByCourse({
      loginUserId: this.props.userData.id,
      jwt: this.props.userData.jwt,
      bookId: eachBookId,
      clientTimeZone: timeZone
    })
  }
  render() {
    var eachFullAssessmentDataTIO = this.props.viewAssessmentsDataPageByCourseTIO;
    return (
      <EachViewAssessmentCourseTIO key={eachFullAssessmentDataTIO.testId} eachFullAssessmentDataTIO={eachFullAssessmentDataTIO}/>
    );
  }
}

const s = ({ state }) => ({
  viewAssessmentsDataPageByCourseTIO: viewAssessmentsData.getViewAssessmentsByCourse(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(ViewAssessmentsCourseTIO);