

//import { createAction } from 'redux-act'
import { createAction } from 'redux-act'
import { createAsyncAction } from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getUserInfo = createAsyncAction(
  `GET_USER_INFO`,
  payload => payload
)

const getAddressInfo = createAsyncAction(
  `GET_ADDRESS_INFO`,
  payload => payload
)

const updateUserInfo = createAsyncAction(
  `UPDATE_USER_INFO`,
  payload => payload
)

const updateAddressInfo = createAsyncAction(
  `UPDATE_ADDRESS_INFO`,
  payload => payload
)

const deleteAddressInfo = createAsyncAction(
  `DELETE_ADDRESS_INFO`,
  payload => payload
)

const setInputValue = createAction(
  `SET_INPUT_VALUE`,
  ({ path, value }) => ({ path, value })
)

const setInputStateValue = createAction(
  `SET_INPUT_STATE_VALUE`,
  ({ path , value }) => ({ path, value })
)

const toggleAddressModal = createAction(
  `TOGGLE_ADDRESS_MODAL`,
  payload => payload
)

const currentEditAddress = createAction(
  `CURRENT_EDIT_ADDRESS`,
  ({ isShippingAddress, index}) => ({
    isShippingAddress,
    index
  })
)

const resetEditAddress = createAction(
  `RESET_EDIT_ADDRESS`,
  payload => payload 
)

const deleteNewAddreess =createAction(
  `DELETE_NEW_ADDRESS`
)

const setAddressType = createAction(
  `SET_ADDRESS_TYPE`,
  payload => payload
)

const toggleAddedAddressAlert = createAction(
  `TOGGLE_ADDRESS_ALERT`,
  payload => payload
)

const toggleUserInfoAlert = createAction(
  `TOGGLE_USER_INFO_ALERT`,
  payload => payload 
)

const createEvent = createAsyncAction(
  `CREATE_EVENT`,
  payload => payload 
)

const cancelEvent = createAsyncAction(
  `CANCEL_EVENT`,
  payload => payload
)

const updateEvents = createAsyncAction(
  `UPDATE_EVENT`,
  payload => payload
)

const getUsers = createAsyncAction(
  `GET_USERS`,
  payload => payload
)

const generateMeetingLink = createAsyncAction(
  `GENERATE_MEETING_LINK`,
  payload => payload
)

const getUpcomingEventsByUserIdAndDate = createAsyncAction(
  `GET_UPCOMING_EVENTS_BY_USER_ID_AND_EVENTS`,
  payload => payload
)

const setCurrentDate = createAction(
  `SET_CURRENT_DATE`,
  payload => payload
)

const setCountry = createAction(
  `SET_COUNTRY`,
  payload => payload
)

const showOverLayLoader = createAction(
  `SHOW OVER LAY LOADER`,
  payload => payload
)

const hideOverlayLoader = createAction(
  `HIDE OVER LAY LOADER`
)



const reducer = {
  [getUserInfo.success]: (state, payload) =>
    mutators.setUserInfo(payload)(state),
  [getAddressInfo.success]: (state, payload) =>
    mutators.setAddressInfo(payload)(state),
  [updateUserInfo.success]: (state, payload) =>
    mutators.setUpdateUserInfoSuccess(payload)(state),
  [updateAddressInfo.success]: (state, payload) =>
    mutators.setAddressInfoSuccess(payload)(state),
  [deleteAddressInfo.success]: (state, payload) =>
    mutators.setDeleteAddressInfoSuccess(payload)(state),
  [setInputValue]: (state, payload) =>
    mutators.setInputValue(payload)(state),

  [toggleAddressModal]: (state, payload) =>
    mutators.toggleAddressModal(payload)(state),

  [currentEditAddress]: (state, payload) =>
    mutators.currentEditAddress(payload)(state),

  [resetEditAddress]: (state, payload) =>
    mutators.resetEditAddress(payload)(state),

  [deleteNewAddreess]: (state, payload) =>
    mutators.deleteNewAddreess(payload)(state),

  [setAddressType]: (state, payload) => 
    mutators.setAddressType(payload)(state),

  [toggleAddedAddressAlert]: (state, payload) =>
    mutators.toggleAddedAddressAlert(payload)(state),

  [toggleUserInfoAlert]: (state, payload) =>
    mutators.toggleUserInfoAlert(payload)(state),

  [getUsers.success]: (state, payload) =>
    mutators.setUsersData(payload)(state),

  [generateMeetingLink.success]: (state,payload) =>
    mutators.setMeetingLink(payload)(state),

  [createEvent.success]: (state, payload) =>
    mutators.removeMeetingLink(payload)(state),

  [createEvent.failure]: (state, payload) =>
    mutators.removeMeetingLink(payload)(state),

  [setCurrentDate]: (state,payload) =>
    mutators.setCurrentDate(payload)(state),

  [getUpcomingEventsByUserIdAndDate.success]: (state, payload) =>
    mutators.getUpcomingEventsByUserIdAndDate(payload)(state),

  [setInputStateValue]: (state, payload) =>
    mutators.setInputStateValue(payload)(state),

  [setCountry]: (state, payload) =>
    mutators.setCountry(payload)(state),

  [showOverLayLoader]: (state, payload) =>
    mutators.showOverLayLoader(payload)(state),

  [hideOverlayLoader]: (state, payload) =>
    mutators.hideOverlayLoader(payload)(state)

  
}

const restApi = {
  [getUserInfo]: api.getUserInfo(getUserInfo),
  [getAddressInfo]: api.getAddressInfo(getAddressInfo),
  [updateAddressInfo]: api.updateAddressInfo(updateAddressInfo),
  [updateUserInfo]: api.updateUserInfo(updateUserInfo),
  [deleteAddressInfo]: api.deleteAddressInfo(deleteAddressInfo),
  [createEvent]: api.createEvent(createEvent),
  [cancelEvent]: api.cancelEvent(cancelEvent),
  [updateEvents]: api.updateEvents(updateEvents),
  [getUsers]: api.getUsers(getUsers),
  [generateMeetingLink]: api.generateMeetingLink(generateMeetingLink),
  [getUpcomingEventsByUserIdAndDate]: api.getUpcomingEventsByUserIdAndDate(getUpcomingEventsByUserIdAndDate)
}

export default {
  actions: {
    getUserInfo,
    updateAddressInfo,
    updateUserInfo,
    getAddressInfo,
    deleteAddressInfo,
    setInputValue,
    toggleAddressModal,
    currentEditAddress,
    resetEditAddress,
    deleteNewAddreess,
    setAddressType,
    toggleAddedAddressAlert,
    toggleUserInfoAlert,
    createEvent,
    cancelEvent,
    updateEvents,
    getUsers,
    generateMeetingLink,
    getUpcomingEventsByUserIdAndDate,
    setCurrentDate,
    setInputStateValue,
    setCountry,
    showOverLayLoader,
    hideOverlayLoader
  },
  restApi,
  reducer
}