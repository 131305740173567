export const getDefaultGroupNamesData = state => state.get('getDefaultGroupNames', '')

export const getStateCodeInfo = state => state.get('getStateCodeInfo', [])

export const getCountryCodeInfo = state => state.get('getCountryCodeInfo', [])

export const openupdateOrderStatusModal = state => state.get('openupdateOrderStatusModal', false)

export const getAssignParentToStudentByStudentId = state => state.get('getAssignParentToStudentByStudentId', [])

export const notificationhandler = state => state.get('notificationhandler', false)