import React, { Component } from 'react';
import CoursesPageInstOrg from './CoursesPageInstOrg';
import InstOrgAddCourseDetailsModal from '../../../modals/InstOrgAddCourseDetailsModal';
import AlertBox from '../../../common/AlertBox';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { instOrgAdminConf } from '../../redux-instOrgAdminconf/index'
import * as appData from '../../../appSelector'
import * as instOrgAdminconfData from '../../redux-instOrgAdminconf/selector'
import * as modalsData from '../../../modals/selector'
import InformationBanner from '../../../common/InformationBanner';
import INFO_BANNER from '../../../common/InfoBanner';
class AddCourseDetailsInstOrg extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.getBooks = this.getBooks.bind(this);
  }
  openCourseDetailsModal = () => {
    this.props.actions.openAddBooksModal();
  }
  closeCourseDetailsModal = () => {
    this.setState({ showAddBooks: false });
    setTimeout(() => {
      this.getBooks();
    }, 3000);
  }
  getBooks() {
    this.props.actions.getBookByOrgUser({
      jwt: this.props.userData.jwt,
      orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
      userId: this.props.userData.id
    })
  }
  componentDidMount() {
    this.getBooks();
  }
  render() {
    return (
      <div  >

        {this.props.showCourseDetailsModal && this.props.showCourseDetailsModal &&
          (<InstOrgAddCourseDetailsModal show={this.props.showCourseDetailsModal} onClose={this.closeCourseDetailsModal} />
          )}
        {
          (this.props.successAlertBox || this.props.failureAlertBox) && (
            <AlertBox successAlert={this.props.successAlertBox}
              successMsg={this.props.courseDetailsPageSuccessMsg}
              errorAlert={this.props.failureAlertBox}
              errorMsg={this.props.courseDetailsPageFailureMsg} />
          )
        }
        <div className='mt-4'>
          <div className='d-flex justify-content-start m-2 mt-4'><InformationBanner isHorizontal
            message={INFO_BANNER.MESSAGE_INFO_BANNER_ADD_COURSEDETAILS_INST} />
          </div>
          <div className='mt-4'>
            <button className="btn btn-sm btn-primary ml-3 mb-3" onClick={this.openCourseDetailsModal}>
              <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} /> Add Course Details
            </button></div>
        </div>
        <hr />
        <CoursesPageInstOrg history={this.props.history} />
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  showCourseDetailsModal: instOrgAdminconfData.openAddBookModal(state),
  successAlertBox: modalsData.successAlertBox(state),
  failureAlertBox: modalsData.failureAlertBox(state),
  courseDetailsPageFailureMsg: modalsData.booksPageFailureMsg(state),
  courseDetailsPageSuccessMsg: modalsData.booksPageSuccessMsg(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...instOrgAdminConf.actions
    },
    d
  )
})
export default connect(s, d)(AddCourseDetailsInstOrg);