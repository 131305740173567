import React from 'react'
import image from '../assets/icons/images.png'

const InformationBanner = (props) => {
    const {
        isVertical,
        isHorizontal,
        message,
        icon
    } = props
    return (
      <div style={{display:'flex',justifyContent: 'center', fontStyle:'italic'}}>
        <div>
          <span className={icon}></span>
          <img alt="i icon" src={image} className="icon" width={"20px"} />
        </div>
        <div>
        {isVertical && <div>{message}</div>}
        {isHorizontal && <div>{message}</div>}
        </div>
       
      </div>
    );
}

export default InformationBanner