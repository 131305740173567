import React, { Component } from 'react';
import ContactUsImage from './../assets/images/ContactUs.jpg';
import { Button } from 'react-bootstrap';
import KnockonUs from './../assets/images/KnockonUs.jpg';
import './ContactUs.css'
import PublicFooter from "../layout/PublicFooter";
import * as app from '../appSelector'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AlertBox from '../common/AlertBox';
import { publicView } from './redux-publicView/index'
import * as publicBookData from './redux-publicView/selector';

class ContactUsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      phoneNumber: null,
      someThingExciting: '',
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSomethingExciting = this.handleSomethingExciting.bind(this);
    this.handleContactSubmit = this.handleContactSubmit.bind(this);
  }


  handleFirstNameChange(evt) {
    this.setState({
      firstName: evt.target.value,
    });
  };

  handleLastNameChange(evt) {
    this.setState({
      lastName: evt.target.value,
    });
  };

  handleCompanyChange(evt) {
    this.setState({
      companyName: evt.target.value,
    });
  };

  handleEmailChange(evt) {
    this.setState({
      email: evt.target.value,
    });
  };
  handlePhoneChange(evt) {
    this.setState({
      phoneNumber: evt.target.value,
    });
  };

  handleSomethingExciting(evt) {
    this.setState({
      someThingExciting: evt.target.value,
    });
  };

  handleContactSubmit() {
let contactFormData = {
  "firstName": this.state.firstName,
  "lastName": this.state.lastName,
  "companyName": this.state.companyName,
  "email": this.state.email,
  "phoneNumber": this.state.phoneNumber,
  "somethingExicting": this.state.someThingExciting
}
    this.props.actions.submitContactForm({
      contactUsFormData: contactFormData
    })
  }
  render() {
    return (
      <div>
        <div style={{ 'position': 'relative', 'top': '-25px' }}>
          <section style={{ 'position': 'relative' }}>
            <div className="blurImageTop">
              <img alt="contact page top" src={ContactUsImage} className="imgclassContact"></img>
            </div>
            <div className="midwhiteGet">
              <h2>GET IN TOUCH </h2>
              <hr className="hrTag" />
              <p>
                Something you wanna share , something you wanna talk to us about? Well please give us a call, send us an email, or fill out form below for us to reach you out.
            </p>
            </div>
          </section>
          <section>
            <div >
              <div className="row">
                <div className="col-sm-6 p-0">
                  <div className="blurImageCol">
                    <img alt="Knock on us contact" src={KnockonUs} className="imgclassContactCol"></img>
                    <div className="midwhiteColContact">
                      <h4 className="contact-reach-knock-align">Knock Us To Reach You</h4>
                      <h4>DURCHERE</h4>
                      <hr className="hrTag" />
                      <div>
                        <p><br />
                      Camp Hill, PA 17011 <br />
                          <span>Email: </span> reachus@durchere.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 p-0">
                  {
                    (this.props.successAlertBox || this.props.failureAlertBox) &&(
                        <AlertBox successAlert={this.props.successAlertBox} 
                        successMsg={this.props.contactAlertSuccessMsg}
                        errorAlert={this.props.failureAlertBox}
                        errorMsg={this.props.contactAlertErrorMsg} />
                    )
                  }
                
                  <div className="contactForm p-5">
                    <h4 className="contact-reach-align">Mail Us To Reach You</h4>
                    <div className="form-inline">
                    </div>
                    <input className="transparentInputs-contactus" type="text" placeholder="Enter First Name" onChange={this.handleFirstNameChange} /><br />
                    <input className="transparentInputs-contactus" type="text" placeholder="Enter Last Name" onChange={this.handleLastNameChange} /><br />
                    <input className="transparentInputs-contactus" type="text" placeholder="Enter Company Name" onChange={this.handleCompanyChange} /><br />
                    <input className="transparentInputs-contactus" type="email" placeholder="Enter Email" onChange={this.handleEmailChange} /><br />
                    <input className="transparentInputs-contactus" type="text" placeholder="Enter Phone Number" onChange={this.handlePhoneChange} /><br />
                    <input className="transparentInputs-contactus" type="text" placeholder="Something Exicting?" onChange={this.handleSomethingExciting} /><br /><br />
                    <div className="contact-reach-align-button">
                      <Button type="submit" className="contactus-button" onClick={this.handleContactSubmit}><span className="fontbuttoncolor">Reach Us</span></Button><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {!this.props.isLoggedIn && <PublicFooter />}
      </div>
    );
  }
}
const s = ({ state }) => ({
  isLoggedIn: app.getLoginStatus(state),
  successAlertBox: publicBookData.successAlertBox(state),
  failureAlertBox: publicBookData.failureAlertBox(state),
  contactAlertSuccessMsg: publicBookData.contactAlertSuccessMsg(state),
  contactAlertErrorMsg: publicBookData.contactAlertErrorMsg(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
        ...publicView.actions
    },
    d
  )
})
export default connect(s, d)(ContactUsPage);
