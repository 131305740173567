import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './viewAssessmentsStudent.css'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import * as viewAssessmentsData from '../../../redux-assessmentModule/selector.js'
import ViewAssessmentLinksStudent from './ViewAssessmentLinksStudent.js'
import * as appData from '../../../../appSelector'
import { Link } from 'react-router-dom';
import login from '../../../../login/loginn'
import WithPagination from '../../../../common/WithPagination';

class ViewAssessmentsStudent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3
    }
    this.getViewAssessments = this.getViewAssessments.bind(this);
  }
  componentDidMount() {
    this.getViewAssessments();
  }
  getViewAssessments() {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (this.props.userData.roleId === "72138" || this.props.userData.roleId === "72166") {
      this.props.actions.getViewAssessments({
        loginUserId: this.props.userData.id,
        jwt: this.props.userData.jwt,
        clientTimeZone: timeZone
      })
    } else {
      let selectedStudentData = JSON.parse(localStorage.getItem('selectedStudentData'));
      this.props.actions.getViewAssessments({
        loginUserId: selectedStudentData.studentId,
        jwt: this.props.userData.jwt,
        clientTimeZone: timeZone
      })
    }
  }
  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }
  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }
  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }
  render() {

    const { currentPage, itemsPerPage, searchText } = this.state;
    const { viewAssessmentsDataPage } = this.props;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    let currentItems = [...viewAssessmentsDataPage];
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }

    // var viewFullAssessmentsData = this.props.viewAssessmentsDataPage;
    const setStartAssessment = this.props.actions.setStartAssessment
    if (currentItems) {
      var showAssessments = currentItems.slice(indexOfFirst, indexOfLast).map((viewEachAssessmentData, i) => {
        return <ViewAssessmentLinksStudent
          key={viewEachAssessmentData.testId}
          viewEachAssessmentData={viewEachAssessmentData}
          setStartAssessment={setStartAssessment}
          refreshToken={() => {
            this.props.actions.renewToken({
              jwt: this.props.userData.jwt,
              username: this.props.userData.username
            })
          }}
        />
      })
    }

    return (

      <div>
        <div>
          {this.props.userData.roleId === "79126" ? (
            <div className='top-container-course-video'>
              <div>
                <div className='row p-2 m-3'>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to={{ pathname: '/ParentHome' }}>Home</Link></li>
                      <li className="breadcrumb-item"><Link to={{ pathname: '/student-dashboard' }}>Student-Dashboard</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">View Student Assessments</li>
                    </ol>
                  </nav>
                </div> </div>
            </div>
          ) : <i> </i>
          }
          {this.props.userData.roleId === "75126" ? (
            <div className='top-container-course-video'>
              <div>
                <div className='row p-2 m-3'>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to={{ pathname: '/ParentinstorgHome' }}>Home</Link></li>
                      <li className="breadcrumb-item"><Link to={{ pathname: '/student-dashboard' }}>Student-Dashboard</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">View Student Assessments</li>
                    </ol>
                  </nav>
                </div> </div>
            </div>
          ) : <i> </i>
          }
        </div>

        <div style={{ "margin-bottom": '70px' }}>
          <WithPagination
            itemsPerPage={itemsPerPage}
            totalItems={currentItems.length}
            currentPage={currentPage}
            onSearch={this.onSearch}
            onPaginate={this.onPaginate}
          >
            {showAssessments}
          </WithPagination>
        </div>
      </div>
    );
  }
}

const s = ({ state }) => ({
  viewAssessmentsDataPage: viewAssessmentsData.getViewAssessments(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions,
      ...login.actions
    },
    d
  )
})
export default connect(s, d)(ViewAssessmentsStudent);