import React, {Component} from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as modalsData from './selector'
import * as appData from '../appSelector'

class AssignUserBookModal extends Component {

    constructor(props) {
        super(props);
            this.state = {
                selectedBooks:[],
                changingAssignBooks: false,
                deletedItemsData: [],
                apiCallBooks:[]
            }
        this.assignBooksToUser = this.assignBooksToUser.bind(this);
        this.callassignBooksToUser = this.callassignBooksToUser.bind(this);
        this.handleAssociatedBooksChange = this.handleAssociatedBooksChange.bind(this);
        this.revertingValues = this.revertingValues.bind(this)
        this.deleteBooksToUser= this.deleteBooksToUser.bind(this)
    }
    assignBooksToUser(){
        if( this.state.changingAssignBooks){
            this.callassignBooksToUser()
        }
        this.props.onClose();
        this.revertingValues()
    }

      handleAssociatedBooksChange= event =>{
        let deletedItems = []
        if(!event) {
            deletedItems = deletedItems.concat(this.props.getDefaultSelectedCourses)  
        }
        else {
            this.props.getDefaultSelectedCourses.forEach((item,index) => {
                const doesNotContain = event.findIndex(e => e.label===item.label) ===-1
                    if(doesNotContain
                        && deletedItems.findIndex(contain => contain.label === item.label)===-1 
                        ) {
                            deletedItems = deletedItems.concat(item) 
                        }
            })
        }
        if(event && event.length>this.props.getDefaultSelectedCourses.length){
            let addedItems = [];
            event.forEach((item,index) => {
                const doesNotContain = this.props.getDefaultSelectedCourses.findIndex(courses => courses.label===item.label) ===-1
                    if(doesNotContain
                        && addedItems.findIndex(contain => contain.label === item.label)===-1 
                        ) {
                            addedItems = addedItems.concat(item) 
                        }
            })
            this.setState({
                apiCallBooks: addedItems,
                changingAssignBooks: true
            })
        }
        this.setState({
            selectedBooks: event && event,
            changingAssignBooks: true
        })
        if(deletedItems.length > 0) {
            this.setState({
                deletedItemsData: deletedItems
            })
        }
    }
    revertingValues () {
        this.setState({
            changingAssignBooks: false
        })
    }
    deleteBooksToUser(book){
        this.props.actions.deleteAssignCourseUser({
            userId  :  this.props.eachrowUserData.id,
            bookId  :  book.value,
            loginId : this.props.userData.id,
            jwt: this.props.userData.jwt
        })
    }

    callassignBooksToUser(){
        var assignUserCourses = {
            "userId": this.props.eachrowUserData.id,
            "bookIds": this.state.apiCallBooks.map(book => book.value),
            "loginId": this.props.userData.id
           }
            const apiCallBooks = [];

            this.state.apiCallBooks.forEach(apiCallBook => {
                const apiCallBookIndex = this.state.deletedItemsData.findIndex(deletedBookData => deletedBookData.label === apiCallBook.label)
                if(apiCallBookIndex === -1){
                    apiCallBooks.push(apiCallBook)
                }
            });
      
           if(apiCallBooks.length>0){
            this.props.actions.assignUserCourse({
                assignUserCourseData: assignUserCourses,
                jwt: this.props.userData.jwt
            })
           }

        if(this.state.deletedItemsData.length>0){
            this.state.deletedItemsData.map(item => this.deleteBooksToUser(item))
        }
        
    }
 
    render() {
        let associtedBooksOptionsData;
        let initialDefaultCourses = [];
        if(["79146","76517","72195"].includes(this.props.userData.roleId))
        {
            associtedBooksOptionsData = this.props.getAllTeacherAssociatedCourses
        }
        else{
            associtedBooksOptionsData = this.props.getAllAssociatedCourses;
        }
        associtedBooksOptionsData.forEach(associtedBooksData => {
            const defaultCoursesIndex = this.props.getDefaultSelectedCourses.findIndex(defaultCourses => defaultCourses.label === associtedBooksData.label)
            if(defaultCoursesIndex !== -1){
                initialDefaultCourses.push(associtedBooksData);
            }
        });
        return (
            <Modal show={this.props.show}
                   backdrop="static"
                   keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Assign Courses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="mt-2 row">
              <div className="col-md-6">
                <label>Associated Courses</label>
              </div>
              <div className="col-md-12">
              <Select value = {
                  this.state.changingAssignBooks 
                  ? this.state.selectedBooks
                  : initialDefaultCourses
                  }
                  isMulti 
                  isClearable 
                  options={associtedBooksOptionsData} 
                  onChange={ this.handleAssociatedBooksChange}>
              </Select>
              </div>
            </div>
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => {
                         this.props.onClose()  
                         this.revertingValues()
                    }
                        }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => this.assignBooksToUser()}>
                        Assign
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const s = ({ state }) => ({
    getDefaultSelectedCourses : modalsData.getDefaultSelectedCourses(state),
    getAllAssociatedCourses : modalsData.getAllAssociatedCourses(state),
    getAllTeacherAssociatedCourses: modalsData.getAllTeacherAssociatedCourses(state),
    userData: appData.getUserData(state)
  })
  const d = (d) => ({
    actions: bindActionCreators(
      {
        ...modals.actions
      },
      d
    )
  })
export default connect(s, d)(AssignUserBookModal);
