import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Select from 'react-select';
import * as videoStreamingData from './redux-videoStreaming/selectors'
import * as appData from '../appSelector';
import './BookResoursesManagement.css';
import { videoStreamingConf } from './redux-videoStreaming/index';
import Addsectionmodal from '../modals/Addsectionmodal';
import AlertBox from '../common/AlertBox';
import * as modalsData from '../modals/selector'
import InformationBanner from '../common/InformationBanner';
import INFO_BANNER from '../common/InfoBanner';
import AlertModal from '../common/AlertModal';

class BookResoursesManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedbookId: "",
            selectedCourseId: "",
            selectedbookResourceType: "",
            enteredbookResourceName: "",
            selectedorderNumber: "",
            selectedresourceId: "",
            selectedsectionId: "",
            enteredvideo: "",
            enteredimage: "",
            enteredvideoTitle: "",
            entereddescription: "",
            selectedaddcourseSectionType: "",
            successAlert: false,
            errorAlert: false,
        };
        this.handleBookResourceType = this.handleBookResourceType.bind(this);
        this.handleResourceName = this.handleResourceName.bind(this);
        this.handleResourceTitle = this.handleResourceTitle.bind(this);
        this.handleCourseSection = this.handleCourseSection.bind(this);
        this.handleOrderNumber = this.handleOrderNumber.bind(this);
        this.handlesectionId = this.handlesectionId.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onImageChange = this.onImageChange.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.handlevideoTitle = this.handlevideoTitle.bind(this);
        this.handleDescription = this.handleDescription.bind(this);
        this.handleaddcourseSectionType = this.handleaddcourseSectionType(this);
    }

    onFileChange(event) {
        this.setState({ enteredvideo: event.target.files[0] });

    }
    onImageChange(event) {
        this.setState({ enteredimage: event.target.files[0] });

    }

    onFileUpload = () => {
        const formDatatest = new FormData();
        var sectionId = this.state.selectedsectionId;
        var resourceId = this.state.selectedresourceId;
        var orderNumber = this.state.selectedorderNumber;
        formDatatest.append('videoTitle', this.state.enteredvideoTitle);
        formDatatest.append('description', this.state.entereddescription);
        formDatatest.append('video', this.state.enteredvideo);
        formDatatest.append('image', this.state.enteredimage);
        formDatatest.append('sectionId', sectionId);
        if (resourceId != null) {
            formDatatest.append('resourceId', resourceId);
        }

        formDatatest.append('orderNumber', orderNumber);
        formDatatest.append('bookResourceType', this.state.selectedbookResourceType);
        formDatatest.append('bookResourceName', this.state.enteredbookResourceName);
        formDatatest.append('bookId', this.props.location.state.bookId);
        formDatatest.append("userId", this.props.userData.id);
        this.props.actions.uploadVideo({
            formData: formDatatest,
            jwt: this.props.userData.jwt
        })

    }

    handleBookResourceType(event) {
        let bookResourceType = event.value;
        console.log("bookResourceType", bookResourceType)
        this.setState({
            selectedbookResourceType: bookResourceType,
        })
        if (bookResourceType === "course") {
            this.callgetBookResourceByBookId();
            this.callgetCourseSection();
        }
    }

    handleResourceName = (event) => {
        let name = event.label
        let bookResourceidType = event.value;
        console.log("bookResourceidType", typeof bookResourceidType)
        this.setState({
            enteredbookResourceName: name,
            selectedresourceId: bookResourceidType,
        })
        this.callgetCourseSection();
    }

    handleaddcourseSectionType(event) {
        let addcourseSectionType = event.value;
        this.setState({
            selectedaddcourseSectionType: addcourseSectionType,
            selectedsectionId: addcourseSectionType
        })
    }

    handleResourceTitle(event) {
        let bookResourceName = event.target.value;
        this.setState({
            enteredbookResourceName: bookResourceName,
        })
    }

    handleCourseSection = (event) => {
        let addcourseSectionType = event.value;
        this.setState({
            selectedaddcourseSectionType: addcourseSectionType,
            selectedsectionId: addcourseSectionType
        })
    }

    handleOrderNumber(event) {
        let orderNumber = event.target.value;
        this.setState({
            selectedorderNumber: orderNumber,
        })
    }

    handlesectionId(event) {
        let sectionId = event.value;
        this.setState({
            selectedsectionId: sectionId,
        })
    }

    handlevideoTitle(event) {
        let videoTitle = event.target.value;
        this.setState({
            enteredvideoTitle: videoTitle,
        })
    }

    handleDescription(event) {
        let description = event.target.value;
        this.setState({
            entereddescription: description,
        })
    }

    callgetCourseSection() {
        this.props.actions.getCourseSection({
            userId: this.props.userData.id,
            jwt: this.props.userData.jwt
        })
    }

    callgetBookResourceByBookId() {
        this.props.actions.getBookResourceByBookId({
            jwt: this.props.userData.jwt,
            bookId: this.props.location.state.bookId,
            dropdownValue: true
        })
    }
    handleSuccessAlertModalClose() {
        this.props.actions.handleSuccessAlertModalClose();

    }
    handleErrorAlertModalClose() {
        this.props.actions.handleErrorAlertModalClose();
    }

    render() {
        var successAlert = this.props.successAlert;
        var errorAlert = this.props.errorAlert;
        var successMsg = this.props.successMsg;
        var errorMsg = this.props.errorMsg;
        const resourceTypeOption = [{ "value": "video", "label": "Video" }, { "value": "course", "label": "Course" }, { "value": "ibook", "label": "I-Book" }];
        return (
            <div className='' >
                <AlertModal
                    type="success"
                    show={successAlert}
                    title="Success"
                    message={successMsg}
                    onClose={() => this.handleSuccessAlertModalClose()}
                ></AlertModal>
                <AlertModal
                    type="danger"
                    show={errorAlert}
                    title="Error"
                    message={errorMsg}
                    onClose={() => this.handleErrorAlertModalClose()}
                ></AlertModal>
                {
                    (this.props.successAlertBox || this.props.failureAlertBox) && (
                        <AlertBox successAlert={this.props.successAlertBox}
                            successShortMsg={this.props.uploadvideoSuccessShortMsg}
                            successMsg={this.props.uploadvideoSuccessLongMsg}
                            errorAlert={this.props.failureAlertBox}
                            errorShortMsg={this.props.uploadvideoFailureShortMsg}
                            errorMsg={this.props.uploadvideoFailureLongMsg} />
                    )
                }

                {this.props.showAddsectionmodal && this.props.showAddsectionmodal &&
                    (<Addsectionmodal show={this.props.showAddsectionmodal} onClose={this.closeAddsectionmodal} />
                    )}
                <div className="container mt-4 p-3">
                    <div className="row">
                        <div className="col font-weight-bold h3  ">
                            Book Resource Management
                        </div>
                        <div className="col d-flex justify-content-end" >
                            <button className="mb-3 text-white btn btn-primary  p-2 col-6  "
                                onClick={() => this.props.actions.openAddsectionmodal()}>Add Sections
                            </button>
                        </div>
                    </div></div>
                <hr></hr>
                <div className="container  ">
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <label className='font-weight-bold h4'>Resource Type :</label>
                        </div>
                        <div className="col-md-8">
                            <Select onChange={this.handleBookResourceType} options={resourceTypeOption} />
                        </div>
                    </div>

                    {this.state.selectedbookResourceType === "course" ? (
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <label className='font-weight-bold h5'>Resource Name :</label>
                            </div>
                            <div className="col-md-8">
                                <Select
                                    options={this.props.getBookResourceByBookIdOptions}
                                    onChange={this.handleResourceName}  >
                                </Select>
                            </div>
                        </div>) : <i></i>}

                    {this.state.selectedresourceId === 0 || this.state.selectedbookResourceType === "video" ? (
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <label className='font-weight-bold h5'>Resource Title : </label>
                            </div>
                            <div className="col-md-8">
                                <input type="text" onChange={this.handleResourceTitle} placeholder="Enter Resource Title" className="css-yk16xz-control form-control" value={this.enteredbookResourceName} 
                                required/>
                            </div>
                        </div>) : <i></i>}

                    {this.state.selectedbookResourceType === "course" ? (
                        <div>

                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <label className='font-weight-bold h5'>Course Section : </label>
                                </div>
                                <div className="col-md-8">
                                    <Select
                                        options={this.props.getCourseSection}
                                        onChange={this.handleCourseSection}    >
                                    </Select>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-4">
                                    <label className='font-weight-bold h5'> Order Number : </label>
                                </div>
                                <div className="col-md-8">
                                    <input type="text"
                                        onChange={this.handleOrderNumber} placeholder="Enter order number" className="css-yk16xz-control form-control" required />
                                </div>
                            </div>

                        </div>
                    ) : <i></i>}

                    <div className="row mt-4">
                        <div className="col-md-4">
                            <label className='  font-weight-bold h5'> {this.state.selectedbookResourceType==="ibook"?"Interactive Book Title :":"Video Title :"} </label>
                        </div>
                        <div className="col-md-8">
                            <input type="text" onChange={this.handlevideoTitle}
                                placeholder={this.state.selectedbookResourceType==="ibook"?"Enter Interactive Book Title":"Enter Video Title"} className="css-yk16xz-control form-control" />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4">
                            <label className='font-weight-bold h5'>{this.state.selectedbookResourceType==="ibook"?"Interactive Book :":"Video :"}</label>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <input type="file" name="image" accept={this.state.selectedbookResourceType==="ibook"?".zip,.rar,.7zip":".mp4"} onChange={this.onFileChange} />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4">
                            <label className='font-weight-bold h5'>image : </label>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <input type="file" name="image" accept=".jpg, .jpeg, .png" onChange={this.onImageChange} />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4">
                            <label className='font-weight-bold h5'>Decription :</label>
                        </div>
                        <div className="col-md-8">
                            <textarea className="form-control mt-3" rows="3"
                                placeholder="Enter the Description" onChange={this.handleDescription} required></textarea>
                        </div>
                    </div>


                    <hr className='mt-4 ' />
                    <div className='mt-4 d-flex justify-content-end'>
                        <button className=" text-white btn btn-primary mt-2 " onClick={this.onFileUpload}>Submit</button>
                    </div>
                </div>
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    successAlertBox: videoStreamingData.successAlertBox(state),
    failureAlertBox: videoStreamingData.failureAlertBox(state),
    uploadvideoFailureShortMsg: videoStreamingData.uploadvideoFailureShortMsg(state),
    uploadvideoFailureLongMsg: videoStreamingData.uploadvideoFailureLongMsg(state),
    uploadvideoSuccessShortMsg: videoStreamingData.uploadvideoSuccessShortMsg(state),
    uploadvideoSuccessLongMsg: videoStreamingData.uploadvideoSuccessLongMsg(state),
    showAddsectionmodal: videoStreamingData.openAddsectionmodal(state),
    courseSectionSuccessMsg: modalsData.courseSectionSuccessMsg(state),
    courseSectionFailureMsg: modalsData.courseSectionFailureMsg(state),
    getCourseSection: videoStreamingData.getCourseSection(state),
    getBookResourceByBookIdOptions: videoStreamingData.getBookResourceByBookId(state),
    successAlert: videoStreamingData.successAlert(state),
    errorAlert: videoStreamingData.getErrorAlert(state),
    successMsg: videoStreamingData.successMsg(state),
    errorMsg: videoStreamingData.getErrorMessage(state),

})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(BookResoursesManagement);
