import React, { Component } from "react";
import "./OrdersPage.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as execData from "../executive/redux-executive/selector";
import { executive } from "../executive/redux-executive/index";
import * as appData from ".././appSelector";
import CartModal from "../modals/CartModal";
import { modals } from '../modals/index'
import AlertBox from '../common/AlertBox'

class OrdersPage extends Component {
  constructor() {
    super();
    this.state = {
      book: null,
      showCartModal: false,
      quantity: "",
    };
  }

  openCartModal = () => {
    this.setState({
      showCartModal: true,
    });
  };

  closeCartModal = () => {
    this.setState({ showCartModal: false });
  };

  handleQuantity = (e) => {
    this.props.actions.setQuantity(e.target.value)
    this.setState({ quantity: e.target.value });
  };


  onCartSubmit = () => {


    const { bookData } = this.props.location.state;

    console.log("this.props.getUserData.", this.props.userData.instInfo)
    const instInfo = this.props.userData.instInfo
    let InstValues = [];
    instInfo.forEach((i, index) => { InstValues = Object.keys(i) });
    const instId = InstValues[0];
    const quantity = this.props.quantity

    const totalAmount = quantity * bookData.price;
    const cartDetails = [
      {
        bookId: bookData.bookId,
        bookPrice: bookData.price,
        instId: instId,
        quantity: parseInt(this.state.quantity),
        totalAmount
      }

    ]

    const cartData = {
      "addToCart": {
        "cartTotal": totalAmount,
        "userId": this.props.userData.id
      },
      cartDetails

    }
    this.props.actions.addToCart({ data: cartData, jwt: this.props.userData.jwt });
  }

  render() {
    const { bookData } = this.props.location.state;
    console.log(bookData, 'bookData')
    var imageFromBucket =
      this.props.bookData &&
      this.props.bookData.responseObject.imageBucketEnable;
    var imageUrl = "";
    if (imageFromBucket) {
      imageUrl = bookData.imageBucketLocationChanged;
    } else {
      imageUrl = bookData.imageappLocationChanged;
    }
    const { showCartModal } = this.state;
    // console.log(bookData);
    return (
      <div className="mt-4">
        <p>
          {
            (this.props.getOrderStatusSuccessAlertExec || this.props.getOrderStatusErrorAlertExec) && (
              <AlertBox successAlert={this.props.getOrderStatusSuccessAlertExec} successMsg={this.props.getaddToCartStatusSuceessExecMsg} errorAlert={this.props.getOrderStatusErrorAlertExec} errorMsg={this.props.getaddToCartStatusErrorExecMsg} />
            )
          }
        </p>

        <div className="info-container ">
          <div className="image">
            <img src={imageUrl} alt="book title" />
          </div>
          <div className="content">
            <h1 className="title"> {bookData.bookTitle} </h1>
            <div className="description">
              <p> {bookData.bookDescription} </p>
            </div>
            <div className="price">
              <p>
                Regular :
                <span className="rupee"></span>
                <del> {bookData.price + 100}</del>{" "}
              </p>
              <p>
                Sale :
                <span className="rupee"></span>
                {bookData.price}</p>
            </div>
            {["76517", "72195", "72138"].includes(this.props.userData.roleId) && (
              <div className="row">
                <div className="col-md-4">
                  <label>Quantity : </label>
                </div>
                <div className="col-md-8">
                  <input
                    value={this.props.quantity}
                    type="number"
                    min={1}
                    max={5}
                    onChange={this.handleQuantity}
                    placeholder="Enter Quantity"
                    className="css-yk16xz-control"
                    required
                  />
                </div>
              </div>
            )}
            {["78129", "77138"].includes(this.props.userData.roleId) && (
              <CartModal
                bookData={bookData}
                history={this.props.history}
                onClose={this.closeCartModal}
                show={showCartModal}
              ></CartModal>
            )}
            <button
              onClick={
                ["76517", "72195", "72138"].includes(this.props.userData.roleId)
                  ? () => this.onCartSubmit()
                  : () => this.openCartModal()
              }
              className="add-to-cart btn btn-warning w-75 mt-5 mx-auto d-block"
            >
              ADD TO CART
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const s = ({ state }) => ({
  bookData: execData.getBookByOrgUser(state),
  userData: appData.getUserData(state),
  quantity: execData.getQuantity(state),
  bookOrders: execData.getAssignCoursesSchoolAdminData(state),
  getOrderStatusSuccessAlertExec: execData.getOrderStatusSuccessAlertExec(state),
  getOrderStatusErrorAlertExec: execData.getOrderStatusErrorAlertExec(state),
  getaddToCartStatusSuceessExecMsg: execData.getaddToCartStatusSuceessExecMsg(state),
  getaddToCartStatusErrorExecMsg: execData.getaddToCartStatusErrorExecMsg(state),

});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...executive.actions,
      ...modals.actions
    },
    d
  ),
});

export default connect(s, d)(OrdersPage);
