import { all } from 'redux-saga/effects'

import  instaOrgAdminTask from './instOrgAdmin/redux-instOrgAdminconf/instaOrgAdminTask'

import adminTask from './admin/redux-adminconf/adminTask'

import loginTask from './login/loginTask'

import orderTask from './order/redux-orders/orderTask'

import profileTask from './profile/profileTask'

const taskListeners = [
    instaOrgAdminTask.listener(),
    adminTask.listener(),
    loginTask.listener(),
    orderTask.listener(),
    profileTask.listener()
]

export default function* rootSaga(...args){
    yield all([...taskListeners])
}