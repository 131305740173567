import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as defaultGrouNamesData from './selector';
import { common } from './index'
import '../common/AddUsers.css';
import AssignGroupModal from "../modals/AssignGroupModal";
import * as appData from '../appSelector'
class AssignGroupLinkRender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            modalassignType:false,
            defaultSelectedBooks: []
        }
        this.openAssignUserGroupModal = this.openAssignUserGroupModal.bind(this);
        this.closeAssignGroupBookModal = this.closeAssignGroupBookModal.bind(this);
        this.defaultSelectedGroups = this.defaultSelectedGroups.bind(this)
    }

    closeAssignGroupBookModal() {
        this.setState({ show: false });
    }
    openAssignUserGroupModal() {
        this.defaultSelectedGroups()
        this.setState({
            show: true,
            modalassignType:true
        });
    }

    defaultSelectedGroups() {
        this.props.actions.getDefaultGroupNames({
            userId: this.props.data.id,
            jwt: this.props.userData.jwt
        })
    }

    render() {
        return (
            (this.state.modalassignType) ? (<div>
                <AssignGroupModal eachrowUserData={this.props.data} show={this.state.show} onClose={this.closeAssignGroupBookModal} />
                <button className="btn btn-success" onClick={() =>this.openAssignUserGroupModal() }>
                Assign Group
                </button>
            </div>) : (
                    <button className="btn btn-success" onClick={() =>this.openAssignUserGroupModal()}>Assign Group</button>
                )
        );
    }
}
const s = ({ state }) => ({
    defaultGrouNamesData: defaultGrouNamesData.getDefaultGroupNamesData(state),
    userData: appData.getUserData(state)
  })
  const d = (d) => ({
    actions: bindActionCreators(
      {
        ...common.actions
      },
      d
    )
  })
export default connect(s,d)(AssignGroupLinkRender);