import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class DeleteCourseModal extends Component {
  render() {
    return (
      <Modal show={this.props.show} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Do you really want to delete the Course? </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deleting course will remove all the data associated with course{" "}
          <b>{this.props.currentDeletedCourse.get("courseName", "")}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.props.onDelete}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteCourseModal;
