//import { createAction } from 'redux-act'
import {createAsyncAction} from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getStudentAssociatedBooks = createAsyncAction(
    `GET_STUDENT_ASSOSIATED_BOOKS_STUDENTS`,
    payload => payload
)

const reducer = {
    [getStudentAssociatedBooks.success]: (state, payload) =>
      mutators.getStudentAssociatedBooks(payload)(state)
}

const restApi = {
   [getStudentAssociatedBooks]: api.getStudentAssociatedBooks(getStudentAssociatedBooks) 
}

export default {
    actions: {
        getStudentAssociatedBooks
    },
    restApi,
    reducer
}