import React, { Component } from 'react';
import TeacherPanelLinksInstOrg from './TeacherPanelLinksInstOrg.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as teacherInstOrgData from './selector';
import { teacherinstorg } from './index'
import * as appData from '.././appSelector'
import { executive } from '../executive/redux-executive/index'
import * as execData from '../executive/redux-executive/selector'
import Calendar from "../calendar";
class TeacherHomeInstOrg extends Component {

  componentDidMount() {
    this.props.actions.getTeacherAssociatedBooks({
      userIdTeacher: this.props.userData.id,
      jwt: this.props.userData.jwt
    })
  }

  render() {

    var teacherBooksData = this.props.teachersData;
    if (teacherBooksData) {
      var showBooks = teacherBooksData.map((teacherAssocBookData, i) => {
        return (
          <TeacherPanelLinksInstOrg
            key={teacherAssocBookData.bookId}
            teacherAssocBookData={teacherAssocBookData}
          />
        );
      })
    }
    return (
      <div className='mt-4'>
        <div className={`${this.props.isCalendarShown ? 'move-left' : ''}`}>
          <div style={{
            paddingBottom: '5px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer'
          }}
            onClick={() => this.props.actions.toggleCalendar(!this.props.isCalendarShown)}
          >
            <img
              src={'https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png'}
              alt="Calendar"
            />
          </div>

          {showBooks}
        </div>
        {
          this.props.isCalendarShown &&
          <div className="calender-wrapper">
            <a className="calendar-external-icon" href="/calendar" target="_blank"><i className="fa-external-link fa" aria-hidden="true" style={{ color: 'black', fontFamily: "FontAwesome" }} /></a>
            <Calendar callApiGetAPI={true} />
          </div>
        }
      </div>
    );

  }
}
const s = ({ state }) => ({
  teachersData: teacherInstOrgData.getTeachersData(state),
  userData: appData.getUserData(state),
  isCalendarShown: execData.isCalendarShown(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...teacherinstorg.actions,
      ...executive.actions,
    },
    d
  )
})
export default connect(s, d)(TeacherHomeInstOrg);