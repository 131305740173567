import React, { Component } from 'react';

class ViewEachUserData extends Component {
  render() {
    return (
      <div>
        <div>
          <div>
              First Name: {this.props.data.firstName}&nbsp;&nbsp;
              Last Name: {this.props.data.lastName}&nbsp;&nbsp;
              Role: {this.props.data.roleName}&nbsp;&nbsp;
              E-mail: {this.props.data.email}&nbsp;&nbsp;
              Cell Phone: {this.props.data.cellPhoneNumber}&nbsp;&nbsp;
            </div>
        </div>
      </div>
    );
  }
}
export default ViewEachUserData;