import React, { Component } from "react";
import "./AddCourses.css";
import CourseTable from "././CourseTable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { adminConf } from "../../redux-adminconf/index";
import adminConfTask from "../../redux-adminconf/adminTask";

import * as selectors from "../../redux-adminconf/selector";

import * as appSelector from "../../../appSelector";

import AlertBox from "../../../common/AlertBox";

import ApiMsg from '../../../common/ApiMsgs'
import InformationBanner from "../../../common/InformationBanner";
import INFO_BANNER from "../../../common/InfoBanner";
class AddCoursesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      standards: [],
      coursesData: [],
      changedCourses: [],
      userId: "",
      orgId: "",
    };
  }
  componentDidMount() {
    let pathSeparator = "/";
    let orgIdAdmRteInCrses = Number(
      Object.keys(this.props.userProps.orgInfo[0])
    );
    let userIdAdmRteInCrses = this.props.userProps.id;
    this.props.actions.getCoursesAndStandardData({
      jwt: this.props.userProps.jwt,
      orgIdAdmRteInCrses,
      pathSeparator,
      userIdAdmRteInCrses,
    });
    this.props.actions.deleteCourseAlert(false);
    this.props.actions.deleteCourseFailureAlert(false)
  }
  render() {
    const userId = this.props.userId;
    const orgId = this.props.orgId;
    let userProps = this.props.userProps;
    return (
      <div className="m-5">
        <div className="container ">
         
        <AlertBox
          successAlert={this.props.deleteCourseAlertBox}
          errorAlert={this.props.deleteCourseFailureAlertBox}
          errorMsg={ApiMsg.COURSE_DELETE_FAILURE}
          successMsg={ApiMsg.COURSE_DELETE_SUCCESS}
        ></AlertBox>
         <div className="row">
            <div className="col-lg-2"><InformationBanner isHorizontal 
            message = {INFO_BANNER.MESSAGE_INFO_BANNER_ADD_COURSE}/></div>
          </div>
        </div>
        <div className="container">
          <CourseTable
            stdCoursesObject={this.props.standards}
            userProps={userProps}
            history={this.props.history}
            userId={userId}
            orgId={orgId}
            addCourses={this.props.actions.addCourses}
            successMsg={this.props.successMsg}
            successAlert={this.props.successAlert}
            errorAlert={this.props.errorAlert}
            errorMsg={this.props.errorMsg}
            handleSuccessAlertModalClose={
              this.props.actions.handleSuccessAlertModalCloseForCourses
            }
            handleErrorAlertModalClose={
              this.props.actions.handleErrorAlertModalCloseForCourses
            }
            deleteCourseAlert={this.props.deleteCourseAlert}
            handleDeleteCourseModalClose={
              this.props.actions.handleDeleteCourseModalClose
            }
            storingIndexesOfCurrentDeletedCourses={
              this.props.actions.storingIndexesOfCurrentDeletedCourses
            }
            removingCourses={this.props.actions.removingCourses}
            addingCourses={this.props.actions.addingCourses}
            jwt={this.props.userProps.jwt}
            updateCourses={this.props.actions.updateCourses}
            orgIdAdmRteInCrses={Number(
              Object.keys(this.props.userProps.orgInfo[0])
            )}
            pathSeparator={"/"}
            userIdAdmRteInCrses={this.props.userProps.id}
            currentDeletedCourse={this.props.currentDeletedCourse}
            deleteCourseAlertBox={this.props.deleteCourseAlertBox}
          />
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  standards: selectors.getStatndars(state),
  userId: selectors.getStandardUrlUserId(state),
  orgId: selectors.getStandardUrlOrgId(state),
  coursesData: selectors.getCourses(state),
  successMsg: selectors.getSuccessMsgCourses(state),
  successAlert: selectors.getSuccessAlertCourses(state),
  errorAlert: selectors.getErrorAlertCourses(state),
  errorMsg: selectors.getErrorMsgCourses(state),
  deleteCourseAlert: selectors.getDeleteCourseAlert(state),
  currentDeletedCourse: selectors.currentDeletedCourse(state),
  deleteCourseAlertBox: selectors.deleteCourseAlert(state),
  deleteCourseFailureAlertBox: selectors.deleteCourseFailureAlertBox(state),
  userProps: appSelector.getUserData(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...adminConf.actions,
      ...adminConfTask.actions,
    },
    d
  ),
});

export default connect(s, d)(AddCoursesPage);
