const API_MSGS = {

    ADD_USER_MSG_SUCCESS: 'Successfully added the user to Organization',
    ADD_USER_MSG_FAIL: 'Failed to add the user to Organization',
    EDIT_USER_MSG_SUCCESS: 'Successfully updated the user to Organization',
    EDIT_USER_MSG_FAIL: 'Failed to update the user to Organization',
    ADD_GROUP_MSG_SUCCESS: 'Successfully created the group',
    ADD_GROUP_MSG_FAIL: 'Failed to create the group',
    ASSIGN_COURSES_MSG_SUCCESS: 'Successfully Assigned the course to the user',
    ASSIGN_COURSES_MSG_FAIL: 'Failed to Assign the course to the user',
    ASSIGN_GROUPS_MSG_SUCCESS: 'Successfully Assigned the groups to the user',
    ASSIGN_GROUPS_MSG_FAIL: 'Failed to Assign the groups to the user',
    REMOVE_USER_MSG_SUCCESS: 'Successfully Deleted the the user from Association',
    REMOVE_USER_MSG_FAIL: 'Failed to Delete the the user from Association',
    ADD_BOOKS_SUCCESS: 'Successfully added the Books Details to Organization',
    ADD_BOOKS_FAIL: 'Failed to add the Books Details to Organization',
    DELETE_BOOK_SUCCESS: 'Successfully Deleted the Book Details from Organization',
    DELETE_BOOK_FAIL: 'Failed to delete the Book Details from Organization',
    CONTACT_FORM_SUCCESS: 'We will reach you as soon as possible',
    CONTACT_FORM_FAIL: 'Please Fill All The Details to Reach us',
    COURSE_DELETE_SUCCESS: 'Course deleted Successfully',
    COURSE_DELETE_FAILURE: 'Course  is not deleted successfully',
    STANDARD_DELETE_SUCCESS: 'Successfully deleted standard',
    STANDARD_DELETE_FAILURE: 'Standard is not deleted successfully',
    ADD_TO_CART_SUCCESS: 'Successfully added to cart',
    ADD_TO_CART_FAILURE: 'not added to cart',
    ADD_BOOK_STOCK_SUCCESS: 'Successfully added the Quantity to book',
    ADD_BOOK_STOCK_FAIL: 'Failed to add the Quantity to the book',
    UPDATE_ORDER_STATUS_SUCCESS: 'Successfully updated the order information',
    UPDATE_ORDER_STATUS_FAIL: 'Failed to update the order information',
    CART_STATUS_SUCCESS_EXEC: 'Successfully added the Items to cart',
    CART_STATUS_EXEC_FAIL: 'Failed to add the Items to cart',
    UPLOAD_VIDEO_FAIL: "Failed to upload the video",
    UPLOAD_VIDEO_SUCCESS: "successfully uploaded the video",
    ADDCOURSE_SECTION_FAIL: "failed to add the details for addSectionCourse",
    ADDCOURSE_SECTION_SUCCESS: " succcessfully added the details for addSectionCourse ",
    ASSIGN_PARENT_TO_STUDENT_MSG_FAIL: "failed to assign parent to student",
    ASSIGN_PARENT_TO_STUDENT_MSG_SUCCESS: "Succesfully assigned parent to student",

}
export default API_MSGS