import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class StudentPanelLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.openAttendance = this.openAttendance.bind(this);
  }
  openAttendance(bookId) {
    console.log("bookId",bookId)
    localStorage.setItem('selectedBookIdRoster', bookId)
    window.open("/attendance")
  }
  render() {
    var imageFromBucket = this.props.studentAssocBookData &&
    this.props.studentAssocBookData.imageBucketEnable;
  var imageUrl = "";
  if (imageFromBucket) {
    imageUrl = this.props.studentAssocBookData.imageBucketLocationChanged;
  } else {
    imageUrl = this.props.studentAssocBookData.imageappLocationChanged;
  }
    return (
      <div className="books-card-wrapper col-md-12">
        <div className="card" key={this.props.studentAssocBookData.bookId}>
          <div className="card-header">
          <b>Standard Name:&nbsp;</b>{this.props.studentAssocBookData.standardName}&nbsp;&nbsp;
          <b>Course Name:&nbsp;</b>{this.props.studentAssocBookData.courseName}
          </div>
          <div className="card-body">
            <div className="row">
            <div className="col-4">
                <img className="bookImageSize" alt={this.props.studentAssocBookData.bookTitle} src={imageUrl} />
              </div>
              <div className="col-4">
                <div>
                  <div>
                    <b>Course Details:</b>
                  </div>
                  <div className="mt-1">
                  Title: {this.props.studentAssocBookData.bookTitle}
                  </div>
                  <div className="mt-1">
                  Description: {this.props.studentAssocBookData.bookDescription}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <ul>
                  <li>
                    <Link className="btn btn-warning mb-3" to={{ pathname: '/ViewAssessmentsByCourse', state: { eachBookId: this.props.studentAssocBookData.bookId} }}>View Assessments</Link>
                  </li>
                  <li>
                      <Link className="mb-3 text-white btn btn-info" to={{ pathname: '/view-resources', state: { bookId: this.props.studentAssocBookData.bookId, bookData: this.props.studentAssocBookData } }}> View Resources </Link>
                  </li>
                  <li>
                    <Link className="mb-3 text-white btn btn-warning" to={{ pathname: '/Orders', state: { bookId: this.props.studentAssocBookData.bookId, bookData: this.props.studentAssocBookData } }} >Order</Link>
                  </li>
                  <li>
                    <Link className="btn btn-dark" onClick={() =>this.openAttendance(this.props.studentAssocBookData.bookId)}>Attendance Roster</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default StudentPanelLink;
