import { createAction } from 'redux-act'

import axios from 'axios'

export const setInterceptors = (type, cb) =>
 axios.interceptors[type].use(
     (config) => cb(config),
     (error) => Promise.reject(error)
 )
 
export const removeInterceptors = (type, id) => axios.interceptors[type].eject(id)

export const identityFunc = (_) => _

export const createAsyncAction = (actionDesc,payloadReducer,metaReducer) => {
    const action = createAction(actionDesc, payloadReducer, metaReducer)
    action.pending = createAction(`${actionDesc}_PENDING`)
    action.success = createAction(
        `${actionDesc}_SUCCESS`,
        (data) => ({data}),
        metaReducer
    )
    action.failure = createAction(
        `${actionDesc}_FAIL`,
        (error) => ({error}),
        true
    )
    return action
}


const _mutate = (mutators, state) => mutators.reduce((s, mutator) => mutator(s), state)

export const pipe = (mutators, state) => state.withMutations( (s) => _mutate(mutators, s))

export const branchIf = (predicate, returnTrue, returnFalse = identityFunc) => (
    state
) => {
    const _caseRender = (fn) => (Array.isArray(fn) ? pipe(fn, state) : fn(state))
    return predicate(state) ? _caseRender(returnTrue) : _caseRender(returnFalse)
}