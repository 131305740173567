
import { fromJS, Map } from 'immutable';

export const setLoginStatus = (payload) => state => {
    // if(localStorage.getItem('onAuthRefreshSuccess') === 'true'){
    //     const updatedState = state
    //     return updatedState
    //     // return Map({ state: state}).setIn(['state','userData'], payload.data)
    // }
    return state.set('userData', payload.data)
        .set('isLoggedIn', true)
}

export const setLoggedInStatus = payload => state => {
    if(localStorage.getItem('onAuthRefreshSuccess') === 'true'){
        return Map()
    }
    return state .set('isLoggedIn', true)
}


export const setNewJwt = ({ data }) => state => {
    return state.setIn(['userData','jwt'], data.jwt)
}

export const setUserNameErrorMessage = payload => state => {
    return state.set('userNameErrorMsg', 'Please Enter Valid UserName or Password')
}

export const setDataAfterReload = ({ userData, isLoggedIn, refreshTokenModal }) => state => {
    state = state.set('userData', JSON.parse(userData))
        .set('isLoggedIn', isLoggedIn)
        .set('refreshTokenModal',refreshTokenModal)
    return state
}

export const setRefreshTokenModalStatus = payload => state => {
    return state.set('refreshTokenModal',payload)
}
export const setSignUpSuccessMsg = payload => state => {
    state = state.set('userNameErrorMsg', 'Please Enter Valid UserName or Password')
    return state;
}

export const setSignupErrorMessage = payload => state => {
    return state.set('signupError', 'Failed to Sign up, We are sorry for the inconvenience Please try again');
}

export const setUsernameSubmitSuccessMsg = payload => state => {
    return state.set('forgotPassword', {
        showUsername: false,
        showOTP: true,
        errorStatus: false,
    });
}

export const setUsernameSubmitErrorMsg = payload => state => {
    return state.set('forgotPassword', {
        errorStatus: true,
        errorMessage: 'entered email was not found'
    });
}

export const setOTPSubmitSuccessMsg = payload => state => {
    return state.set('forgotPassword', {
        errorStatus: false,
        showOTP: false,
        showChangePassword: true
    });
}

export const setOTPSubmitErrorMsg = payload => state => {
    return state.set('forgotPassword', {
        errorStatus: true,
        errorMessage: 'Invalid OTP. Please check the OTP entered'
    });
}

export const setChangePasswordSubmitSuccessMsg = payload => state => {
    // setTimeout(() => {
    //     window.location.href = '/login';
    // }, 1500);
    return state.set('forgotPassword', {
        errorStatus: false,
        successStatus: true,
        errorMessage: '',
        successMessage: 'Password updated successfully'
    });
}

export const setChangePasswordSubmitErrorMsg = payload => state => {
    return state.set('forgotPassword', {
        errorStatus: true,
        errorMessage: 'Something went wrong..! Please try after some time'
    });
}

export const setEmailSubmitSuccessMsg = payload => state => {
    return state.set('forgotUsername', {
        errorStatus: false,
        successStatus: true,
        successMessage: 'An email has been sent with the username. Please check your email.'
    });
}

export const setEmailSubmitErrorMsg = payload => state => {
    return state.set('forgotUsername', {
        errorStatus: true,
        errorMessage: payload.error || 'entered email was not found.'
    });
}

export const removeUserData = payload => state => {
    return state
           .set('isLoggedIn', false)
           .set('userData',{})
}

export const redirectToKeyCloakLogin = payload => state => {
    return state.set('isRedirectToKeyCloak',true)
}

export const setTokens = payload => state => {
    // if(localStorage.getItem('onAuthRefreshSuccess') === 'true'){
    //     return Map()
    // }
    return state.set('tokens', fromJS(payload))
}

export const resetLoginStatus = payload => state => {
    return state.set('isLoggedIn', false)
}

export const setLogoutStatus = payload => state => {
    return state.set('isLogout', true)
}


export const setRefreshRokenPopup = payload => state => {
    return state.set('refreshTokenModal', true)
}