import React, { Component } from "react";
import "./AddCoursesInstOrg.css";
import CourseTableInstOrg from "./CourseTableInstOrg";
import { instOrgAdminConf } from "../../redux-instOrgAdminconf/index";
import instaOrgAdminTask from "../../redux-instOrgAdminconf/instaOrgAdminTask";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as selectors from "../../redux-instOrgAdminconf/selector";

import * as appSelector from "../../../appSelector";

import ApiMsg from "../../../common/ApiMsgs";

import AlertBox from "../../../common/AlertBox";
import InformationBanner from "../../../common/InformationBanner";
import INFO_BANNER from "../../../common/InfoBanner";
class AddCoursesPageInstOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      standards: [],
      coursesData: [],
      changedCourses: [],
      userId: "",
      orgId: "",
    };
  }
  componentDidMount() {
    let pathSeparator = "/";
    let orgIdAdmRteInCrses = Number(
      Object.keys(this.props.userProps.orgInfo[0])
    );
    let userIdAdmRteInCrses = this.props.userProps.id;
    this.props.actions.getCoursesAndStandardData({
      jwt: this.props.userProps.jwt,
      orgIdAdmRteInCrses,
      pathSeparator,
      userIdAdmRteInCrses,
    });

    this.props.actions.deleteCourseAlert(false);
    this.props.actions.deleteCourseFailureAlert(false)
  }
  render() {
    const userId = this.props.userId;
    const orgId = this.props.orgId;
    let userProps = this.props.userProps;
    return (
      <div className="mt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <InformationBanner isHorizontal
              message = {INFO_BANNER.MESSAGE_INFO_BANNER_INST_ADD_COURSE}/>
            </div>
          </div>
        </div>
        <AlertBox
          successAlert={this.props.deleteCourseAlertBox}
          errorAlert={this.props.deleteCourseFailureAlertBox}
          errorMsg={ApiMsg.COURSE_DELETE_FAILURE}
          successMsg={ApiMsg.COURSE_DELETE_SUCCESS}
        ></AlertBox>
        <div className="container">
          <CourseTableInstOrg
            stdCoursesObject={this.props.standards}
            userProps={userProps}
            history={this.props.history}
            userId={userId}
            orgId={orgId}
            addCourses={this.props.actions.addCourses}
            successMsg={this.props.successMsg}
            successAlert={this.props.successAlert}
            errorAlert={this.props.errorAlert}
            errorMsg={this.props.errorMsg}
            handleSuccessAlertModalClose={
              this.props.actions.handleSuccessAlertModalCloseForCourses
            }
            handleErrorAlertModalClose={
              this.props.actions.handleErrorAlertModalCloseForCourses
            }
            deleteCourseAlert={this.props.deleteCourseAlert}
            handleDeleteCourseModalClose={
              this.props.actions.handleDeleteCourseModalClose
            }
            storingIndexesOfCurrentDeletedCourses={
              this.props.actions.storingIndexesOfCurrentDeletedCourses
            }
            removingCourses={this.props.actions.removingCourses}
            addingCourses={this.props.actions.addingCourses}
            jwt={this.props.userProps.jwt}
            updateCourses={this.props.actions.updateCourses}
            orgIdAdmRteInCrses={Number(
              Object.keys(this.props.userProps.orgInfo[0])
            )}
            pathSeparator={"/"}
            userIdAdmRteInCrses={this.props.userProps.id}
            currentDeletedCourse={this.props.currentDeletedCourse}
            deleteCourseAlertBox={this.props.deleteCourseAlertBox}
          />
        </div>
      </div>
    );
  }
}

const s = ({ state }) => ({
  standards: selectors.getStatndars(state),
  userId: selectors.getStandardUrlUserId(state),
  orgId: selectors.getStandardUrlOrgId(state),
  coursesData: selectors.getCourses(state),
  successMsg: selectors.getSuccessMsgCourses(state),
  successAlert: selectors.getSuccessAlertCourses(state),
  errorAlert: selectors.getErrorAlertCourses(state),
  errorMsg: selectors.getErrorMsgCourses(state),
  userProps: appSelector.getUserData(state),
  deleteCourseAlert: selectors.getDeleteCourseAlert(state),
  currentDeletedCourse: selectors.currentDeletedCourse(state),
  deleteCourseAlertBox: selectors.deleteCourseAlert(state),
  deleteCourseFailureAlertBox: selectors.deleteCourseFailureAlertBox(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...instOrgAdminConf.actions,
      ...instaOrgAdminTask.actions,
    },
    d
  ),
});
export default connect(s, d)(AddCoursesPageInstOrg);
