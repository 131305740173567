import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '../appSelector'
import { assessmentModule } from '../assessmentModule/redux-assessmentModule/index'


class EndAssessmentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.endAssessmentHandlerModal = this.endAssessmentHandlerModal.bind(this);
        this.callEndAssessment = this.callEndAssessment.bind(this);
        this.closeEndAssessment = this.closeEndAssessment.bind(this);
    }
    closeEndAssessment(){
        this.props.actions.closeEndAssessmentModal()
    }
    endAssessmentHandlerModal(){
        this.callEndAssessment();
        this.closeEndAssessment();
        window.close();
      }
      callEndAssessment(){
        this.props.actions.totalMarks() &&
        this.props.actions.endTestData({
          jwt: this.props.userData.jwt,
          testId: localStorage.getItem('eachTestId'),
          userId: this.props.userData.id
        })
      }
    render() {
        return (
            <Modal show={this.props.show}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>End Assessment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to End the Assessment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.closeEndAssessment}>
                        Continue
                    </Button>
                    <Button variant="danger" onClick={this.endAssessmentHandlerModal}>
                        End Assessment
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...modals.actions,
            ...assessmentModule.actions
        },
        d
    )
})
export default connect(s, d)(EndAssessmentModal);
