

export const organisationDetails = state => state.get('organisationDetails', '')
export const getUserDetails = state => state.get('getUserDetails', [])
export const mondayData = state => state.get('mondayData', [])
export const tuesdayData = state => state.get('tuesdayData', [])
export const wednesdayData = state => state.get('wednesdayData', [])
export const thursdayData = state => state.get('thursdayData', [])
export const fridayData = state => state.get('fridayData', [])
export const saturdayData = state => state.get('saturdayData', [])
export const sundayData = state => state.get('sundayData', [])
export const getSudentTimeEntrys = state => state.get('sudentTimeEntrysData', [])

export const getAttendanceErrorAlert = state => state.get('attendanceErrorAlert',false)
export const getAttendanceErrorShortMsg = state => state.get('attendanceErrorShortMsg','')
export const getAttendanceErrorLongMsg = state => state.get('attendanceErrorLongMsg','')
export const getAttendanceSuccessAlert = state => state.get('attendanceSuccessAlert',false)
export const getAttendanceSuccessShortMsg = state => state.get('attendanceSuccessShortMsg','')
export const getAttendanceSuccessLongMsg = state => state.get('attendanceSuccessLongMsg','')
