import axios from 'axios'
import APICONF from '.././config/ApiConfig.js';

export const getDefaultGroupNames = (action) => async (
    { dispatch },
    { userId, jwt }
) => {
    const getDefaultGroupNames = APICONF.GET_USER_ASSIGNED_GROUPS + "/" + userId;
    const [err, result] = await axios
        .get(getDefaultGroupNames, {
            headers: {
                "authorization": jwt
            }
        })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}

export const getStateCodeInfo = (action) => async (
    { dispatch },
    { jwt }
) => {
    const getStateCodeInfoUrl = APICONF.GET_STATE_CODE_INFO
    const [err, result] = await axios
        .get(getStateCodeInfoUrl, {
            headers: {
                "authorization": jwt
            }
        })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}

export const getCountryCodeInfo = (action) => async (
    { dispatch },
    { jwt }
) => {
    const getCountryCodeInfoUrl = APICONF.GET_COUNTRY_CODE_INFO
    const [err, result] = await axios
        .get(getCountryCodeInfoUrl, {
            headers: {
                "authorization": jwt
            }
        })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}

export const getAssignParentToStudentByStudentId = (action) => async (
    { dispatch },
    { jwt, studentId }
) => {
    const getAssignParentToStudentByStudentIdUrl = APICONF.GETASSIGN_PARENTTOSTUDENT_BYSTUDENTID
    const [err, result] = await axios
        .get(getAssignParentToStudentByStudentIdUrl + "/" + studentId, {
            headers: {
                "authorization": jwt
            }
        })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}