import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import StudentHomeInstOrg from "../studentinstorg/StudentHomeInstOrg.js";
import StudentInstOrgHeader from ".././layout/studentInstOrgHeader/StudentInstOrgHeader";
import PublicFooter from "../layout/PublicFooter";
import ViewAssessmentsStudent from "../assessmentModule/assessmentPages/viewAssessment/student/ViewAssessmentsStudent";
import ViewAssessmentsStudentByCourse from "../assessmentModule/assessmentPages/viewAssessment/student/ViewAssessmentsStudentByCourse";
import StartAssessment from "../assessmentModule/assessmentPages/questionnaire/student/StartAssessment";
import ViewQuestionnaireStudent from "../assessmentModule/assessmentPages/questionnaire/student/viewQuestionnaireStudent";
import ViewResultsByUser from "../assessmentModule/assessmentPages/viewAssessment/common/ViewResultsByUser";
import NotFoundPage from "../common/404";
import Calendar from "../calendar.js";
import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import ProfileHome from "../profile/ProfileHome.js";
import AttendanceHome from "../attendance/AttendanceHome.js";

export default class StudentRouterInstOrg extends Component {
  render() {
    var path = window.location.pathname;
    let noHeaderFooterRoutes = [
      "/StartAssessment",
      "/ViewQuestionnaireStudent",
    ];
    return (
      <ConnectedRouter history={createBrowserHistory()}>
        <div>
          <div className="page-body">
            <div className="page-content-studentinstorg">
              {!noHeaderFooterRoutes.includes(path) && (
                <StudentInstOrgHeader></StudentInstOrgHeader>
              )}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/StudentHomeInstOrg" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/StudentHomeInstOrg" />
                </Route>
                <Route
                  exact
                  path="/StudentHomeInstOrg"
                  render={(props) => <StudentHomeInstOrg {...props} />}
                />
                <Route
                  exact
                  path="/ViewAssessmentsByCourse"
                  render={(props) => (
                    <ViewAssessmentsStudentByCourse {...props} />
                  )}
                />
                <Route
                  exact
                  path="/ViewAssessmentsStudent"
                  render={(props) => <ViewAssessmentsStudent {...props} />}
                />
                <Route
                  exact
                  path="/StartAssessment"
                  render={(props) => <StartAssessment {...props} />}
                />
                <Route
                  exact
                  path="/ViewQuestionnaireStudent"
                  render={(props) => <ViewQuestionnaireStudent {...props} />}
                />
                <Route
                  exact
                  path="/ViewAnswersStudent"
                  render={(props) => <ViewResultsByUser {...props} />}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />
                 <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <ProfileHome onlyPersonalInfo={true} {...props} />
                  )}
                />
                <Route exact path="/attendance" render={(props) => (<AttendanceHome {...props} />)}  />
                <Route exact path="/Partners" component={PartnersPage} />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/Careers" component={CareersPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
          {!noHeaderFooterRoutes.includes(path) && <PublicFooter />}
        </div>
      </ConnectedRouter>
    );
  }
}
