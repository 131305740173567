export const openAddsectionmodal = (payload) => (state) => {
  return state.set("openAddsectionmodal", true);
};


export const uploadvideoSuccessAlertBox = (payload) => (state) => {
  return state.
    set("successMsg", payload.data.responseMessages[0].longMessage)
    .set("successAlert", true)
}
export const deleteLearningListSuccessAlertBox = (payload) => (state) => {
  return state.
    set("successMsg", payload.data.responseMessages[0].longMessage)
    .set("successAlert", true)
}

export const uploadvideoFailureAlertBox = (payload) => (state) => {
  return state
    .set("errorAlert", true)
    .set("errorMsg", payload.data.responseMessages[0].longMessage)
}
export const handleSuccessAlertModalClose = () => (state) =>
  state.set("successAlert", false);

export const handleErrorAlertModalClose = () => (state) =>
  state.set("errorAlert", false);

export const getCourseSection = (payload) => (state) => {
  let allCourseSectionData = [];
  payload.data.responseObject.map(function (dataChange) {
    var groups = {
      value: dataChange.courseSectionId,
      label: dataChange.courseSectionTitle
    }
    allCourseSectionData.push(groups)
  })
  return state.set('getCourseSection', allCourseSectionData)
}

export const getBookResourceByBookId = (payload) => (state) => {
  if (payload.data.dropdown) {
    let allbookResourceIdData = [{ value: 0, label: "New Resource" }];
    payload.data.responseObject.map(function (dataChange) {
      var resourceOptions = {
        value: dataChange.bookResourceId,
        label: dataChange.bookResourceName,
      }
      allbookResourceIdData.push(resourceOptions)
    })
    return state.set('allbookResourceIdData', allbookResourceIdData)
  }
  return state.set('allbookResourceIdData', payload.data.responseObject)
}

export const addLearningListSuccess = (payload) => (state) => {
  return state
  .set("successMsg", payload.data.responseMessages[0].longMessage)
  .set("successAlert", true)
};


export const getLearningList = (payload) => (state) => {
  return state.set("getExistingLearningList", payload.data.responseObject);
};

export const getVideosByBookResourceId = (payload) => (state) => {
  if(payload.data.responseObject.bookResourceType ==="course" && payload.data.responseObject.sectionList !== null){
    let srcVideoObjectArray = [];
    payload.data.responseObject.sectionList.map((sectionList, sectionListIndex) => {
      sectionList.sectionVideos.map((sectionVideos, sectionVideosIndex) => {
        if (sectionListIndex === 0 && sectionVideosIndex === 0) {
          sectionVideos.courseVideos.map(courseVideos => {
            let srcObject = {
              src: courseVideos.courseVidDestinationS3url,
              type: 'application/x-mpegURL'
            };
            srcVideoObjectArray.push(srcObject);
          })
        }
      })
    })
  
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: srcVideoObjectArray
    };
    return state.set("getVideosByBookResourceId", payload.data.responseObject)
      .set("videoJsOptions", videoJsOptions);
  }
  if(payload.data.responseObject.bookResourceType ==="ibook"){
    let ibookUrl = payload.data.responseObject.ibooks[0].s3url
    window.open(ibookUrl, '_blank').focus();
  }
  return state.set("getVideosByBookResourceId", payload.data.responseObject);

};

export const openSingleVideoPlayer = (payload) => (state) => {
  return state.set("openSingleVideoPlayer", true);
};

export const closeSingleVideoPlayer = (payload) => (state) => {
  return state.set("openSingleVideoPlayer", false);
};

export const setVideoJsOptions = (payload) => (state) => {
  console.log("setVideoJsOptions::::::::::::::", payload)
  return state.set("videoJsOptions", payload);
};