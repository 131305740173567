import React, { Component } from 'react';

class TestHeaderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eachAssessmentData: null
    };
  }
  render() {
    var eachViewEachAssessmentData = this.props.eachTestData
    return (
      <div className="books-card-wrapper col-md-12">
        <div key={eachViewEachAssessmentData.testId} className="card">
          <div className="card-header">
            <b>Standard Name:&nbsp;{eachViewEachAssessmentData.standardName}</b>&nbsp;&nbsp;
            <b>Course Name:&nbsp;{eachViewEachAssessmentData.courseName}</b>&nbsp;&nbsp;
            {
              (this.props.forResults) && (this.props.forResults) && (
                <span>
                  <b>Scored Points:&nbsp;{this.props.scoredPoints}</b>&nbsp;&nbsp;
                  <b>Assessment Status:&nbsp;{this.props.qualifiedStatus}</b>&nbsp;&nbsp;
                </span>
              )
            }
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <b>Assessment Name:&nbsp;{eachViewEachAssessmentData.testName}</b>
              </div>
              <div className="col-6">
                <div>
                  <div className="mt-1">
                    <b>Assessments Details:</b>
                  </div>
                  <div className="mt-1">
                    Start Date: {eachViewEachAssessmentData.startDate}&nbsp;&nbsp;<br />
                    End Date: {eachViewEachAssessmentData.endDate}&nbsp;&nbsp;
                    </div>
                  <div className="mt-1">
                    Total Questions: {eachViewEachAssessmentData.totalQuestions}
                  </div>
                  <div className="mt-1">
                    Assessment Duration: {eachViewEachAssessmentData.testDuration}
                  </div>
                  <div className="mt-1">
                    Total Points: {eachViewEachAssessmentData.totalMarks}
                  </div>
                  <div className="mt-1">
                    Qualification Points: {eachViewEachAssessmentData.qualificationMarks}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>
                  <div>
                    <b>Course Details:</b>
                  </div>
                  <div className="mt-1">
                    Title: {eachViewEachAssessmentData.bookTitle}
                  </div>
                  <div className="mt-1">
                    Description: {eachViewEachAssessmentData.bookDescription}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TestHeaderView;