import { LOCATION_CHANGE } from "connected-react-router";
import {pathToRegexp} from 'path-to-regexp'

import orders from '../order/redux-orders/orders'

import login from '../login/loginn'

import { modals } from '../modals'

import { profile } from '../profile'

const config = [
  {
    path: "/Payment/:id",
    thunk: ({ params, dispatch }) =>
      dispatch(
        orders.actions.setShippingId({
          shippingId: params.id,
        })
      ),
  },
  {
    path: "/Login",
    thunk: ({ params, dispatch }) =>
      dispatch(
        login.actions.redirectToKeyCloakLogin()
      ),
  },
  {
    path: "/OrderHistory",
    thunk: ({ params, dispatch }) =>
      dispatch(
        orders.actions.getAllOrderDetailsByUserId()
      )
  },
  {
    path: "/calendar",
    thunk: ({ params, dispatch, state }) => {
      const userData = state.get("userData", "");
      if (userData){
      return (
       
        dispatch(profile.actions.setCurrentDate()) &&
        dispatch(
          modals.actions.getGroupNames({
            loginUserId: userData && userData.id,
            jwt: userData && userData.jwt,
          })
        ) &&
        dispatch(
            profile.actions.getUsers({
            id: userData && userData.id,
            jwt: userData && userData.jwt,
          })
        ) &&
        dispatch(profile.actions.getUpcomingEventsByUserIdAndDate({
          date: new Date()
        }))
      );
    }
    },
  },
  {
    path: "/ExecHome",
    thunk: ({ params, dispatch, state }) => {
      const userData = state.get("userData", "");

      return (
        dispatch(profile.actions.setCurrentDate()) &&
        dispatch(
          modals.actions.getGroupNames({
            loginUserId: userData && userData.id,
            jwt: userData && userData.jwt,
          })
        ) &&
        dispatch(
            profile.actions.getUsers({
            id: userData && userData.id,
            jwt: userData && userData.jwt,
          })
        ) &&
        dispatch(profile.actions.getUpcomingEventsByUserIdAndDate({
          date: new Date()
        }))
      );
    },
  },
];

const getParams = (path, url) => {
    let keys = []
    const re = pathToRegexp(path, keys)
    return keys.reduce(
        (params, { name }, k) => ({ ...params, [name]: re.exec(url)[k+1]}),
        {}
    )
}


const middleware = (config) => store => next => action => {
    if(action.type === LOCATION_CHANGE) {
        const url = action.payload.location.pathname + action.payload.location.search
        const route = config.find(x => pathToRegexp(x.path).test(url))
        if(route) {
            const params =getParams(route.path, url)
            const newPayload = {...action.payload, params}
            return (
                next({...action, payload: newPayload}) &&
                route.thunk({ params, dispatch: store.dispatch, state: store.getState().state})
            )
        }
    }
    return next(action)
}

export default {
    middleware,
    config
}