import axios from 'axios'
import APICONF from './../../config/ApiConfig.js';
import { Map } from 'immutable'



export const createAssessment = (action) => async (
    { dispatch },
    { createAssessmentData, jwt }) => {
    const createTest = APICONF.CREATE_TEST
    const [err, result] = await axios({
        method: 'post', 
        url: createTest,
        data: createAssessmentData,
        headers: {
            "authorization": jwt
        }
    })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}



export const getViewAssessments = (action) => async (
    { dispatch },
    { loginUserId, jwt, clientTimeZone }) => {
    const getViewAssessments = APICONF.GET_VIEW_TESTS
    const [err, result] = await axios({
        method: 'post', 
        url: getViewAssessments,
        data: {
            userId: loginUserId,
            timeZone: clientTimeZone
        },
        headers: {
            "authorization": jwt
        }
    })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}

export const addQuestionToAssessment = (action) => async (
        { dispatch },
        { addQuestionToAssessmentData, jwt }) => {
        const addQuestionToAssessment = APICONF.ADD_QUESTION_TO_ASSESSMENT
        const [err, result] = await axios({
            method: 'post', 
            url: addQuestionToAssessment,
            data: addQuestionToAssessmentData,
            headers: {
                "authorization": jwt
            }
        })
            .then(res => [null, res.data])
            .catch(err => [err, null])
        const actionToDispatch = err ? action.failure(err) : action.success(result)
        return dispatch(actionToDispatch)
} 

export const getQuestions = (action) => async (
    { dispatch },
    { jwt, testId }) => {
    const getQuestionUrl = APICONF.GET_QUESTIONS +"/"+testId
    const [err, result] = await axios.get(getQuestionUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 

export const getAssmentAssocParagraphs = (action) => async (
    { dispatch },
    { testId, jwt }
  ) => {
    const getAssmentAssocParagraphs = APICONF.GET_ASSESSMENT_PARAGRAPHS_BY_TEST_ID + "/" + testId;
    const [err, result] = await axios
      .get(getAssmentAssocParagraphs, {
        headers: {
          "authorization": jwt
        }
      })
      .then(res => [null, res.data])
      .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
  }

export const addParagraphToAssessment = (action) => async (
    { dispatch },
    { addParagraphToAssessmentData, jwt }) => {
    const addParagraphToAssessment = APICONF.ADD_PARAGRAPH_TO_ASSESSMENT
    const [err, result] = await axios({
        method: 'post', 
        url: addParagraphToAssessment,
        data: addParagraphToAssessmentData,
        headers: {
            "authorization": jwt
        }
    })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 


export const getViewAssessmentsByCourse = (action) => async (
    { dispatch },
    { loginUserId, bookId, jwt, clientTimeZone }) => {
    const getViewAssessments = APICONF.GET_VIEW_TESTS_BY_COURSE
    const [err, result] = await axios({
        method: 'post', 
        url: getViewAssessments,
        data: {
            userId: loginUserId,
            bookId :bookId,
            timeZone: clientTimeZone
        },
        headers: {
            "authorization": jwt
        }
    })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 

export const startAssessment = (action) => async (
        { dispatch },
        { jwt, testId, userId }) => {
        const startAssessment = APICONF.START_ASSESSMENT +"/"+testId+"/"+userId
        const [err, result] = await axios.get(startAssessment, {
            headers: {
                "authorization": jwt
            }
         })
         .then(res => [null,res.data])
         .catch( err => [err, null])
        const actionToDispatch = err ? action.failure(err) : action.success(result)
        return dispatch(actionToDispatch)
} 

export const getAllResultsByTestId = (action) => async (
    { dispatch },
    { loginUserId, eachTestId, jwt }) => {
    const getAllResultsByTestIdUrl = APICONF.GET_ALL_RESULTS_BY_TEST_ID +"/"+eachTestId+"/"+loginUserId
    const [err, result] = await axios.get(getAllResultsByTestIdUrl, {
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 

export const endTestData = (action) => async (
    { dispatch, getState },
    {  jwt, userId, testId }) => {

        const answers = getState().state.get('resultsOfStudent',Map())
                         .reduce(
                             (final,current, index) => [
                                 ...final,
                                 {
                                    testAnswerId: current.get('testAnswerId',0),
                                    testAnswerValue: current.get('answerOption',''),
                                    testQuestionnaireId: current.get('testQuestionnaireId','')
                                 }
                             ],
                             []
                         )
        const totalMarks = getState().state.get('totalMarks',0)
        let globalEachTestData = JSON.parse(localStorage.getItem('eachTestData'))
        let qualifiedMarks = globalEachTestData.qualificationMarks;
        let qualifiedStatusString;
        if(totalMarks>qualifiedMarks){
            qualifiedStatusString="Passed"
        }
        if(totalMarks<qualifiedMarks){
        qualifiedStatusString="Failed"
        }
    const endTestData = APICONF.END_TEST_DATA
    const [err, result] = await axios({
        method: 'post', 
        url: endTestData,
        data: {
            answers,
            testResult: {
                testResult: totalMarks,
                testId,
                userId,
                qualifiedStatus: qualifiedStatusString
            },
            userId,
            testId
        },
        headers: {
            "authorization": jwt
        }
    })
        .then(res => [null, res.data])
        .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 

