import React, { Component } from 'react';
import './PublicHome.css';
import PublicFooter from "../layout/PublicFooter";
import UIConfig from '../config/UiConfig';

import UNIFIED_EDUCATION from './../assets/images/UNIFIED_EDUCATION_ECOSYSTEM.jpg';
import VIDEO_CONFERENCE from './../assets/images/VIDEO_CONFERENCE.jpg';
import ASSESSMENTS from './../assets/images/ASSESSMENTS.jpg';
import BACK_TO_SCHOOL from './../assets/images/BACK_TO_SCHOOL.jpg';
import ABOUT_PRODUCT from './../assets/images/ABOUT_PRODUCT_NEW.jpg';
import PARTNERS_CLIENTS from './../assets/images/PARTNERS_CLIENTS.jpg';
import VIDEO_STREAMING from './../assets/images/VIDEO_STREAMING.jpg';
import ATTENDANCE_MANAGEMENT from './../assets/images/ATTENDANCE_MANAGEMENT.jpg';

class PublicHome extends Component {
  render() {
    return (
      <div className='container-fluid p-0 overflow-hidden'>
        <div className='row'>
          <div >
            <div >
              <img className="img-fluid" alt="UNIFIED_EDUCATION" src={UNIFIED_EDUCATION} />
              <a href={`${UIConfig.UI_SIGNUP}`} target="_blank" rel="noreferrer">
                <h1 className="center_text_unified_link mt-4" >
                  <button className='btn btn-warning mt-4'>join the durchere
                    &nbsp; <i className="fa fa-external-link" aria-hidden="true" style={{ color: 'black' }} />
                  </button></h1>
              </a>
            </div>
          </div>
          <div>
            <img className="img-fluid" alt="VIDEO_CONFERENCE" src={VIDEO_CONFERENCE} />
          </div>
          <div>
            <img className="img-fluid" alt="ASSESSMENTS" src={ASSESSMENTS} />
          </div>
          <div>
            <img className="img-fluid" alt="ATTENDANCE_MANAGEMENT" src={ATTENDANCE_MANAGEMENT} />
          </div>
          <div>
            <img className="img-fluid" alt="BACK_TO_SCHOOL" src={BACK_TO_SCHOOL} />
          </div>
          <div>
            <img className="img-fluid" alt="VIDEO_STREAMING" src={VIDEO_STREAMING} />
          </div>
          <div>
            <img className="img-fluid" alt="ABOUT_PRODUCT" src={ABOUT_PRODUCT} />
          </div>
          <div>
            <img className="img-fluid" alt="PARTNERS_CLIENTS" src={PARTNERS_CLIENTS} />
          </div>
        </div>
        <div>
          <PublicFooter />
        </div>
      </div>
    );
  }
}
export default PublicHome;
