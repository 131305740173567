import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as groupData from './selector';
import * as defaultGroupData from '../common/selector';
import * as appData from '../appSelector'
class AssignGroupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroups: [],
            changingGroups: false,
            addedGroups: []
        }
        this.getAllGroupNames = this.getAllGroupNames.bind(this);
        this.assignGroupsToUser = this.assignGroupsToUser.bind(this);
        this.callAssignGroupsToUser = this.callAssignGroupsToUser.bind(this);
        this.deleteGroupsToUser = this.deleteGroupsToUser.bind(this);
        this.handleAssignedGroupsChange = this.handleAssignedGroupsChange.bind(this);
    }
    componentDidMount() {
        this.getAllGroupNames();
    }
    handleAssignedGroupsChange = event => {
        let deletedItems = []
        let addedGroupItems = []
        if (event && event.length > this.props.defaultSelectedGroups.length) {
            if (this.props.defaultSelectedGroups.length === 0) {
                event.forEach((eventIniData) => {
                    addedGroupItems.push(eventIniData);
                })
            }
            event.forEach((eventData) => {
                this.props.defaultSelectedGroups.forEach((defaultGroupData) => {
                    if (eventData.value !== defaultGroupData.groupId) {
                        addedGroupItems.push(eventData);
                    }
                })
            })
        }
        else {
            if (event && this.props.defaultSelectedGroups.length === event.length) {
                deletedItems = [];
            }
            else {
                if (!event) {
                    this.props.defaultSelectedGroups.forEach((deletedGroupSingle) => {
                        deletedItems.push(deletedGroupSingle)
                    })
                }
                else {
                    this.props.defaultSelectedGroups.forEach((item) => {
                        event && event.forEach((event, i) => {
                            if (event.value !== item.groupId) {
                                deletedItems.push(item);
                            }
                        })
                    })
                }
            }
        }
        this.setState({
            selectedGroups: event && event,
            changingGroups: true,
            addedGroups: addedGroupItems
        })
        if (deletedItems.length > 0) {
            deletedItems.map(item => this.deleteGroupsToUser(item))
        }
    }

    deleteGroupsToUser(group) {
        this.props.actions.deleteAssignGroupNames({
            loginUserId: this.props.userData.id,
            jwt: this.props.userData.jwt,
            userId: this.props.eachrowUserData.id,
            groupId: group.groupId
        })
    }
    assignGroupsToUser() {
        if (this.state.changingGroups) {
            this.callAssignGroupsToUser()
        }
        this.props.onClose();
        this.revertingValues()
    }
    revertingValues() {
        this.setState({
            changingGroups: false
        })
    }
    getAllGroupNames() {
        this.props.actions.getGroupNames({
            loginUserId: this.props.userData.id,
            jwt: this.props.userData.jwt
        })
    }

    callAssignGroupsToUser() {
        this.props.actions.addAssignGroupNames({
            loginUserId: this.props.userData.id,
            jwt: this.props.userData.jwt,
            userId: this.props.eachrowUserData.id,
            groupIds: this.state.addedGroups.map(group => group.value),
        })
    }
    render() {
        let allGroupOptionsData = [];
        let defaultGroupOptionsData = [];
        if (this.props.getGroupNamesData) {
            this.props.getGroupNamesData.map(function (dataChange) {
                var groups = {
                    value: dataChange.groupId,
                    label: dataChange.groupName
                }
                allGroupOptionsData.push(groups)
            })
        }
        if (this.props.defaultSelectedGroups) {
            this.props.defaultSelectedGroups.map(function (dataChange) {
                var defaultGroups = {
                    value: dataChange.groupId,
                    label: dataChange.groupName
                }
                defaultGroupOptionsData.push(defaultGroups)
            })
        }
        return (
            <Modal show={this.props.show}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Assign Groups</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-2 row">
                        <div className="col-md-6">
                            <label>Associated Groups</label>
                        </div>
                        <div className="col-md-12">
                            <Select value={
                                this.state.changingGroups
                                    ? this.state.selectedGroups
                                    : defaultGroupOptionsData
                            }
                                isMulti
                                isClearable
                                options={allGroupOptionsData}
                                onChange={this.handleAssignedGroupsChange}>
                            </Select>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => {
                        this.props.onClose()
                        this.revertingValues()
                    }
                    }>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={() => this.assignGroupsToUser()}>
                        Assign
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
const s = ({ state }) => ({
    getGroupNamesData: groupData.getGroupNames(state),
    addAssignGroupNames: groupData.addAssigngroupNames(state),
    defaultSelectedGroups: defaultGroupData.getDefaultGroupNamesData(state),
    userData: appData.getUserData(state)
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...modals.actions
        },
        d
    )
})
export default connect(s, d)(AssignGroupModal);
