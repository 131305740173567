import React, { Component } from 'react';
import AboutDurchere from './../assets/videos/AboutDurchere.mp4';
import './AboutUs.css'
import PublicFooter from "../layout/PublicFooter";
import * as app from '../appSelector'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
class AboutUsPage extends Component {
  render() {
    return (
      <div>
        <div>
          <video className="video-class-about" autoPlay loop muted>
            <source src={AboutDurchere} type='video/mp4' />
          </video>
          <div className="mid-video-about-mission-head">
            <h5>OUR MISSION:</h5>
          </div>
          <div >
            <h1 className="mid-video-about-mission-line1-left">BE</h1>
            <h1 className="mid-video-about-mission-line2-left">YOU</h1>
            <h1 className="mid-video-about-mission-line1-right">FOR</h1>
            <h1 className="mid-video-about-mission-line2-right">YOU</h1>
          </div>
        </div>
        <div className="about-durchere">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="about-vertical-spce">
                <h1 className="about-h1">ABOUT DURCHERE</h1>
              </div>
            </div>
            <div className="vertical-line-about hidden-mobile">
            </div>
            <div className="about-horizonal-spce"></div>
            <div className="col-md-4">
              <div className="about-vertical-spce-mission">
                <div className="about-vertical-spce-p">
                  <p className="text-mission-about"><strong>"Durchere's mission is to make everything for you to be you."</strong></p>
                </div>
                <p>&nbsp;&nbsp;Our mission is what drives us to do everything possible for you. We do that by creating ground for innovations, by working with our partners, our team by building a creative and diverse world for you.</p>
                <p>Based in Camp Hill, PA-17011</p>
              </div>
            </div>
          </div>
        </div>
{!this.props.isLoggedIn&&<PublicFooter />}      
      </div>
    );
  }
}
const s = ({ state }) => ({
  isLoggedIn: app.getLoginStatus(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
    //  ...loginn.actions
    },
    d
  )
})
export default connect(s,d)(AboutUsPage);
