import React, { Component } from 'react';
import './Books.css';
import BookBox from '././BookBox.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as adminData from '../../redux-adminconf/selector';
import DeleteBookModal from '../../../modals/DeleteBookModal.js'
import BookQuantityModal from '../../../modals/BookQuantityModal.js'
import { adminConf } from '../../redux-adminconf/index'
import WithPagination from '../../../common/WithPagination';
class BooksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3
    }
    this.closeDeleteModalMain = this.closeDeleteModalMain.bind(this);
    this.closeQuantityModalMain = this.closeQuantityModalMain.bind(this);
  }
  closeDeleteModalMain() {
    this.props.actions.closeDeleteBookModal();
  }

  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }
  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }
  closeQuantityModalMain() {
    this.props.actions.closeQuantityModal();
  }

  render() {
    const { currentPage, itemsPerPage, searchText } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;

    var imageFromBucket = this.props.bookData
    && this.props.bookData.responseObject.imageBucketEnable;
     let currentItems=[]
     this.props.bookData &&  this.props.bookData.responseObject.booksData.map((bookData)=>{
         currentItems.push(bookData)
     })
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }
    if (currentItems) {
      var showBooks = currentItems.slice(indexOfFirst, indexOfLast).map((bookData, i) => {
        var imageUrl = '';
       
        if (imageFromBucket) {
          imageUrl = bookData.imageBucketLocationChanged
        }
        else {
          imageUrl = bookData.imageappLocationChanged
        }
        return <BookBox key={bookData.bookId}  bookData={bookData} imageUrl={imageUrl}/>
      })
    }
    return (
      <div>
          <WithPagination
            itemsPerPage={itemsPerPage}
            totalItems={currentItems.length}
            currentPage={currentPage}
            onSearch={this.onSearch}
            onPaginate={this.onPaginate}
          >
             {
            (this.props.openDeleteBookModal)&&(
              <DeleteBookModal onClose={this.closeDeleteModalMain}
              show={this.props.openDeleteBookModal} />
              )
                }
                {
            (this.props.openQuantityModal)&&(
              <BookQuantityModal onClose={this.closeQuantityModalMain}
              show={this.props.openQuantityModal} />
              )
                }
        {showBooks}
        </WithPagination>
        </div>
    );
  }
}
const s = ({ state }) => ({
  bookData : adminData.getBookByOrgUser(state),
  openDeleteBookModal: adminData.openDeleteBookModal(state),
  openQuantityModal: adminData.openQuantityModal(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...adminConf.actions
    },
    d
  )
})
export default connect(s,d)(BooksPage);