import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as modalsData from './selector'
import * as appData from '../appSelector'
class UserModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      selectedInsstituteId: null,
      enteredFirstName: '',
      enteredLastName: '',
      enteredEmail: '',
      enteredCellNumber: '',
      selectedRoleId: null,
      selectedOrgId: null,
      orgIdsArray: [],
      orgNames:[],
      deleteOrgIds: [],
      addedOrgIds: [],
      selectedOrganizations:[],
      changingAssignOrganizations: false,
      deletedItemsData: [],
      apiCallOrganizations:[]
    }
    this.handleInstitute = this.handleInstitute.bind(this);
    this.handleFirstName = this.handleFirstName.bind(this);
    this.handleLastName = this.handleLastName.bind(this);
    this.handleRole = this.handleRole.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleCellPhone = this.handleCellPhone.bind(this);
    this.handleOrganization = this.handleOrganization.bind(this);
    this.callUserCloseModal = this.callUserCloseModal.bind(this);
    this.callAddUser = this.callAddUser.bind(this);
    this.callEditUser = this.callEditUser.bind(this);
    this.resetUserNameChnagedFlag = this.resetUserNameChnagedFlag.bind(this)
    this.callGetAllInstitutes = this.callGetAllInstitutes.bind(this)
    this.callGetRoleTypes = this.callGetRoleTypes.bind(this)
    this.callGetOrganizationDetailsByUser = this.callGetOrganizationDetailsByUser.bind(this)
    this.assignOrganizationsToUser = this.assignOrganizationsToUser.bind(this);
    this.callassignOrganizationsToUser = this.callassignOrganizationsToUser.bind(this);
    this.handleAssociatedBooksChange = this.handleAssociatedBooksChange.bind(this);
    this.deleteBooksToUser= this.deleteBooksToUser.bind(this)
  }

  callassignOrganizationsToUser(){
    var assignUserCourses = {
        "userId": this.props.eachrowUserData.id,
        "bookIds": this.state.apiCallOrganizations.map(org => org.value),
        "loginId": this.props.userData.id
       }
        const apiCallOrganizations = [];

        this.state.apiCallOrganizations.forEach(apiCallOrg => {
            const apiCallOrgIndex = 
            this.state.deletedItemsData.findIndex(deletedOrgData => deletedOrgData.label === apiCallOrg.label)
            if(apiCallOrgIndex === -1){
                apiCallOrganizations.push(apiCallOrg)
            }
        });
  
       if(apiCallOrganizations.length>0){
        this.props.actions.assignUserCourse({
            assignUserCourseData: assignUserCourses,
            jwt: this.props.userData.jwt
        })
       }

    if(this.state.deletedItemsData.length>0){
        this.state.deletedItemsData.map(item => this.deleteBooksToUser(item))
    }
    
}

deleteBooksToUser(org){
  this.props.actions.deleteUserFromOrgAssociation({
      userId  :  this.props.eachrowUserData.id,
      orgId  :  org.value,
      jwt: this.props.userData.jwt
  })
}

  assignOrganizationsToUser(){
    if( this.state.changingAssignOrganizations){
        this.callassignOrganizationsToUser()
    }
    this.props.onClose();
    this.revertingValues()
}

revertingValues () {
  this.setState({
      changingAssignOrganizations: false
  })
}

  callUserCloseModal() {
    this.resetUserNameChnagedFlag();
    this.callAddUser();
    this.props.onClose();
  }
  callUserEdiModal() {
    this.resetUserNameChnagedFlag();
    this.callEditUser();
    this.props.onClose();
  }
  callEditUser() {
    var orgIdsArray = [];
    var instIdsArray = [];
    var instIdsEachRowArray = [];
    var orgInfo = this.props.userData.orgInfo;
    Object.values(orgInfo).forEach(function (orgIdsData) {
      orgIdsArray.push(Number(Object.keys(orgIdsData)));
    })
    var instInfo = this.props.eachrowUserData.instInfo;
    Object.values(instInfo).forEach(function (instIdsData) {
      var eachInstId = Number(Object.keys(instIdsData));
      instIdsEachRowArray.push(eachInstId);
    })
    if (this.state.selectedInsstituteId != null) {
      instIdsArray.push(this.state.selectedInsstituteId.value);
    }
    var editUserData = {
      "userId": this.props.userData.id,
      "orgIds": orgIdsArray,
      "instIds": this.state.changingInst ? instIdsArray : instIdsEachRowArray,
      "user": {
        "userId": this.props.eachrowUserData.id,
        "firstName": this.state.changingFirstName ? this.state.enteredFirstName : this.props.eachrowUserData.firstName,
        "lastName": this.state.changingLastName ? this.state.enteredLastName : this.props.eachrowUserData.lastName,
        "roleType": this.state.changingRole ? this.state.selectedRoleId.value : this.props.eachrowUserData.roleId,
        "email": this.state.changingEmail ? this.state.enteredEmail : this.props.eachrowUserData.email,
        "phoneNumber": this.state.changingCellphone ? this.state.enteredCellNumber : this.props.eachrowUserData.cellPhoneNumber,
      }
    }
    this.props.actions.editUserToOrganization({
      editUserData: editUserData,
      jwt: this.props.userData.jwt
    })
  }
  callAddUser() {
    console.log(this.state.apiCallOrganizations,'adeddItems')
    var orgIdsArray = [];
    var instIdsArray = [];
    var orgInfo = this.props.userData.orgInfo;
    let roleId =this.props.userData.roleId
    if(roleId === "76517"){
      // const orgs = this.state.orgNames;
      this.state.apiCallOrganizations.forEach(org => {
        orgIdsArray.push(org.value)
      })
    }
    else{
      Object.values(orgInfo).forEach(function (orgIdsData) {
        orgIdsArray.push(Number(Object.keys(orgIdsData)));
      })
    }
    if(roleId === "76517"){
      instIdsArray.push(Object.keys(this.props.userData.instInfo[0]).[0]);
    }
    else{
      if (this.state.selectedInsstituteId != null) {
        instIdsArray.push(this.state.selectedInsstituteId.value);
      }
    }
    var addUserData = {
      "userId": this.props.userData.id,
      "orgIds": orgIdsArray,
      "instIds": instIdsArray,
      "users": [{
        "firstName": this.state.enteredFirstName,
        "lastName": this.state.enteredLastName,
        "roleType": this.state.selectedRoleId.value,
        "email": this.state.enteredEmail,
        "phoneNumber": this.state.enteredCellNumber
      }]
    }
    this.props.actions.addUserToOrganization({
      addUserData: addUserData,
      jwt: this.props.userData.jwt
    })
  }
  callGetAllInstitutes(){
    this.props.actions.getAllInstitutes({
      jwt: this.props.userData.jwt
    })
  }
  callGetRoleTypes(){
    this.props.actions.getRoleTypesByUser({
      jwt: this.props.userData.jwt,
      userId: this.props.userData.id,
      roleType: this.props.userData.roleId
    }) 
  }
  callGetOrganizationDetailsByUser(){
    this.props.actions.getAssociatedOrgDetailsByUserId({
      jwt: this.props.userData.jwt,
      userId: this.props.userData.id
    }) 
  }
  componentDidMount() {
    let roleId =this.props.userData.roleId
    if((roleId !== "73146") && (roleId !== "79146") && (roleId !== "72195") && (roleId !== "76517")){
      this.callGetAllInstitutes()
    }
    this.callGetRoleTypes()
    if(roleId === "76517"){
      this.callGetOrganizationDetailsByUser()
    }
  }

  handleInstitute = event => {
    let instituteId = event;
    this.setState({
      changingInst: true,
      selectedInsstituteId: instituteId,
    })
  }

  handleFirstName = event => {
    let firstName = event.target.value;
    this.setState({
      changingFirstName: true,
      enteredFirstName: firstName,
    })
  }
  resetUserNameChnagedFlag = () => {
    this.setState({
      changingFirstName: false,
      changingLastName: false,
      changingEmail: false,
      changingCellphone: false,
      changingRole: false,
      changingInst: false,
    })
  }
  handleLastName = event => {
    let lastName = event.target.value;
    this.setState({
      changingLastName: true,
      enteredLastName: lastName,
    })
  }
  handleRole = event => {
    let roleId = event;
    this.setState({
      changingRole: true,
      selectedRoleId: roleId,
    })
  }
  handleEmail = event => {
    let emailAddress = event.target.value;
    this.setState({
      changingEmail: true,
      enteredEmail: emailAddress,
    })
  }

  handleCellPhone = event => {
    let cellPhoneNumber = event.target.value;
    this.setState({
      changingCellphone: true,
      enteredCellNumber: cellPhoneNumber,
    })
  }
  handleOrganization = (orgName, actionMeta) => {

    let defaultOrganization = []

    this.props.eachrowUserData.orgInfo.map((orgInfo, index) => {
      for ( let x in orgInfo) {
        defaultOrganization.push(x)
      }
    })

    // console.log(defaultOrganization.includes(actionMeta.removedValue.value), 'defaultChecking')

    if(
      actionMeta.action === 'remove-value' && 
     !this.state.deleteOrgIds.includes(actionMeta.removedValue.value)
     ) {
       if(this.state.addedOrgIds.includes(actionMeta.removedValue.value)) {
        this.setState(prevState => ({
          addedOrgIds: prevState.addedOrgIds.filter( value => value !== actionMeta.removedValue.value)
          }))
       }
       if(defaultOrganization.includes(actionMeta.removedValue.value)) {
        this.setState(prevState => ({
          deleteOrgIds: prevState.deleteOrgIds.concat(actionMeta.removedValue.value) 
          }))

       }
      
    }else if(
      actionMeta.action === 'select-option' && 
      !this.state.addedOrgIds.includes(actionMeta.option.value.toString()) 
      ){

        if(defaultOrganization.includes(actionMeta.option.value.toString())){
          this.setState(prevState => ({
            deleteOrgIds: prevState.deleteOrgIds.filter( value => value !== actionMeta.option.value)
            }))

        }else{
          this.setState(prevState => ({
            addedOrgIds: prevState.addedOrgIds.concat(actionMeta.option.value) 
            }))

        }
      
    }

    // this.props.actions.deleteUserFromOrgAssociation({
    //   userId:this.props.userData.id,
    //   orgId: this.props.eachrowUserData.id,
    //   jwt: this.props.userData.jwt


    // })
    this.setState({ orgNames: orgName });
  }
  
  selectDropdownRole(rolesOptionsFinal, eachRoleId) {
    const selectedOption = rolesOptionsFinal.find((optionsValue) => { return optionsValue.value === eachRoleId });
    if (this.props.modalEditType && this.state.changingRole) {
      this.setState({
        selectedRoleId: selectedOption && selectedOption.value,
      })
    }
    return selectedOption;
  }

  selectDropdownInst(institutesOptionsFinal, eachInstId) {
    const selectedOption = institutesOptionsFinal.find((optionsValue) => { return optionsValue.value === eachInstId });
    if (this.props.modalEditType && this.state.changingInst) {
      this.setState({
        selectedInsstituteId: selectedOption && selectedOption.value,
      })
    }
    return selectedOption;
  }


  handleAssociatedBooksChange= (event,defaultOrganization) =>{
    let deletedItems = []
    if(!event) {
        deletedItems = deletedItems.concat(defaultOrganization)  
    }
    else {
        defaultOrganization.forEach((item,index) => {
            const doesNotContain = event.findIndex(e => e.label===item.label) ===-1
                if(doesNotContain
                    && deletedItems.findIndex(contain => contain.label === item.label)===-1 
                    ) {
                        deletedItems = deletedItems.concat(item) 
                    }
        })
    }
    if(event && event.length>defaultOrganization.length){
        let addedItems = [];
        event.forEach((item,index) => {
            const doesNotContain = defaultOrganization.findIndex(courses => courses.label===item.label) ===-1
                if(doesNotContain
                    && addedItems.findIndex(contain => contain.label === item.label)===-1 
                    ) {
                        addedItems = addedItems.concat(item) 
                    }
        })
        this.setState({
            apiCallOrganizations: addedItems,
            changingAssignOrganizations: true
        })
    }
    this.setState({
        selectedOrganizations: event && event,
        changingAssignOrganizations: true
    })
    if(deletedItems.length > 0) {
        this.setState({
            deletedItemsData: deletedItems
        })
    }
}

  render() {
    var modalEdit = this.props.modalEditType;
    var firstName = '';
    var lastName = '';
    var emailId = '';
    var cellPhoneNumber = '';
    var eachRoleId = undefined;
    var eachInstId = undefined;
    var instInformationData = [];
    let defaultOrganization = [];
    if (modalEdit) {
      this.props.eachrowUserData.orgInfo.map((orgInfo, index) => {
        for ( let x in orgInfo) {
          defaultOrganization.push({value: x, label: orgInfo[x]})
        }
      })
      firstName = this.props.eachrowUserData.firstName;
      lastName = this.props.eachrowUserData.lastName;
      emailId = this.props.eachrowUserData.email;
      cellPhoneNumber = this.props.eachrowUserData.cellPhoneNumber;
      eachRoleId = this.props.eachrowUserData.roleId;
      instInformationData = this.props.eachrowUserData.instInfo;
      Object.values(instInformationData).forEach(function (instIdsData) {
        eachInstId = Number(Object.keys(instIdsData));
      })
    }
    
    let institutesOptionsFinal = this.props.allInstitutes;
    let orgDetailsFinal = this.props.getAssociatedOrgDetailsData
    let rolesOptionsFinal = this.props.allRoles;
    let roleId = this.props.userData.roleId;

    const showInstitute =  !["73146","79146","76517","72195"].includes(roleId)
                           ? this.state.selectedRoleId !== null
                              ? !['77138'].includes(this.state.selectedRoleId.value)
                              : true
                           : false

    return (
      <Modal show={this.props.show}>
        <form onSubmit={(modalEdit) ? (e) => {
           e.preventDefault();
           this.callUserEdiModal()
           this.assignOrganizationsToUser() 
           } : (e) => { e.preventDefault();this.callUserCloseModal() }}>
          <Modal.Header>
            <Modal.Title>{modalEdit ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <label>First Name:  </label>
              </div>
              <div className="col-md-8">
                <input
                  value={this.state.changingFirstName ? this.state.enteredFirstName : firstName}
                  type="text"
                  onChange={this.handleFirstName}
                  placeholder="Enter First Name"
                  className="css-yk16xz-control"
                  required
                />
              </div>
            </div>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Last Name:  </label>
              </div>
              <div className="col-md-8">
                <input type="text" 
                value={this.state.changingLastName ? 
                this.state.enteredLastName : lastName
                } 
                onChange={this.handleLastName} 
                placeholder="Enter Last Name" 
                className="css-yk16xz-control" 
                required />
              </div>
            </div>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Role: </label>
              </div>
              <div className="col-md-5">
                <Select value={
                  this.state.changingRole ? 
                  this.state.selectedRoleId : 
                  (modalEdit && this.selectDropdownRole(rolesOptionsFinal, eachRoleId))} 
                  onChange={this.handleRole} 
                  options={rolesOptionsFinal} 
                  required>
                </Select>
              </div>
            </div>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>E-mail: </label>
              </div>
              <div className="col-md-8">
                <input 
                 value={
                    this.state.changingEmail ? 
                    this.state.enteredEmail : 
                    emailId
                    } 
                    type="text" 
                    onChange={this.handleEmail} 
                    placeholder="Enter Email" 
                    className="css-yk16xz-control" 
                    required />
              </div>
            </div>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Cell Phone: </label>
              </div>
              <div className="col-md-8">
                <input 
                value={
                  this.state.changingCellphone ? 
                  this.state.enteredCellNumber : 
                  cellPhoneNumber
                } 
                type="text" 
                onChange={this.handleCellPhone} 
                placeholder="Enter Cell Phone Number" 
                className="css-yk16xz-control" required />
              </div>
            </div>
            {
              showInstitute ? (
                <div className="mt-2 row">
                  <div className="col-md-4">
                    <label>Institute Name:  </label>
                  </div>
                  <div className="col-md-5">
                    <Select 
                    value={
                      this.state.changingInst ? 
                       this.state.selectedInsstituteId 
                       : (modalEdit && this.selectDropdownInst(institutesOptionsFinal, eachInstId))
                       } 
                       onChange={this.handleInstitute} 
                       options={institutesOptionsFinal} 
                       required 
                      />
                  </div>
                </div>) : null}
            {
              (roleId === "76517") ? (
                <div className="mt-2 row">
                  <div className="col-md-4">
                    <label>Organization Name: </label>
                  </div>
                  <div className="col-md-12">
                  <Select
                  value = {
                    this.state.changingAssignOrganizations 
                    ? this.state.selectedOrganizations
                    : defaultOrganization
                    }
                isClearable
                isMulti
                onChange={ (event) => this.handleAssociatedBooksChange(event,defaultOrganization)}
                options={orgDetailsFinal}
                
              />
                  </div>
                </div>)
                : null
            }
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger btn-sm btn-responsive"
              onClick={(e) => {
                e.preventDefault()
                this.props.onClose()
                this.resetUserNameChnagedFlag()
              }}>Close</button>
            {
              (modalEdit) ? <button 
                type="submit" 
                className="btn btn-primary btn-sm btn-responsive">Update</button>
                : (<button type="submit" className="btn btn-primary btn-sm btn-responsive" >Add</button>)
            }

          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
const s = ({ state }) => ({
  allInstitutes: modalsData.getAllInstitutesData(state),
  getAssociatedOrgDetailsData: modalsData.getAssociatedOrgDetailsData(state),
  allRoles: modalsData.getRoleTypesByUser(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions
    },
    d
  )
})
export default connect(s,d)(UserModal);
