import { createAction } from "redux-act";
import { createAsyncAction } from "../../lib/utils";

import * as api from "./api";
import * as mutators from "./mutators";

import { pipe, branchIf } from "../../lib/utils";

const getBookByOrgUser = createAsyncAction(
  `GET_BOOKS_BY_ORG_USER_IO`,
  (payload) => payload
);

const openAddBooksModal = createAction(
  `OPEN_ADD_BOOKS_MODAL_IO`,
  (payload) => payload
);

const openDeleteBookModal = createAction(
  `OPEN_DELETE_BOOK_MODAL_IO`,
  (payload) => payload
);

const closeDeleteBookModal = createAction(
  `CLOSE_DELETE_BOOK_MODAL_IO`,
  (payload) => payload
);

const getStandards = createAsyncAction(`GET_STANDARDS`, (payload) => payload);

const changedStandards = createAction(
  `CHANGED STANDARDS`,
  (payload) => payload
);

const handleAddEvent = createAction(`HANDLE_ADD_EVENT`, (payload) => payload);

const handleSaveStandards = createAsyncAction(
  `HANDLE_SAVE_STANDARD`,
  (payload) => payload
);

const handleSuccessAlertModalClose = createAction(
  `CLOSE_SUCCESS_ALERT_MODAL`,
  (payload) => payload
);

const handleErrorAlertModalClose = createAction(
  `HANDLE_ERROR_MODAL_CLOSE`,
  (payload) => payload
);

const deleteStandard = createAction(`DELETE_STANDARD`, ({ standard }) => ({
  standard,
}));

const hideDeleteStandardModal = createAction(
  `HIDE_DELETE_STANDARD_MODAL`,
  (payload) => payload
);

const updateStandards = createAsyncAction(
  `UPDATE_STANDARDS`,
  (payload) => payload
);

const getStandardsUrlInCrses = createAsyncAction(
  `GET_STANDARD_COURSES`,
  (payload) => payload
);

const getCoursesUrlInCrses = createAsyncAction(
  `GET_COURSES_URL`,
  (payload) => payload
);

const addCourses = createAsyncAction(`GET_ADD_COURSES`, (payload) => payload);

const handleSuccessAlertModalCloseForCourses = createAction(
  `HANDLE_UNSUCCESSFUL_MODAL`,
  (payload) => payload
);

const handleErrorAlertModalCloseForCourses = createAction(
  `HANDLE_ERROR_ALERT_MODAL_CLOSE`,
  (payload) => payload
);

const updateCourses = createAsyncAction(
  `UPDATE_COURSES_INSTA_ORG`,
  (payload) => payload
);

const storingIndexesOfCurrentDeletedCourses = createAction(
  `STORING_INDEX_OF_CURRENT_DELETING_COURSES_INSTA_ORG`,
  (payload) => payload
);

const addingCoursesNamesToStandard = createAction(
  `ADDING_COURSES_NAMES_TO_STANDARD_INSTA_ORG`,
  (payload) => payload
);

const addingCourses = createAction(
  `ADDING_COURSES_INSTA_ORG`,
  (payload) => payload
);

const handleDeleteCourseModalClose = createAction(
  `HANDLE_DELETE_COURSE_MODAL_INSTA_ORG_ADMIN`,
  (payload) => payload
);

const deleteStandardAlert = createAction(
  `HANDLE_DELETE_STANDARD_CREATE_ACTION`
)

const deleteCourseAlert = createAction(`DELETE_COURSE_ALERT_INSTA_ORG_ADMIN`, payload => payload)

const deleteCourseFailureAlert = createAction(`DELETE_COURSE_FAILUTE_ALERT_INSTA_ORG_ADMIN`, payload => payload)

const deleteStandardAlertFailure = createAction(`DELETE_STANDARD_ALERT_FAILUE_INSTA_ORG_ADMIN`, payload => payload)

const getOrganizationDetails = createAsyncAction(
  `GET_ORGANIZATION_DETAILS`
)


const reducer = {
  [getBookByOrgUser.success]: (state, payload) =>
    mutators.getBookByOrgUser(payload)(state),
  [openAddBooksModal]: (state, payload) =>
    mutators.openAddBooksModal(payload)(state),
  [openDeleteBookModal]: (state, payload) =>
    mutators.openDeleteBookModal(payload)(state),
  [closeDeleteBookModal]: (state, payload) =>
    mutators.closeDeleteBookModal(payload)(state),
  [getStandards.success]: (state, payload) =>
    mutators.setStandards(payload)(state),
  [changedStandards]: (state, payload) =>
    mutators.changedStandards(payload)(state),
  [handleAddEvent]: (state, payload) => mutators.handleAddEvent(payload)(state),
  [handleSaveStandards.success]: (state, payload) =>
    mutators.saveStandards(payload)(state),
  [handleSaveStandards.failure]: (state, payload) =>
    mutators.failureStandards(payload)(state),
  [handleSuccessAlertModalClose]: (state, payload) =>
    mutators.handleSuccessAlertModalClose(payload)(state),
  [handleErrorAlertModalClose]: (state, payload) =>
    mutators.handleErrorAlertModalClose(payload)(state),
  [deleteStandard]: (state, payload) =>
    pipe(
      [
        branchIf(
          mutators.isNewAddedStandard(payload),
          mutators.deleteNewlyAddedStandard(payload),
          mutators.deleteStandard(payload)
        ),
      ],
      state
    ),
  [hideDeleteStandardModal]: (state, payload) =>
    mutators.hideDeleteStandardModal(payload)(state),
  [getStandardsUrlInCrses.success]: (state, payload) =>
    mutators.getStandardsUrlInCrses(payload)(state),
  [getCoursesUrlInCrses.success]: (state, payload) =>
    pipe(
      [
        mutators.getCoursesUrlInCrses(payload),
        mutators.setCourseNamesAndIds(),
        mutators.addingCoursesToStandard(),
      ],
      state
    ),
  [addCourses.success]: (state, payload) => mutators.addCourses(payload)(state),
  [addCourses.failure]: (state, payload) =>
    mutators.addCoursesFailute(payload)(state),
  [handleSuccessAlertModalCloseForCourses]: (state, payload) =>
    mutators.handleSuccessAlertModalCloseForCourses()(state),
  [handleErrorAlertModalCloseForCourses]: (state, payload) =>
    mutators.handleErrorAlertModalCloseForCourses()(state),

  [storingIndexesOfCurrentDeletedCourses]: (state, payload) =>
    pipe(
      [
        mutators.storingIndexesOfCurrentDeletedCourses(payload),
        branchIf(
          mutators.isNewAddedCourse(),
          mutators.deleteCourses(),
          mutators.handleDeleteCourseModalClose(true)
        ),
      ],
      state
    ),

  [addingCourses]: (state, payload) => mutators.addingCourses(payload)(state),
  [addingCoursesNamesToStandard]: (state, payload) =>
    mutators.addingCoursesNamesToStandard(payload)(state),

  [handleDeleteCourseModalClose]: (state, payload) =>
    mutators.handleDeleteCourseModalClose(payload)(state),

  [deleteStandardAlert]: (state,payload) =>
    mutators.deleteStandardAlert(payload)(state),

  [deleteCourseAlert]: (state, payload) => mutators.deleteCourseAlert(payload)(state),
  [deleteCourseFailureAlert]: (state, payload) => mutators.deleteCourseFailureAlert(payload)(state),
  [deleteStandardAlertFailure]: (state, payload) => mutators.deleteStandardAlertFailure(payload)(state),
  [getOrganizationDetails.success]: (state, payload) => mutators.getOrganizationDetails(payload)(state)


  
};

const restApi = {
  [getBookByOrgUser]: api.getBookByOrgUser(getBookByOrgUser),
  [getStandards]: api.getStandards(getStandards),
  [handleSaveStandards]: api.handleSaveStandards(handleSaveStandards),
  [updateStandards]: api.updateStandards(updateStandards),
  [getStandardsUrlInCrses]: api.getStandardsUrlInCrses(getStandardsUrlInCrses),
  [getCoursesUrlInCrses]: api.getCoursesUrlInCrses(getCoursesUrlInCrses),
  [addCourses]: api.addCourses(addCourses),
  [updateCourses]: api.updateCourses(updateCourses),
  [getOrganizationDetails]: api.getOrganizationDetails(getOrganizationDetails),
  
};

export default {
  actions: {
    getBookByOrgUser,
    openAddBooksModal,
    openDeleteBookModal,
    closeDeleteBookModal,
    getStandards,
    changedStandards,
    handleAddEvent,
    handleSaveStandards,
    handleSuccessAlertModalClose,
    handleErrorAlertModalClose,
    deleteStandard,
    hideDeleteStandardModal,
    updateStandards,
    getStandardsUrlInCrses,
    getCoursesUrlInCrses,
    addCourses,
    handleSuccessAlertModalCloseForCourses,
    handleErrorAlertModalCloseForCourses,
    updateCourses,
    storingIndexesOfCurrentDeletedCourses,
    addingCourses,
    addingCoursesNamesToStandard,
    handleDeleteCourseModalClose,
    deleteStandardAlert,
    deleteCourseAlert,
    deleteCourseFailureAlert,
    deleteStandardAlertFailure,
    getOrganizationDetails
  },
  restApi,
  reducer,
};
