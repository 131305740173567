
import { profile } from '../profile/index';

const middleware = () => store => next => action => {
    if(action.type.endsWith('_PENDING')) {
        store.dispatch(profile.actions.showOverLayLoader())
    }
    else if(action.type.endsWith('_SUCCESS') || action.type.endsWith('_FAIL') ) {
        store.dispatch(profile.actions.hideOverlayLoader())
    }
    return  next(action)
}

export default {
    middleware
}