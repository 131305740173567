import axios from 'axios'
import APICONF from '../../config/ApiConfig.js';
import API_MSGS from '../../common/ApiMsgs.js';

export const uploadVideo = (action) => async (
    { dispatch },
    { formData, jwt }
  ) => {
    const uploadVideoUrl = APICONF.UPLOAD_VIDEO
    const [err, result] = await axios({
      method: 'post', //you can set what request you want to be
      url: uploadVideoUrl,
      data: formData,
      headers: {
        "authorization": jwt
      }
    })
      .then(res => [null, res.data])
      .catch(err => [err, null])
    const actionToDispatch = err ? action.failure({ ...err, msg: API_MSGS.UPLOAD_VIDEO_FAIL }) : action.success({ ...result, msg: API_MSGS.UPLOAD_VIDEO_SUCCESS })
    return dispatch(actionToDispatch)
  }

  export const  getCourseSection = (action) => async (
    { dispatch } ,
    { userId,jwt }
    ) => {
    const getCourseSection = APICONF.GET_ADDCOURSE_SECTION   + "?userId="+userId;
    const [err, result] = await axios 
         .get(getCourseSection, {
            headers: {
                "authorization": jwt
            }
         })
         .then(res =>{ 
          console.log("res --> ",res)
          return  [null,res.data]})
         .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err ) : action.success(result)
    return dispatch(actionToDispatch)
    }

    
    export const  getBookResourceByBookId = (action) => async (
      { dispatch } ,
      { bookId,jwt, dropdownValue }
      ) => {
      const getBookResourceByBookId = APICONF.GET_BOOKRESOURCE_BYBOOKID   + "?bookId="+bookId;
      const [err, result] = await axios 
           .get(getBookResourceByBookId, {
              headers: {
                  "authorization": jwt
              }
           })
           .then(res =>{ 
            console.log("res --> ",res)
            return  [null,res.data]})
           .catch( err => [err, null])
      const actionToDispatch = err ? action.failure(err ) : action.success({...result, dropdown: dropdownValue})
      return dispatch(actionToDispatch)
      }

export const addLearningList = (action) => async (
        { dispatch },
        {jwt, learningListData }) => {
        const addLearningList = APICONF.ADD_LEARNING_LIST
        const [err, result] = await axios({
            method: 'post', 
            url: addLearningList,
            data: learningListData,
            headers: {
                "authorization": jwt
            }
        })
            .then(res => [null, res.data])
            .catch(err => [err, null])
        const actionToDispatch = err ? action.failure(err) : action.success(result)
        return dispatch(actionToDispatch)
} 

export const getLearningList = (action) => async (
  { dispatch } ,
  { bookResourceId, jwt }
  ) => {
  const getLearningList = APICONF.GET_LEARNING_LIST + "/"+bookResourceId;
  const [err, result] = await axios 
       .get(getLearningList, {
          headers: {
              "authorization": jwt
          }
       })
       .then(res =>{ 
        console.log("res --> ",res)
        return  [null,res.data]})
       .catch( err => [err, null])
  const actionToDispatch = err ? action.failure(err ) : action.success(result)
  return dispatch(actionToDispatch)
  }

  export const deleteLearningList = (action) => async (
    { dispatch },
    { deleteLearningListData, jwt, }
  ) => {
    const deleteLearningList = APICONF.DELETE_LEARNING_LIST
    const [err, result] = await axios({
      method: 'delete', //you can set what request you want to be
      url: deleteLearningList,
      data: deleteLearningListData, 
      headers: {
        "authorization": jwt
      }
    })
      .then(res => [null, res.data])
      .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
  }
export const getVideosByBookResourceId = (action) => async (
    { dispatch } ,
    { bookResourceId, jwt }
    ) => {
      console.log("bookResourceId", bookResourceId)
    const getVideosByBookResourceIdUrl = APICONF.GET_VIDEOS_BY_BOOK_RESOURCE_ID + "/" + bookResourceId;
    const [err, result] = await axios 
         .get(getVideosByBookResourceIdUrl, {
            headers: {
                "authorization": jwt
            }
         })
         .then(res =>{ 
          console.log("res --> ",res)
          return  [null,res.data]})
         .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err ) : action.success(result)
    return dispatch(actionToDispatch)
}  