import { List } from "immutable"


export const getGroupNames = state => state.get('groupNames', '')
export const addAssigngroupNames = state => state.get('addAssigngroupNames', '')
export const deleteAssignGroupNames = state => state.get('deleteAssignGroupNames', '')
export const getCurrentTestId = state => state.get('currentTestId', '')
export const getAllInstitutesData = state => state.get('allInstitutesData', [])
export const getAddToCartStatus = state => state.get('addToCartStatus', false)
export const getRoleTypesByUser = state => state.get('rolesData', [])
export const successAlertBox = state => state.get('successAlertBox',false)
export const failureAlertBox = state => state.get('failureAlertBox',false)
export const showUserAlertSuccessMsg = state => state.get('userSuccessMsg','')
export const showUserAlertSuccessShortMsg = state => state.get('userSuccessShortMsg','')
export const showUserErrorMsg = state => state.get('userFailureMsg','')
export const getDefaultSelectedCourses = state => state.get('defaultSelectedCourses',[])
export const getAllAssociatedCourses = state => state.get('allAssociatedCourses',[])
export const getAllTeacherAssociatedCourses = state => state.get('teacherCoursesOptions',[])
export const getStandardsOptionsData = state => state.get('standardsOptionsData', [])
export const getCoursesOptions = state => state.get('coursesOptionsData', [])
export const booksPageSuccessMsg = state => state.get('addedBooksSuccessMsg','')
export const booksPageFailureMsg = state => state.get('addedBooksFailureMsg','')
export const openDeleteBookModal = state => state.get('openDeleteBookModalCourses', false)
export const openDeleteBookModalBookData = state => state.get('bookData', '')
export const getAssociatedOrgDetailsData = state => state.get('userAssociatedOrgsData', [])
export const courseSectionSuccessMsg = state => state.get('addcourseSectionSuccessMsg','')
export const courseSectionFailureMsg = state => state.get('addcourseSectionFailureMsg','')
export const getUsersByRoleId = state => state.get('getUsersByRoleId', false)
export const deleteAssignParentNames= state => state.get('deleteAssignParentNames', '')
export const assignParentToStudent = state => state.get('assignParentToStudent','');
export const assignParentToStudentFailure = state => state.get('assignParentToStudentFailure','');

export const getGroupNamesForCalendar = state => {
    const groupNames = state.get('groupNames', List())
    return groupNames.reduce((allGroupNames, currentGroupName) => {
        return [
            ...allGroupNames,
            {
                Id: currentGroupName.groupId,
                GroupName: currentGroupName.groupName
            }
        ]
    },[])

}

export const openQuantityModalBookData = state => state.get('bookData', '')

export const getOrderStatusConstants = state => state.get('orderStatusConstantsData', [])

export const getOrderIdUpdateStatus = state => state.get('orderId', '')

export const getCourierServiceProviders = state => state.get('courierServiceProvidersOptions', [])

export const orderHistoryPageSuccessMsg = state => state.get('orderHistoryPageSuccessMsg','')
export const orderHistoryPageFailureMsg = state => state.get('orderHistoryPageFailureMsg','')

export const getOrderStatusCurrentIndex = state => state.get('orderStatusCurrentIndex',0)


export const getCurrentOrderStatus = (state) => {
  const orderStatusCurrentIndex = state.get("orderStatusCurrentIndex", 0);
  return state.getIn(
    ["orderHistoryData", "ordersData", orderStatusCurrentIndex, "orderStatus"],
   ''
  );
};



export const getPostalServiceStatus = (state) => {
    const orderStatusCurrentIndex = state.get("orderStatusCurrentIndex", 0);
    return state.getIn(
      ["orderHistoryData", "ordersData", orderStatusCurrentIndex, "postalService"],
     ''
    );
  };

export const successAlertModal = state => state.get('successAlertModal',false)
export const failureAlertModal = state => state.get('failureAlertModal',false)
export const createInstituteSuccessShortMsg = state => state.get('createInstituteSuccessShortMsg','')
export const createInstituteSuccessLongMsg = state => state.get('createInstituteSuccessLongMsg','')
export const createInstituteFailureShortMsg = state => state.get('createInstituteFailureShortMsg','')
export const createInstituteFailureLongMsg = state => state.get('createInstituteFailureLongMsg','')












