import { createAction } from 'redux-act'
import {createAsyncAction} from '../../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getExecOrgAssociatedBooks = createAsyncAction(
    `GET_EXEC_ORG_ASSOSIATED_BOOKS`,
    payload => payload
)

const setQuantity = createAction(
    `SET_QUANTITY`,
    payload => payload
)

const toggleCalendar = createAction(
    `TOGGLE_CALENDAR`,
    payload => payload
)

const reducer = {
    [getExecOrgAssociatedBooks.success]: (state, payload) =>
      mutators.getExecOrgAssociatedBooks(payload)(state),
    [setQuantity]: (state, payload) =>
      mutators.setQuantity(payload)(state),

    [toggleCalendar]: (state, payload) => 
      mutators.toggleCalendar(payload)(state)
}

const restApi = {
   [getExecOrgAssociatedBooks]: api.getExecOrgAssociatedBooks(getExecOrgAssociatedBooks) 
}

export default {
    actions: {
        getExecOrgAssociatedBooks,
        setQuantity,
        toggleCalendar
    },
    restApi,
    reducer
}