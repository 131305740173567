import React, {Component} from 'react';
import StandardEditableCell from '../standards/StandardEditableCell';
import './AddStandard.css';
class StanadardTableRow extends Component {
    render() {
      return (
          <tr>
          <StandardEditableCell 
            onStandardsTablesUpdate={this.props.onStandardsTablesUpdate} 
            cellData={{
              "type": "standardName",
              value: this.props.standard.standardName,
              id: this.props.standard.standardId
            }}
          />
          <td >
            <button 
             type="button" 
             onClick={() => this.props.deleteStandard(
                {
                  standard: this.props.standard
                }
              )
            }  
             className="col-md-12 btn btn-danger"
             >
               Delete
             </button>
           </td>
        </tr>
      );
    }
  }
  export default StanadardTableRow;