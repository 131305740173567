import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../redux-assessmentModule/index'
import * as assessmentModuleData from '../../redux-assessmentModule/selector';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-classic-with-mathtype';
import './questionnaire.css'
import Select from 'react-select';
import AlertModal from '../../../common/AlertModal';
import * as appData from '../../../appSelector'
import 'ckeditor5-classic-with-mathtype/build/translations/en';

const editorConfiguration = {
  language: 'en',
  toolbar: {
    items: [
      'heading', 'MathType', 'ChemType',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'imageUpload',
      'mediaEmbed',
      'insertTable',
      'blockQuote',
      'undo',
      'redo',
    ],
  },

}
class Questionnaire extends Component {
  constructor() {
    super();
    this.state = {
      showAddquestion: true,
      showAddParaGraph: false,
      showViewQuestions: false,
      questionCategory: '',
      questionType: '',
      fillInTheBlankAnswer: '',
      totalAnswerOptionsCount: null,
      questionText: '',
      marksForQuestion: null,
      selectedParaGraphId: null,
      paragraphTitle: '',
      answerOptionsData: [],
      allTextAnswers: [],
      selectedCorrectAnswerdId: null,
      paperBasedAnswerText: ''
    }
    this.getQuestionType = this.getQuestionType.bind(this);
    this.getQuestionCategory = this.getQuestionCategory.bind(this);
    this.handleFillInTheBlankAnswer = this.handleFillInTheBlankAnswer.bind(this);
    this.totalAnswerOptionsCount = this.totalAnswerOptionsCount.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
    this.callCreateQuestion = this.callCreateQuestion.bind(this);
    this.getQuestionText = this.getQuestionText.bind(this);
    this.marksForQuestion = this.marksForQuestion.bind(this);
    this.handleParagraphSelection = this.handleParagraphSelection.bind(this);
    this.handleAddQuestion = this.handleAddQuestion.bind(this);
    this.handleAddParagraph = this.handleAddParagraph.bind(this);
    this.callCreateParagraph = this.callCreateParagraph.bind(this);
    this.handleParagraphTitle = this.handleParagraphTitle.bind(this);
    this.getParagraphOptionsData = this.getParagraphOptionsData.bind(this);
    this.getAnswers = this.getAnswers.bind(this);
    this.handleCorrectAnswer = this.handleCorrectAnswer.bind(this);
    this.handlePaperBasedAnswer = this.handlePaperBasedAnswer.bind(this);
    this.handleViewQuestions = this.handleViewQuestions.bind(this);
    this.handleQuestionnaireModalClose = this.handleQuestionnaireModalClose.bind(this);
  }

  handleViewQuestions() {
    const { eachTestId } = this.props.location.state
    localStorage.setItem('eachTestId', eachTestId)
    const { eachTestData } = this.props.location.state
    localStorage.setItem('eachTestData', JSON.stringify(eachTestData))
    var width = window.screen.width;
    var height = window.screen.height;
    var testType = eachTestData.testType;
    var viewQuestionnaireRoute;
    if (testType === 'paper') {
      viewQuestionnaireRoute = "ViewQuestionnairePaper"
    }
    if (testType === 'online') {
      viewQuestionnaireRoute = "ViewQuestionnaire"
    }
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=0,top=0`;
    window.open(`${viewQuestionnaireRoute}`, "ViewQuestionnaireCreate", params)
  }
  getQuestionType(evt) {
    this.setState({
      questionType: evt.target.value,
    });
  }
  marksForQuestion(evt) {
    this.setState({
      marksForQuestion: evt.target.value,
    });
  }
  getQuestionCategory(evt) {
    this.setState({
      questionCategory: evt.target.value,
    });
  }
  handleFillInTheBlankAnswer(evt) {
    var fillInTheBlankAnswer = evt.target.value;
    var answerOptionFillData = {
      "answerOption": fillInTheBlankAnswer,
      "correctOption": "Y"
    }
    var answerOptionFillDataArray = [];
    answerOptionFillDataArray.push(answerOptionFillData);
    this.setState({
      fillInTheBlankAnswer: fillInTheBlankAnswer,
      answerOptionsData: answerOptionFillDataArray
    });

  }

  handlePaperBasedAnswer(evt) {
    var paperBasedAnswer = evt.target.value;
    var answerOptionPaperData = {
      "answerOption": paperBasedAnswer,
      "correctOption": "Y"
    }
    var answerOptionPaperDataArray = [];
    answerOptionPaperDataArray.push(answerOptionPaperData);
    this.setState({
      paperBasedAnswerText: paperBasedAnswer,
      answerOptionsData: answerOptionPaperDataArray
    });

  }

  totalAnswerOptionsCount(evt) {
    this.setState({
      totalAnswerOptionsCount: evt.target.value,
    });
  }
  handleParagraphSelection(evt) {
    let paraGraphId = evt.value;
    this.setState({
      selectedParaGraphId: paraGraphId,
    })
  }

  handleCorrectAnswer(evt) {
    let correctAnswerdId = evt.value;
    let answerOptionsMultipleChoice = []
    this.props.getAnswerOptionsData.map(
      answersData => answerOptionsMultipleChoice.push(
        {
          "answerOption": answersData.get("label", ''),
          "correctOption": answersData.get("value", '') === correctAnswerdId ? "Y" : "N"
        }
      )
    )
    this.setState({
      selectedCorrectAnswerdId: correctAnswerdId,
      answerOptionsData: answerOptionsMultipleChoice
    })
  }
  handleAddQuestion(evt) {
    this.setState({
      showAddquestion: true,
      showAddParaGraph: false,
    });
  }
  handleAddParagraph(evt) {
    this.setState({
      showAddParaGraph: true,
      showAddquestion: false,
    });
  }
  handleParagraphTitle(evt) {
    this.setState({
      paragraphTitle: evt.target.value,
    });
  }
  getAnswers(evt, i) {
    this.props.actions.setAnswerOptionsData(
      {
        [i]: {
          value: i, label: evt.target.value
        }
      })
  }
  renderInputs(value) {
    const inputs = [];
    for (let i = 0; i < value; i++) {
      inputs.push(
        <div key={i}>
          <div className="row">
            <div className="col-3">
              <p><b>Answer option&nbsp;&nbsp;&nbsp;</b></p>
            </div>
            <div className="col-9">
              <div><input type="text" onChange={(e) => { this.getAnswers(e, i) }} />
              </div>
            </div>
          </div>
        </div>
      )
    }
    return inputs;
  }
  getQuestionText(event, editor) {
    this.setState({
      questionText: editor.getData(),
    });
  }

  getParagraphOptionsData() {
    const { eachTestId } = this.props.location.state
    this.props.actions.getAssmentAssocParagraphs({
      testId: eachTestId,
      jwt: this.props.userData.jwt
    })
  }

  callCreateQuestion(evt) {
    const { eachTestId } = this.props.location.state
    var testQuestionnaire = {
      "testId": eachTestId,
      "questionCategory": this.state.questionCategory,
      "questionType": this.state.questionType,
      "questionText": this.state.questionText,
      "createdBy": this.props.userData.id,
      "marksForQuestion": this.state.marksForQuestion,
      "passageId": this.state.questionType === "1" ? this.state.selectedParaGraphId : null
    }
    var testAnswerOptions = this.state.answerOptionsData
    var questionData = {
      "testQuestion": testQuestionnaire,
      "testAnswerOptions": testAnswerOptions
    }
    this.props.actions.addQuestionToAssessment({
      addQuestionToAssessmentData: questionData,
      jwt: this.props.userData.jwt
    })
  }

  callCreateParagraph() {
    const { eachTestId } = this.props.location.state
    var paragraphData = {
      "testId": eachTestId,
      "passageText": this.state.questionText,
      "passageTitle": this.state.paragraphTitle
    }
    this.props.actions.addParagraphToAssessment({
      addParagraphToAssessmentData: paragraphData,
      jwt: this.props.userData.jwt
    })
  }
  handleQuestionnaireModalClose(){
    this.props.actions.closeQuestionnaireModal();
  }
  render() {
    const { eachTestData } = this.props.location.state;
    const testType = eachTestData.testType
    let correctAnswerArrayData = [];
    this.props.getAnswerOptionsData.map(
      correctOptionsData => {
        correctAnswerArrayData.push({
          value: correctOptionsData.get("value", ''),
          label: correctOptionsData.get("label", ''),
        })
      }
    )
    let assmentAssocParagraphsOptions = [];
    if (this.props.assmentAssocParagraphs) {
      this.props.assmentAssocParagraphs.map(function (dataChange) {
        var paragraphs = {
          value: dataChange.testPassageId,
          label: dataChange.passageTitle
        }
        assmentAssocParagraphsOptions.push(paragraphs)
      })
    }
    return (
      <React.Fragment>
                {(this.props.getCreateQuestionnaireSuccessAlert)&&(
          <AlertModal type="success" show={true} title="Success" message={this.props.getCreateQuestionnaireSuccessMsg} onClose={() => this.handleQuestionnaireModalClose()}/>
        )}
        {(this.props.getCreateQuestionnaireErrorAlert)&&(
          <AlertModal type="danger" show={true} title="Error" message={this.props.getCreateQuestionnaireErrorMsg} onClose={() => this.handleQuestionnaireModalClose()}/>
        )}
      <div>
        <div className="container-fluid mt-4">
          <div className="row">
            <div>
              <button className="btn btn-sm btn-primary ml-2" onClick={this.handleAddQuestion}>
                <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} /> Add Question
            </button>
            </div>
            <div>
              <button className="btn btn-sm btn-primary ml-2" onClick={this.handleAddParagraph}>
                <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} /> Add Paragraph
            </button>
            </div>
            <div className="float-right">
              <button className="btn btn-sm btn-warning ml-2" onClick={this.handleViewQuestions}>
                <i className="fa fa-external-link" aria-hidden="true" style={{ color: 'black' }} /> View Questions
            </button>
            </div>
          </div>
          <div className="top-margin-create-questionnaire">
          <hr/>
          </div>

          {(this.state.showAddquestion) &&
            <div>
              <div onChange={this.getQuestionType} className="row">
                <div className="col-3">
                  <p><b>Question Type&nbsp;&nbsp;&nbsp;</b></p>
                </div>
                <div className="col-9">
                  <span>
                    <input type="radio" id="regularQuestion" name="questionType" value="0" />&nbsp;&nbsp;&nbsp;</span>
                  <span><label htmlFor="regularQuestion">Regular Question</label>&nbsp;&nbsp;&nbsp;</span>
                  <span><input onClick={this.getParagraphOptionsData} type="radio" id="paragraphQuestion" name="questionType" value="1" />&nbsp;&nbsp;&nbsp;</span>
                  <span><label htmlFor="paragraphQuestion">Paragraph Question</label>&nbsp;&nbsp;&nbsp;</span>
                </div>
              </div>
              {this.state.questionType === "1" ? (
                <div className="row">
                  <div className="col-3">
                    <p><b>Paragraph</b></p>
                  </div>
                  <div className="col-3">
                    <Select onChange={this.handleParagraphSelection} options={assmentAssocParagraphsOptions} />
                  </div>
                </div>
              )
                : null
              }
              <div onChange={this.getQuestionCategory} className="row">
                <div className="col-3">
                  <p><b>Question Category&nbsp;&nbsp;&nbsp;</b></p>
                </div>
                <div className="col-9">
                  <span>
                    <input type="radio" id="fillInTheBlank" name="questionCategory" value="0" />&nbsp;&nbsp;&nbsp;</span>
                  <span><label htmlFor="fillInTheBlank">Fill In The Blank</label>&nbsp;&nbsp;&nbsp;</span>
                  <span><input type="radio" id="multipleChoice" name="questionCategory" value="1" />&nbsp;&nbsp;&nbsp;</span>
                  <span><label htmlFor="multipleChoice">Multiple Choice</label>&nbsp;&nbsp;&nbsp;</span>
                  {(testType === 'paper') && (this.state.questionType === "0") ? (
                    <React.Fragment>
                      <span><input type="radio" id="paperBasedPassage" name="questionCategory" value="2" />&nbsp;&nbsp;&nbsp;</span>
                      <span><label htmlFor="paperBasedPassage">Paper Based Passage</label>&nbsp;&nbsp;&nbsp;</span>
                    </React.Fragment>
                  ) : null}

                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-3">
                    <p><b>Points for question&nbsp;&nbsp;&nbsp;</b></p>
                  </div>
                  <div className="col-9">
                    <input type="number" onChange={this.marksForQuestion} placeholder="Points for Question" />
                  </div>
                </div>
              </div>
              {this.state.questionCategory === "0" ?
                (
                  <div>
                    <div className="row">
                      <div className="col-3">
                        <p><b>Enter Answer&nbsp;&nbsp;&nbsp;</b></p>
                      </div>
                      <div className="col-9">
                        <input className="answer-fill-in-the-blank" type="text" placeholder="Enter Answer" onChange={this.handleFillInTheBlankAnswer} />
                      </div>
                    </div>
                  </div>)
                : null
              }
              {this.state.questionCategory === "1" ?
                (
                  <div>
                    <div className="row">
                      <div className="col-3">
                        <p><b>Total Number for answer options&nbsp;&nbsp;&nbsp;</b></p>
                      </div>
                      <div className="col-9">
                        <input type="number" onChange={this.totalAnswerOptionsCount} placeholder="Total Answer Options" />
                      </div>
                    </div>
                  </div>
                )
                : null
              }
              {this.state.questionCategory === "1" && this.state.totalAnswerOptionsCount > 0 ?
                (
                  <div>
                    {this.renderInputs(this.state.totalAnswerOptionsCount)}
                  </div>
                )
                : null
              }
              {this.state.questionCategory === "1" && this.state.totalAnswerOptionsCount > 0 ? (
                <div className="row">
                  <div className="col-3">
                    <p><b>Correct Answer</b></p>
                  </div>
                  <div className="col-3">
                    <Select onChange={this.handleCorrectAnswer} options={correctAnswerArrayData} />
                  </div>
                </div>
              )
                : null
              }
              {this.state.questionCategory === "2" ?
                (
                  <div>
                    <div className="row">
                      <div className="col-3">
                        <p><b>Enter Answer&nbsp;&nbsp;&nbsp;</b></p>
                      </div>
                      <div className="col-9">
                        <input className="answer-fill-in-the-blank" type="text" placeholder="Enter Answer" onChange={this.handlePaperBasedAnswer} />
                      </div>
                    </div>
                  </div>)
                : null
              }
            </div>
          }
          {this.state.showAddParaGraph ?
            (
              <div>
                <div className="row">
                  <div className="col-3">
                    <p><b>Paragraph Title&nbsp;&nbsp;&nbsp;</b></p>
                  </div>
                  <div className="col-9">
                    <input className="answer-fill-in-the-blank" type="text" placeholder="Enter Paragraph Title" onChange={this.handleParagraphTitle} />
                  </div>
                </div>
              </div>)
            : null
          }
          <hr />
          <div>
            <CKEditor editor={ClassicEditor}
              config={editorConfiguration}
              data="<p>Please Enter your Question here!</p>"
              onReady={editor => {

              }}
              onChange={(event, editor) => {
                this.getQuestionText(event, editor)
              }}
              onBlur={(event, editor) => {
              }}
              onFocus={(event, editor) => {
              }}
            />
          </div>
          <hr />
          <div>
            <div className="row">
              {
                this.state.showAddquestion ?
                  (
                    <div>
                      <div className="col-12">
                        <button onClick={this.callCreateQuestion} className="btn btn-primary btn-md my-2  btn-responsive" >Submit Question</button>
                      </div>
                    </div>
                  )
                  : null
              }
              {
                this.state.showAddParaGraph ?
                  (
                    <div>
                      <div className="col-12">
                        <button onClick={this.callCreateParagraph} className="btn btn-primary btn-md my-2  btn-responsive" >Submit Paragraph</button>
                      </div>
                    </div>
                  )
                  : null
              }
            </div>
          </div>
        </div>

      </div>
      </React.Fragment>
    );
  }
}

const s = ({ state }) => ({
  assmentAssocParagraphs: assessmentModuleData.getAssmentAssocParagraphs(state),
  getAnswerOptionsData: assessmentModuleData.getAnswerOptionsData(state),
  getCreateQuestionnaireSuccessAlert: assessmentModuleData.getCreateQuestionnaireSuccessAlert(state),
  getCreateQuestionnaireSuccessMsg: assessmentModuleData.getCreateQuestionnaireSuccessMsg(state),
  getCreateQuestionnaireErrorAlert: assessmentModuleData.getCreateQuestionnaireErrorAlert(state),
  getCreateQuestionnaireErrorMsg: assessmentModuleData.getCreateQuestionnaireErrorMsg(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(Questionnaire);