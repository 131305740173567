import { Map, fromJS, List } from 'immutable'


export const setUserInfo = (payload) => (state)=> {
  return state.set('userInfo', fromJS(payload.data))
}

export const setAddressInfoSuccess = (payload) => (state)=> {
  return state.set('addressInfoSuccess', payload.data)
              .set('newAddress', Map())
}

export const setUpdateUserInfoSuccess = (payload) => (state)=> {
  return state.set('updateUserInfoSuccess', payload.data)
}

export const setAddressInfo = (payload) => (state)=> {

  const address = fromJS(payload.data.responseObject)

  const updatedAddress = address.map((address, key) => {

    if(key==='shippingAddressDetails') {
      return address.map(shiipingAddrees => {

        console.log(shiipingAddrees.toJS(), 'shiipingAddrees')

        if(shiipingAddrees.get('shippingAddress','') === 'Y'){
          shiipingAddrees = shiipingAddrees.set('addressType','shipping')
        }

        if(shiipingAddrees.get('shippingAddressDefault') === 'Y'){
          shiipingAddrees  = shiipingAddrees.set('isDefault',true)
        }

        return shiipingAddrees

      })
    }else{
      return address.map(billingAddrees => {

        if(billingAddrees.get('billingAddress') === 'Y'){
          billingAddrees= billingAddrees.set('addressType','billing')
        }

        if(billingAddrees.get('billingAddressDefault') === 'Y'){
          billingAddrees=billingAddrees.set('isDefault',true)
        }

        return billingAddrees

      })
    }

  })
  return state.set('addressInfo', updatedAddress)
}

export const setDeleteAddressInfoSuccess = (payload) => (state)=> {
  return state.set('deleteAddressInfoSuccess', payload.data)
}

export const setInputValue = ( {path, value }) => state =>  {
  return state.setIn([...path ], value)
}

export const setInputStateValue = ({ path, value }) => state => {
  return state.setIn([...path,'state'],value.label)
  .setIn([...path,'stateCode'],value.value)
}

export const setCountry =
  ({ path, value }) =>
  (state) => {
    return state
      .setIn([...path, "country"], value.label)
      .setIn([...path, "countryCode"], value.value);
  };

export const toggleAddressModal = (payload) => state => {
  return state.setIn(['modals', 'address'], payload)
}

export const currentEditAddress = ({isShippingAddress, index}) => state => {
  return state.setIn(['edittingAddress','isShippingAddress'], isShippingAddress)
               .setIn(['edittingAddress','currentAddressIndex'], index)
               .setIn(['edittingAddress','isEditingAddress'], true)
}

export const resetEditAddress = () => state => state.deleteIn(['edittingAddress'])

export const deleteNewAddreess = () => state => state.deleteIn(['newAddress'])


export const setAddressType = (payload) => state => state.setIn(['newAddress','addressType'], payload)

export const toggleAddedAddressAlert =(payload) => state => state.set('addressAlert', payload)

export const toggleUserInfoAlert = (payload) => state => state.set('userInfoAlert', payload)
export const toggleEventAlert = (payload) => state => state.set('eventData', payload)


export const setUsersData = (payload) => (state) => {
  const userProfileData = fromJS(payload.data)
  return state.set('userProfileData', userProfileData)
};

export const setMeetingLink = (payload) => (state) => {
  const meetingUrl = payload.data && payload.data.data;
  return state.set('meetingUrl',meetingUrl)
};

export const removeMeetingLink = payload => state => {
  const eventData = fromJS(payload.data) //&& payload.data.data;
  return state.set('eventData',eventData)
  
}

export const setCurrentDate = payload => state => {
  const currentDate = new Date()
  return state.set('currentDate', currentDate)
}

export const getUpcomingEventsByUserIdAndDate = payload => state => { 
  const events = payload.data
  if(events.length < 1000){
    const immutableEvents = fromJS(events)
    const updatedEvents = immutableEvents.map((event) => {
      return {
        Id: event.getIn(['eventDetails','eventManagementId'],''),
        Subject: event.getIn(['eventDetails','eventTitle'],''),
        StartTime: new Date(event.getIn(['eventDetails','startTime'],'')),
        EndTime: new Date(event.getIn(['eventDetails','endTime'],'')),
        StartDate: new Date(event.getIn(['eventDetails','startDate'],'')),
        EndDate: new Date(event.getIn(['eventDetails','endDate'],'')),
        MeetingLink: event.getIn(['eventDetails','meetingLink'],''),
        generateMeetingLink: event.getIn(['eventDetails','meetingLink'],'') ? true: false,
        UserId: event.getIn(['eventUsers','userId'],''),
        UserEventDetailsId: event.getIn(['eventUsers','userEventDetailsId'],''),
        RecurrenceRule: event.getIn(['eventDetails','recurrenceRule'],''),
        EventReason: event.getIn(['eventDetails','eventReason'],''),
        EventDescription: event.getIn(['eventDetails','eventDescription'],''),
       // GroupId: event.getIn(0,['eventUsers','groupId'],'')
      }
    })

    const existingEvents = state.get('event', List())

    const allEvents = existingEvents.concat(updatedEvents)
    return state.set('allEventsData',allEvents)
  }
  return state
}


export const showOverLayLoader = (payload) => state =>{
  return state.set('overlayLoader', true)
}



export const hideOverlayLoader = (payload) => state =>{
  return state.set('overlayLoader', false)
}
