import React, { Component } from 'react';
import '../common/AddUsers.css';
import AssignUserBookModal from "../modals/AssignUserBookModal";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from '../modals/index'
import * as userData from '../appSelector'
class AssignUserBookLinkRender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            modalassignType:false,
            defaultSelectedBooks: []
        }
        this.openAssignUserBookModal = this.openAssignUserBookModal.bind(this);
        this.closeAssignUserBookModal = this.closeAssignUserBookModal.bind(this);
        this.defaultSelectedBooks = this.defaultSelectedBooks.bind(this);
        this.getassociatedBooks = this.getassociatedBooks.bind(this);
    }

    closeAssignUserBookModal() {
        this.setState({ show: false });
    }
    openAssignUserBookModal() {
        this.defaultSelectedBooks()
        this.getassociatedBooks()
        this.setState({
            show: true,
            modalassignType:true
        });
    }

    defaultSelectedBooks() {
        this.props.actions.getDefaultSelectedCourses({
            eachUserId: this.props.data.id,
            jwt: this.props.userData.jwt
        })
    }

    getassociatedBooks() {
        if(["79146", "72195"].includes(this.props.userData.roleId)){
            this.props.actions.getAllTeacherAssociatedCourses(
                {
                    userIdTeacher: this.props.userData.id,
                    jwt: this.props.userData.jwt
                }
            )
        }
        if(this.props.userData.roleId==="76517") {

            this.props.actions.getAllTeacherAssociatedCourses(
                {
                    userIdTeacher: this.props.userData.id,
                    jwt: this.props.userData.jwt
                }
            )
        }
        else{
            this.props.actions.getAllAssociatedCourses({
                orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
                userId: this.props.userData.id,
                jwt: this.props.userData.jwt
            })
        }

      }

    render() {
        console.log("this.props.data", this.props.data);
        return (
            (this.state.modalassignType) ? (
                !["77138"].includes(this.props.data.roleId) &&
                <div>
                <AssignUserBookModal 
                 eachrowUserData={this.props.data} 
                 show={this.state.show} 
                 onClose={this.closeAssignUserBookModal} 
                />
                <button className="btn btn-info" onClick={() =>this.openAssignUserBookModal() }>
                Assign Course
                </button>
            </div>) : (
                
            !["77138"].includes(this.props.data.roleId) &&
                    <button className="btn btn-info" onClick={() =>this.openAssignUserBookModal()}>Assign Course</button>
                )
        );
    }
}

const s = ({ state }) => ({
    userData: userData.getUserData(state)
  })
  const d = (d) => ({
    actions: bindActionCreators(
      {
        ...modals.actions
      },
      d
    )
  })

export default connect(s,d)(AssignUserBookLinkRender);
