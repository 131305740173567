import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { instOrgAdminConf } from '../../redux-instOrgAdminconf/index'
import * as instOrgAdminconfData from '../../redux-instOrgAdminconf/selector'
import { Link } from 'react-router-dom';

class CoursesInstOrgBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    }
    this.showDeleteModalMain = this.showDeleteModalMain.bind(this);
    this.showQuantityModal = this.showQuantityModal.bind(this);
  }

  showDeleteModalMain() {
    let bookData = this.props.bookData
    this.props.actions.openDeleteBookModal(
      bookData
    );
  }

  showQuantityModal() {

  }
  render() {
    return (
      <div className="books-card-wrapper col-md-12">
        <div className="card" key={this.props.bookData.bookId}>
          <div className="card-header">
            <b>Standard Name:&nbsp;</b>{this.props.bookData.standardName}&nbsp;&nbsp;
            <b>Course Name:&nbsp;</b>{this.props.bookData.courseName}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <div>
                  <div>
                    <b>Course Details:</b>
                  </div>
                  <div className="mt-1">
                    Title: {this.props.bookData.bookTitle}
                  </div>
                  <div className="mt-1">
                    Description: {this.props.bookData.bookDescription}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <ul>
                  <li>
                    <Link className="mb-3 text-white btn btn-primary" onClick={this.handleModalOpen} to={{ pathname: '/book-resourses-management', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData } }} >Manage Book Resources</Link>
                  </li>
                  <li>
                    <a className="mb-3 text-white btn btn-danger" onClick={this.showDeleteModalMain} >Remove Course</a>
                  </li>
                  {/* <li>
                    <a className="mb-3 text-white btn btn-warning" href="/Orders" >Order</a>
                  </li> */}
                  {/* <li>
                    <a className="mb-3 text-white btn btn-success" onClick={this.showQuantityModal}>Add Quantity</a>
                  </li> */}
                  <li>
                    <Link className="mb-3 text-white btn btn-info" href="/ViewCourseLessons" to={{ pathname: '/view-resources', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData } }}>View Resources</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  openDeleteBookModal: instOrgAdminconfData.openDeleteBookModal(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...instOrgAdminConf.actions
    },
    d
  )
})
export default connect(s, d)(CoursesInstOrgBox);
