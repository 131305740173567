import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import ExecHome from "../executive/ExecutiveHome";
import Calendar from '../calendar';
import PublicFooter from "../layout/PublicFooter";
import ExecHeader from "../layout/execHeader/ExecHeader";
import AddUsers from "../../src/common/AddUsers.js";
import OrdersPage from "../../src/order/OrdersPage.js";
import CreateInstitute from "../executive/CreateInstitute";
import ExecutiveCartSummary from "../../src/order/cartSummary/ExecutiveCartSummary";
import Review from "../../src/order/cartSummary/review";
import Payment from "../../src/order/cartSummary/payment";
import NotFoundPage from "../common/404";
import OrderHistory from "../order/orderHistory/OrderHistory";
import ProfileHome from "../profile/ProfileHome";
import BookResoursesManagement from '../videoStreaming/BookResoursesManagement.js';
import SingleVideoView from '../videoStreaming/single-video-view/SingleVideoView.js';
import CourseVideoMainView from '../videoStreaming/course-video-view/CourseVideoMainView.js';
import ViewBookResources from '../videoStreaming/ViewBookResources';

import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";

export default class ExecutiveRouter extends Component {
  render() {
    return (
      <div>
        <div className="page-body">
          <div className="page-content-exec height-fix">
            <ConnectedRouter history={createBrowserHistory()}>
              <ExecHeader></ExecHeader>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/ExecHome" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/ExecHome" />
                </Route>
                <Route
                  exact
                  path="/ExecHome"
                  render={(props) => <ExecHome {...props} />}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />
                <Route
                  exact
                  path="/ExecAddUsers"
                  render={(props) => <AddUsers {...props} />}
                />
                <Route
                  exact
                  path="/Orders"
                  render={(props) => <OrdersPage {...props} />}
                />
                <Route
                  exact
                  path="/AddInstitute"
                  render={(props) => <CreateInstitute {...props} />}
                />
                <Route
                  exact
                  path="/CartSummary"
                  render={(props) => <ExecutiveCartSummary {...props} />}
                />
                <Route
                  exact
                  path="/Review"
                  render={(props) => <Review {...props} />}
                />
                <Route
                  exact
                  path="/OrderHistory"
                  render={(props) => <OrderHistory {...props} />}
                />
                <Route
                  exact
                  path="/Payment/:id"
                  render={(props) => <Payment {...props} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <ProfileHome onlyPersonalInfo={true} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/view-resources"
                  render={(props) => <ViewBookResources {...props} />}
                />
                <Route
                  exact
                  path="/book-resourses-management"
                  render={(props) => <BookResoursesManagement {...props} />}
                />
                                <Route
                  exact
                  path="/single-video-view"
                  render={(props) => <SingleVideoView {...props} />}
                />
                <Route
                  exact
                  path="/course-video-view"
                  render={(props) => <CourseVideoMainView {...props} />}
                />
                <Route exact path="/Partners" component={PartnersPage} />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/Careers" component={CareersPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
              <PublicFooter />
            </ConnectedRouter>
          </div>
        </div>
      </div>
    );
  }
}
