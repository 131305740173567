import axios from 'axios'
import APICONF from '.././config/ApiConfig.js';
import APIMSGS from '../common/ApiMsgs';



export const addGroupName = (action) => async (
  { dispatch },
  { loginUserId, groupText, jwt }
) => {
  const addGroupName = APICONF.ADD_GROUP_NAME
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: addGroupName,
    data: {
      groupName: groupText,
      createdBy: loginUserId
    },
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.ADD_GROUP_MSG_FAIL }) : action.success({ ...result, msg: APIMSGS.ADD_GROUP_MSG_SUCCESS })
  return dispatch(actionToDispatch)
}

export const getGroupNames = (action) => async (
  { dispatch },
  { loginUserId, jwt }
) => {
  const getGroupNames = APICONF.GET_GROUP_NAMES + "/" + loginUserId;
  const [err, result] = await axios
    .get(getGroupNames, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const addAssignGroupNames = (action) => async (
  { dispatch },
  { loginUserId, jwt, userId, groupIds }
) => {
  const addAssignGroupName = APICONF.ADD_ASSIGN_GROUP_NAMES
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: addAssignGroupName,
    data: {
      loginId: loginUserId,
      userId: userId,
      groupIds: groupIds
    },
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.ASSIGN_GROUPS_MSG_FAIL })
    : action.success({ ...result, msg: APIMSGS.ASSIGN_GROUPS_MSG_SUCCESS })
  return dispatch(actionToDispatch)
}

export const deleteAssignGroupNames = (action) => async (
  { dispatch },
  { loginUserId, jwt, userId, groupId }
) => {
  const deleteAssignGroupName = APICONF.DELETE_ASSIGN_GROUP_NAME_BY_USER_ID + "/" + loginUserId + "/" + userId + "/" + groupId
  const [err, result] = await axios({
    method: 'delete', //you can set what request you want to be
    url: deleteAssignGroupName,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const deleteAssessment = (action) => async (
  { dispatch },
  { testId, jwt }
) => {
  const deleteAssessment = APICONF.DELETE_ASSESSMENT + "/" + testId
  const [err, result] = await axios({
    method: 'delete', //you can set what request you want to be
    url: deleteAssessment,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const getAllInstitutes = (action) => async (
  { dispatch },
  { jwt }
) => {
  const getAllInstitutes = APICONF.GET_ALLINSTITUTES
  const [err, result] = await axios
    .get(getAllInstitutes, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const addInstitute = (action) => async (
  { dispatch },
  { data, jwt }
) => {
  const addOrUpdateInstitute = APICONF.ADD_INSTITUTES
  const [err, result] = await axios({
    method: 'post',
    url: addOrUpdateInstitute,
    data,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const addToCart = (action) => async (
  { dispatch },
  { data, jwt }
) => {
  const addOrUpdateCart = APICONF.ADD_TO_CART;
  const [err, result] = await axios({
    method: 'post',
    url: addOrUpdateCart,
    data,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.CART_STATUS_EXEC_FAIL }) : action.success({ ...result, msg: APIMSGS.CART_STATUS_SUCCESS_EXEC })
  return dispatch(actionToDispatch)
}

export const getRoleTypesByUser = (action) => async (
  { dispatch },
  { userId, roleType, jwt }
) => {
  const getAllInstitutes = APICONF.GET_USERACCESSROLES + "/" + userId + "/" + roleType;
  const [err, result] = await axios
    .get(getAllInstitutes, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const addUserToOrganization = (action) => async (
  { dispatch },
  { addUserData, jwt }
) => {
  const addUserDataToOrganization = APICONF.ADD_USER
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: addUserDataToOrganization,
    data: addUserData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.ADD_USER_MSG_FAIL }) : action.success({ ...result, msg: APIMSGS.ADD_USER_MSG_SUCCESS })
  return dispatch(actionToDispatch)
}

export const editUserToOrganization = (action) => async (
  { dispatch },
  { editUserData, jwt }
) => {
  const editUserDataToOrganization = APICONF.EDIT_USER
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: editUserDataToOrganization,
    data: editUserData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.EDIT_USER_MSG_FAIL }) : action.success({ ...result, msg: APIMSGS.EDIT_USER_MSG_SUCCESS })
  return dispatch(actionToDispatch)
}

export const assignUserCourse = (action) => async (
  { dispatch },
  { assignUserCourseData, jwt }
) => {
  const assignUserCourseUrl = APICONF.ASSIGN_BOOKS
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: assignUserCourseUrl,
    data: assignUserCourseData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.ASSIGN_COURSES_MSG_FAIL }) : action.success({ ...result, msg: APIMSGS.ASSIGN_COURSES_MSG_SUCCESS })
  return dispatch(actionToDispatch)
}

export const deleteAssignCourseUser = (action) => async (
  { dispatch },
  { loginId, jwt, userId, bookId }
) => {
  const deleteAssignCourseUserUrl = APICONF.DELETE_ASSIGN_BOOK_BY_USER_ID + "/" + userId + "/" + bookId + "/" + loginId
  const [err, result] = await axios({
    method: 'delete', //you can set what request you want to be
    url: deleteAssignCourseUserUrl,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const getDefaultSelectedCourses = (action) => async (
  { dispatch },
  { eachUserId, jwt }
) => {
  const getDefaultSelectedCourses = APICONF.GET_USER_ASSOCIATED_BOOKS + "/" + eachUserId
  const [err, result] = await axios
    .get(getDefaultSelectedCourses, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}


export const getAllAssociatedCourses = (action) => async (
  { dispatch },
  { orgId, userId, jwt }
) => {
  const getAllAssociatedCoursesUrl = APICONF.GET_BOOKS_ORG_USER + "/" + orgId + "/" + userId
  const [err, result] = await axios
    .get(getAllAssociatedCoursesUrl, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const removeUserFromAssociation = (action) => async (
  { dispatch },
  { eachUserId, jwt }
) => {
  const removeUserFromAssociationUrl = APICONF.REMOVE_USER_FROM_ASSOCIATION
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: removeUserFromAssociationUrl,
    data: eachUserId,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.REMOVE_USER_MSG_FAIL }) : action.success({ ...result, msg: APIMSGS.REMOVE_USER_MSG_SUCCESS })
  return dispatch(actionToDispatch)
}

export const getTeacherAssociatedBooks = (action) => async (
  { dispatch },
  { userIdTeacher, jwt }
) => {
  const getBooksUrl = APICONF.GET_USER_ASSOCIATED_BOOKS + "/" + userIdTeacher;
  const [err, result] = await axios
    .get(getBooksUrl, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const getStandardsUser = (action) => async (
  { dispatch },
  { userId, orgId, jwt }
) => {
  const getStandardsUser = APICONF.GET_STANDARDS_USER + "/" + orgId + "/" + userId;
  const [err, result] = await axios
    .get(getStandardsUser, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const getCoursesWithIds = (action) => async (
  { dispatch },
  { userId, orgId, jwt }
) => {
  const getCoursesUrlInBks = APICONF.GET_COURSES_USER + "/" + orgId + "/" + userId + "/" + true;
  const [err, result] = await axios
    .get(getCoursesUrlInBks, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const addBooks = (action) => async (
  { dispatch },
  { formData, jwt }
) => {
  const addBooksUrl = APICONF.ADD_BOOKS
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: addBooksUrl,
    data: formData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.ADD_BOOKS_FAIL }) : action.success({ ...result, msg: APIMSGS.ADD_BOOKS_SUCCESS })
  return dispatch(actionToDispatch)
}


export const addCourseSection = (action) => async (
  { dispatch },
  { formData, jwt }
) => {
  const addCourseSectionUrl = APICONF.ADDCOURSE_SECTION
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: addCourseSectionUrl,
    data: formData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const deleteBook = (action) => async (
  { dispatch },
  { userId, orgId, bookId, jwt }
) => {
  const removeBookUrl = APICONF.REMOVE_BOOK_ORG_USER + "/" + orgId + "/" + bookId + "/" + userId;
  const [err, result] = await axios
    ({
      method: 'put', //you can set what request you want to be
      url: removeBookUrl,
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.DELETE_BOOK_FAIL }) : action.success({ ...result, msg: APIMSGS.DELETE_BOOK_SUCCESS })
  return dispatch(actionToDispatch)
}

export const getAssociatedOrgDetailsByUserId = (action) => async (
  { dispatch },
  { jwt, userId }
) => {
  const getUserAssocitetedOrgDetailsUrl = APICONF.GET_USER_ASSOCIATED_ORG_DETAILS + "/" + userId
  const [err, result] = await axios
    .get(getUserAssocitetedOrgDetailsUrl, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}





export const deleteUserFromOrgAssociation = (action) => async (
  { dispatch },
  { jwt, userId, orgId }
) => {
  const deleteUserFromOrgAssociation = `${APICONF.DELETE_USER_FROM_ORG_ASSOCIATION}/${orgId}/${userId}`
  const [err, result] = await axios
    .delete(deleteUserFromOrgAssociation, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const addQuantityBook = (action) => async (
  { dispatch },
  { bookStockData, jwt }
) => {
  const addQuantityBookUrl = APICONF.ADD_BOOK_STOCK
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: addQuantityBookUrl,
    data: bookStockData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.ADD_BOOK_STOCK_FAIL }) : action.success({ ...result, msg: APIMSGS.ADD_BOOK_STOCK_SUCCESS })
  return dispatch(actionToDispatch)
}

export const getOrderStatusConstants = (action) => async (
  { dispatch },
  { jwt }
) => {
  const getOrderStatusConstantsUrl = APICONF.GET_ORDER_STATUS_CONSTANTS
  const [err, result] = await axios
    .get(getOrderStatusConstantsUrl, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const getCourierServiceProviders = (action) => async (
  { dispatch },
  { jwt }
) => {
  const getCourierServiceProvidersUrl = APICONF.GET_COURIER_SERVICE_PROVIDERS
  const [err, result] = await axios
    .get(getCourierServiceProvidersUrl, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const saveUpdateOrderStatus = (action) => async (
  { dispatch },
  { updateStatusData, jwt }
) => {
  const saveUpdateOrderStatusUrl = APICONF.UPDATE_ORDER_STATUS
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: saveUpdateOrderStatusUrl,
    data: updateStatusData,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure({ ...err, msg: APIMSGS.UPDATE_ORDER_STATUS_FAIL }) : action.success({ ...result, msg: APIMSGS.UPDATE_ORDER_STATUS_SUCCESS })
  return dispatch(actionToDispatch)
}

export const assignParentToStudent = (action) => async (
  { dispatch },
  { jwt, addedParents }
) => {
  const assignParentToStudent = APICONF.ASSIGN_PARENT_TO_STUDENT
  const [err, result] = await axios({
    method: 'post', //you can set what request you want to be
    url: assignParentToStudent,
    data: addedParents,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err ) : action.success(result)
    return dispatch(actionToDispatch)
}

export const getUsersByRoleId = (action) => async (
  { dispatch },
  { jwt, id, roleId }
) => {

  const getUsersByRoleIdUrl = APICONF.GET_USER_BY_ROLE + "/" + id + "/" + roleId;
  const [err, result] = await axios
    .get(getUsersByRoleIdUrl, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const deleteAssignParentNames = (action) => async (
  { dispatch },
  {  jwt, parentStudentAssignId }
) => {
  const deleteAssignParentNames = APICONF.DELETE_ASSIGN_PARENT_NAME_BY_USER_ID + "/" + parentStudentAssignId 
  const [err, result] = await axios({
    method: 'delete', //you can set what request you want to be
    url: deleteAssignParentNames,
    headers: {
      "authorization": jwt
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}