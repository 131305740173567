import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default class VideoPlayer extends React.Component {

   // Initialize video.js player instance
   componentDidMount() {
      this.player = videojs(this.videoNode, this.props, () => {
         videojs.log('video player ready', this);
      });
   }

   componentDidUpdate(prevProps) {
      if (this.props.sources !== prevProps.sources) {
         this.player.src(this.props.sources[0])
     }
   }
   // Destroy video.js player reference
   componentWillUnmount() {
      if (this.player) {
         this.player.dispose();
      }
   }

   // wrap player with data-vjs-player` attribute
   // so no additional wrapper are created in the DOM
   render() {
      console.log("this.props In Video Player Testing",this.props)
      return (
         <div data-vjs-player>
            <video ref={node => this.videoNode = node}
               className="video-js vjs-default-skin vjs-big-play-centered image-video"></video>
         </div>
      );
   }
}