import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { executive } from './redux-executive/index'
import { Link } from 'react-router-dom';

class ExecutiveLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.showQuantityModal = this.showQuantityModal.bind(this);
  }


  showQuantityModal() {

  }
  render() {
    return (
      <div className="books-card-wrapper col-md-12">
        <div className="card" key={this.props.bookData.bookId}>
          <div className="card-header">
            Standard Name: {this.props.bookData.standardName}&nbsp;&nbsp;
            Course Name: {this.props.bookData.courseName}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <img className="bookImageSize" alt={this.props.bookData.bookTitle} src={this.props.imageUrl} />
              </div>
              <div className="col-6">
                <ul>
                <li>
                    <Link className="mb-3 text-white btn btn-primary" to={{pathname:'/book-resourses-management', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData}}}>Manage Book Resources</Link>
                  </li>
                  <li>
                    <Link className="mb-3 text-white btn btn-warning" to={{ pathname: '/Orders', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData } }}> Order </Link>
                  </li>
                  <li>
                    <Link className="mb-3 text-white btn btn-info" to={{ pathname: '/view-resources', state: { bookId: this.props.bookData.bookId, bookData: this.props.bookData }}}> View Resources </Link>
                  </li>
                </ul>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({

})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...executive.actions
    },
    d
  )
})
export default connect(s,d)(ExecutiveLinks);
