import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import orderTask from "../redux-orders/orderTask";
import * as selectors from "../redux-orders/selector";
import * as appData from "../../appSelector";
import { List } from "immutable";
import './CartSummary.css';
import { Alert } from 'react-bootstrap';
import InformationBanner from "../../common/InformationBanner";
import INFO_BANNER from "../../common/InfoBanner";
import WithPagination from '../../common/WithPagination';

class CartSummary extends Component {
  componentDidMount() {
    this.props.actions.getCartSummaryByUserId({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });
  }

  changeQuantity = (e, instituteIndex, bookIndex) => {
    this.props.actions.changeQuantity({
      instituteIndex,
      bookIndex,
      value: e.target.value,
    });
  };

  navigateToCart = () => {
    this.props.history.push("/Checkout")

  }
  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }
  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }

  render() {
    const {
      cartSummary,
      userData,
      taxAmount,
      convenienceFee,
      cartTotalBeforeTax,
      cartGrandTotal,
      updateCartSuccessAlert,
      updateCartFailureAlert,
      deleteCartSuccessAlert,
      deleteCartFailureAlert
    } = this.props;
    const { currentPage, itemsPerPage, searchText } = this.props;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;

    let currentItems = [...cartSummary];
    console.log(currentItems)
    console.log(currentItems)
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      

      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          console.log(key)
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
            console.log(item)
            console.log(itemString)
          }
        }
        return itemString.match(searchTermRegex);
      })
    }
    if (currentItems) {

      currentItems.slice(indexOfFirst, indexOfLast).map((cartData, i) => {
        return <cartSummary key={cartSummary.cartData}/>
      })

    return (
      <div className="container mt-4 p-1">
        
        <div className="review-button ">
        <div style={{"margin-bottom": '70px'}}>
          
          <button className="btn btn-primary"  hidden = {!this.props.cartSummary.size >0 }
        onClick={this.navigateToCart}>Checkout</button>
        </div>

         <div hidden ={this.props.cartSummary.size >0} className="empty-content" >
         <InformationBanner isHorizontal 
      message = {INFO_BANNER.MESSAGE_CHECKOUT}/>
          </div> 
          <div hidden ={!this.props.cartSummary.size >0}>
          <WithPagination
            itemsPerPage={itemsPerPage}
            totalItems={currentItems.length}
            currentPage={currentPage}
            onSearch={this.onSearch}
            onPaginate={this.onPaginate}
            
          >
       {cartSummary.map((cartData, instituteIndex) => (
          <div>
            {cartData.get("bookDtls", List()).map((book, bookIndex) => (
              <div className="cart-data-wrapper">
                <div className="cart-img-wrapper">
                  <img
                    src={book.get("bookImage", "")}
                    alt={"book"}
                    width={"300"}
                    height={"200"}
                  />
                </div>
                <div className="cart-content-wrapper">
                  <p className="price">
                  Price:
                  <span className="rupee"></span>
                    {`  ${book.get("bookPrice", "")}`}
                  </p>
                  <p className="total-amount">Total Amount:
                  <span className="rupee"></span>
                    {` ${book.get("totalAmount", "")}`}
                  </p>
                  <p className="quantity">
                    Quantity:&nbsp;
                    <input
                      type="text"
                      value={book.get("quantity", "")}
                      onChange={(e) =>
                        this.changeQuantity(e, instituteIndex, bookIndex)
                      }
                    />
                    &nbsp;&nbsp;
                    {updateCartSuccessAlert(bookIndex) && <Alert variant='success'className="p-1 m-1">{'SuccessfullyAdded'}</Alert>}
                    {updateCartFailureAlert(bookIndex) && <Alert variant='danger'className="p-1 m-1 ">{'Update failed'}</Alert>}
                    {deleteCartSuccessAlert(bookIndex) && <Alert variant='success'className="p-1 m-1">{'SuccessFully deleted'}</Alert>}
                    {deleteCartFailureAlert(bookIndex) && <Alert variant='danger'className="p-1 m-1">{'Delete failed'}</Alert>}
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => {
                        this.props.actions.updateCart({
                          jwt: userData.jwt,
                          cartDetailsId: book.get("cartDetailsId", ""),
                          instituteIndex,
                          bookIndex,
                          user: this.props.userData.id,
                        });
                      }}
                    >
                      Update
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => {
                        this.props.actions.deleteCart({
                          jwt: userData.jwt,
                          cartDetailsId: book.get("cartDetailsId", ""),
                          instituteIndex,
                          bookIndex,
                          user: this.props.userData.id,
                        });
                      }}
                    >
                      Delete
                    </button>
                  </p>
                </div>
              </div>
            ))}
              </div>
            ))}
              <div className="cart-dataa-wrapper" hidden = {!this.props.cartSummary.size >0 }>
              <div className="price text-right">
               <b>Total Amount Before Tax and Fees: </b>
               <span className="rupee"></span> 
                 {` ${cartTotalBeforeTax}`}
            </div>
                <div className="price text-right">
                  <b>Tax Amount: </b>
                  <span className="rupee"></span> 
                  {` ${taxAmount}`}
                </div>
                <div style={{ borderBottom: '1px solid lightgrey', paddingBottom: '.5rem' }} className="total-amount text-right">
                  <b>Convenience Fee: </b>
                  <span className="rupee"></span> 
                  {` ${convenienceFee}`}
                </div>
                <br/>
                <div className=" text-right">
                  <h3 style={{ display: 'inline-block', margin: 0 }}>Cart Grand Total: </h3>
                  <span className="rupee"></span> 
                  {` ${cartGrandTotal}`}
                </div>
              
              </div>
              </WithPagination>
              </div>
          </div>
      </div>
    );
  }
  
}
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  cartSummary: selectors.getCartSummary(state),
  cartId: selectors.cartId(state),
  taxAmount: selectors.taxAmount(state),
  convenienceFee: selectors.convenienceFee(state),
  cartTotalBeforeTax: selectors.cartTotalBeforeTax(state),
  cartGrandTotal: selectors.cartGrandTotal(state),
  updateCartSuccessAlert: selectors.getUpdateCartSuccessAlert(state),
  updateCartFailureAlert: selectors.getUpdateCartFailureAlert(state),
  deleteCartSuccessAlert: selectors.getDeleteCartSuccessAlert(state),
  deleteCartFailureAlert: selectors.getDeleteCartFailureAlert(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
      ...orderTask.actions
    },
    d
  ),
});
export default connect(s, d)(CartSummary);
