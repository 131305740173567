import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { teacheractions } from './index'
import * as appData from '../appSelector'
class TeacherPanelLink extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.openAttendance = this.openAttendance.bind(this);
  }

  openAttendance(bookId) {
    console.log("bookId",bookId)
    localStorage.setItem('selectedBookIdRoster', bookId)
    window.open("/attendance")
  }

  render() {
    console.log("this.props.teacherAssocBookData", this.props.teacherAssocBookData)
    var imageFromBucket = this.props.teacherAssocBookData &&
      this.props.teacherAssocBookData.imageBucketEnable;
    var imageUrl = "";
    if (imageFromBucket) {
      imageUrl = this.props.teacherAssocBookData.imageBucketLocationChanged;
    } else {
      imageUrl = this.props.teacherAssocBookData.imageappLocationChanged;
    }
    return (
      <div>
        <div className="books-card-wrapper col-md-12">
          <div className="card" key={this.props.teacherAssocBookData.bookId}>
            <div className="card-header">
              <b>Standard Name:&nbsp;</b>
              {this.props.teacherAssocBookData.standardName}&nbsp;&nbsp;
              <b>Course Name:&nbsp;</b>
              {this.props.teacherAssocBookData.courseName}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <img className="bookImageSize" alt={this.props.teacherAssocBookData.bookTitle} src={imageUrl} />
                </div>
                <div className="col-4">
                  <div>
                    <div>
                      <b>Course Details:</b>
                    </div>
                    <div className="mt-1">
                      Title: {this.props.teacherAssocBookData.bookTitle}
                    </div>
                    <div className="mt-1">
                      Description:{" "}
                      {this.props.teacherAssocBookData.bookDescription}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <ul>
                    <li>
                      <Link
                        className="btn btn-success mb-3"
                        to={{
                          pathname: "/CreateAssessment",
                          state: {
                            eachBookId: this.props.teacherAssocBookData.bookId,
                          },
                        }}
                      >
                        Create Assessment
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="btn btn-warning mb-3"
                        to={{
                          pathname: "/ViewAssessmentsCourseTIO",
                          state: {
                            eachBookId: this.props.teacherAssocBookData.bookId,
                          },
                        }}
                      >
                        View Assessments
                      </Link>
                    </li>
                    <li>
                      <Link className="mb-3 text-white btn btn-info" to={{ pathname: '/view-resources', state: { bookId: this.props.teacherAssocBookData.bookId, bookData: this.props.teacherAssocBookData } }}> View Resources </Link>
                    </li>
                    <li>
                      <Link className="mb-3 text-white btn btn-warning" to={{ pathname: '/Orders', state: { bookId: this.props.teacherAssocBookData.bookId, bookData: this.props.teacherAssocBookData } }} >Order</Link>
                    </li>
                    <li>
                    <Link className="btn btn-dark" onClick={() =>this.openAttendance(this.props.teacherAssocBookData.bookId)}>Attendance Roster</Link>
                  </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  getUserData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...teacheractions.actions
    },
    d
  )
})
export default connect(s, d)(TeacherPanelLink);
