import { fromJS, List, Map } from "immutable";
export const setStandards = (payload) => (state) =>
  state.set("standards", payload);

export const changedStandards = (payload) => (state) =>
  state.set("changedStandards", payload);

export const handleAddEvent = (payload) => (state) => {
  const min = 1;
  const max = 100;
  var id = min + Math.random() * (max - min);
  var standard = {
    standardId: id,
    standardName: "",
  };

  const standards = state.getIn(
    ["standards", "data", "responseObject", "standardDetails"],
    List()
  );

  const addingStandards = standards.concat(standard);
  return state.setIn(
    ["standards", "data", "responseObject", "standardDetails"],
    addingStandards
  );
};

export const saveStandards = ({ data }) => (state) => {
  return state
    .set("savedStandards", data)
    .set("successMsg", data.responseMessages[0].longMessage)
    .set("successAlert", true);
};

export const failureStandards = (payload) => (state) => {
  return state
    .set("errorAlert", true)
    .set("errorMsg", "Please check the Standardnames you have entered");
};

export const handleSuccessAlertModalClose = () => (state) =>
  state.set("successAlert", false);

export const handleErrorAlertModalClose = () => (state) =>
  state.set("errorAlert", false);

export const deleteStandard = ({ standard }) => (state) => {
  return state
    .set("isDeletedModalShow", true)
    .set("currentDeletedModalStandard", standard);
};

export const hideDeleteStandardModal = () => (state) => {
  return state.set("isDeletedModalShow", false);
};

export const getBookByOrgUser = (payload) => (state) => {
  return state.set("booksDataOrgUser", payload.data);
};
export const openAddBooksModal = (payload) => (state) => {
  return state.set("openAddBookModal", true);
};
export const openDeleteBookModal = (payload) => (state) => {
  return state.set("openDeleteBookModalCourses", true).set("bookData", payload);
};
export const closeDeleteBookModal = (payload) => (state) => {
  return state.set("openDeleteBookModalCourses", false);
};

export const getStandardsUrlInCrses = ({ data }) => (state) =>
  state.set("standardUrl", fromJS(data));

export const getCoursesUrlInCrses = ({ data }) => (state) =>
  state.set("courseUrl", fromJS(data));

export const addCourses = ({ data }) => (state) =>
  state
    .set("successMsgCourses", data.responseMessages[0].longMessage)
    .set("successAlertCourses", true);

export const addCoursesFailute = () => (state) =>
  state
    .set("errorAlertCourses", true)
    .set("errorMsgCourses", "Please check the course details you have entered");

export const handleSuccessAlertModalCloseForCourses = () => (state) =>
  state.set("successAlertCourses", false);

export const handleErrorAlertModalCloseForCourses = () => (state) =>
  state.set("errorAlertCourses", false);

export const setCourseNamesAndIds = () => (state) => {
  const coursesData = state.getIn(
    ["courseUrl", "responseObject", "coursesData"],
    List()
  );

  const updatedCoursesData = coursesData.map((course) => {
    const updatedCourseIdNames = course.get("courseIdNames", Map()).toJS();

    let updatedCourses = List();

    Object.entries(updatedCourseIdNames).forEach((keyValue) => {
      updatedCourses = updatedCourses.concat(
        List([
          Map({
            courseId: keyValue[0],
            courseName: keyValue[1],
          }),
        ])
      );
    });
    return course.set("courseIdNames", updatedCourses);
  });

  return state.setIn(
    ["courseUrl", "responseObject", "coursesData"],
    updatedCoursesData
  );
};

export const addingCoursesToStandard = () => (state) => {
  const standards = state.getIn(
    ["standardUrl", "responseObject", "standardDetails"],
    List()
  );

  const coursesData = state.getIn(
    ["courseUrl", "responseObject", "coursesData"],
    List()
  );

  const updatedStandards = standards.map((standard) => {
    const currentCoursesData =
      coursesData.find(
        (course) =>
          course.get("standardId", "") === standard.get("standardId", "")
      ) || Map();

    return standard.set(
      "courseNames",
      currentCoursesData.get("courseIdNames", List())
    );
  });
  return state.setIn(
    ["standardUrl", "responseObject", "standardDetails"],
    updatedStandards
  );
};

export const storingIndexesOfCurrentDeletedCourses = ({
  currentStandardIndex,
  currentCourseIndex,
}) => (state) => {
  return state
    .set("currentStandardIndex", currentStandardIndex)
    .set("currentCourseIndex", currentCourseIndex);
};

export const handleDeleteCourseModalClose = (payload) => (state) =>
  state.set("deleteCourseAlert", payload);

export const addingCourses = ({ value, e, standardId }) => (state) => {
  const path = ["standardUrl", "responseObject", "standardDetails"];

  let standards = state.getIn([...path], List());
  let currentStandardIndex = standards.findIndex(
    (standard) => standard.get("standardId", "") === standardId
  );
  if (e.key === "Enter" && value) {
    if (currentStandardIndex !== -1) {
      let currentStandardsCourses = state.getIn(
        [...path, currentStandardIndex, "courseNames"],
        List()
      );
      const updatedCourses = currentStandardsCourses.concat(
        List([
          Map({
            courseId: e.target.id,
            courseName: value,
            isNewAddedCourse: true
          }),
        ])
      );
      return state.setIn(
        [...path, currentStandardIndex, "courseNames"],
        updatedCourses
      );
    }
  }

  return state;
};

export const addingCoursesNamesToStandard = (payload) => (state) => {
  let standardsImmutable = state.getIn(
    ["standardUrl", "responseObject", "standardDetails"],
    List()
  );

  const coursesImmutable = state.getIn(
    ["courseUrl", "responseObject", "coursesData"],
    []
  );
  standardsImmutable.forEach((standard, index) => {
    const currentCourses = coursesImmutable.find(
      (course) => course.get("standardId", "") === standard.get("standardId")
    );

    const updateStandards = standard.set(
      "courseNames",
      currentCourses.get("courseNames", List())
    );

    standardsImmutable = standardsImmutable.set(index, updateStandards);
  });

  return state.setIn(
    ["standardUrl", "responseObject", "standardDetails"],
    standardsImmutable
  );
};

export const isNewAddedStandard = ({ standard }) => (state) => {
  const standards = state.getIn(
    ["standards", "data", "responseObject", "standardDetails"],
    List()
  );

  const currentStandard = standards.find(
    (standardObj) => standardObj.standardId === standard.standardId
  );

  const currentStandardImmutable = fromJS(currentStandard);

  return (
    currentStandard && currentStandardImmutable.get("createdBy", "") === ""
  );
};

export const deleteNewlyAddedStandard = ({ standard }) => (state) => {
  const standards = state.getIn(
    ["standards", "data", "responseObject", "standardDetails"],
    List()
  );

  const changedStandards = state.get("changedStandards", List());

  const updatedStandard = standards.filter(
    (standardObj) => standardObj.standardId !== standard.standardId
  );

  const updatedChangedStandard = changedStandards.filter(
    (standardObj) => standardObj.standardId !== standard.standardId
  );

  state
    .setIn(
      ["standards", "data", "responseObject", "standardDetails"],
      updatedStandard
    )
    .set("changedStandards", updatedChangedStandard);
};



export const isNewAddedCourse = () => state => {

  const currentStandardIndex = state.get("currentStandardIndex", 0);
  const currentCourseIndex = state.get("currentCourseIndex", 0);
  const path = [
    "standardUrl",
    "responseObject",
    "standardDetails",
    currentStandardIndex,
  ];

  
  const currentDeletedCourse = state.getIn(
    [...path, "courseNames", currentCourseIndex],
    ""
  );

  return currentDeletedCourse.get('isNewAddedCourse',false)
}


export const deleteCourses = () => state => {

  const currentStandardIndex = state.get("currentStandardIndex", 0);
  const currentCourseIndex = state.get("currentCourseIndex", 0);
  const path = [
    "standardUrl",
    "responseObject",
    "standardDetails",
    currentStandardIndex,
  ];

  
  return state.deleteIn(
    [...path, "courseNames", currentCourseIndex],
    ""
  );

}

export const deleteStandardAlert = payload => state => {

  return state.set('deleteStandardAlert', payload)
}



export const deleteCourseAlert = payload => state => {
  return state.set('deleteCourseAlertBox', payload)
}


export const deleteCourseFailureAlert = payload => state => {
  return state.set('deleteCourseFailureAlert', payload)
}


export const deleteStandardAlertFailure = payload => state => {
  return state.set('deleteStandardAlertFailure', payload)
}

export const getOrganizationDetails = payload => state => {
  return state.set('organizationData', payload.data)
}
