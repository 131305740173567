import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../redux-assessmentModule/index'
import { modals } from '../../../modals/index'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import './createAssessment.css'
import * as groupData from '../../../modals/selector';
import * as assessmentModuleData from '../../redux-assessmentModule/selector'
import 'react-datepicker/dist/react-datepicker.css';
import { teacherinstorg } from '../../../teacherinstorg/index'
import AlertModal from '../../../common/AlertModal';
import * as appData from '../../../appSelector'
class CreateAssessment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      assessmentType: '',
      testName: '',
      numberOfQuestions: null,
      totalMarks: null,
      qualificationMarks: null,
      startOpen: false,
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      timeDuration: null,
      selectedGroupId: null
    }
    this.getAssessmentType = this.getAssessmentType.bind(this);
    this.getNumberOfQuestions = this.getNumberOfQuestions.bind(this);
    this.getTotalMarks = this.getTotalMarks.bind(this);
    this.getQualificationMarks = this.getQualificationMarks.bind(this);
    this.callCreateAssessment = this.callCreateAssessment.bind(this);
    this.getGroupNames = this.getGroupNames.bind(this);
    this.getStartDate = this.getStartDate.bind(this);
    this.getEndDate = this.getEndDate.bind(this);
    this.getTimeDuration = this.getTimeDuration.bind(this);
    this.getTestName = this.getTestName.bind(this);
    this.handleGroupSelection = this.handleGroupSelection.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  getAssessmentType(evt) {
    this.setState({
      assessmentType: evt.target.value,
    });
  }
  getTestName(evt) {
    this.setState({
      testName: evt.target.value,
    });
  }
  getTimeDuration(evt) {
    this.setState({
      timeDuration: evt.target.value,
    });
  }
  getNumberOfQuestions(evt) {
    this.setState({
      numberOfQuestions: evt.target.value,
    });
  }
  getTotalMarks(evt) {
    this.setState({
      totalMarks: evt.target.value,
    });
  }
  getQualificationMarks(evt) {
    this.setState({
      qualificationMarks: evt.target.value,
    });
  }
  getStartDate(date) {
    this.setState({
      startOpen: true,
      startDate: date
    })
  }
  getEndDate(date) {
    this.setState({
      startOpen: true,
      endDate: date
    })
  }
  getGroupNames() {
    this.props.actions.getGroupNames({
      loginUserId: this.props.userData.id,
      jwt: this.props.userData.jwt
    })
  }

  handleGroupSelection = event => {
    let groupId = event.value;
    this.setState({
      selectedGroupId: groupId,
    })
  }
  componentDidMount() {
    this.getGroupNames();
  }

  callCreateAssessment() {
    const { eachBookId } = this.props.location.state
    var createAssessmentPostData = {
      "bookId": eachBookId,
      "testDuration": this.state.timeDuration,
      "totalQuestions": this.state.numberOfQuestions,
      "testStartDate": this.state.startDate,
      "testEndDate": this.state.endDate,
      "testType": this.state.assessmentType,
      "testName": this.state.testName,
      "createdBy": this.props.userData.id,
      "totalMarks": this.state.totalMarks,
      "qualificationMarks": this.state.qualificationMarks,
      "groupId": this.state.selectedGroupId,
    }
    this.props.actions.createAssessment({
      createAssessmentData: createAssessmentPostData,
      jwt: this.props.userData.jwt
    })
  }
  handleModalClose(payload){
    this.props.actions.closeAlertModal(payload);
  }
  render() {
    let allGroupOptionsData = [];
    if (this.props.getGroupNamesData) {
      this.props.getGroupNamesData.map(function (dataChange) {
        var groups = {
          value: dataChange.groupId,
          label: dataChange.groupName
        }
        allGroupOptionsData.push(groups)
      })
    }
    return (
      <React.Fragment>
        {(this.props.getCreateAssessmentSuccessAlert)&&(
          <AlertModal type="success" show={true} title="Success" message={this.props.getCreateAssessmentSuccessMsg} onClose={() => this.handleModalClose(true)}/>
        )}
        {(this.props.getCreateAssessmentErrorAlert)&&(
          <AlertModal type="danger" show={true} title="Error" message={this.props.getCreateAssessmentErrorMsg} onClose={() => this.handleModalClose(false)}/>
        )}
      <div className="container-fluid mt-4">

        <div className="container">
          <b>Create Assessment</b>
          <div className="top-margin-create-assessment">
          </div>
        </div>
        <hr />
        <div className="container top-margin-create-assessment">
          <div onChange={this.getAssessmentType} className="row">
            <div className="col-3">
              <p><b>Assessment Type&nbsp;&nbsp;&nbsp;</b></p>
            </div>
            <div className="col-9">
              <span>
                <input type="radio" id="onlineAssessment" name="assessment" value="online" />&nbsp;&nbsp;&nbsp;</span>
              <span><label htmlFor="onlineAssessment">Online based</label>&nbsp;&nbsp;&nbsp;</span>
              <span><input type="radio" id="paperAssessment" name="assessment" value="paper" />&nbsp;&nbsp;&nbsp;</span>
              <span><label htmlFor="paperAssessment">Paper based</label>&nbsp;&nbsp;&nbsp;</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Assessment Name</b></p>
            </div>
            <div className="col-9">
              <span >
                <input className="textBox-style-assessment" type="text" onChange={this.getTestName} />&nbsp;&nbsp;
          </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Assessment Duration</b></p>
            </div>
            <div className="col-9">
              <span >
                <input className="textBox-style-assessment" type="number" min="0" max="179" onChange={this.getTimeDuration} placeholder="00" />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Total Number of Questions</b></p>
            </div>
            <div className="col-9">

              <span>
                <input className="textBox-style-assessment" type="number" onChange={this.getNumberOfQuestions} />&nbsp;&nbsp;
            </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Total Points</b></p>
            </div>
            <div className="col-9">
              <span >
                <input className="textBox-style-assessment" type="number" onChange={this.getTotalMarks} />&nbsp;&nbsp;
            </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Qualification Points</b></p>
            </div>
            <div className="col-9">
              <span >
                <input className="textBox-style-assessment" type="number" onChange={this.getQualificationMarks} />&nbsp;&nbsp;
            </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Start Date</b></p>
            </div>
            <div className="col-9">
              <DatePicker
                showFullMonthYearPicker={true}
                startOpen={this.state.startOpen}
                selected={this.state.startDate} showTimeInput={true} onChange={this.getStartDate} />

            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>End Date</b></p>
            </div>
            <div className="col-9">
              <DatePicker
                showFullMonthYearPicker={true}
                startOpen={this.state.startOpen}
                selected={this.state.endDate} showTimeInput={true} onChange={this.getEndDate} />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <p><b>Group</b></p>
            </div>
            <div className="col-3">
              <Select onChange={this.handleGroupSelection} options={allGroupOptionsData} />
            </div>
          </div>
          <button onClick={this.callCreateAssessment} className="btn btn-primary btn-md  btn-responsive" >Create Assessment</button>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

const s = ({ state }) => ({
  getGroupNamesData: groupData.getGroupNames(state),
  getCreateAssessmentErrorAlert: assessmentModuleData.getCreateAssessmentErrorAlert(state),
  getCreateAssessmentSuccessAlert: assessmentModuleData.getCreateAssessmentSuccessAlert(state),
  getCreateAssessmentSuccessMsg: assessmentModuleData.getCreateAssessmentSuccessMsg(state),
  getCreateAssessmentErrorMsg: assessmentModuleData.getCreateAssessmentErrorMsg(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions,
      ...modals.actions,
      ...teacherinstorg.actions
    },
    d
  )
})
export default connect(s, d)(CreateAssessment);