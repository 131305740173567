import { createAction } from "redux-act";
import { createAsyncAction } from "../../lib/utils";

import * as api from "./api";
import * as mutators from "./mutators";

  const getSudentTimeEntrys = createAsyncAction(
    `GET_STUDENT_TIME_ENTRIES`,
    (payload) => payload
  );

  const saveStudentTimeEntrys = createAsyncAction(
    `SAVE_STUDENT_TIME_ENTRIES`,
    (payload) => payload
  );

  const closeAttendanceAlertModal = createAction(
    `CLOSE_ATTENDANCE_ALERT_MODAL`,
    payload => payload
  )
  const reducer = {
    [getSudentTimeEntrys.success]: (state, payload) =>
    mutators.getSudentTimeEntrys(payload)(state),
    [saveStudentTimeEntrys.success]: (state, payload) =>
    mutators.saveStudentTimeEntrysDataSuccess(payload)(state),
    [saveStudentTimeEntrys.failure]: (state, payload) =>
    mutators.saveStudentTimeEntrysDataFailure(payload)(state),
    [closeAttendanceAlertModal]: (state, payload) =>
      mutators.closeAttendanceAlertModal(payload)(state),
  }

  const restApi = {
    [getSudentTimeEntrys]: api.getSudentTimeEntrys(getSudentTimeEntrys),
    [saveStudentTimeEntrys]: api.saveStudentTimeEntrys(saveStudentTimeEntrys),
  };

  export default {
    actions: {
        getSudentTimeEntrys,
        saveStudentTimeEntrys,
        closeAttendanceAlertModal,
    },
    restApi,
    reducer,
  };
