import React, { Component } from 'react';
import AdminRouter from '../src/routers/AdminRouter.js';
import StudentRouterInstOrg from '.././src/routers/StudentRouterInstOrg.js';
import TeacherRouterInstOrg from '.././src/routers/TeacherRouterInstOrg.js';
import TeacherRouter from '.././src/routers/TeacherRouter'
import StudentRouter from '.././src/routers/StudentRouter'
import InstOrgAdminRouter from '../src/routers/InstOrgAdminRouter'
import PublicRouter from '../src/routers/PublicRouter.js';
import { Route } from 'react-router-dom'
import GuestRouter from '../src/routers/GuestRouter'
import ExecutiveRouter from '../src/routers/ExecutiveRouter'
import SchoolAdminRouter from '../src/routers/SchoolAdminRouter'
import PartnersPage from '../src/publicview/PartnersPage';
import PublicBooksPage from '../src/publicview/PublicBooksPage.js';
import AboutUsPage from '../src/publicview/AboutUsPage.js';
import CareersPage from '../src/publicview/CareersPage.js';
import ContactUsPage from '../src/publicview/ContactUsPage.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as app from './appSelector'
import { loginn } from './login/index'
import loginTask from './login/loginTask'
import './App.css'
import RefreshTokenModal from './modals/RefreshTokenModal'
import PublicOrders from './order/PublicOrders.js';
import ParentRouter from './routers/ParentRouter.js';
import ParentinstorgRouter from './routers/ParentinstorgRouter.js';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      isLoggedIn: false,
      userData: null,
    };
    this.commonRoutes = this.commonRoutes.bind(this);
  }

  isTokenExpired(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const { exp } = JSON.parse(jsonPayload);
    const expired = Date.now() >= exp * 1000
    return expired
  }

  componentDidMount() {
    var loggedIn = localStorage.getItem("isLoggedIn");
    var userData = JSON.parse(localStorage.getItem("userData"));
    var jwt;
    if (userData !== null) {
      jwt = userData.jwt
      if (this.isTokenExpired(jwt)) {
        this.clearLocalStorage()
      }
    }
    if (!this.props.isLoggedIn && loggedIn) {
      this.props.actions.setDataAfterReload({
        isLoggedIn: localStorage.getItem("isLoggedIn"),
        userData: localStorage.getItem("userData"),
        refreshTokenModal: window.localStorage.getItem("refreshTokenModal"),
      });
    }
  }



  commonRoutes = () => {
    return (
      <React.Fragment>
        <Route exact path="/Partners" component={PartnersPage} />
        <Route exact path="/PublicBooks" component={PublicBooksPage} />
        <Route
          exact
          path="/PublicOrders"
          render={(props) => <PublicOrders {...props} />}
        />
        <Route eaxct path="/AboutUs" component={AboutUsPage} />
        <Route exact path="/Careers" component={CareersPage} />
        <Route exact path="/ContactUs" component={ContactUsPage} />
        {/* <Route component={NotFoundPage} /> */}
      </React.Fragment>
    );
  }

  clearLocalStorage = () => {
    console.log(this.props.history, 'history')
    const keyCloak = JSON.parse(localStorage.getItem('keycloak'))
    console.log(keyCloak, 'keycloak keycloak')
    // keyCloak.logout()
    localStorage.clear();
    this.props.actions.resetLoginStatus()
    this.props.actions.setLogoutStatus()
    window.location.href = "/Login"
    localStorage.setItem('isLogout', true)
  }

  render() {
    console.log('localStorage.getItem("isLoggedIn")', localStorage.getItem("isLoggedIn"))
    return (
      <div>
        {/* <Loader show={this.props.overLayLoader}/> */}
        {/* {!this.props.startAssessment && (
          <RefreshTokenModal
            show={this.props.refreshToken}
            no={() =>
              this.props.actions.setRefreshTokenModalStatus(false) &&
              this.clearLocalStorage()
            }
            onGenerateNewToken={() =>
              this.props.actions.setRefreshTokenModalStatus(false)
              // this.props.actions.renewToken({
              //   jwt: this.props.userData.jwt,
              //   username: this.props.userData.username,
              // })
            }
          />
        )} */}

        {!this.props.isLoggedIn && (
          <PublicRouter
            loggedIn={this.props.isLoggedIn}
            commonRoutes={this.commonRoutes}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "78129" && (
          <AdminRouter
            userData={this.props.userData}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "77138" && (
          <ExecutiveRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "76517" && (
          <SchoolAdminRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "72195" && (
          <TeacherRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "72138" && (
          <StudentRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "79126" && (
          <ParentRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "73146" && (
          <InstOrgAdminRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "79146" && (
          <TeacherRouterInstOrg
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "72166" && (
          <StudentRouterInstOrg
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "75812" && (
          <GuestRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
        {this.props.isLoggedIn && this.props.userData.roleId === "75126" && (
          <ParentinstorgRouter
            userData={this.props.userData}
            commonRoutes={() => this.commonRoutes()}
          />
        )}
      </div>
    );
  }
}

const s = ({ state }) => ({
  isLoggedIn: app.getLoginStatus(state),
  userData: app.getUserData(state),
  refreshToken: app.getRefreshTokenModalStatus(state),
  startAssessment: app.startAssessment(state),
  // overLayLoader: app.getOverLayLoader(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions,
      ...loginTask.actions,
    },
    d
  ),
});


export default connect(s, d)(App)
