import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '../appSelector'
class AddGroupName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groupNameText:''
    }
    this.addGroupName = this.addGroupName.bind(this);
    this.callAddGroupName = this.callAddGroupName.bind(this);
    this.groupText = this.groupText.bind(this);
  }
  callAddGroupName() {
    this.addGroupName();
    this.props.onClose();
  }
  addGroupName(){
    this.props.actions.addGroupName({
      loginUserId : this.props.userData.id,
      jwt: this.props.userData.jwt,
      groupText: this.state.groupNameText
    })
  }
  groupText = event =>{
    let groupName = event.target.value;
    this.setState({
      groupNameText: groupName,
    })
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show}>
        <form onSubmit={this.callAddGroupName}>
          <Modal.Header>
            <Modal.Title>Add Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <div className="mt-2 row">
            <div className="col-md-4">
              <label>Group Name:  </label>
            </div>
            <div className="col-md-8">
              <input type="text" required onChange={this.groupText} placeholder="Enter Group Name" className="css-yk16xz-control" />
            </div>
          </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose} >
              Close
          </Button>
            <button type="submit" className="btn btn-primary">
              Save
          </button>
          </Modal.Footer>
          </form>
        </Modal>

      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state)
  })
  const d = (d) => ({
    actions: bindActionCreators(
      {
        ...modals.actions
      },
      d
    )
  })
export default connect(s,d)(AddGroupName);