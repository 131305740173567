const BASE_API = "https://uat-services.durchere.in"

export const KEYCLOAK_URL = 'https://uat-auth.durchere.in/auth/'

export const REALM = "durchere"

export const APP_CLIENT_ID = "durchere_ui_1"

export const IS_PROD = true

export const PAYMENT_KEY_GENERATED_FROM_DASHBOARD = "rzp_test_tgB6tWR7Gxlnvb"


const URL_CONSTANT_MAP = {
  LOGIN: `${BASE_API}/login`,
  SIGNUP: `${BASE_API}/signUp`,
  FORGOT_PASSWORD: `${BASE_API}/forGotPassword`,
  FORGOT_USERNAME: `${BASE_API}/forGotUserName`,
  VALIDATE_OTP: `${BASE_API}/validateOtp`,
  UPDATE_PASSWORD: `${BASE_API}/updatePassword`,
  GET_BOOKS_ORG_USER: `${BASE_API}/getBooks`,
  GET_STANDARDS_USER: `${BASE_API}/getStandardNames`,
  GET_COURSES_USER: `${BASE_API}/getCoursesNames`,
  GET_USERS: `${BASE_API}/getUsers`,
  GET_ALLINSTITUTES: `${BASE_API}/getAllInstituteDetails`,
  ADD_INSTITUTES: `${BASE_API}/addOrUpdateInstitute`,
  ADD_TO_CART: `${BASE_API}/addItemsToCart`,
  GET_USERACCESSROLES: `${BASE_API}/userAccessRoles`,
  GET_PUBLIC_BOOKS: `${BASE_API}/getPublicBooks`,
  GET_USER_ASSOCIATED_BOOKS: `${BASE_API}/getAssociatedBooksByUserId`,
  GET_GROUP_NAMES: `${BASE_API}/getGroupNames`,
  GET_USER_ASSIGNED_GROUPS: `${BASE_API}/getAssignedGroupByUserId`,
  GET_VIEW_TESTS: `${BASE_API}/getTests`,
  GET_ASSESSMENT_PARAGRAPHS_BY_TEST_ID: `${BASE_API}/getTestParagraphByTestId`,
  GET_VIEW_TESTS_BY_COURSE: `${BASE_API}/getTestsByBookId`,
  START_ASSESSMENT: `${BASE_API}/startTest`,
  ADD_STANDARDS: `${BASE_API}/addStandards`,
  ADD_COURSES: `${BASE_API}/addCourses`,
  ADD_BOOKS: `${BASE_API}/addBooks`,
  ASSIGN_BOOKS: `${BASE_API}/assignBook`,
  ADD_USER: `${BASE_API}/addUsers`,
  ADD_GROUP_NAME: `${BASE_API}/addGroup`,
  ADD_ASSIGN_GROUP_NAMES: `${BASE_API}/assignGroup`,
  CONTACTUS: `${BASE_API}/contactUs`,
  CREATE_TEST: `${BASE_API}/createTest`,
  ADD_QUESTION_TO_ASSESSMENT: `${BASE_API}/addTestQuestion`,
  ADD_PARAGRAPH_TO_ASSESSMENT: `${BASE_API}/addTestParagraph`,
  GET_QUESTIONS: `${BASE_API}/getTestQuestionsById`,
  REMOVE_BOOK_ORG_USER: `${BASE_API}/removeBook`,
  EDIT_USER: `${BASE_API}/updateUser`,
  REMOVE_USER_FROM_ASSOCIATION: `${BASE_API}/deleteUserFromAssociation`,
  DELETE_ASSIGN_BOOK_BY_USER_ID: `${BASE_API}/deleteAssocBookByUserId`,
  DELETE_ASSIGN_GROUP_NAME_BY_USER_ID: `${BASE_API}/deleteGrpMberByGrpIdAndUserId`,
  DELETE_ASSESSMENT: `${BASE_API}/deleteTestById`,
  GET_ALL_RESULTS_BY_TEST_ID: `${BASE_API}/getResultByTestId`,
  END_TEST_DATA: `${BASE_API}/endTest`,
  MEET_END_POINT: "https://meeting.durchere.com/",
  UPDATE_STANDARDS: `${BASE_API}/updateStandards`,
  UPDATE_COURSES: `${BASE_API}/updateCourses`,
  GET_CART_ITEMS_BY_USER_ID: `${BASE_API}/getCartItemsBasedOnUserId`,
  GET_ORGANIZATION_DETAILS: `${BASE_API}/findOrgDetailsById`,
  RENEW_TOKEN: `${BASE_API}/renewToken`,
  UPDATE_CART_ITEMS: `${BASE_API}/updateCartItems`,
  UPDATE_GUEST_CART_ITEMS: `${BASE_API}/updateGuestCartItems`,
  DELETE_CART_ITEMS: `${BASE_API}/removeItemFromCart`,
  DELETE_GUEST_CART_ITEMS: `${BASE_API}/removeItemFromGuestCart`,
  GET_PARTNER_DETAILS: `${BASE_API}/getPartnersDetails`,
  ORDER_REQUEST: `${BASE_API}/orderBooks`,
  GET_INSTITUTE_DETAILS: `${BASE_API}/findInstituteDetailsById`,
  GET_USER_ASSOCIATED_ORG_DETAILS: `${BASE_API}/getOrgDetailsByUserId`,
  DELETE_USER_FROM_ORG_ASSOCIATION: `${BASE_API}/deleteUserFromOrgAssociation`,
  GUEST_CART: `${BASE_API}/addItemsToGuestCart`,
  GET_GUEST_CART_ITEMS_BY_USER_ID: `${BASE_API}/getGuestCartItemsBasedOnUserId`,
  GET_USER_INFO: `${BASE_API}/getUserAccountInfo`,
  GET_ADDRESS_INFO: `${BASE_API}/getUserAddressDetails`,
  DELETE_ADDRESS_INFO: `${BASE_API}/removeAddressInfo`,
  UPDATE_USER_INFO: `${BASE_API}/updateUserAccountInfo`,
  UPDATE_ADDRESS_INFO: `${BASE_API}/updateAddressInfo`,
  CREATE_ORDER_BY_CART_ID: `${BASE_API}/createOrderByCartId`,
  SAVE_PAYMENT: `${BASE_API}/savePayment`,
  ORDER_BOOKS_FOR_GUEST: `${BASE_API}/orderBooksByGuest`,
  USER_ADDRESS_DETAILS: `${BASE_API}/getUserAddressDetails`,
  CREATE_EVENT: `${BASE_API}/createEvents`,
  CANCEL_EVENT: `${BASE_API}/cancelEvent`,
  UPDATE_EVENT: `${BASE_API}/updateEvents`,
  GET_ALL_ORDER_DETAILS: `${BASE_API}/getAllOrderDetailsByUserId`,
  MEETING_LINK: `${BASE_API}/getMeetingLink`,
  UPCOMING_EVENTS: `${BASE_API}/getUpcomingEventsByUserIdAndDate`,
  ADD_BOOK_STOCK: `${BASE_API}/addBookStock`,
  GET_STATE_CODE_INFO: `${BASE_API}/getStateCodeList`,
  GET_COUNTRY_CODE_INFO: `${BASE_API}/getCountryCodeList`,
  GET_ORDER_STATUS_CONSTANTS: `${BASE_API}/getOrderStatusConstants`,
  GET_COURIER_SERVICE_PROVIDERS: `${BASE_API}/getCourierServiceProviders`,
  UPDATE_ORDER_STATUS: `${BASE_API}/updateOrderStatus`,
  LOGIN_OBJECT: `${BASE_API}/loginObj`,
  INST_ADDRESS_DETAILS: `${BASE_API}/getInstAddressDetails`,
  UPLOAD_VIDEO: `${BASE_API}/uploadVideo`,
  ADDCOURSE_SECTION: `${BASE_API}/addCourseSection`,
  GET_ADDCOURSE_SECTION: `${BASE_API}/getCourseSection`,
  GET_BOOKRESOURCE_BYBOOKID: `${BASE_API}/getBookResourcesByBookId`,
  ASSIGN_PARENT_TO_STUDENT: `${BASE_API}/assignParentToStudent`,
  GETASSIGN_PARENTTOSTUDENT_BYSTUDENTID: `${BASE_API}/getAssignParentToStudent`,
  GET_USER_BY_ROLE: `${BASE_API}/getUsersByRoleId`,
  DELETE_ASSIGN_PARENT_NAME_BY_USER_ID: `${BASE_API}/deleteAssignParentNames`,
  GET_VIDEO: `${BASE_API}/bookResourceId`,
  ADD_LEARNING_LIST: `${BASE_API}/addLearningList`,
  GET_LEARNING_LIST: `${BASE_API}/getLearningList`,
  DELETE_LEARNING_LIST: `${BASE_API}/deleteLearningListById`,
  GET_VIDEOS_BY_BOOK_RESOURCE_ID: `${BASE_API}/getVideo`,
  GET_ASSIGN_PARENTTOSTUDENT_BY_PARENTID : `${BASE_API}/getAssignStudentToParent`,
  GET_ASSIGN_PARENTTOSTUDENTS_BY_PARENTID :`${BASE_API}/getAssignStudentToParent`,
  GET_STUDENT_TIME_ENTRYS : `${BASE_API}/getSudentTimeEntrys`,
  SAVE_STUDENT_TIME_ENTRYS : `${BASE_API}/saveSudentTimeEntrys`,
}

export default URL_CONSTANT_MAP