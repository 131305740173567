import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import rootReducer from './rootReducer';
import { connectRouter, routerMiddleware} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import {
  api
} from '../src/middlewares/middlewares'

import {
  routeChangeFetch
} from '../src/middlewares/middlewares'

import {
  overlayLoader
} from '../src/middlewares/middlewares'

import createSagaMiddleware from 'redux-saga'

import roootSaga from './rootSaga'

import { IS_PROD } from './config/ApiConfig';

// import UserService from './userService'

const history = createBrowserHistory();

const sagaMiddleWare = createSagaMiddleware()
const middleWares =[
  api.middleware(api.config),
  routeChangeFetch.middleware(routeChangeFetch.config),
  overlayLoader.middleware(),
  sagaMiddleWare,
  routerMiddleware(history)
];

const composedMiddleWares =applyMiddleware(...middleWares);
const envMiddlewares = IS_PROD ? composedMiddleWares : composeWithDevTools(composedMiddleWares)
const store = createStore(
  combineReducers({
    state: rootReducer,
    router: connectRouter(history),
  }),
  envMiddlewares,
);

sagaMiddleWare.run(roootSaga)

ReactDOM.render(
  <Provider store={store}>
  <App  />
</Provider>,
document.getElementById("root")
  );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
