import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import * as selectors from "../redux-orders/selector";
import * as appData from "../../appSelector";
import './CartSummary.css'
import { Alert } from 'react-bootstrap';
import InformationBanner from "../../common/InformationBanner";
import INFO_BANNER from "../../common/InfoBanner";
class GuestCartSummary extends Component {
  componentDidMount() {
    this.props.actions.getGuestCartSummaryByUserId({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });
  }

  changeQuantity = (e, bookIndex) => {
    this.props.actions.changeQuantityForGuestSummary({
      bookIndex,
      value: e.target.value,
    });
  };

  navigateToCart = () => {
    this.props.history.push("/GuestCheckout")
  }

  render() {
    const {
      cartSummary,
      userData,
      updateGuestCartSuccessAlert,
      updateGuestCartFailureAlert,
      deleteGuestCartSuccessAlert,
      deleteGuestCartFailureAlert,
      guestTaxAmount,
      guestConvenienceFee,
      guestCartGrandTotal,
      guestCartTotalBeforeTax
    } = this.props;
    return (
      <div className="container mt-4">
        <div className="review-button">
          <button className="btn btn-primary" hidden = {!this.props.cartSummary.size >0 }
          onClick={this.navigateToCart}>Checkout</button>
        </div>
        <div hidden ={this.props.cartSummary.size >0} className="empty-content" >
         <InformationBanner isHorizontal 
      message = {INFO_BANNER.MESSAGE_CHECKOUT}/>
          </div> 
        {cartSummary.map((book, bookIndex) => (
          <div>
            <div className="cart-data-wrapper">
              <div className="cart-img-wrapper">
                <img
                  src={book.get("bookImage", "")}
                  alt={"book"}
                  width={"300"}
                  height={"200"}
                />
              </div>
              <div className="cart-content-wrapper">
                <p className="price">Price:  
                <span className="rupee"></span> 
                  {` ${book.get("bookPrice", "")}`}
                </p>
                <p className="total-amount"> Total Amount:
                <span className="rupee"></span>
                  {` 	 ${book.get("totalAmount", "")}`}
                </p>
                <p className="quantity">
                  Quantity:&nbsp;
                  <input
                    type="text"
                    value={book.get("quantity", "")}
                    onChange={(e) =>
                      this.changeQuantity(e, bookIndex)
                    }
                  />
                  {updateGuestCartSuccessAlert(bookIndex) && <Alert variant='success'className="p-1 m-1">{'SuccessfullyAdded'}</Alert>}
                  {updateGuestCartFailureAlert(bookIndex) && <Alert variant='danger'className="p-1 m-1 ">{'Update failed'}</Alert>}
                  {deleteGuestCartSuccessAlert(bookIndex) && <Alert variant='success'className="p-1 m-1">{'SuccessFully deleted'}</Alert>}
                  {deleteGuestCartFailureAlert(bookIndex) && <Alert variant='danger'className="p-1 m-1">{'Delete failed'}</Alert>}
                  &nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => {
                      this.props.actions.updateGuestCart({
                        jwt: userData.jwt,
                        cartDetailsId: book.get('cartDetailsId', ''),
                        bookIndex,
                        user: this.props.userData.id,
                      });
                    }}
                  >
                    Update
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => {
                      this.props.actions.deleteGuestCart({
                        jwt: userData.jwt,
                        cartDetailsId: book.get('cartDetailsId', ''),
                        bookIndex,
                        user: this.props.userData.id,
                      });
                    }}
                  >
                    Delete
                  </button>
                </p>
              </div>
            </div>
          </div>
        ))}
        <div className="cart-dataa-wrapper" hidden = {!this.props.cartSummary.size >0 }>
           <div className="price text-right">
               <b>Total Amount Before Tax and Fees: </b>
               <span className="rupee"></span> 
                 {` ${guestCartTotalBeforeTax}`}
            </div>
            <div className="price text-right">
              <b>Tax Amount: </b>
              <span className="rupee"></span> 
                {` ${guestTaxAmount}`}
            </div>
            <div style={{ borderBottom: '1px solid lightgrey', paddingBottom: '.5rem' }} className="total-amount text-right">
                  <b>Convenience Fee: </b>
                  <span className="rupee"></span> 
                  {` ${guestConvenienceFee}`}
            </div>
            <br/>
            <div className=" text-right">
              <h3 style={{ display: 'inline-block', margin: 0 }}>Cart Grand Total: </h3>
              <span className="rupee"></span>
              {` ${guestCartGrandTotal}`}
            </div>
          </div>
      </div>
    );
  }
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  cartSummary: selectors.getGuestCartSummary(state),
  guestTaxAmount: selectors.guestTaxAmount(state),
  guestConvenienceFee: selectors.guestConvenienceFee(state),
  guestCartGrandTotal: selectors.guestCartGrandTotal(state),
  guestCartTotalBeforeTax: selectors.guestCartTotalBeforeTax(state),
  updateGuestCartSuccessAlert: selectors.getUpdateCartSuccessAlert(state),
  updateGuestCartFailureAlert: selectors.getUpdateCartFailureAlert(state),
  deleteGuestCartSuccessAlert: selectors.getDeleteCartSuccessAlert(state),
  deleteGuestCartFailureAlert: selectors.getDeleteCartFailureAlert(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
    },
    d
  ),
});
export default connect(s, d)(GuestCartSummary);
