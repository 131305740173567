import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import InstOrgAdminHome from "../../src/instOrgAdmin/InstOrgAdminHome";
import AddStandardsPageInstOrg from "../instOrgAdmin/instOrgAdminConfiguration/standardInstOrg/AddStandardsPageInstOrg";
import AddCoursesPageInstOrg from "../instOrgAdmin/instOrgAdminConfiguration/coursesInstOrg/AddCoursesPageInstOrg";
import AddCourseDetailsInstOrg from "../instOrgAdmin/instOrgAdminConfiguration/courseDetailsInsOrg/AddCourseDetailsInstOrg";
import AddUsers from "../../src/common/AddUsers.js";
import ViewCourseVideosPage from "../../src/common/ViewCourseVideosPage.js";
import Order from "../../src/order/OrdersPage.js";
import InstOrgAdminHeader from "../layout/instOrgAdminHeader/InstOrgAdminHeader";
import { createBrowserHistory } from "history";
import "../instOrgAdmin/InstOrgAdminHome.css";
import PublicFooter from "../layout/PublicFooter";
import NotFoundPage from "../common/404";
import ProfileHome from "../profile/ProfileHome";
import Calendar from "../calendar";
import ViewBookResources from '../videoStreaming/ViewBookResources';
import BookResoursesManagement from '../videoStreaming/BookResoursesManagement.js';
import SingleVideoView from '../videoStreaming/single-video-view/SingleVideoView.js';
import CourseVideoMainView from '../videoStreaming/course-video-view/CourseVideoMainView.js';
import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";

export default class InstOrgAdminRouter extends Component {
  render() {
    return (
      <div>
        <div className="page-body">
          <div className="page-content-admin height-fix">
            <ConnectedRouter history={createBrowserHistory()}>
              <InstOrgAdminHeader></InstOrgAdminHeader>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/InstOrgAdminHome" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/InstOrgAdminHome" />
                </Route>
                <Route
                  exact
                  path="/InstOrgAdminHome"
                  render={(props) => <InstOrgAdminHome {...props} />}
                />
                <Route
                  exact
                  path="/InstOrgAddStandards"
                  render={(props) => <AddStandardsPageInstOrg {...props} />}
                />
                <Route
                  exact
                  path="/InstOrgAddCourses"
                  render={(props) => <AddCoursesPageInstOrg {...props} />}
                />
                <Route
                  exact
                  path="/InstOrgAddCourseDetails"
                  render={(props) => <AddCourseDetailsInstOrg {...props} />}
                />
                <Route
                  exact
                  path="/InstOrgAddUsers"
                  render={(props) => <AddUsers {...props} />}
                />
                <Route
                  exact
                  path="/InstOrgOrders"
                  render={(props) => <Order {...props} />}
                />
                <Route
                  exact
                  path="/InstViewCourseLessons"
                  render={(props) => <ViewCourseVideosPage {...props} />}
                />
                 <Route
                  exact
                  path="/view-resources"
                  render={(props) => <ViewBookResources {...props} />}
                />
                 <Route
                  exact
                  path="/book-resourses-management"
                  render={(props) => <BookResoursesManagement {...props} />}
                />
                <Route
                  exact
                  path="/single-video-view"
                  render={(props) => <SingleVideoView {...props} />}
                />
                <Route
                  exact
                  path="/course-video-view"
                  render={(props) => <CourseVideoMainView {...props} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <ProfileHome onlyPersonalInfo={true} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />

                <Route exact path="/Partners" component={PartnersPage} />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/Careers" component={CareersPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
              <PublicFooter />
            </ConnectedRouter>
          </div>
        </div>
      </div>
    );
  }
}
