import { createAction } from 'redux-act'
import { createAsyncAction } from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const addGroupName = createAsyncAction(
  `ADD_GROUP_NAME`,
  payload => payload
)

const getGroupNames = createAsyncAction(
  `GET_GROUP_NAMES`,
  payload => payload
)

const addAssignGroupNames = createAsyncAction(
  `ADD_ASSIGN_GROUP_NAMES`,
  payload => payload
)

const deleteAssignGroupNames = createAsyncAction(
  `DELETE_ASSIGN_GROUP_NAME`,
  payload => payload
)

const deleteAssessment = createAsyncAction(
  `DELETE_ASSESSMENT`,
  payload => payload
)

const closeDeleteAssessmentModal = createAction(
  `CLOSE_DELETE_ASSESSMENT`,
  payload => payload
)

const getAllInstitutes = createAsyncAction(
  `GET_ALL_INSTITTUTES`,
  payload => payload
)

const addInstitute = createAsyncAction(
  `ADD_INSTITTUTE`,
  payload => payload
)

const addToCart = createAsyncAction(
  `ADD_TO_CART`,
  payload => payload
)

const getRoleTypesByUser = createAsyncAction(
  `GET_ROLES_BY_USER`,
  payload => payload
)

const addUserToOrganization = createAsyncAction(
  `ADD_USER_TO_ORGANIZATION`,
  payload => payload
)

const editUserToOrganization = createAsyncAction(
  `EDIT_USER_TO_ORGANIZATION`,
  payload => payload
)

const assignUserCourse = createAsyncAction(
  `ASSIGN_USER_COURSE`,
  payload => payload
)

const deleteAssignCourseUser = createAsyncAction(
  `DELETE_ASSIGN_USER_COURSE`,
  payload => payload
)

const getDefaultSelectedCourses = createAsyncAction(
  `GET_DEFAULT_SELECTED_COURSES`,
  payload => payload
)

const getAllAssociatedCourses = createAsyncAction(
  `GET_ALL_ASSOCIATED_COURSES`,
  payload => payload
)

const removeUserFromAssociation = createAsyncAction(
  `REMOVE_USER_FROM_ASSOCIATION`,
  payload => payload
)
const getAllTeacherAssociatedCourses = createAsyncAction(
  `GET_ALL_TEACHER_ASSOCIATED_COURSES`,
  payload => payload
)
const closeEndAssessmentModal = createAction(
  `CLOSE_END_ASSESSMENT_MODAL`,
  payload => payload
)

const closeEndAssessmentTimerModal = createAction(
  `CLOSE_END_ASSESSMENT_TIMER_MODAL`,
  payload => payload
)

const closeBookModal = createAction(
  `CLOSE_BOOK_MODAL`,
  payload => payload
)


const getStandardsUser = createAsyncAction(
  `GET_STANDARDS_USER`,
  payload => payload
)

const getCoursesWithIds = createAsyncAction(
  `GET_COURSES_USER_IDS`,
  payload => payload
)

const getCoursesOptions = createAction(
  `GET_COURSE_OPTIONS`,
  payload => payload
)

const addBooks = createAsyncAction(
  `ADD_BOOKS`,
  payload => payload
)

const deleteBook = createAsyncAction(
  `DELETE_BOOK`,
  payload => payload
)

const getAssociatedOrgDetailsByUserId = createAsyncAction(
  `GET_ASSOCIATED_ORG_DETAILS_BY_USER`,
  payload => payload
)

const deleteUserFromOrgAssociation = createAsyncAction(
  `DELETE_USER_FROM_ORG_ASSOCIATION`,
  payload => payload
)

const addQuantityBook = createAsyncAction(
  `ADD_QUANTITY_BOOK`,
  payload => payload
)

const getOrderStatusConstants = createAsyncAction(
  `GET_ORDER_STATUS_CONSTANTS`,
  payload => payload
)

const getCourierServiceProviders = createAsyncAction(
  `GET_COURIER_SERVICE_PROVIDERS`,
  payload => payload
)

const saveUpdateOrderStatus = createAsyncAction(
  `SAVE_UPDATE_ORDER_STATUS`,
  payload => payload
)

const setOrderStatus = createAction(
  `SET_ORDER_STATUS`,
  payload => payload
)

const setPostalService = createAction(
  `SET POSTAL SERVICE`,
  payload => payload
)

const addCourseSection = createAsyncAction(
  `ADDCOURSE_SECTION`,
  payload => payload
)

const closeAddSectionmodal = createAction(
  `CLOSE_ADD_SECTION_MODAL`,
  payload => payload
)
const closeInstituteAlertModal = createAction(
  `CLOSE_INSTITUTE_ALERT_MODAL`,
  payload => payload
)
const assignParentToStudent = createAsyncAction(
  `ASSIGN_PARENT_TO_STUDENT`,
  payload => payload
)

const deleteAssignParentNames = createAsyncAction(
  `DELETE_ASSIGN_PARENT_NAME_BY_USER_ID`,
  payload => payload
)

const getUsersByRoleId = createAsyncAction(
  `GET_USER_BY_ROLE`,
  payload => payload
);

const reducer = {
  [addGroupName.success]: (state, payload) =>
    mutators.addGroupName(payload)(state),
  [addGroupName.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [getGroupNames.success]: (state, payload) =>
    mutators.getGroupNames(payload)(state),
  [addAssignGroupNames.success]: (state, payload) =>
    mutators.addAssignGroupNames(payload)(state),
  [addAssignGroupNames.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [deleteAssignGroupNames.success]: (state, payload) =>
    mutators.deleteAssignGroupNames(payload)(state),
  [deleteAssignGroupNames.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [deleteAssessment.success]: (state, payload) =>
    mutators.deleteAssessment(payload)(state),
  [deleteAssessment.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [closeDeleteAssessmentModal]: (state, payload) =>
    mutators.closeDeleteAssessmentModal(payload)(state),
  [getAllInstitutes.success]: (state, payload) =>
    mutators.getAllInstitutes(payload)(state),
  [addInstitute.success]: (state, payload) =>
    mutators.addInstituteSuccess(payload)(state),
  [addInstitute.failure]: (state, payload) =>
    mutators.addInstituteError(payload)(state),
  [addToCart.success]: (state, payload) =>
    mutators.addToCartSuccess(payload)(state),
  [addToCart.failure]: (state, payload) =>
    mutators.addToCartError(payload)(state),
  [getRoleTypesByUser.success]: (state, payload) =>
    mutators.getRoleTypesByUser(payload)(state),
  [addUserToOrganization.success]: (state, payload) =>
    mutators.showUserSuccessAlertBox(payload)(state),
  [addUserToOrganization.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [editUserToOrganization.success]: (state, payload) =>
    mutators.showUserSuccessAlertBox(payload)(state),
  [editUserToOrganization.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [assignUserCourse.success]: (state, payload) =>
    mutators.showUserSuccessAlertBox(payload)(state),
  [assignUserCourse.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [deleteAssignCourseUser.success]: (state, payload) =>
    mutators.deleteAssignCourseUser(payload)(state),
  [getDefaultSelectedCourses.success]: (state, payload) =>
    mutators.getDefaultSelectedCourses(payload)(state),
  [getAllAssociatedCourses.success]: (state, payload) =>
    mutators.getAllAssociatedCourses(payload)(state),
  [removeUserFromAssociation.success]: (state, payload) =>
    mutators.showUserSuccessAlertBox(payload)(state),
  [removeUserFromAssociation.failure]: (state, payload) =>
    mutators.showUserFailureAlertBox(payload)(state),
  [getAllTeacherAssociatedCourses.success]: (state, payload) =>
    mutators.getAllTeacherAssociatedCourses(payload)(state),
  [closeEndAssessmentModal]: (state, payload) =>
    mutators.closeEndAssessmentModal(payload)(state),
  [closeEndAssessmentTimerModal]: (state, payload) =>
    mutators.closeEndAssessmentTimerModal(payload)(state),
  [closeBookModal]: (state, payload) =>
    mutators.closeBookModal(payload)(state),
  [getStandardsUser.success]: (state, payload) =>
    mutators.getStandardsUser(payload)(state),
  [getCoursesWithIds.success]: (state, payload) =>
    mutators.getCoursesWithIds(payload)(state),
  [getCoursesOptions]: (state, payload) =>
    mutators.getCoursesOptions(payload)(state),
  [addBooks.success]: (state, payload) =>
    mutators.booksPageSuccessAlertBox(payload)(state),
  [addBooks.failure]: (state, payload) =>
    mutators.booksPageFailureAlertBox(payload)(state),
  [deleteBook.success]: (state, payload) =>
    mutators.booksPageSuccessAlertBox(payload)(state),
  [deleteBook.failure]: (state, payload) =>
    mutators.booksPageFailureAlertBox(payload)(state),
  [getAssociatedOrgDetailsByUserId.success]: (state, payload) =>
    mutators.getAssociatedOrgDetailsByUserId(payload)(state),
  [addQuantityBook.success]: (state, payload) =>
    mutators.booksPageSuccessAlertBox(payload)(state),
  [addQuantityBook.failure]: (state, payload) =>
    mutators.booksPageFailureAlertBox(payload)(state),
  [getOrderStatusConstants.success]: (state, payload) =>
    mutators.getOrderStatusConstants(payload)(state),
  [getCourierServiceProviders.success]: (state, payload) =>
    mutators.getCourierServiceProviders(payload)(state),
  [saveUpdateOrderStatus.success]: (state, payload) =>
    mutators.orderHistoryPageSuccessAlertBox(payload)(state),
  [saveUpdateOrderStatus.failure]: (state, payload) =>
    mutators.orderHistoryPageFailureAlertBox(payload)(state),
  [setOrderStatus]: (state, payload) =>
    mutators.setOrderStatus(payload)(state),
  [setPostalService]: (state, payload) =>
    mutators.setPostalService(payload)(state),
  [closeAddSectionmodal]: (state, payload) =>
    mutators.closeAddSectionmodal(payload)(state),
  [addCourseSection.success]: (state, payload) =>
    mutators.addCourseSectionSuccessAlertBox(payload)(state),
  [addCourseSection.failure]: (state, payload) =>
    mutators.addCourseSectionFailureAlertBox(payload)(state),
  [closeInstituteAlertModal]: (state, payload) =>
    mutators.closeInstituteAlertModal(payload)(state),
  [assignParentToStudent.success]: (state, payload) =>
    mutators.assignParentToStudent(payload)(state),
  [assignParentToStudent.failure]: (state, payload) =>
    mutators.assignParentToStudentFailure(payload)(state),
  [deleteAssignParentNames.success]: (state, payload) =>
    mutators.deleteAssignParentNames(payload)(state),
  [getUsersByRoleId.success]: (state, payload) =>
    mutators.getUsersByRoleId(payload)(state),
}

const restApi = {
  [addGroupName]: api.addGroupName(addGroupName),
  [getGroupNames]: api.getGroupNames(getGroupNames),
  [addAssignGroupNames]: api.addAssignGroupNames(addAssignGroupNames),
  [deleteAssignGroupNames]: api.deleteAssignGroupNames(deleteAssignGroupNames),
  [deleteAssessment]: api.deleteAssessment(deleteAssessment),
  [getAllInstitutes]: api.getAllInstitutes(getAllInstitutes),
  [addInstitute]: api.addInstitute(addInstitute),
  [addToCart]: api.addToCart(addToCart),
  [getRoleTypesByUser]: api.getRoleTypesByUser(getRoleTypesByUser),
  [addUserToOrganization]: api.addUserToOrganization(addUserToOrganization),
  [editUserToOrganization]: api.editUserToOrganization(editUserToOrganization),
  [assignUserCourse]: api.assignUserCourse(assignUserCourse),
  [deleteAssignCourseUser]: api.deleteAssignCourseUser(deleteAssignCourseUser),
  [getDefaultSelectedCourses]: api.getDefaultSelectedCourses(getDefaultSelectedCourses),
  [getAllAssociatedCourses]: api.getAllAssociatedCourses(getAllAssociatedCourses),
  [removeUserFromAssociation]: api.removeUserFromAssociation(removeUserFromAssociation),
  [getAllTeacherAssociatedCourses]: api.getTeacherAssociatedBooks(getAllTeacherAssociatedCourses),
  [getStandardsUser]: api.getStandardsUser(getStandardsUser),
  [getCoursesWithIds]: api.getCoursesWithIds(getCoursesWithIds),
  [addBooks]: api.addBooks(addBooks),
  [deleteBook]: api.deleteBook(deleteBook),
  [getAssociatedOrgDetailsByUserId]: api.getAssociatedOrgDetailsByUserId(getAssociatedOrgDetailsByUserId),
  [deleteUserFromOrgAssociation]: api.deleteUserFromOrgAssociation(deleteUserFromOrgAssociation),
  [addQuantityBook]: api.addQuantityBook(addQuantityBook),
  [getOrderStatusConstants]: api.getOrderStatusConstants(getOrderStatusConstants),
  [getCourierServiceProviders]: api.getCourierServiceProviders(getCourierServiceProviders),
  [saveUpdateOrderStatus]: api.saveUpdateOrderStatus(saveUpdateOrderStatus),
  [addCourseSection]: api.addCourseSection(addCourseSection),
  [assignParentToStudent]: api.assignParentToStudent(assignParentToStudent),
  [deleteAssignParentNames]: api.deleteAssignParentNames(deleteAssignParentNames),
  [getUsersByRoleId]: api.getUsersByRoleId(getUsersByRoleId),
}

export default {
  actions: {
    addGroupName,
    getGroupNames,
    addAssignGroupNames,
    deleteAssignGroupNames,
    deleteAssessment,
    closeDeleteAssessmentModal,
    getAllInstitutes,
    addInstitute,
    getRoleTypesByUser,
    addUserToOrganization,
    editUserToOrganization,
    assignUserCourse,
    deleteAssignCourseUser,
    getDefaultSelectedCourses,
    getAllAssociatedCourses,
    removeUserFromAssociation,
    getAllTeacherAssociatedCourses,
    closeEndAssessmentModal,
    closeEndAssessmentTimerModal,
    closeBookModal,
    getStandardsUser,
    getCoursesWithIds,
    getCoursesOptions,
    addBooks,
    deleteBook,
    addToCart,
    getAssociatedOrgDetailsByUserId,
    deleteUserFromOrgAssociation,
    addQuantityBook,
    getOrderStatusConstants,
    getCourierServiceProviders,
    saveUpdateOrderStatus,
    setOrderStatus,
    setPostalService,
    addCourseSection,
    closeAddSectionmodal,
    closeInstituteAlertModal,
    assignParentToStudent,
    deleteAssignParentNames,
    getUsersByRoleId,
  },
  restApi,
  reducer
}