import React, {Component} from 'react';
import '../common/AddUsers.css';
import UserModal from "../modals/UserModal";

class EditUserLinkRender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalEditType: false,
            show:false,
            successAlert: false,
            errorAlert: false,
            successMsg: '',
            errorMsg: ''
        }
        this.openAddUserModal = this.openAddUserModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
    }
    closeEditModal(){
        this.setState({ show: false });
      }
    openAddUserModal(){
        this.setState({
            modalEditType: true,
            show:true
          });
    }
    displayMessage = (type, message) => {
        if(type === 'success'){
          this.setState({
            successAlert: true,
            successMsg: message
          })
        } else {
          this.setState({
            errorAlert: true,
            errorMsg: message
          })
        }
      }

    render() {
         return (
            (this.state.modalEditType)?(<div>
            <UserModal displayMessage={this.displayMessage} eachrowUserData = {this.props.data} show={this.state.show}  onClose={this.closeEditModal} modalEditType = {this.state.modalEditType}/>
            <button className="btn btn-warning" onClick={this.openAddUserModal}>Edit</button></div>):(
                <button className="btn btn-warning" onClick={this.openAddUserModal}>Edit</button>
            )
         );

    }
}
export default EditUserLinkRender;
