import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './viewAssessments.css'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import ViewAssessmentLinks from './ViewAssessmentLinks.js'
import WithPagination from '../../../../common/WithPagination';

class EachViewAssessmentCourseTIO extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3,


    };


  }
  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }

  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }
  render() {
    var viewFullAssessmentsData = this.props.eachFullAssessmentDataTIO;
    const { currentPage, itemsPerPage, searchText } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    let currentItems = []
    viewFullAssessmentsData && viewFullAssessmentsData.map((viewFullAssessmentsDataEach) => {

      currentItems.push(viewFullAssessmentsDataEach)


    })
    console.log("currentPage", currentPage, "itemsPerPage", itemsPerPage)
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }

    if (currentItems) {
      var showAssessments =
        currentItems.slice(indexOfFirst, indexOfLast).map
          ((viewEachAssessmentData, i) => {
            return (
              <ViewAssessmentLinks key={viewEachAssessmentData.testId} viewEachAssessmentData={viewEachAssessmentData} />
            );
          })
    }
    return (
      <>
        <div className='container'>
          <div style={{ "margin-bottom": '70px' }}>
            <WithPagination
              itemsPerPage={itemsPerPage}
              totalItems={currentItems.length}
              currentPage={currentPage}
              onSearch={this.onSearch}
              onPaginate={this.onPaginate}>
              {showAssessments}
            </WithPagination>
          </div>
        </div>
      </>
    );
  }
}

const s = ({ state }) => ({
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(EachViewAssessmentCourseTIO);