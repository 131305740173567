import axios from 'axios'
import APICONF from '../../config/ApiConfig.js';

export const getEachOrganizationDetails = (action) => async (
    { dispatch },
    { jwt, orgId, index }) => {
    const getOrgDetailsUrl = APICONF.GET_ORGANIZATION_DETAILS + "/" + orgId;
    const [err, result] = await axios.get(getOrgDetailsUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success({
        ...result,
        index
    })
    return dispatch(actionToDispatch)
}

export const getEachInstituteDetails = (action) => async (
    { dispatch },
    { jwt, instId, index }) => {
    const getInstDetailsUrl = APICONF.GET_INSTITUTE_DETAILS + "/" + instId;
    const [err, result] = await axios.get(getInstDetailsUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success({
        ...result,
        index
    })
    return dispatch(actionToDispatch)
}

export const getAssignedCoursesSchoolAdmin = (action) => async (
    { dispatch },
    { jwt, userId, index }) => {
    const getAssignedCoursesSchoolAdminUrl = APICONF.GET_USER_ASSOCIATED_BOOKS + "/" + userId;
    const [err, result] = await axios.get(getAssignedCoursesSchoolAdminUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success({
        ...result
    })
    return dispatch(actionToDispatch)
}

