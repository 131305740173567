import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

export const getCartSummary = state => state.getIn(['cartSummary', 'data', 'instituteDtls'], List())
export const getGuestCartSummary = state => state.getIn(['guestCartSummary', 'data', 'bookDtls'], List())
export const cartId = state => state.getIn(['cartSummary', 'data', 'cartId'], '')

export const getPayemntOptions = state => state.get('paymentOptions',{})

export const guestCartId = state => state.getIn(['guestCartSummary', 'data', 'cartGuestId'], '')

export const guestTaxAmount = state => state.getIn(['guestCartSummary', 'data', 'taxAmount'], '')

export const guestConvenienceFee = state => state.getIn(['guestCartSummary', 'data', 'convenienceFee'], '')

export const guestCartTotalBeforeTax = state => state.getIn(['guestCartSummary', 'data', 'cartTotal'], '')

export const guestCartGrandTotal = state => state.getIn(['guestCartSummary', 'data', 'cartGrandTotal'], '')


export const taxAmount = state => state.getIn(['cartSummary', 'data', 'taxAmount'], '')

export const convenienceFee = state => state.getIn(['cartSummary', 'data', 'convenienceFee'], '')

export const cartTotalBeforeTax = state => state.getIn(['cartSummary', 'data', 'cartTotal'], '')

export const cartGrandTotal = state => state.getIn(['cartSummary', 'data', 'cartGrandTotal'], '')

export const getAddToCartSuccessAlert = state => state.get('addToCartSuccessAlert', false)

export const getAddToCartFailureAlert = state => state.get('addToCartFailureAlert', false)

export const getShippingAddressModalStatus = state => state.get('shippingAddressModalStatus', false)

export const getUserAddressDetails = state => state.getIn(
    [
        'userAddressDetails',
        'responseObject',
        'shippingAddressDetails'
    ],
    List()
)

export const getUpdateGuestCartAlerts = state => state.get('updateCartAlerts',Map())

export const getDeleteGuestCartAlerts = state => state.get('deleteCartAlerts',Map())

export const getUpdateCartSuccessAlert = createSelector(
    getUpdateGuestCartAlerts,
    (updateCartAlerts) => {
        return (bookIndex) => {
            return updateCartAlerts.getIn([bookIndex,'successAlert'], false)
        }
    }  
)

export const getUpdateCartFailureAlert = createSelector(
    getUpdateGuestCartAlerts,
    (updateCartAlerts) => {
        return (bookIndex) => {
            return updateCartAlerts.getIn([bookIndex,'failureAlert'], false)
        }
    }  
)



export const getDeleteCartSuccessAlert = createSelector(
    getDeleteGuestCartAlerts,
    (deleteCartAlerts) => {
        return (bookIndex) => {
            return deleteCartAlerts.getIn([bookIndex,'successAlert'], false)
        }
    }  
)

export const getDeleteCartFailureAlert = createSelector(
    getDeleteGuestCartAlerts,
    (deleteCartAlerts) => {
        return (bookIndex) => {
            return deleteCartAlerts.getIn([bookIndex,'failureAlert'], false)
        }
    }  
)

export const getAllOrderDetailsByUserId = state => state.getIn(['orderHistoryData', 'ordersData'], List())

export const getSelectedOrderHistoryYear = (state) =>
  state.get("selectedOrderHistoryYear", {
    value: 0,
    label: "3 months",
  });


  export const getpaymentFailureAlert = state => state.get('paymentFailure',false)
  export const getpaymentFailureMsg = state => state.get('paymentFailureMsg','')
  export const getorderBooksSuccessAlert = state => state.get('orderBooksSuccess',false)
  export const getorderBooksSuccessMsg = state => state.get('orderBooksSuccessMsg','')
  export const getorderBooksFailureAlert = state => state.get('orderBooksFailure',false)
  export const getorderBooksFailureMsg = state => state.get('orderBooksFailureMsg','')
  export const getorderBooksForGuestSuccessAlert = state => state.get('orderBooksForGuestSuccess',false)
  export const getorderBooksForGuestSuccessMsg = state => state.get('orderBooksForGuestSuccessMsg','')
  export const getorderBooksForGuestFailureAlert = state => state.get('orderBooksForGuestFailure',false)
  export const getorderBooksForGuestFailureMsg = state => state.get('orderBooksForGuestFailureMsg','')
  export const getShippingId = state => state.get('shippingId','')
  export const getinstShipArray = state => state.getIn(['instShipArray'], List());
  export const getshowpaymentOrdersFailure = state => state.get('showpaymentOrdersFailure',false)
  export const getshowpaymentOrdersFailureMsg = state => state.get('createpaymentOrdersFailureMsg','')
  