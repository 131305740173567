import React, { Component } from 'react';
import AssignedCoursesFromOrgLinks from './AssignedCoursesFromOrgLinks.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { schoolAdmin } from './redux-schoolAdmin/index'
import * as appData from '.././appSelector'
import * as schoolAdminData from './redux-schoolAdmin/selector'
import WithPagination from '../common/WithPagination.js';

class AssignedCoursesFromOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3
    }
  }
  componentDidMount() {
    this.props.actions.getAssignedCoursesSchoolAdmin({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt
    })
  }
  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }
  onSearch = (input) => {
    
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }

  render() {
    const { currentPage, itemsPerPage, searchText } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    let currentItems = [];
    this.props.schoolAdminAssignedCoursesData && this.props.schoolAdminAssignedCoursesData.map((eachSchoolAdminAssignedCoursesData)=>{
        currentItems.push(eachSchoolAdminAssignedCoursesData)
    })
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        const eachitem = item.toJS();
        let itemString = '';
        for (let key in eachitem) {
          if (eachitem.hasOwnProperty(key) && eachitem[key] && eachitem[key] !== '') {
            itemString += eachitem[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }

    if (currentItems) {
      var showCourses = currentItems.slice(indexOfFirst, indexOfLast).map((schoolAdminAssignedCourseData, i) => {
        return (
          <AssignedCoursesFromOrgLinks
          key={schoolAdminAssignedCourseData.get('bookId', '')}
            schoolAdminAssignedCourseData={schoolAdminAssignedCourseData}
          />
        );
      })
    }

    return (
      <div className='mt-4 p-1'>
        <div className='mt-4' style={{ "margin-bottom": '70px' }}>
          <WithPagination 
            itemsPerPage={itemsPerPage}
            totalItems={currentItems.length}
            currentPage={currentPage}
            onSearch={this.onSearch}
            onPaginate={this.onPaginate}  >
            {showCourses}
          </WithPagination>
        </div>
      </div>
    );

  }
}
const s = ({ state }) => ({
  schoolAdminAssignedCoursesData: schoolAdminData.getSchoolAdminAssignedCoursesData(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...schoolAdmin.actions
    },
    d
  )
})
export default connect(s, d)(AssignedCoursesFromOrg);