import React, { Component } from 'react';
import BooksPage from '././BooksPage';
import AddBooksModal from '../../../modals/AddBooksModal';
import AlertBox from '../../../common/AlertBox';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { adminConf } from '../../redux-adminconf/index'
import * as appData from '../../../appSelector'
import * as adminData from '../../redux-adminconf/selector'
import * as modalsData from '../../../modals/selector'
import InformationBanner from '../../../common/InformationBanner';
import INFO_BANNER from '../../../common/InfoBanner';
class AddBooksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.getBooks = this.getBooks.bind(this);
  }
  openAddBooksModal = () => {
    this.props.actions.openAddBooksModal();
  }
  closeAddBooksModal = () => {
    setTimeout(() => {
      this.getBooks();
    }, 3000);
  }
  getBooks() {
    this.props.actions.getBookByOrgUser({
      jwt: this.props.userData.jwt,
      orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
      userId: this.props.userData.id
    })
  }
  componentDidMount() {
    this.getBooks();
  }
  render() {
    return (
      <div>
        {this.props.showAddBookModal && this.props.showAddBookModal &&
          (<AddBooksModal show={this.props.showAddBookModal} onClose={this.closeAddBooksModal} />
          )}
        {
          (this.props.successAlertBox || this.props.failureAlertBox) && (
            <AlertBox successAlert={this.props.successAlertBox} successMsg={this.props.booksPageSuccessMsg} errorAlert={this.props.failureAlertBox} errorMsg={this.props.booksPageFailureMsg} />
          )
        }
        <div className='mt-4'>
          <div className="d-flex justify-content-start m-2 mt-4">
            <InformationBanner isHorizontal
              message={INFO_BANNER.MESSAGE_INFO_BANNER_ADDBOOKS} /></div>
          <div className="mt-4 mb-2">
            <div className="col-sm-2 col-xs-2 col-md-2 col-lg-2">
              <button className="btn btn-sm btn-success" onClick={this.openAddBooksModal}>
                <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} /> Add Books
              </button>
            </div>
          </div>
        </div>
        <hr />
        <BooksPage history={this.props.history} />
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  showAddBookModal: adminData.openAddBookModal(state),
  successAlertBox: modalsData.successAlertBox(state),
  failureAlertBox: modalsData.failureAlertBox(state),
  booksPageFailureMsg: modalsData.booksPageFailureMsg(state),
  booksPageSuccessMsg: modalsData.booksPageSuccessMsg(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...adminConf.actions
    },
    d
  )
})
export default connect(s, d)(AddBooksPage);