//import { createAction } from 'redux-act'
import {createAsyncAction} from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getTeacherAssociatedBooks = createAsyncAction(
    `GET_TEACHER_ASSOSIATED_BOOKS`,
    payload => payload
)


const reducer = {
    [getTeacherAssociatedBooks.success]: (state, payload) =>
      mutators.getTeacherAssociatedBooks(payload)(state)
}

const restApi = {
   [getTeacherAssociatedBooks]: api.getTeacherAssociatedBooks(getTeacherAssociatedBooks)
}

export default {
    actions: {
        getTeacherAssociatedBooks
    },
    restApi,
    reducer
}