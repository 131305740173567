import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import ParentinstorgHeader from "../layout/parentinstorgHeader/parentinstorgHeader.js";
import PublicFooter from "../layout/PublicFooter";
import NotFoundPage from "../common/404";
import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";

import ProfileHome from "../profile/ProfileHome.js";
import Calendar from "../calendar.js";
import ViewAssessmentsStudent from "../assessmentModule/assessmentPages/viewAssessment/student/ViewAssessmentsStudent";
import ParentinstHome from "../parentinstorg/ParentinstorgHome.js";
import StudentDashboard from "../parent/StudentDashboard.js";
import AttendanceHome from "../attendance/AttendanceHome.js";

export default class ParentinstorgRouter extends Component {
    render() {
        var path = window.location.pathname;
        let noHeaderFooterRoutes = [
            "/StartAssessment",
            "/ViewQuestionnaireStudent",
        ];
        return (
            <ConnectedRouter history={createBrowserHistory()}>
                <div>
                    <div className="page-body">
                        <div className="page-content-parentinstorg">
                            {!noHeaderFooterRoutes.includes(path) && (
                                <ParentinstorgHeader></ParentinstorgHeader>
                            )}
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/ParentinstorgHome" />
                                </Route>
                                <Route exact path="/Login">
                                    <Redirect to="/ParentinstorgHome" />
                                </Route>
                                {/*<Route path="/ParentHome" render={props => (<Books {...props} />)}/>*/}
                                <Route
                                    exact
                                    path="/ParentinstorgHome"
                                    render={(props) => <ParentinstHome {...props} />}
                                />
                                <Route
                                    exact
                                    path="/student-dashboard"
                                    render={(props) => <StudentDashboard {...props} />}
                                />
                                <Route
                                    exact
                                    path="/ViewAssessmentsStudent"
                                    render={(props) => <ViewAssessmentsStudent {...props} />}
                                />
                                <Route
                                    exact
                                    path="/profile"
                                    render={(props) => (
                                        <ProfileHome onlyPersonalInfo={true} {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/calendar"
                                    render={(props) => <Calendar {...props} />}
                                />
                                <Route exact path="/attendance" render={(props) => (<AttendanceHome {...props} />)}  />
                                <Route exact path="/Partners" component={PartnersPage} />
                                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                                <Route exact path="/Careers" component={CareersPage} />
                                <Route exact path="/ContactUs" component={ContactUsPage} />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </div>
                    </div>
                </div>
                {!noHeaderFooterRoutes.includes(path) && <PublicFooter />}
            </ConnectedRouter>
        );
    }
}
