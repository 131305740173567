import React, { Component } from "react";
import GuestHomePanelLinks from "./GuestHomePanelLinks.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as appData from "../appSelector";
import { guest } from "./redux-guest/index";
import * as guestData from "../guest/redux-guest/selector";
import { orders } from "../order/redux-orders";
import './GuestHome.css';
import { executive } from "../executive/redux-executive/index";
import * as execData from "../executive/redux-executive/selector";
import Calendar from "../calendar";
import WithPagination from "../common/WithPagination.js";
class GuestHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3,

      guestAssociatedBooksData: [],
    };
    this.getGuestAssociatedBooks = this.getGuestAssociatedBooks.bind(this);
  }

  getGuestAssociatedBooks() {
    this.props.actions.getGuestHomeBooks({
      jwt: this.props.userData.jwt,
    });
  }
  componentDidMount() {
    this.getGuestAssociatedBooks();
    const isLocalCart = window.sessionStorage.getItem("localCart");
    if (isLocalCart) this.addItemsToCart();
  }

  addItemsToCart() {
    let cart = JSON.parse(window.sessionStorage.getItem("cart")) || [];
    let cartTotal = 0;
    cart = cart.map((item) => {
      cartTotal += Number(item.totalAmount);
      return { ...item };
    });
    console.log(cart);
    const data = {
      addToCart: {
        cartTotal: cartTotal,
        userId: this.props.userData.id,
      },
      guestCartSummary: cart,
    };
    console.log(data);
    this.props.actions.addToGuestCart({ data, jwt: this.props.userData.jwt });
  }

  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }

  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }

  render() {
    const { currentPage, itemsPerPage, searchText } = this.state;    
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    let currentItems=[]
    this.props.guestHomeBooksData &&  this.props.guestHomeBooksData.responseObject.orgBooks.map((guestBooksDataEach)=>{
      guestBooksDataEach.booksData.map((eachBookObject)=>{
        currentItems.push(eachBookObject)
      })
      
    })
    console.log("currentPage",currentPage,"itemsPerPage",itemsPerPage)
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }
    

   
if (currentItems) {
  var orgBooksData =
      currentItems.slice(indexOfFirst, indexOfLast).map(
        (guestBooksData, keyId) => {
          return (
            <GuestHomePanelLinks
              key={guestBooksData.bookId}
              guestEachOrgBooksData={guestBooksData}
            />
          );
        }
      );
      }
    return (
      <div className="mt-4">
      <div className="data-wrap-size">
       
        <div className={`${this.props.isCalendarShown ? "move-left" : ""}`}>
          <div
            style={{
              paddingBottom: "5px",
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
            onClick={() =>
              this.props.actions.toggleCalendar(!this.props.isCalendarShown)
            }
          >
            <img
              src={
                "https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png"
              }
              alt="Calendar"
            />
          </div>
          
             <div>
       <WithPagination
       itemsPerPage={itemsPerPage}
       totalItems={currentItems.length}
       currentPage={currentPage}
       onSearch={this.onSearch}
       onPaginate={this.onPaginate}
       
      >
          {orgBooksData}
          </WithPagination>
          </div>
          
   
        </div>
        {this.props.isCalendarShown && (
          <div className="calender-wrapper">
            <a
              className="calendar-external-icon"
              href="/calendar"
              target="_blank"
            >
              <i
                className="fa-external-link fa"
                aria-hidden="true"
                style={{ color: "black", fontFamily: "FontAwesome" }}
              />
            </a>
            <Calendar callApiGetAPI={true} />
          </div>
        )}
      </div></div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  guestHomeBooksData: guestData.getGuestHomeBooks(state),
  isCalendarShown: execData.isCalendarShown(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...guest.actions,
      ...orders.actions,
      ...executive.actions,
    },
    d
  ),
});
export default connect(s, d)(GuestHome);
