import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import ParentHome from '../parent/parentHome.js';
import ParentHeader from '../layout/parentHeader/parentHeader.js';
import PublicFooter from "../layout/PublicFooter";
import NotFoundPage from "../common/404";
import OrdersPage from "../../src/order/OrdersPage.js";
import CartSummary from "../../src/order/cartSummary/CartSummary";
import Checkout from "../../src/order/cartSummary/Checkout";
import OrderHistory from "../order/orderHistory/OrderHistory";
import Payment from "../../src/order/cartSummary/payment";
import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import PublicBooksPage from "../publicview/PublicBooksPage.js";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import PublicOrders from "../order/PublicOrders.js";
import ProfileHome from "../profile/ProfileHome.js";
import Calendar from "../calendar.js";
import ViewResultsByUser from "../assessmentModule/assessmentPages/viewAssessment/common/ViewResultsByUser";
import StudentDashboard from "../parent/StudentDashboard.js";
import ViewAssessmentsStudent from "../assessmentModule/assessmentPages/viewAssessment/student/ViewAssessmentsStudent";
import AttendanceHome from "../attendance/AttendanceHome.js";

// import Books from '../admin/adminConfiguration/books/BooksPage.js';

export default class ParentRouter extends Component {
    render() {
        var path =
            window.location.pathname.indexOf("/Payment") === -1
                ? window.location.pathname
                : "/Payment";
        let noHeaderFooterRoutes = [
            "/Payment",
        ];
        return (
            <ConnectedRouter history={createBrowserHistory()}>
                <div>
                    <div className="page-body">
                        <div className="page-content-parentinstorg">
                            {!noHeaderFooterRoutes.includes(path) && (
                                <ParentHeader> </ParentHeader>
                            )}
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/ParentHome" />
                                </Route>
                                <Route exact path="/Login">
                                    <Redirect to="/ParentHome" />
                                </Route>
                                {/*<Route path="/ParentHome" render={props => (<Books {...props} />)}/>*/}
                                <Route
                                    exact
                                    path="/ParentHome"
                                    render={(props) => <ParentHome {...props} />}
                                />
                                <Route
                                    exact
                                    path="/student-dashboard"
                                    render={(props) => <StudentDashboard {...props} />}
                                />

                                <Route
                                    exact
                                    path="/Orders"
                                    render={(props) => <OrdersPage {...props} />}
                                />
                                <Route
                                    exact
                                    path="/CartSummary"
                                    render={(props) => <CartSummary {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Checkout"
                                    render={(props) => <Checkout {...props} />}
                                />
                                <Route
                                    exact
                                    path="/OrderHistory"
                                    render={(props) => <OrderHistory {...props} />}
                                />
                                <Route
                                    exact
                                    path="/Payment/:id"
                                    render={(props) => <Payment {...props} />}
                                />
                                <Route
                                    exact
                                    path="/ViewAssessmentsStudent"
                                    render={(props) => <ViewAssessmentsStudent {...props} />}
                                />
                                <Route
                                    exact
                                    path="/ViewAnswersStudent"
                                    render={(props) => <ViewResultsByUser {...props} />}
                                />
                                <Route
                                    exact
                                    path="/profile"
                                    render={(props) => (
                                        <ProfileHome onlyPersonalInfo={true} {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/calendar"
                                    render={(props) => <Calendar {...props} />}
                                />
                                <Route exact path="/Partners" component={PartnersPage} />
                                <Route
                                    exact
                                    path="/PublicBooks"
                                    component={PublicBooksPage}
                                />
                                <Route
                                    exact
                                    path="/PublicOrders"
                                    render={(props) => <PublicOrders {...props} />}
                                />
                                <Route exact path="/attendance" render={(props) => (<AttendanceHome {...props} />)}  />
                                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                                <Route exact path="/Careers" component={CareersPage} />
                                <Route exact path="/ContactUs" component={ContactUsPage} />
                                <Route component={NotFoundPage} />
                            </Switch>
                        </div>
                    </div>
                </div>
                {!noHeaderFooterRoutes.includes(path) && <PublicFooter />}
            </ConnectedRouter>
        );
    }
}
