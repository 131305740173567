import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '../appSelector'
import { assessmentModule } from '../assessmentModule/redux-assessmentModule/index'


class EndAssessmentTimerEnd extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.endAssessmentTimerHandlerModal = this.endAssessmentTimerHandlerModal.bind(this);
        this.callEndAssessmentTimer = this.callEndAssessmentTimer.bind(this);
        this.closeEndAssessmentTimer = this.closeEndAssessmentTimer.bind(this);
    }
    closeEndAssessmentTimer(){
        this.props.actions.closeEndAssessmentTimerModal()
    }
    endAssessmentTimerHandlerModal(){
        this.callEndAssessmentTimer();
        this.closeEndAssessmentTimer();
        window.close();
      }
      callEndAssessmentTimer(){
        this.props.actions.totalMarks() &&
        this.props.actions.endTestData({
          jwt: this.props.userData.jwt,
          testId: localStorage.getItem('eachTestId'),
          userId: this.props.userData.id
        })
      }
    render() {
        return (
            <Modal show={this.props.show}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>End Assessment</Modal.Title>
                </Modal.Header>
                <Modal.Body>End of the Assessment</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.endAssessmentTimerHandlerModal}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...modals.actions,
            ...assessmentModule.actions
        },
        d
    )
})
export default connect(s, d)(EndAssessmentTimerEnd);
