import axios from 'axios'
import APICONF from '../../config/ApiConfig.js';

export const getExecOrgAssociatedBooks = (action) => async (
        { dispatch },
        { jwt, orgId, userId }) => {
        const getBooksUrl = APICONF.GET_BOOKS_ORG_USER + "/" + orgId + "/" + userId;
        const [err, result] = await axios.get(getBooksUrl,{
            headers: {
                "authorization": jwt
            }
         })
         .then(res => [null,res.data])
         .catch( err => [err, null])
        const actionToDispatch = err ? action.failure(err) : action.success(result)
        return dispatch(actionToDispatch)
    }