
import {createAsyncAction} from '../../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getEachOrganizationDetails = createAsyncAction(
    `GET_EACH_ORGANIZATION_DETAILS`,
    (payload) => payload
)
const getEachInstituteDetails = createAsyncAction(
    `GET_EACH_INSTITUTE_DETAILS`,
    (payload) => payload
)
const getAssignedCoursesSchoolAdmin = createAsyncAction(
    `GET_ASSIGNED_COURSES_SCHOOL_ADMIN`,
    (payload) => payload
)
const reducer = {
    [getEachOrganizationDetails.success]: (state, payload) => mutators.getEachOrganizationDetails(payload)(state),
    [getEachInstituteDetails.success]: (state, payload) => mutators.getEachInstituteDetails(payload)(state),
    [getAssignedCoursesSchoolAdmin.success]: (state, payload) => mutators.getAssignedCoursesSchoolAdmin(payload)(state)
}

const restApi = {
   [getEachOrganizationDetails]: api.getEachOrganizationDetails(getEachOrganizationDetails),
   [getEachInstituteDetails]: api.getEachInstituteDetails(getEachInstituteDetails),
   [getAssignedCoursesSchoolAdmin]: api.getAssignedCoursesSchoolAdmin(getAssignedCoursesSchoolAdmin),
}

export default {
    actions: {
        getEachOrganizationDetails,
        getEachInstituteDetails,
        getAssignedCoursesSchoolAdmin
    },
    restApi,
    reducer
}