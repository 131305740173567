
import {createAsyncAction} from '../../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getGuestHomeBooks = createAsyncAction(
    `GET_GUEST_HOME_BOOKS`,
    payload => payload
)

const reducer = {
    [getGuestHomeBooks.success]: (state, payload) =>
      mutators.getGuestHomeBooks(payload)(state)
}

const restApi = {
   [getGuestHomeBooks]: api.getGuestHomeBooks(getGuestHomeBooks) 
}

export default {
    actions: {
        getGuestHomeBooks
    },
    restApi,
    reducer
}