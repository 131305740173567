import { fromJS,List, Map } from 'immutable'

export const getCartSummaryByUserId = ({data}) => (state)=> {
    return state.set('cartSummary',fromJS(data))
                .set('teacherAssocImmutableData', fromJS(data))
}

export const getGuestCartSummaryByUserId = ({data}) => (state)=> {
    return state.set('guestCartSummary',fromJS(data));
}


export const changeQuantity = ({ instituteIndex, bookIndex, value }) => state => {

    return state
      .setIn(
        [
          "cartSummary",
          "data",
          "instituteDtls",
          instituteIndex,
           'bookDtls',
           bookIndex,
          "quantity",
        ],
        value
      )
      .set("currentInstituteIndex", instituteIndex)
      .set("currentBookIndex", bookIndex);

}

export const changeGuestQuantity = ({ bookIndex, value }) => state => {

    return state
      .setIn(
        [
          "cartSummary",
          "data",
           'bookDtls',
           bookIndex,
          "quantity",
        ],
        value
      )
      .set("currentBookIndex", bookIndex);

}


export const deleteCart = ({ data }) => state =>{

    return state.deleteIn(["cartSummary", "data", "instituteDtls", data.instituteIndex, "bookDtls", data.bookIndex])
}


export const setCurrentDataIndex = ({ currentDataIndex }) =>state => {
    return state.set("currentDataIndex", currentDataIndex)
}


export const updateCartSucess = ({ data }) => state => {

  return state.setIn(['updateCartAlerts',data.bookIndex,'successAlert'], true)

}

export const updateCartFailure = (payload) => state => {

  return state.setIn(['updateCartAlerts',payload.error.bookIndex,'failureAlert'],true)
}

export const deleteCartSuccess = ({ data }) => state => {
  return state.setIn(['deleteCartAlerts',data.bookIndex,'successAlert'], true)
}


export const deleteCartFailure = (payload) => state => {
  return state.setIn(['deleteCartAlerts',payload.error.bookIndex,'failureAlert'],true)
}

export const resestUpdatedCartAlerts = () => (state) => {
  return state.deleteIn(['updateCartAlerts'])
};

export const resetDeleteCartAlerts = () => (state) => {
  return state.deleteIn(['deleteCartAlerts'])
};


export const addToGuestCartSuccess = (payload) => state => {
  return state.set('addToCartStatus', true)
         .set('addToCartSuccessAlert', true)
}

export const paymentOrders = ({data}) => state => {
  const paymentSuccesData = data.data
  localStorage.setItem('options',JSON.stringify(data.options))
  localStorage.setItem('cartId', data.cartId)
  const shippingid = state.get('shippingId',0)
  const paymentWindow = window.open(`/Payment/${shippingid}`)
  localStorage.setItem('paymentWindow',paymentWindow)
  return state.set('paymentOrders',fromJS(paymentSuccesData))
          .set('paymentOptions', data.options)
}

export const paymentOrdersFailure = (payload) => state => {
  console.log("payload:::::::",payload.error.response.data)
  if(payload.error.response.data !=''){
    return state.set('showpaymentOrdersFailure', true)
    .set('createpaymentOrdersFailureMsg', payload.error.response.data.message)
  }
  return state.set('showpaymentOrdersFailure', true)
  .set('createpaymentOrdersFailureMsg', "Failed to create the payments")
  
}

export const addToGuestCartError = (payload) => state => {
  return state.set('addToCartStatus', false)
  .set('addToCartFailureAlert', true)
}

export const changeQuantityForGuestSummary = ({ bookIndex, value }) => state => {
  return state
  .setIn(
    [
      "guestCartSummary",
      "data",
       'bookDtls',
       bookIndex,
      "quantity",
    ],
    value
  )
  .set("currentBookIndex", bookIndex);
}


export const resetAddToCartAlerts = () => state => {
  return state.set('addToCartSuccessAlert', false)
            .set('addToCartFailureAlert', false)
}


export const setUserAddressDetails =  ( {data}) => state => {
  return state.set('userAddressDetails', fromJS(data))
}

export const getShippingAddressForInstituteSuceess =  ( {data}) => state => {
  return state.set('userAddressDetails', fromJS(data))
}

export const getShippingAddressForInstituteFailure =  ( {data}) => state => {
  return state.set('userAddressDetails', "")
}

export const setShippingId = ({ shippingId }) => state => state.set('shippingId',shippingId)

export const setSelectedInstIdShipping = ({ selectedInstId, instituteIndexValue }) => state => {
  return state.set('selectedInstId',selectedInstId)
               .set('instituteIndexValue', instituteIndexValue)
}

export const setShippingIdAdmin = ({ selectedAddress }) => state => {
  
  let instId = state.get('selectedInstId',"")
  let instituteIndexValue = state.get('instituteIndexValue',"")
  const currentShipArray = state.getIn(['instShipArray'],List());
  let updatedShipArray = currentShipArray.filter(currentShipData=>currentShipData.get("instId","") !== instId)
  let instShipObject = Map({
    instId:instId,
    selectedShippingId: selectedAddress.get("userAddressInfoId", ""),
    instituteIndexValue:instituteIndexValue,
    street1: selectedAddress.get("street1", ""),
    street2: selectedAddress.get("street2", ""),
    landmark: selectedAddress.get("landmark", ""),
    cityName: selectedAddress.get("cityName", ""),
    district: selectedAddress.get("district", ""),
    state: selectedAddress.get("state", ""),
    country: selectedAddress.get("country", ""),
    zipCode:  selectedAddress.get("zipCode", "")
  })
  updatedShipArray = updatedShipArray.push(instShipObject)
  return state.setIn(['instShipArray'],updatedShipArray)
}


export const toggleShippingAddressModal = (payload) => state => state.set('shippingAddressModalStatus', payload)


export const setAllOrderDetailsByUserId = ({data}) => (state)=> {
  return state.set('orderHistoryData',fromJS(data));
}

export const selectOrderHistoryYear = (payload) => state => {
  return state.set('selectedOrderHistoryYear',payload)
}


export const setCurrentOrderIndex = payload => state => {
  return state.set('orderStatusCurrentIndex', payload)
}


export const paymentSuccess = payload => state => {
  return state
}

export const paymentFailure = payload => state => {
  return state.set("paymentFailure", true)
  .set("paymentFailureMsg", "Your Order will be placed in 24hrs, Thank you! for your order");
}

export const orderBooksForGuestSuccess = payload => state => {
  return state.set("orderBooksForGuestSuccess", true)
  .set("orderBooksForGuestSuccessMsg", "Your Order will be placed successfully, Thank you! for your order ");
}

export const orderBooksForGuestFailure = payload => state => {
  return state.set("orderBooksForGuestFailure", true)
  .set("orderBooksForGuestFailureMsg", "Your Order will be placed in 24hrs, Thank you! for your order ");
}

export const orderBooksSuccess = payload => state => {
  if(payload.data.responseCode === 500 && payload.data.data === "Books not available"){
    return state.set("orderBooksFailure",true)
    .set("orderBooksFailureMsg", "Books are not available");
  }
  else{
  return state.set("orderBooksSuccess", true)
  .set("orderBooksSuccessMsg", "Your Order will be placed successfully, Thank you! for your order");
}
}

export const orderBooksFailure = payload => state => {
  if(payload.error.response === undefined){
    return state.set("orderBooksFailure",true)
    .set("orderBooksFailureMsg", "Unable to process Order please try after sometime ");
  }
  else{
  return state.set("orderBooksFailure", true)
  .set("orderBooksFailureMsg", "Your Order will be placed in 24hrs, Thank you! for your order");
}
}

export const closeOrderPaymentAlertModal = (payload) => (state) => {
  if(payload){
      return state.set('orderBooksSuccess', false)
      .set('orderBooksSuccessMsg','')
      .set('orderBooksForGuestSuccess',false)
      .set('orderBooksForGuestSuccessMsg','')
  }else{
      return state.set('paymentFailure', false)
           .set('paymentFailureMsg','')
           .set('orderBooksFailure',false)
           .set('orderBooksFailureMsg','')
           .set('orderBooksForGuestFailure',false)
           .set('orderBooksForGuestFailureMsg','')
  }
  
}