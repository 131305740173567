import * as api from './api'
import * as mutators from './mutators'

import { createAction } from "redux-act";
import { createAsyncAction } from '../../lib/utils'

const getAssignStudentToParent = createAsyncAction(
    `GET_ASSIGN_PARENTTOSTUDENTS_BY_PARENTID`,
    payload => payload
)

const reducer = {
    [getAssignStudentToParent.success]: (state, payload) =>
        mutators.getAssignStudentToParent(payload)(state),
}

const restApi = {
    [getAssignStudentToParent]: api.getAssignStudentToParent(getAssignStudentToParent),
}

export default {
    actions: {
        getAssignStudentToParent
    },
    restApi,
    reducer
}