import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import UserModal from "../modals/UserModal";
import '../common/AddUsers.css';
import DeleteUserModal from "../modals/DeleteUserModal";
import EditUserLinkRender from "./EditUserLinkRender";
import DeleteUserLinkRender from "./DeleteUserLinkRender";
import AssignGroupLinkRender from "./AssignGroupLinkRender";
import AssignBooksModal from "./AssignUserBookLinkRender";
import AddGroupName from "../modals/AddGroupName";
import ViewEachUserData from './ViewEachUserData'
import APICONF from '.././config/ApiConfig.js';
import AlertBox from './AlertBox';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as modalsData from '../modals/selector'
import * as appData from '../appSelector'
import AssignParentToStudent from './AssignParentToStudent';

class AddUsers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: 'User Details',
          minWidth: 120,
          cellRenderer: 'viewEachUserData',
          resizable: true,
          getQuickFilterText: function (params) {
            let firstName = params.data.firstName;
            let lastName = params.data.lastName;
            let email = params.data.email;
            let roleName = params.data.roleName;
            let cellPhoneNumber = params.data.cellPhoneNumber.toString();
            let searchValue = params.data.searchValue;
            if (firstName && searchValue && firstName.toLowerCase().includes(
              searchValue.toLowerCase()
            )) {
              return params.data.firstName
            }
            if (lastName && searchValue && lastName.toLowerCase().includes(
              searchValue.toLowerCase()
            )) {
              return params.data.lastName
            }
            if (email && searchValue && email.toLowerCase().includes(
              searchValue.toLowerCase()
            )) {
              return params.data.email
            }
            if (roleName && searchValue && roleName.toLowerCase().includes(
              searchValue.toLowerCase()
            )) {
              return params.data.roleName
            }
            if (cellPhoneNumber && searchValue && cellPhoneNumber.toLowerCase().includes(
              searchValue.toLowerCase()
            )) {
              return params.data.cellPhoneNumber
            }
          }
        },
        {
          headerName: 'Institute Name',
          field: 'instituteName',
          cellRenderer: this.instituteName,
          minWidth: 150,
          resizable: true
        },
        {
          headerName: 'Organization Name',
          field: 'org',
          cellRenderer: this.orgName,
          minWidth: 150,
          resizable: true,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: 'Orders By',
          field: 'ordersBy',
          cellRenderer: this.showOrdersByLink,
          minWidth: 150,
          resizable: true,
        },
        {
          headerName: 'Assign Parent',
          field: 'assignParent',
          cellRenderer: 'assignParentToStudent',
          minWidth: 50,
          resizable: true,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: 'Assign Courses',
          field: 'assignCourses',
          cellRenderer: 'assignBooksModal',
          minWidth: 50,
          resizable: true,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: 'Assign Group Name',
          cellRenderer: 'assignGroupModal',
          minWidth: 50,
          resizable: true,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: 'Edit',
          cellRenderer: 'editUserLinkRender',
          minWidth: 10,
          maxWidth: 80,
          resizable: true,
          cellStyle: { 'text-align': 'center' }
        },
        {
          headerName: 'Delete',
          cellRenderer: 'deleteUserLinkRender',
          minWidth: 10,
          maxWidth: 100,
          cellStyle: { 'text-align': 'center' }
        }
      ],
      defaultColDef: { sortable: true },
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-wrapper"><i class="fa fa-spinner" aria-hidden="true"></i></span>',
      overlayNoRowsTemplate:
        '<svg class="spinner" viewBox="0 0 50 50"><circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle></svg>',
      rowData: [],
      showAddUser: false,
      showEditUser: false,
      showDeleteUser: false,
      showAddGroup: false,
      paginationPageSize: 10,
      context: { componentParent: this },
      frameworkComponents: {
        editUserLinkRender: EditUserLinkRender,
        deleteUserLinkRender: DeleteUserLinkRender,
        assignParentToStudent: AssignParentToStudent,
        assignBooksModal: AssignBooksModal,
        assignGroupModal: AssignGroupLinkRender,
        viewEachUserData: ViewEachUserData
      },
      successAlert: false,
      errorAlert: false,
      successMsg: '',
      errorMsg: '',
    };
    this.getAllUsers = this.getAllUsers.bind(this);
  }

  getAllUsers() {
    const getUsersUrl = APICONF.GET_USERS + "/" + this.props.userData.id;
    fetch(getUsersUrl, {
      headers: {
        "authorization": this.props.userData.jwt
      }
    })
      .then(response => response.json())
      .then(data => {
        this.setState({ rowData: data });
      })
      .catch(error => { });
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    let loggedInroleId = this.props.userData.roleId;

    if (loggedInroleId === "73146" || loggedInroleId === "79146") {
      this.gridColumnApi.setColumnVisible('ordersBy', false);
      this.gridColumnApi.setColumnVisible('instituteName', false);
    }
    if (loggedInroleId === "77138") {
      this.gridColumnApi.setColumnVisible('assignCourses', false);
    }
    if (loggedInroleId === "78129" || loggedInroleId === "77138" || loggedInroleId === "73146") {
      this.gridColumnApi.setColumnVisible('assignParent', false);
    }
    this.getAllUsers();
  }

  onFirstDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  handleQuickFilter = event => {
    this.gridApi.setQuickFilter(event.target.value);
    const updatedData = this.state.rowData.map(
      eachRow => {
        return {
          ...eachRow,
          searchValue: event.target.value
        }
      }
    )
    this.setState({
      rowData: updatedData
    })
  };

  onBtShowLoading = () => {
    this.gridApi.showLoadingOverlay();
  };

  onBtShowNoRows = () => {
    this.gridApi.showNoRowsOverlay();
  };

  onBtHide = () => {
    this.gridApi.hideOverlay();
  };

  openAddUserModal = () => {
    this.setState({ showAddUser: true });
  }

  closeAddUserModal = () => {
    this.setState({ showAddUser: false });
    setTimeout(() => {
      this.getAllUsers();
    }, 3000);
  }

  openDeleteUserModal = () => {
    this.setState({ showDeleteUser: true });
  }

  closeDeleteUserModal = () => {
    this.setState({ showDeleteUser: false });
  }

  openAddGroupModal = () => {
    this.setState({ showAddGroup: true });
  }

  closeAddGroupModal = () => {
    this.setState({ showAddGroup: false });
  }

  showOrdersByLink = (params) => {
    let newLink =
      `<span class="grid-link">Click here</span>`;
    return newLink;
  }

  orgName = (params) => {
    var paramsData = params.data;
    var orgInfo = paramsData.orgInfo;
    var orgNames = undefined;
    Object.values(orgInfo).forEach(function (orgData) {
      var orgNamesObject = Object.values(orgData);
      orgNames = orgNamesObject.join(", ")
    })
    return orgNames;
  }
  instituteName = (params) => {
    var paramsData = params.data;
    var instInfo = paramsData.instInfo;
    var instNames = undefined;
    Object.values(instInfo).forEach(function (instData) {
      var instNamesObject = Object.values(instData);
      instNames = instNamesObject.join(", ")
    })
    return instNames;
  }
  handleCreate = (inputValue) => {

    setTimeout(() => {
      window.open('/AddInstitute');
    }, 100)
  }

  render() {
    return (
      <div className='mt-4'>
        <div className="container-fluid mt-4 ">
          {
            (this.props.successAlertBox || this.props.failureAlertBox) && (
              <AlertBox successAlert={this.props.successAlertBox} successMsg={this.props.userPageSuccessMsg}
                errorAlert={this.props.failureAlertBox} errorMsg={this.props.userPageErrorMsg} successShortMsg={this.props.userSuccessShortMsg}
              />
            )
          }
          {(this.state.showAddUser) &&
            (<UserModal show={this.state.showAddUser} onClose={this.closeAddUserModal} />)}
          {(this.state.showAddGroup) &&
            (<AddGroupName show={this.state.showAddGroup} onClose={this.closeAddGroupModal} />)}
          {(this.state.showDeleteUser) && (
            <DeleteUserModal show={this.state.showDeleteUser} onClose={this.closeDeleteUserModal} />)}
          <div id="grid-wrapper" style={{ padding: '10px 0' }} >
            <div style={{ padding: '0 0 10px 0' }} className='clearfix'>
              <button className="btn btn-sm btn-primary" onClick={this.openAddUserModal}>
                <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} /> Add User
              </button>
              <button className="btn btn-sm btn-primary ml-3 " onClick={this.openAddGroupModal}>
                <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} /> Add Group
              </button>
              {this.props.userData.roleId === "77138" || this.props.userData.roleId === "78129" ? (
                <button className='btn btn-sm btn-primary ml-3' onClick={this.handleCreate}>
                  <i className="fa fa-plus" aria-hidden="true" style={{ color: 'black' }} />  Create Institute</button>
              ) : <i></i>
              }
              <input
                type="text"
                placeholder="Search"
                style={{ float: 'right' }}
                onChange={this.handleQuickFilter}
              />
            </div>
            <div
              id="myGrid"
              style={{ width: '100%', height: 520 }}
              className="ag-theme-alpine"
            >
              <AgGridReact
                columnDefs={this.state.columnDefs}
                context={this.state.context}
                frameworkComponents={this.state.frameworkComponents}
                defaultColDef={this.state.defaultColDef}
                onGridReady={this.onGridReady}
                overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                onGridSizeChanged={this.onGridSizeChanged.bind(this)}
                rowData={this.state.rowData}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
                rowHeight={50}
                autoHeight={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  successAlertBox: modalsData.successAlertBox(state),
  failureAlertBox: modalsData.failureAlertBox(state),
  userPageErrorMsg: modalsData.showUserErrorMsg(state),
  userPageSuccessMsg: modalsData.showUserAlertSuccessMsg(state),
  userSuccessShortMsg: modalsData.showUserAlertSuccessShortMsg(state),
  userData: appData.getUserData(state),
  overLayLoader: appData.getOverLayLoader(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {

    },
    d
  )
})
export default connect(s, d)(AddUsers);
