import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appData from '.././appSelector'
import { modals } from './index'
import * as modalsData from './selector'

class BookQuantityModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      enteredQuantity: null,
    }
    this.addQuantity = this.addQuantity.bind(this);
    this.callAddQuantityCloseModal = this.callAddQuantityCloseModal.bind(this);
    this.handleQuantity = this.handleQuantity.bind(this);

    
  }
  callAddQuantityCloseModal() {
    this.addQuantity();
    this.props.onClose();
  }
  handleQuantity = event => {
    let quantity = event.target.value;
    this.setState({
        enteredQuantity: quantity,
    })
}
  addQuantity() {
    var bookStock ={
      "bookId" : this.props.bookModalData.bookId,
      "bookQuantity": this.state.enteredQuantity,
    }
    this.props.actions.addQuantityBook({
      bookStockData : bookStock,
      jwt : this.props.userData.jwt
    })
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} centered>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-8">
                <input type="number" onChange={this.handleQuantity} placeholder="Enter Quantity" className="css-yk16xz-control" />
              </div>
            <Button variant="primary" onClick={this.callAddQuantityCloseModal}>
              Add Quantity
          </Button>
            </div>
          <hr></hr>
          <p>Total Quantity <b>{this.props.bookModalData.bookTotalQuantity}</b></p> 
          <p>Remaining Quantity <b>{this.props.bookModalData.bookRemainingQuantity}</b></p> 
          <p>Sold Quantity <b>{this.props.bookModalData.bookSoldQuantity}</b></p> 
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose} >
              Close
          </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  bookModalData: modalsData.openQuantityModalBookData(state) 
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions
    },
    d
  )
})
export default connect(s,d)(BookQuantityModal);