import {createAsyncAction} from '../lib/utils'

import { put,take, takeLatest } from 'redux-saga/effects'

import profile from './Profile'

const generateEvent = createAsyncAction(
    `GENERATE_EVENT`,
    payload => payload 
)

function *generateEventTask ({
    payload
}) {

   const {
       data, eventUsersMap
   } = payload

console.log("eventUsersMap:::::::::",eventUsersMap)
   const generateMeetingLink = data[0].generateMeetingLink || false

   if(generateMeetingLink) {
    yield put(profile.actions.generateMeetingLink())
    yield take(profile.actions.generateMeetingLink.success)

   }
    yield put(profile.actions.createEvent({ data, eventUsersMap}))
    yield take(profile.actions.createEvent.success)

}


function *listener() {
    yield takeLatest(generateEvent,generateEventTask)
}

export default {
    actions: {
        generateEvent
    },
    listener
}