import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';


class AlertModal extends Component {
  render() {
    const { show, message, type, onClose, title } = this.props;
    return (
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title className={`text-${type}`}>{title ? title : type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {message}
        </Modal.Body>
        <Modal.Footer>
          <button className={`btn btn-sm btn-responsive btn-${type}`} onClick={onClose}>Ok</button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AlertModal
