import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '../appSelector';
import * as modalsData from './selector';
import * as defaultParentData from '../common/selector';

class AssignParentToStudentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedrelationshipType: "",
            selectedParentId: ""
        }
        this.handleParentRelationship = this.handleParentRelationship.bind(this);
        this.assignParentToStudent = this.assignParentToStudent.bind(this);
        this.callAssignParentToStudent = this.callAssignParentToStudent.bind(this);
        this.getUsersByRoleId = this.getUsersByRoleId.bind(this);
        this.deleteParentToUser = this.deleteParentToUser.bind(this);
        this.handleSelectedParent = this.handleSelectedParent.bind(this);
    }

    componentDidMount() {
        this.getUsersByRoleId();
    }
    deleteParentToUser(item) {
        console.log("item:::::::::::", item)
        this.props.actions.deleteAssignParentNames({
            jwt: this.props.userData.jwt,
            id: this.props.userData.id,
            parentStudentAssignId: item.parentStudentAssignId,
        })
        this.props.onClose();
    }

    assignParentToStudent() {
        this.callAssignParentToStudent()
        this.props.onClose();
    }

    getUsersByRoleId() {
        if (this.props.userData.roleId === "76517" || this.props.userData.roleId === "72195") {
            this.props.actions.getUsersByRoleId({
                jwt: this.props.userData.jwt,
                id: this.props.userData.id,
                roleId: "79126",
            })
        } {
            if (this.props.userData.roleId === "79146" || this.props.userData.roleId === "73146") {
                this.props.actions.getUsersByRoleId({
                    jwt: this.props.userData.jwt,
                    id: this.props.userData.id,
                    roleId: "75126",
                })
            }
        }
    }

    callAssignParentToStudent() {
        let addedParents = [{
            "createdBy": this.props.userData.id,
            "parentId": this.state.selectedParentId,
            "relationshipType": this.state.selectedrelationshipType,
            "studentId": this.props.eachrowUserData.id
        }]
        this.props.actions.assignParentToStudent({
            jwt: this.props.userData.jwt,
            addedParents: addedParents,
        })
    }

    handleParentRelationship(event) {
        let relationshipType = event.value;
        console.log("relationshipType", event.value, event)
        this.setState({
            selectedrelationshipType: relationshipType,
        })
    }

    handleSelectedParent(event) {
        let parentId = event.value;
        console.log("relationshipType", event.value, event)
        this.setState({
            selectedParentId: parentId,
        })
    }

    render() {
        const relationShipOptions = [{ "value": "Mother", "label": "Mother" }, { "value": "Father", "label": "Father" }, { "value": "Guardian", "label": "Guardian" }];
        let defaultParentOptionsData = [];
        if (this.props.defaultparentNames) {
            this.props.defaultparentNames.map(function (dataChange) {

                var defaultparents = {
                    value: dataChange.parentStudentAssignId,
                    label: dataChange.firstName + "_" + dataChange.lastName + "(" + dataChange.relationshipType + ")"
                }
                defaultParentOptionsData.push(defaultparents)
            })
        }
        return (
            <Modal
                show={this.props.show}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Assign Parent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <label>Relationship</label>
                        <Select
                            options={relationShipOptions}
                            onChange={this.handleParentRelationship}
                        ></Select>
                    </div>
                    <div>
                        <label>Parent</label>
                        <Select
                            options={this.props.getUsersByRoleIdParents}
                            onChange={this.handleSelectedParent}
                        ></Select>
                    </div>
                    <div className='mt-3'>
                        <hr />
                        {this.props.defaultparentNames.map((defaultparents, index) => {
                            return <div key={index} className='row'>
                                <div className='col'>
                                    <div key={defaultparents.parentStudentAssignId}>
                                        {defaultparents.firstName + "-" + defaultparents.lastName}&nbsp;({defaultparents.relationshipType})
                                        &nbsp;&nbsp;
                                        <span className="mt-n1 text-color-red" role="button">
                                            <i className="fa fa-times-circle" onClick={() => this.deleteParentToUser(defaultparents)}></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        })}
                        <hr />
                    </div>
                    <Button variant="success" className="float-right mt-3" onClick={() => this.assignParentToStudent()} >
                        Assign
                    </Button>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="float-right" variant="light" onClick={() => {
                        this.props.onClose()
                    }}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
const s = ({ state }) => ({
    userData: appData.getUserData(state),
    assignParentToStudent: modalsData.assignParentToStudent(state),
    defaultparentNames: defaultParentData.getAssignParentToStudentByStudentId(state),
    getUsersByRoleIdParents: modalsData.getUsersByRoleId(state),
})

const d = (d) => ({
    actions: bindActionCreators(
        {
            ...modals.actions
        },
        d
    )
})
export default connect(s, d)(AssignParentToStudentModal);