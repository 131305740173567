import React, { Component } from 'react';
import OrgBooksRenderer from "./OrgBooksRenderer";
import PublicFooter from "../layout/PublicFooter";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { publicView } from './redux-publicView/index'
import * as publicBookData from './redux-publicView/selector';
import * as appData from "../appSelector";

class PublicBooksPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            books: [],
            showAllOrgBooks: false
        }
    // this.updateShowAllOrgBooks = this.updateShowAllOrgBooks().bind(this)
    }
    updateShowAllOrgBooks = (orgId) => {
        this.setState({
            showAllOrgBooks: true
        });
        const allOrgBooks = this.state.books.filter((item) => item.orgId === orgId);
        this.setState({
            books: allOrgBooks
        });
    }
    componentDidMount() {
        this.props.actions.getPublicBooks({})
    }

    render() {
        const organizations = this.props.publicBookData && this.props.publicBookData.map((item) =>
            <OrgBooksRenderer key={item.orgId} organization={item} onSeeAll={this.updateShowAllOrgBooks} showAll={this.state.showAllOrgBooks} />
        );
        const orgBooks = this.props.publicBookData && this.props.publicBookData.map((item) =>
            <OrgBooksRenderer key={item.orgId} organization={item} onSeeAll={this.updateShowAllOrgBooks} showAll={this.state.showAllOrgBooks} />
        );
        return (
            <div>
                <div className="page-body">
                    <div className="page-content">
                        <div className="container-fluid org-header-logo-sapce">
                            {(this.state.showAllOrgBooks) ? orgBooks : organizations}
                        </div>
                    </div>
                </div>
                {
                   (!(this.props.userData.roleId === "76517" 
                   || this.props.userData.roleId === "72195" || this.props.userData.roleId === "72138"
                    || this.props.userData.roleId === "79126")) &&
                   <PublicFooter  isPublicPath={this.props.match.path === '/PublicBooks'}/>
                }
            </div>
        );
    }
}
const s = ({ state }) => ({
    publicBookData: publicBookData.getPublicBooks(state),
    userData: appData.getUserData(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...publicView.actions
    },
    d
  )
})
export default connect(s,d)(PublicBooksPage);
