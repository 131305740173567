import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";

import orderTask from "../redux-orders/orderTask";
import * as selectors from "../redux-orders/selector";
import * as appData from "../../appSelector";
import './CartSummary.css'
import AlertBox from '../../common/AlertBox';
import { Modal } from 'react-bootstrap';

class GuestCheckout extends Component {
 
  componentDidMount() {
    this.props.actions.getGuestCartSummaryByUserId({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });

    this.props.actions.getUserAddressDetails({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });

  }

  changeQuantity = (e, bookIndex) => {
    this.props.actions.changeQuantityForGuestSummary({
      bookIndex,
      value: e.target.value,
    });
  };

  

  render() {
    const {
      cartSummary,
      cartId,
      cartGrandTotal,
      taxAmount,
      convenienceFee,
      shippingAddressModalStatus,
      actions,
      shippingAdressDetails,
      shippingId,
      guestCartTotalBeforeTax,
      address
    } = this.props;
    console.log(cartSummary)
    return (
      <div className="container mt-4">
                {
            (this.props.getshowpaymentOrdersFailure) &&(
              <AlertBox errorAlert={this.props.getshowpaymentOrdersFailure} errorMsg={this.props.getshowpaymentOrdersFailureMsg} />
            )
        } 
        <div className="review-button">
          <button
            className="btn btn-primary"
            hidden={!shippingId>0}
            
            onClick={() => {
              this.props.actions.displayRazorpay({
                userId: this.props.userData.id,
                jwt: this.props.userData.jwt,
                username: this.props.userData.username,
                cartId,     
              });
              
              
            }
          }
          >
            Place Order
          </button>
  
        </div>
        {cartSummary.map((book, bookIndex) => (
          <div>
            <div className="cart-data-wrapper">
              <div className="cart-img-wrapper">
                <img
                  src={book.get("bookImage", "")}
                  alt={"book"}
                  width={"300"}
                  height={"200"}
                />
              </div>
              <div className="cart-content-wrapper">
                <p className="price">Price: 
                <span className="rupee"></span> 
                  {` ${book.get("bookPrice", "")}`}
                </p>
                <p className="quantity">
                  Quantity:&nbsp; {book.get("quantity", "")}
                </p>
                <p className="total-amount">Total Amount:
                <span className="rupee"></span> 
                  {` ${book.get("totalAmount", "")}`}
                </p>

              </div>
            </div>
          </div>
        ))}
        <div className="">
          <div className="total-content-wrapper">
            <div className="shipping-button">
              <div className="cart-dataa-wrapper">
              <div  
              hidden={!shippingId>0}
              >
                  {shippingAdressDetails.filter(address => address.get("userAddressInfoId", "") === this.props.shippingId).map((address) => (
                <div className="cart-data-wrap">
                  <div className="cart-content-wrapper">
                      
                    {address.get("street1", "")},
                    {address.get("street2", "")},
                    {address.get("landmark", "")},
                    {address.get("cityName", "")},
                    {address.get("district", "")},
                    {address.get("state", "")},
                    {address.get("country", "")},
                    {address.get("zipCode", "")}
                    
                    </div>      
              </div>
                  ))}    
              </div>
                <button className="btn btn-warning"
                onClick={() => actions.toggleShippingAddressModal(true)}>
                  Select Shipping Address
                </button>  
              </div>
            </div>
            <div className="price text-right">
              <b>Total Amount Before Tax and Fees: </b>
              <span className="rupee"></span> 
              {` ${guestCartTotalBeforeTax}`}
            </div>
            <div className="price text-right">
              <b>Tax Amount: </b>
              <span className="rupee"></span> 
              {` ${taxAmount}`}
            </div>
            <div style={{ borderBottom: '1px solid lightgrey', paddingBottom: '.5rem' }} className="total-amount text-right">
              <b>Convenience Fee: </b>
              <span className="rupee"></span> 
              {` ${convenienceFee}`}
            </div>
            <div className=" text-right">
              <h3 style={{ display: 'inline-block', margin: 0 }}>Cart Grand Total: </h3>
              <span className="rupee"></span> 
              {` ${cartGrandTotal}`}
            </div>
          </div>
        </div>
        <Modal show={shippingAddressModalStatus}>
          <Modal.Header closeButton onClick={() =>
            actions.toggleShippingAddressModal(false)}>
            <Modal.Title className="text-font">Select Shipping Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {shippingAdressDetails.map((address) => (
              <div>
                <div className="cart-data-wrap">
                  <div className="cart-content-wrapper">
                    {address.get("street1", "")}<br/>
                    {address.get("street2", "")}<br/>
                    {address.get("landmark", "")}<br/>
                    {address.get("cityName", "")}<br/>
                    {address.get("district", "")}<br/>
                    {address.get("state", "")}<br/>
                    {address.get("country", "")}<br/>
                    {address.get("zipCode", "")}<br/><br/>
                    </div>
                    <div className="useAddress-button">
                    <button className="btn btn-warning"
                   
                      onClick={() =>
                        actions.setShippingId({
                          shippingId: address.get("userAddressInfoId", ""),
                        }) &&
                        actions.toggleShippingAddressModal(false)
                        
                      }
                    >
                      Deliver to this address
                    </button>
                    </div>
                  </div>
                
              </div>
            ))}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  cartSummary: selectors.getGuestCartSummary(state),
  cartId: selectors.guestCartId(state),
  taxAmount: selectors.guestTaxAmount(state),
  convenienceFee: selectors.guestConvenienceFee(state),
  guestCartTotalBeforeTax: selectors.guestCartTotalBeforeTax(state),
  cartGrandTotal: selectors.guestCartGrandTotal(state),
  shippingAddressModalStatus: selectors.getShippingAddressModalStatus(state),
  shippingAdressDetails: selectors.getUserAddressDetails(state),
  shippingId:selectors.getShippingId(state),
  getshowpaymentOrdersFailure: selectors.getshowpaymentOrdersFailure(state),
  getshowpaymentOrdersFailureMsg: selectors.getshowpaymentOrdersFailureMsg(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
      ...orderTask.actions
    },
    d
  ),
});
export default connect(s, d)(GuestCheckout);
