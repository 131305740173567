import React, { Component } from 'react';
import StudentPanelLinksInstOrg from './StudentPanelLinksInstOrg.js';
import { studentInstOrg } from './index'
import * as studentInstOrgData from './selector';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './StudentHomeInstOrg.css'
import * as appData from '.././appSelector'
import { executive } from '../executive/redux-executive/index'
import * as execData from '../executive/redux-executive/selector'
import Calendar from "../calendar";
class StudentHomeInstOrg extends Component {
  componentDidMount() {
    this.props.actions.getStudentAssociatedBooks({
      userIdStudent : this.props.userData.id,
      jwt: this.props.userData.jwt
    })
  }

  render() {
    var studentBooksData = this.props.studentsAssocData;
    if (studentBooksData) {
      var showBooks = studentBooksData.map((studentAssocBookData, i) => {
        return (
          <StudentPanelLinksInstOrg key={studentAssocBookData.bookId}  studentAssocBookData={studentAssocBookData} />
        );
      })
    }
    return (
      <div>
      <div className={`${this.props.isCalendarShown? 'move-left' : ''}`}>
        <div style={{
          paddingBottom: '5px',
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer'
        }}
          onClick={() => this.props.actions.toggleCalendar(!this.props.isCalendarShown)}
        >
          <img
            src={'https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png'}
            alt="Calendar"
          />
        </div>

        {showBooks}
      </div>
      { 
        this.props.isCalendarShown &&
        <div className="calender-wrapper">
          <a className="calendar-external-icon" href="/calendar" target="_blank"><i className="fa-external-link fa" aria-hidden="true" style={{ color: 'black', fontFamily: "FontAwesome" }} /></a>
          <Calendar callApiGetAPI={true} />
        </div>
      }
    </div>
    );

  }
}
const s = ({ state }) => ({
  studentsAssocData: studentInstOrgData.getStudentsData(state),
  userData: appData.getUserData(state),
  isCalendarShown: execData.isCalendarShown(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...studentInstOrg.actions,
      ...executive.actions,
    },
    d
  )
})
export default connect(s,d)(StudentHomeInstOrg);