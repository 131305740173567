export const getDefaultGroupNames = (payload) => (state)=> {
    return state.set('getDefaultGroupNames',payload.data)
}

export const getStateCodeInfo = (payload) => (state)=> {
    let stateCodeOptions =[]
    console.log("payload:::",payload)
    payload.data.map((dataChange) => {
            var stateCodesObject = {
              value: dataChange.stateCode,
              label: dataChange.stateName,
            }
            stateCodeOptions.push(stateCodesObject)
        if (stateCodeOptions.length > 0) {
          return stateCodeOptions;
        }
        return stateCodeOptions;
      })
    return state.set('getStateCodeInfo',stateCodeOptions)
}

export const getCountryCodeInfo = (payload) => (state)=> {
    let countryCodeOptions =[]
    console.log("payload:::",payload)
    payload.data.map((dataChange) => {
            var countryCodesObject = {
              value: dataChange.countryCode,
              label: dataChange.countryName,
            }
            countryCodeOptions.push(countryCodesObject)
        if (countryCodeOptions.length > 0) {
          return countryCodeOptions;
        }
      })
    return state.set('getCountryCodeInfo',countryCodeOptions)
}

export const openupdateOrderStatusModal = (payload) => (state) => {
  return state.set("openupdateOrderStatusModal", true).set("orderId", payload);
};
export const closeupdateOrderStatusModal = (payload) => (state) => {
  return state.set("openupdateOrderStatusModal", false);
};

export const getAssignParentToStudentByStudentId = (payload) => (state)=> {
   return state.set('getAssignParentToStudentByStudentId',payload.data.responseObject)
  }

  export const notificationhandler = (payload) => (state) => {
       return state.set("notificationhandler", true)
   };