import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as appData from '../appSelector'
import * as modalsData from './selector'
import { assessmentModule } from '../assessmentModule/redux-assessmentModule/index'


class DeleteAssessmentModal extends Component {
    constructor(props) {
        super(props);
        this.deleteAssessment = this.deleteAssessment.bind(this);
        this.callDeleteAssessment = this.callDeleteAssessment.bind(this);
        this.closeDeleteAssessmentModal = this.closeDeleteAssessmentModal.bind(this);
        this.getViewAssessments = this.getViewAssessments.bind(this);
    }
    deleteAssessment() {
        this.callDeleteAssessment();
        this.props.actions.closeDeleteAssessmentModal();
        setTimeout(() => {
            this.getViewAssessments()
          }, 3000);
        
    }
    callDeleteAssessment() {
    this.props.actions.deleteAssessment({
        testId: this.props.getCurrentTestId,
        jwt:this.props.userData.jwt
    })
    }
    closeDeleteAssessmentModal(){
        this.props.actions.closeDeleteAssessmentModal()
    }
    getViewAssessments() {
        var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        this.props.actions.getViewAssessments({
          loginUserId: this.props.userData.id,
          jwt: this.props.userData.jwt,
          clientTimeZone: timeZone
        })
      }
    render() {
        return (
            <Modal show={this.props.show}
                backdrop="static"
                keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Confirm Delete Assessment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete the Assessment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.closeDeleteAssessmentModal}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={this.deleteAssessment}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getCurrentTestId: modalsData.getCurrentTestId(state)
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...modals.actions,
            ...assessmentModule.actions
        },
        d
    )
})
export default connect(s, d)(DeleteAssessmentModal);
