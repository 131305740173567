import React, { Component } from 'react';
import '../common/AddUsers.css';
import DeleteUserModal from "../modals/DeleteUserModal";

class DeleteUserLinkRender extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalDeleteType: false,
            show: false
        }
        this.openDeleteUserModal = this.openDeleteUserModal.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
    }

    closeDeleteModal() {
        this.setState({ show: false });
    }
    openDeleteUserModal() {
        this.setState({
            modalDeleteType: true,
            show: true
        });
    }

    render() {
        return (
            (this.state.modalDeleteType) ? (<div>
                <DeleteUserModal eachrowUserData={this.props.data} show={this.state.show} onClose={this.closeDeleteModal} modalDeleteType={this.state.modalDeleteType} />
                <button className="btn btn-danger text-white" onClick={this.openDeleteUserModal}>
                    {/* <i className="fa fa-times" aria-hidden="true" /> */}
                     Delete
                </button>
            </div>) : (
                    <button className="btn btn-danger text-white" onClick={this.openDeleteUserModal}>
                        {/* <i className="fa fa-times" aria-hidden="true" />  */}
                    Delete</button>
                )
        );
    }
}

export default DeleteUserLinkRender;
