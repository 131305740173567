import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import * as appData from '../../../../appSelector'
class ViewAssessmentLinksStudent extends Component {

  constructor(props) {
    super(props)
    this.openStartAssessment = this.openStartAssessment.bind(this);
    this.openQuestionnaire = this.openQuestionnaire.bind(this);
    this.openResult = this.openResult.bind(this);
  }

  openResult(){
    localStorage.setItem('eachTestId', this.props.viewEachAssessmentData.testId)
    localStorage.setItem('eachUserId',this.props.userData.id)
    localStorage.setItem('eachTestData', JSON.stringify(this.props.viewEachAssessmentData))
    window.open("/ViewAnswersStudent")
  }
  openStartAssessment(){
    const refreshToken = this.props.refreshToken
     const eachTestId = this.props.viewEachAssessmentData.testId
     localStorage.setItem('eachTestId',eachTestId)
     const eachTestData = this.props.viewEachAssessmentData
     localStorage.setItem('eachTestData',JSON.stringify(eachTestData))
     var width = window.screen.width;
     var height = window.screen.height;
     let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=0,top=0`;
     window.open("StartAssessment", "AssessmentWindow", params);
     const startAssessmentTime = new Date().getTime()
    window.localStorage.setItem('startAssessmentTime',startAssessmentTime/1000)
    
    window.localStorage.removeItem('endAssesment')

    this.props.setStartAssessment(true)
    new Promise(
      (resolve, reject) => {
        setTimeout(
          () => {
            resolve( refreshToken())
          },
          window.localStorage.getItem('startAssessmentTime') - window.localStorage.getItem('loginTime')
        )
      }
    )
  }
  openQuestionnaire() {
    const eachTestId = this.props.viewEachAssessmentData.testId
    localStorage.setItem('eachTestId', eachTestId)
    const eachTestData = this.props.viewEachAssessmentData
    localStorage.setItem('eachTestData', JSON.stringify(eachTestData))
    var width = window.screen.width;
    var height = window.screen.height;
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=0,top=0`;
    window.open("ViewQuestionnaireStudent", "StudentQuestionnaireWindow", params)
  }
  render() {
    var eachViewEachAssessmentData = this.props.viewEachAssessmentData;
    var testType = this.props.viewEachAssessmentData.testType;
    return (
      <React.Fragment>
        {
          (testType === "online") &&
          <div className="books-card-wrapper col-md-12">
            <div key={eachViewEachAssessmentData.testId} className="card">
              <div className="card-header">
                <b>Standard Name:&nbsp;{eachViewEachAssessmentData.standardName}</b>&nbsp;&nbsp;
            <b>Course Name:&nbsp;{eachViewEachAssessmentData.courseName}</b>&nbsp;&nbsp;
            </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <b>Assessment Name:&nbsp;{eachViewEachAssessmentData.testName}</b>
                  </div>
                  <div className="col-5">
                    <div>
                      <div className="mt-1">
                        <b>Assessments Details:</b>
                      </div>
                      <div className="mt-1">
                        Start Date: {eachViewEachAssessmentData.startDate}&nbsp;&nbsp;
                    End Date: {eachViewEachAssessmentData.endDate}&nbsp;&nbsp;
                    </div>
                      <div className="mt-1">
                        Total Questions: {eachViewEachAssessmentData.totalQuestions}
                      </div>
                      <div className="mt-1">
                        Assessment Duration: {eachViewEachAssessmentData.testDuration}
                      </div>
                      <div className="mt-1">
                        Total Points: {eachViewEachAssessmentData.totalMarks}
                      </div>
                      <div className="mt-1">
                        Qualification Points: {eachViewEachAssessmentData.qualificationMarks}
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <div>
                        <b>Course Details:</b>
                      </div>
                      <div className="mt-1">
                        Title: {eachViewEachAssessmentData.bookTitle}
                      </div>
                      <div className="mt-1">
                        Description: {eachViewEachAssessmentData.bookDescription}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 d-flex mt-2">
                  <ul>
                  {
                      (eachViewEachAssessmentData.enabledTest) &&
                      (
                        <li>
                          <button 
                            className="btn btn-primary mb-3 min-width" 
                            onClick={this.openStartAssessment}
                          >
                          Start Assessment 
                          </button>
                        </li> 
                      )
                    }
                    {
                      !eachViewEachAssessmentData.enabledTest && eachViewEachAssessmentData.showResult &&
                      <li>
                        <button className="btn btn-warning mb-3 min-width" onClick={this.openQuestionnaire}>
                      View Questionnaire </button>
                      </li>
                      }
                      {
                      eachViewEachAssessmentData.showResult &&
                      <li>
                      <button className="btn btn-warning mb-3 min-width" onClick={this.openResult}>
                      View Results </button>
                      </li>
                      }
                  </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

const s = ({ state }) => ({
userData : appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(ViewAssessmentLinksStudent);