
import { put, take, takeLatest } from 'redux-saga/effects' 

import {createAsyncAction} from '../../lib/utils'

import { adminConf } from './index'

export const getCoursesAndStandardData = createAsyncAction(
    `GET_COURSES_AND_STANDARD_DATA_ADMIN`,
    ({
        jwt, 
        orgIdAdmRteInCrses, 
        pathSeparator, 
        userIdAdmRteInCrses,
        updateCoursesAndStandards
    }) => ({
        jwt, 
        orgIdAdmRteInCrses, 
        pathSeparator, 
        userIdAdmRteInCrses,
        updateCoursesAndStandards
    })
)


export const getUpdatedStandards = createAsyncAction(
    `GET_UPDATED_STANDARDS_ADMIN`,
    ({
        jwt, userId, orgId, orgIdAdmRteInStdrds, userIdAdmRteInStdrds
    }) => ({
        jwt, userId, orgId, orgIdAdmRteInStdrds, userIdAdmRteInStdrds
    })
)

function *getCoursesAndStandardDataTask ({ payload }) {
    const {
        jwt, 
        orgIdAdmRteInCrses, 
        pathSeparator, 
        userIdAdmRteInCrses,
        updateCoursesAndStandards = false
    } = payload
    yield put(adminConf.actions.getStandardsUrlInCrses({
        jwt, 
        orgIdAdmRteInCrses, 
        pathSeparator, 
        userIdAdmRteInCrses
    }) 
    )
    const standardPayload = yield take(adminConf.actions.getStandardsUrlInCrses.success)

    yield put(adminConf.actions.getCoursesUrlInCrses({
        jwt, 
        orgIdAdmRteInCrses, 
        pathSeparator, 
        userIdAdmRteInCrses 
    }))

    const coursesPayload =  yield take(adminConf.actions.getCoursesUrlInCrses.success)

    if(updateCoursesAndStandards) {

        if(standardPayload.payload.data || coursesPayload.payload.data) {
             yield put(adminConf.actions.deleteCourseAlert(true))
        }
        else{
            yield put(adminConf.actions.deleteCourseFailureAlert(true))
        }

    }

}


function *getUpdatedStandardsTask ({ payload }) {

    const {
        jwt, 
        userId, 
        orgId,
        orgIdAdmRteInStdrds,
        userIdAdmRteInStdrds
    } = payload

    yield put(adminConf.actions.updateStandards({
        jwt, 
        userId, 
        orgId
    }))
    
    yield take(adminConf.actions.updateStandards.success)

    yield put(adminConf.actions.getStandards({
        orgIdAdmRteInStdrds,
        userIdAdmRteInStdrds,
        jwt
    }))

    yield take(adminConf.actions.getStandards.success)
    
}

// SAGA LISTENER
function * listener() {
    yield takeLatest(getCoursesAndStandardData,getCoursesAndStandardDataTask)
    yield takeLatest(getUpdatedStandards,getUpdatedStandardsTask)
}

export default {
    actions: {
        getCoursesAndStandardData,
        getUpdatedStandards
    },
    listener
}