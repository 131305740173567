import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import './viewQuestionnaireStudent.css'
import * as selectors from '../../../redux-assessmentModule/selector'
import { List } from 'immutable'
import TestHeaderView from '../../viewAssessment/teacher/TestHeaderView'
import { Navbar } from "react-bootstrap";
import durchereLogoText from '../../../../assets/logo/durchere_text_logo.png';
import MathJax from 'react-mathjax-preview'
import * as appData from '../../../../appSelector'
const generateInputBaseOnType = (inputType, props) => {
  const inputTypes = {
    1: props => {
      const { questionAnswerOption, answer } = props
      return (
        <div key={questionAnswerOption}>
          <label className="checkbox-inline"><input name={inputType} id={answer} value={answer} type="radio" /> {questionAnswerOption}</label>
        </div>
      )
    },
    0: props => <div><input type='text'></input></div>,
    2: props => {
      return (
        <div></div>
      )
    }
  }
  return inputTypes[inputType](props)
}
const InputGenerator = props => {
  const { inputType, ...restProps } = props
  return generateInputBaseOnType(inputType, restProps)
}
class viewQuestionnaireStudent extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    var globalTestId = localStorage.getItem('eachTestId');
    this.props.actions.getQuestions({
      jwt: this.props.userData.jwt,
      testId: globalTestId
    })
  }

  render() {
    var globalEachTestData = JSON.parse(localStorage.getItem('eachTestData'))
    return (
      <React.Fragment>
        <Navbar expand="lg" className="navbar navbar-expand-md navbar-dark fixed-top">
          <Navbar.Brand href="/">
            <img alt="durchere logo" src={durchereLogoText} className="durchere-logo" />
          </Navbar.Brand>
        </Navbar>
        <div className="container top-margin-window-view-questonnaire-paper">
          <TestHeaderView eachTestData={globalEachTestData} />
          <div className="view-questionnaire-paper-wrapper col-md-12">
            {
              this.props.getQuestions.get('passages', List()).map(
                passage => <div>
                  {passage.getIn(['testPassage', 'passageTitle'], '')}
                  <MathJax math={passage.getIn(['testPassage', 'passageText'], '')}/>
                  {
                    passage.get('passagesQuestions', List()).map(
                      (question, index) =>
                        <div className="question-wrapper">
                          <div className="d-flex">
                          {index+1}.<MathJax math={question.get('questionText', '')}/>
                          </div>
                          <div>
                            {question.get('marksForQuestion', '')}&nbsp;Points
                          </div>
                        </div>
                    )
                  }
                </div>
              )
            }
            {
              this.props.getQuestions.get('questions', List()).map(
                (question, index) =>
                  <React.Fragment>
                    <div className="question-wrapper">
                      <div className="d-flex">
                      {index+1}.<MathJax math={question.getIn(['testQuestion', 'questionText'], '')}/>
                      </div>
                      <div>
                        {question.getIn(['testQuestion', 'marksForQuestion'], '')}&nbsp;Points
                      </div>
                    </div>
                    {
                      question.get("testAnswerOptions", List()).map(
                        testAnswer =>
                          <InputGenerator
                            inputType={
                              question.getIn(['testQuestion', 'questionCategory'], '')
                            }
                            questionAnswerOption={
                              testAnswer.get('answerOption', '')
                            }
                            answer={
                              testAnswer.get('testAnswerId', '')
                            }
                          />
                      )
                    }
                  </React.Fragment>
              )
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const s = ({ state }) => ({
  getQuestions: selectors.getQuestions(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(viewQuestionnaireStudent);