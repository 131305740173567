import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appData from '.././appSelector'
import { modals } from './index'
import * as modalsData from './selector'
import SingleVideoPreview from '../videoStreaming/single-video-view/SingleVideoPreview';
//import { videoStreamingConf } from '.././videoStreaming/redux-videoStreaming/index';

class VideoPlayerModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div>
        <Modal show={this.props.show} centered>
          <Modal.Header>
            <Modal.Title>
              {this.props.eachBookResourceByBookId.bookResourceName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SingleVideoPreview eachBookResourceByBookId={this.props.eachBookResourceByBookId} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose} >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  showCoursesModal: modalsData.openDeleteBookModal(state),
  bookModalData: modalsData.openDeleteBookModalBookData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions,
    },
    d
  )
})
export default connect(s, d)(VideoPlayerModal);