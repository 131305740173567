import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appData from '.././appSelector'
import { modals } from './index'
import * as modalsData from './selector'
import Select from 'react-select'

class OrderStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orderStatus: '',
      trackingNumber: '',
      postalService: ''
    }
    this.updateOrderStatus = this.updateOrderStatus.bind(this);
    this.callUpdateOrderStatus = this.callUpdateOrderStatus.bind(this);
    this.handleOrderstatus = this.handleOrderstatus.bind(this);
    this.handleCourierProvider = this.handleCourierProvider.bind(this);
    this.handleTrackingNumber = this.handleTrackingNumber.bind(this);

  }
  callUpdateOrderStatus() {
    this.updateOrderStatus();
    this.props.onClose();
  }
  handleOrderstatus = event => {
    let orderStatusValue = event.target.value;
    this.setState({
      orderStatus: orderStatusValue,
    })
  }

  handleCourierProvider = event => {
    let handleCourierProviderValue = event.target.value;
    this.setState({
      postalService: handleCourierProviderValue,
    })
  }
  handleTrackingNumber = event => {
    let trackingNumber = event.target.value;
    this.setState({
      trackingNumber: trackingNumber,
    })
  }
  componentDidMount() {
    this.props.actions.getOrderStatusConstants({
      jwt: this.props.userData.jwt,
    })

  }
  componentDidUpdate(prevProps) {
    if((prevProps.currentOrderStatus !== this.props.currentOrderStatus) 
    && (this.props.currentOrderStatus === "Shipment in Process")){
      this.props.actions.getCourierServiceProviders({
        jwt: this.props.userData.jwt,
      })
    }
  }
  updateOrderStatus() {
    var updateStatus = {
      "orderId": this.props.orderId,
      "orderStatus": this.state.enteredQuantity,
      "trackingNumber": this.state.trackingNumber,
      "postalService": this.state.postalService
    }
    this.props.actions.saveUpdateOrderStatus({
      updateStatusData: updateStatus,
      jwt: this.props.userData.jwt
    })
  }
  render() {
    
    const {
      actions,
      orderStatusCurrentIndex,
      currentOrderStatus,
      postalServiceStatus
    } = this.props
    return (
      <div>
        <Modal show={this.props.show} centered>
          <Modal.Header>
            <Modal.Title>Update Order Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <label>Order Status: </label>
                </div>
                <div className="col-md-8">
                  <div>
                    <Select
                      value={
                        this.props.orderStatusConstantsOptions.find(
                          (option) => option.label === currentOrderStatus
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        actions.setOrderStatus({
                          selectedOption,
                          orderStatusCurrentIndex,
                        })
                      }
                      options={this.props.orderStatusConstantsOptions}
                    />
                  </div>
                </div>
              </div>
              {currentOrderStatus === "Shipment in Process" && (
                <div className="mt-2 row">
                  <div className="col-md-4">
                    <label>Courier Service Provider: </label>
                  </div>
                  <div className="col-md-8">
                    <div>
                      <Select
                        value={
                          this.props.courierServiceProvidersOptions.find(
                            (option) => option.label === postalServiceStatus
                          ) || null
                        }
                        onChange={(selectedOption) =>
                          actions.setPostalService({
                            selectedOption,
                            orderStatusCurrentIndex,
                          })
                        }
                        options={this.props.courierServiceProvidersOptions}
                      />
                    </div>
                  </div>
                </div>
              )}

              {currentOrderStatus === "Shipment in Process" && (
                <div className="mt-2 row">
                  <div className="col-md-4">
                    <label>Tracking Number: </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      required
                      onChange={this.handleTrackingNumber}
                      placeholder="Enter Tracking Number"
                      className="css-yk16xz-control"
                    />
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.callUpdateOrderStatus}>
              Save
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  orderId: modalsData.getOrderIdUpdateStatus(state),
  orderStatusConstantsOptions: modalsData.getOrderStatusConstants(state),
  courierServiceProvidersOptions: modalsData.getCourierServiceProviders(state),
  orderStatusCurrentIndex: modalsData.getOrderStatusCurrentIndex(state),
  currentOrderStatus: modalsData.getCurrentOrderStatus(state),
  postalServiceStatus: modalsData.getPostalServiceStatus(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions
    },
    d
  )
})
export default connect(s, d)(OrderStatus);