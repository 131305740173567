import { List, Map } from 'immutable'
export const getBookByOrgUser = state => state.get('booksDataOrgUser', '')
export const openAddBookModal = state => state.get('openAddBookModal', false)
// export const openDeleteBookModal = state => state.get('openDeleteBookModal', false)
export const getStandards = state => {
    return state.getIn(
        ['standards', 'data','responseObject','standardDetails'],
        List()
    )

}

export const getUserId = state => state.getIn(['standards', 'data','responseObject','userId'],'')


export const getOrgId = state => state.getIn(['standards', 'data','responseObject','orgId'],'')

export const getChangedStandards = state => state.get('changedStandards',List())

export const savedStandards = state => state.get('savedStandards',List())

export const successMsg = state => state.get('successMsg','')

export const successAlert = state => state.get('successAlert', false)



export const getErrorAlert = state => state.get('errorAlert',false)

export const getErrorMessage = state => state.get('errorMsg','')

export const isDeletedModalShow = state => state.get('isDeletedModalShow', false)

export const getCurrentDeletedStandard = state => state.get('currentDeletedModalStandard',{})



export const getStatndars = state => state.getIn(
    ['standardUrl','responseObject','standardDetails'],
    List()
)

export const getStandardUrlUserId = state => state.getIn(
    ['standardUrl','responseObject','userId'],
    ''
)

export const getStandardUrlOrgId = state => state.getIn([
    'standardUrl','responseObject','orgId'  
],'')

export const getCourses = state => state.getIn(
    ['courseUrl','responseObject','coursesData'], []
)

export const getSuccessMsgCourses = state => state.get(
    'successMsgCourses',''
)

export const getSuccessAlertCourses = state => state.get('successAlertCourses',false)

export const getErrorAlertCourses = state => state.get('errorAlertCourses',false)

export const getErrorMsgCourses = state => state.get('errorMsgCourses','')

export const openDeleteBookModal = state => state.get('openDeleteBookModalCourses', false)

export const getDeleteCourseAlert = state => state.get('deleteCourseAlert',false)



export const currentDeletedCourse = state => {

    const currentStandardIndex = state.get("currentStandardIndex", 0);
    const currentCourseIndex = state.get("currentCourseIndex", 0);
    const path = [
      "standardUrl",
      "responseObject",
      "standardDetails",
      currentStandardIndex,
    ];
    
    return state.getIn(
      [...path, "courseNames", currentCourseIndex],
      Map()
    );
  
  }

  export const deleteStandardAlert = state => {
      return state.get('deleteStandardAlert', false)
  }

  export const deleteCourseAlert =  state => {
    return state.get('deleteCourseAlertBox', false)
  }

  export const deleteCourseFailureAlertBox = state => {
    return state.get('deleteCourseFailureAlert',false)
}


export const deleteStandardFailureAlertBox = state => {
    return state.get('deleteStandardAlertFailure', false)
}

export const getOrganizationDetails = state => {
    return state.get('organizationData', '')
}

