import axios from 'axios'
import APICONF from '../../config/ApiConfig';

export const getSudentTimeEntrys = (action) => async (
    { dispatch },
    { bookId, groupId, userId, weekNumber, attendedYear, jwt}
  ) => {
    const getSudentTimeEntrys = APICONF.GET_STUDENT_TIME_ENTRYS+"/"+ bookId+"/" + userId + "/" + groupId+"/"+weekNumber+"/"+attendedYear;
    const [err, result] = await axios
      .get(getSudentTimeEntrys, {
        headers: {
          "authorization": jwt
        }
      })
      .then(res => [null, res.data])
      .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
  }

  export const saveStudentTimeEntrys = (action) => async (
    { dispatch },
    { studentTimeTablesData, jwt }
  ) => {
    const studentTimeTablesUrl = APICONF.SAVE_STUDENT_TIME_ENTRYS
    const [err, result] = await axios({
      method: 'post', //you can set what request you want to be
      url: studentTimeTablesUrl,
      data: studentTimeTablesData,
      headers: {
        "authorization": jwt
      }
    })
      .then(res => [null, res.data])
      .catch(err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
  }