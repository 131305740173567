import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as videoStreamingData from './redux-videoStreaming/selectors'
import * as appData from '../appSelector';
import './BookResoursesManagement.css';
import { videoStreamingConf } from './redux-videoStreaming/index';
import { Link } from 'react-router-dom';

class ViewBookResources extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.getIbookUrl = this.getIbookUrl.bind(this);
    }

    componentDidMount() {
        this.props.actions.getBookResourceByBookId({
            bookId: this.props.location.state.bookId,
            jwt: this.props.userData.jwt,
            dropdownValue: false
        })
    }
    getIbookUrl(bookResourceId){
        console.log("bookResourceId:::",bookResourceId)
        this.props.actions.getVideosByBookResourceId({
            bookResourceId: bookResourceId,
            jwt: this.props.userData.jwt
        })
    }

    render() {
        return (
            <div className='container'>
                <h1 className='mt-3'>Resources</h1>
                <hr />
                {this.props.getAllBookResourceByBookId.map((eachBookResourceByBookId, i) => (
                    <div key={eachBookResourceByBookId.bookResourceId} className="book-card">
                        {eachBookResourceByBookId.bookResourceType === 'video' &&
                            (
                                <div>
                                    <Link to={{ pathname: '/single-video-view', state: { eachBookResourceByBookId: eachBookResourceByBookId } }} className="book-Card-link">
                                        <div className="book-card-image-holder">
                                            <div style={{ backgroundImage: `url(${eachBookResourceByBookId.imageUrl})` }} className="book-card-image" />
                                        </div>
                                        <div className="book-card-name">{eachBookResourceByBookId.bookResourceName}
                                        </div>
                                    </Link>

                                </div>
                            )}
                        {eachBookResourceByBookId.bookResourceType === 'course'
                            &&
                            (
                                <div>
                                    <Link to={{ pathname: '/course-video-view', state: { eachBookResourceByBookId: eachBookResourceByBookId } }} className="book-Card-link">
                                        <div className="book-card-image-holder">
                                            <div style={{ backgroundImage: `url(${eachBookResourceByBookId.imageUrl})` }} className="book-card-image" />
                                        </div>
                                        <div className="book-card-name">{eachBookResourceByBookId.bookResourceName}
                                        </div>
                                    </Link>
                                </div>
                            )
                        }
                        {eachBookResourceByBookId.bookResourceType === 'ibook' && (
                            <div onClick={() => this.getIbookUrl(eachBookResourceByBookId.bookResourceId)}>
                                <div className="book-card-image-holder">
                                    <div style={{ backgroundImage: `url(${eachBookResourceByBookId.imageUrl})` }} className="book-card-image" />
                                </div>
                                <div className="book-card-name">{eachBookResourceByBookId.bookResourceName}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getAllBookResourceByBookId: videoStreamingData.getBookResourceByBookId(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(ViewBookResources);
