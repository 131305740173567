import React, { Component } from 'react'
import PublicFooter from "../layout/PublicFooter";
import AlertBox from '../common/AlertBox';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as selectors from './selectors'
import { loginn } from './index'

import './ForgotPassword.css'
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUsername: true,
      showOTP: false,
      showChangePassword: false,
      username: '',
      otp: '',
      password: '',
      confirmPassword: '',
      errorStatus: false,
      errorMessage: '',
      successStatus: false,
      successMessage: ''
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      ...nextProps.forgotPassword
    }
  }

  onUsernameSubmit = (e) => {
    const { username } = this.state;
    e.preventDefault();
    this.props.actions.usernameSubmit(username);
    // fetch(`${APICONF.FORGOT_PASSWORD}/${username}`).then(response => response.json())
    //   .then(data => {
    //     if (data.status === 'otp email sent') {
    //       this.setState({
    //         showUsername: false,
    //         showOTP: true,
    //         errorStatus: false,
    //       })
    //     } else {
    //       this.setState({
    //         errorStatus: true,
    //         errorMessage: 'Please check the username entered'
    //       })
    //     }
    //   }).catch(error => {
    //     this.setState({
    //       errorStatus: true,
    //       errorMessage: 'Something went wrong..! Please try after some time'
    //     })
    //   })
  }

  onOTPSubmit = (e) => {
    const { username, otp } = this.state;
    e.preventDefault();
    this.props.actions.otpSubmit({ username, otp });
    // fetch(`${APICONF.VALIDATE_OTP}/${username}/${encodeURIComponent(otp)}`).then(response => response.text())
    //   .then(data => {
    //     if (data === 'Valid otp') {
    //       this.setState({
    //         errorStatus: false,
    //         showOTP: false,
    //         showChangePassword: true
    //       })
    //     } else {
    //       this.setState({
    //         errorStatus: true,
    //         errorMessage: 'Invalid OTP. Please check the OTP entered'
    //       })
    //     }
    //   }).catch(error => {
    //     this.setState({
    //       errorStatus: true,
    //       errorMessage: 'Invalid OTP. Please check the OTP entered'
    //     })
    //   })
  }

  onChangePasswordSubmit = (e) => {
    e.preventDefault();
    const { username, otp, password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      this.setState({
        errorStatus: true,
        errorMessage: 'password and confirm password must be same.'
      });
      return;
    }
    this.props.actions.changePasswordSubmit({
      username,
      otp,
      password,
      history: this.props.history
    });
    // fetch(`${APICONF.UPDATE_PASSWORD}`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     username,
    //     otp,
    //     password
    //   })
    // }).then(response => response.json())
    //   .then(data => {
    //     if (data.status === 'Password updated successfully') {
    //       this.setState({
    //         errorStatus: true,
    //         successStatus: true,
    //         successMessage: 'Password updated successfully'
    //       });
    //       setTimeout(() => {
    //         this.props.history.push('/login');
    //       }, 1500);
    //     }
    //   }).catch(error => {
    //     this.setState({
    //       errorStatus: true,
    //       errorMessage: 'Something went wrong..! Please try after some time'
    //     })
    //   })
  }
  render() {
    const { showUsername, showOTP, showChangePassword, errorStatus, errorMessage } = this.state;
    // const { showUsername, showOTP, showChangePassword, errorStatus, errorMessage } = this.props.forgotPassword;
    return (
      <div className='forgot-password-page'>
        {
          showUsername && <form onSubmit={this.onUsernameSubmit}>
            <div className="position-forgot-page">
              <AlertBox errorAlert={errorStatus} errorMsg={errorMessage} />
              <h4 className="h4FontColor-signup">Forgot Password</h4>
              <input id="username" name="username" className="transparentInputs-signup" type="text" placeholder="Enter User Name" required onChange={this.handleInputChange} /><br />
              <button className="btn" type="submit">Find Account</button><br />
            </div>
          </form>
        }
        {
          showOTP && <form onSubmit={this.onOTPSubmit}>
            <div className="position-forgot-page">
              <AlertBox errorAlert={errorStatus} errorMsg={errorMessage} />
              <h4 className="h4FontColor-signup">OTP</h4>
              <input id="otp" name="otp" className="transparentInputs-signup" type="text" placeholder="Enter OTP" required onChange={this.handleInputChange} /><br />
              <button className="btn" type="submit">Submit</button><br />
            </div>
          </form>
        }
        {
          showChangePassword && <form onSubmit={this.onChangePasswordSubmit}>
            <div className="position-forgot-page">
              <AlertBox errorAlert={errorStatus} errorMsg={errorMessage} />
              <h4 className="h4FontColor-signup">Update Password</h4>
              <input id="password" name="password" className="transparentInputs-signup" type="password" placeholder="New Password" onChange={this.handleInputChange} /><br />
              <input id="confirmPassword" name="confirmPassword" className="transparentInputs-signup" type="password" placeholder="Confirm Password" required onChange={this.handleInputChange} /><br />
              <button className="btn" type="submit">Submit</button><br />
            </div>
          </form>
        }
        <PublicFooter />
      </div>
    )
  }
}

const s = ({ state }) => ({
  forgotPassword: selectors.getForgotPassword(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions
    },
    d
  )
})

export default connect(s, d)(ForgotPassword)
