import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './Loader.css';
class Loader extends Component {
  render() {
    return (
      <Modal show={this.props.show} contentClassName  ="spinner-back">
        <Modal.Body className ="bdack">
          <div className={"lds-ring"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default Loader;