import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import TeacherHome from "../teacher/TeacherHome.js";
import TeacherHeader from ".././layout/teacher/TeacherHeader";
import PublicFooter from "../layout/PublicFooter";
import AddUsers from "../../src/common/AddUsers.js";
import CreateAssessment from "./../assessmentModule/assessmentPages/createAssessment/CreateAssessment.js";
import ViewAssessments from "./../assessmentModule/assessmentPages/viewAssessment/teacher/ViewAssessments.js";
import Questionnaire from "../assessmentModule/assessmentPages/questionnaire/Questionnaire.js";
import ViewQuestionnaire from "../assessmentModule/assessmentPages/questionnaire/teacher/ViewQuestionnaire";
import ViewQuestionnairePaper from "../assessmentModule/assessmentPages/questionnaire/teacher/ViewQuestionnairePaper";
import ViewAssessmentsCourseTIO from "./../assessmentModule/assessmentPages/viewAssessment/teacher/ViewAssessmentsCourseTIO.js";
import ViewAllResultsPage from "./../assessmentModule/assessmentPages/viewAssessment/teacher/ViewAllResultsPage";
import ViewResultsByUser from "./../assessmentModule/assessmentPages/viewAssessment/common/ViewResultsByUser";
import NotFoundPage from "../common/404";
import OrdersPage from "../../src/order/OrdersPage.js";
import CartSummary from "../../src/order/cartSummary/CartSummary";
import Checkout from "../../src/order/cartSummary/Checkout";
import OrderHistory from "../order/orderHistory/OrderHistory";
import Payment from "../../src/order/cartSummary/payment";
import ProfileHome from "../profile/ProfileHome.js";
import Calendar from "../calendar.js";
import SingleVideoView from '../videoStreaming/single-video-view/SingleVideoView.js';
import CourseVideoMainView from '../videoStreaming/course-video-view/CourseVideoMainView.js';
import ViewBookResources from '../videoStreaming/ViewBookResources';

import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import PublicBooksPage from "../publicview/PublicBooksPage.js";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import PublicOrders from "../order/PublicOrders.js";
import AttendanceHome from "../attendance/AttendanceHome.js";

export default class TeacherRouter extends Component {
  render() {
    var path =
      window.location.pathname.indexOf("/Payment") === -1
        ? window.location.pathname
        : "/Payment";
    let noHeaderFooterRoutes = [
      "/ViewQuestionnaire",
      "/ViewQuestionnairePaper",
      "ViewQuestionnaireOnline",
      "/Payment",
    ];
    return (
      <ConnectedRouter history={createBrowserHistory()}>
        <div>
          <div className="page-body">
            <div className="page-content-teacherinstorg">
              {!noHeaderFooterRoutes.includes(path) && (
                <TeacherHeader> </TeacherHeader>
                )}
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/TeacherHome" />
                    </Route>
                    <Route exact path="/Login">
                      <Redirect to="/TeacherHome" />
                    </Route>
                    <Route
                      exact
                      path="/TeacherHome"
                      render={(props) => <TeacherHome {...props} />}
                    />
                    <Route
                      exact
                      path="/AddUsers"
                      render={(props) => <AddUsers {...props} />}
                    />
                    <Route
                      exact
                      path="/CreateAssessment"
                      render={(props) => <CreateAssessment {...props} />}
                    />
                    <Route
                      exact
                      path="/ViewAssessments"
                      render={(props) => <ViewAssessments {...props} />}
                    />
                    <Route
                      exact
                      path="/Questionnaire"
                      render={(props) => <Questionnaire {...props} />}
                    />
                    <Route
                      exact
                      path="/ViewQuestionnaire"
                      render={(props) => <ViewQuestionnaire {...props} />}
                    />
                    <Route
                      exact
                      path="/ViewQuestionnaireOnline"
                      render={(props) => <ViewQuestionnaire {...props} />}
                    />
                    <Route
                      exact
                      path="/ViewQuestionnairePaper"
                      render={(props) => <ViewQuestionnairePaper {...props} />}
                    />
                    <Route
                      exact
                      path="/ViewAssessmentsCourseTIO"
                      render={(props) => (
                        <ViewAssessmentsCourseTIO {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/ViewAllResults"
                      render={(props) => <ViewAllResultsPage {...props} />}
                    />
                    <Route
                      exact
                      path="/ViewAnswers"
                      render={(props) => <ViewResultsByUser {...props} />}
                    />
                    <Route
                      exact
                      path="/Orders"
                      render={(props) => <OrdersPage {...props} />}
                    />
                    <Route
                      exact
                      path="/CartSummary"
                      render={(props) => <CartSummary {...props} />}
                    />
                    <Route
                      exact
                      path="/Checkout"
                      render={(props) => <Checkout {...props} />}
                    />
                    <Route
                      exact
                      path="/OrderHistory"
                      render={(props) => <OrderHistory {...props} />}
                    />
                    <Route
                      exact
                      path="/Payment/:id"
                      render={(props) => <Payment {...props} />}
                    />

                    <Route
                      exact
                      path="/profile"
                      render={(props) => (
                        <ProfileHome onlyPersonalInfo={true} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/calendar"
                      render={(props) => <Calendar {...props} />}
                    />
                    <Route
                      exact
                      path="/view-resources"
                      render={(props) => <ViewBookResources {...props} />}
                    />
                    <Route
                      exact
                      path="/single-video-view"
                      render={(props) => <SingleVideoView {...props} />}
                    />
                    <Route
                      exact
                      path="/course-video-view"
                      render={(props) => <CourseVideoMainView {...props} />}
                    />
                    <Route exact path="/attendance" render={(props) => (<AttendanceHome {...props} />)}  />

                    {/* Footer Links  */}
                    <Route exact path="/Partners" component={PartnersPage} />
                    <Route
                      exact
                      path="/PublicBooks"
                      component={PublicBooksPage}
                    />
                    <Route
                      exact
                      path="/PublicOrders"
                      render={(props) => <PublicOrders {...props} />}
                    />
                    <Route eaxct path="/AboutUs" component={AboutUsPage} />
                    <Route exact path="/Careers" component={CareersPage} />
                    <Route exact path="/ContactUs" component={ContactUsPage} />
                    <Route component={NotFoundPage} />
                  </Switch>
            </div>
          </div>
          {!noHeaderFooterRoutes.includes(path) && <PublicFooter />}
        </div>
      </ConnectedRouter>
    );
  }
}
