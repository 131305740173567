import React, { Component} from 'react';
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { teacherinstorg } from './index'
import * as appData from '../appSelector'
class TeacherPanelLinksInstOrg extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.openAttendance = this.openAttendance.bind(this);
  }
  
  openAttendance(bookId) {
    console.log("bookId",bookId)
    localStorage.setItem('selectedBookIdRoster', bookId)
    window.open("/attendance")
  }
  render() {
    return (
      <div>
      <div className="books-card-wrapper col-md-12">
        <div className="card" key={this.props.teacherAssocBookData.bookId}>
          <div className="card-header">
          <b>Standard Name:&nbsp;</b>{this.props.teacherAssocBookData.standardName}&nbsp;&nbsp;
          <b>Course Name:&nbsp;</b>{this.props.teacherAssocBookData.courseName}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <div>
                  <div>
                    <b>Course Details:</b>
                  </div>
                  <div className="mt-1">
                  Title: {this.props.teacherAssocBookData.bookTitle}
                  </div>
                  <div className="mt-1">
                  Description: {this.props.teacherAssocBookData.bookDescription}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <ul>
                  <li>
                    <Link className="btn btn-success mb-3" to={{ pathname: '/CreateAssessment', state: { eachBookId: this.props.teacherAssocBookData.bookId} }}>Create Assessment</Link>
                  </li>
                  <li>
                    <Link className="btn btn-warning mb-3" to={{ pathname: '/ViewAssessmentsCourseTIO', state: { eachBookId: this.props.teacherAssocBookData.bookId} }}>View Assessments</Link>
                  </li>
                  <li>
                    <Link className="btn btn-dark" onClick={() =>this.openAttendance(this.props.teacherAssocBookData.bookId)}>Attendance Roster</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  getUserData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...teacherinstorg.actions
    },
    d
  )
})
export default connect(s,d)(TeacherPanelLinksInstOrg);
