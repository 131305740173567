import React, { Component } from 'react';
import './Courses.css';
import CoursesInstOrgBox from './CoursesInstOrgBox.js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as instOrgAdminconfData from '../../redux-instOrgAdminconf/selector'
import DeleteBookModal from '../../../modals/DeleteBookModal.js'
import { instOrgAdminConf } from '../../redux-instOrgAdminconf/index'
import WithPagination from '../../../common/WithPagination';

class CoursesPageInstOrg extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bookData: [],
      booksPerPage: 3,
      currentPage: 1,
      filteredBookData: [],
      searchText: ''
    }
    this.closeDeleteModalMain = this.closeDeleteModalMain.bind(this);
    this.myRef = React.createRef();
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.bookData && props.bookData !== state.bookData) {
  //     return {
  //       bookData: props.bookData,
  //       filteredBookData: props.bookData.responseObject.booksData
  //     }
  //   }
  // }

  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }

  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  filterBooks = (books, searchText) => {

  }

  componentDidUpdate(){
    document.body.scrollTo(0,0);
  }

  closeDeleteModalMain() {
    this.props.actions.closeDeleteBookModal();
  }

  render() {
    const { currentPage, booksPerPage, searchText } = this.state;
    const indexOfLastBook = currentPage * booksPerPage;
    const indexOfFirstBook = indexOfLastBook - booksPerPage;

    let currentBooks = this.props.bookData ? this.props.bookData.responseObject.booksData : [];

    if(currentBooks && searchText){
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentBooks = currentBooks.filter(book => {
        let bookString = '';
        for(let key in book) {
          if(book.hasOwnProperty(key) && book[key] && book[key] !== '') {
            bookString += book[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return bookString.match(searchTermRegex);
      })
    }

    var imageFromBucket = this.props.bookData
      && this.props.bookData.responseObject.imageBucketEnable;
    var showBooks = this.props.bookData
      && currentBooks.slice(indexOfFirstBook, indexOfLastBook).map((bookData, i) => {
        var imageUrl = '';
        if (imageFromBucket) {
          imageUrl = bookData.imageBucketLocationChanged
        }
        else {
          imageUrl = bookData.imageappLocationChanged
        }
        return (
          <CoursesInstOrgBox key={bookData.bookId} bookData={bookData} imageUrl={imageUrl} />
        );
      })
    return (
      <>
        <span ref={this.myRef}></span>
        {
          (this.props.openDeleteBookModal) && (
            <DeleteBookModal onClose={this.closeDeleteModalMain}
              show={this.props.openDeleteBookModal} />
          )
        }
        <WithPagination
          itemsPerPage={booksPerPage}
          totalItems={currentBooks.length}
          currentPage={currentPage}
          onSearch={this.onSearch}
          onPaginate={this.onPaginate}
        >
          {showBooks}
        </WithPagination>
      </>
    );

  }
}
const s = ({ state }) => ({
  bookData: instOrgAdminconfData.getBookByOrgUser(state),
  openDeleteBookModal: instOrgAdminconfData.openDeleteBookModal(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...instOrgAdminConf.actions

    },
    d
  )
})
export default connect(s, d)(CoursesPageInstOrg);