import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import './ViewResultsByUser.css'
import * as selectors from '../../../redux-assessmentModule/selector'
import { List } from 'immutable'
import TestHeaderView from '../../viewAssessment/teacher/TestHeaderView'
import MathJax from 'react-mathjax-preview'
import * as appData from '../../../../appSelector'
const generateInputBaseOnType = (inputType, props) => {
  const inputTypes = {
    1: props => {
      const { question, answer, testQuestionnaireId, getCurrentAnswer} = props
      return (
        <div className="options-wrapper" key={question}>
          <label className="checkbox-inline"><input name={inputType} id={answer} value={answer} checked={getCurrentAnswer(testQuestionnaireId) === question} type="radio" /> {question}</label>
        </div>
      )
    },
    0: props => {
      const { testQuestionnaireId, getCurrentAnswer} = props
      return (
      <div className="options-wrapper">
      <input type='text'  value={getCurrentAnswer(testQuestionnaireId)} placeholder='Answer'></input></div>
      )
    }
  }
  return inputTypes[inputType](props)
}
const InputGenerator = props => {
  const { inputType, ...restProps } = props
  return generateInputBaseOnType(inputType, restProps)
}
class ViewResultsByUser extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    var globalTestId = localStorage.getItem('eachTestId');
    var eachUserId = localStorage.getItem('eachUserId');
    this.props.actions.getResultsByuser({
      loginUserId: eachUserId,
      jwt: this.props.userData.jwt,
      eachTestId: globalTestId
    })
  }

  render() {
    const passageIndex = this.props.getPassageQuestionIndex
    const questionsIndex = this.props.getQuestionsIndex
    var globalEachTestData;
    if (this.props.location.state) {
      const { eachTestData } = this.props.location.state
      globalEachTestData = eachTestData;
    }
    if (!globalEachTestData) {
      globalEachTestData = JSON.parse(localStorage.getItem('eachTestData'))
    }
    let scoredPoints = this.props.getQuestions.getIn(['result','testResult'],'')
    let qualifiedStatus = this.props.getQuestions.getIn(['result','qualifiedStatus'],'')
    return (
      <>
        <div className="container top-margin-window-view-answers-online" >
          <TestHeaderView forResults={true} scoredPoints={scoredPoints} qualifiedStatus={qualifiedStatus} eachTestData={globalEachTestData} />
          <div className="view-answers-wrapper col-md-12">
            {
              (!this.props.showQuestionsRelatedToParaGraph) && (!this.props.showregularQuestions) && (
                <div>
                  <div>
                    <b>Passage Title :</b>{this.props.getQuestions.getIn(['passages', passageIndex, 'testPassage', 'passageTitle'], '')}
                  </div>
                  <div className="top-margin p-3">
                  <MathJax math={this.props.getQuestions.getIn(['passages', passageIndex, 'testPassage', 'passageText'], '')} />
                  </div>
                  <div className="top-margin">
                    <button
                      className="col-md-12 no-btn-padding btn btn-success"
                      type="button"
                      onClick={this.props.actions.showQuestionsForParaGraphResultsByuser}
                    >
                      Next
                </button>
                  </div>
                </div>
              )
            }
            {
              (this.props.showQuestionsRelatedToParaGraph) && (!this.props.showregularQuestions) && (
                <React.Fragment>
                  <div className="question-wrapper">
                  <MathJax math={this.props.getQuestions
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionIndex,
                              'testQuestion',
                              'questionText'
                            ], '')} />
                  <div>
                    <b>Correct Answer: </b>&nbsp;
                  {
                        this.props.getQuestions
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionIndex,
                              'correctOption'], '')
                      }
                  </div>
                    <div>
                      {
                        this.props.getQuestions
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionIndex,
                              'testQuestion',
                              'marksForQuestion'
                            ], '')
                      }&nbsp;Points
                  </div>
                  </div>

                  <div className="p-3">
                    {this.props.getQuestions
                      .getIn(
                        [
                          'passages',
                          passageIndex,
                          'passagesQuestions',
                          this.props.getCurrentQuestionIndex,
                          'testAnswerOptions'
                        ], List()
                      ).map(currentAnswer =>
                        <InputGenerator
                          inputType={
                            this.props.getQuestions
                              .getIn(
                                [
                                  'passages',
                                  passageIndex,
                                  'passagesQuestions',
                                  this.props.getCurrentQuestionIndex,
                                  'testQuestion',
                                  'questionCategory'
                                ], '')
                          }

                          question={
                            currentAnswer.get('answerOption', '')
                          }
                          answer={
                            currentAnswer.get('testAnswerId', '')
                          }
                          testQuestionnaireId={
                            currentAnswer.get('testQuestionnaireId', '')
                          }
                          getCurrentAnswer={
                            this.props.getCurrentAnswer
                          }
                        />)
                    }

                    <div className="row d-flex justify-content-between">
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => this.props.actions.currentQuestionIndexResultsByuser(
                          {
                            currentIndex: this.props.getCurrentQuestionIndex,
                            isDecrease: true
                          }
                        )}
                      >
                        Prev question
                </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => this.props.actions.currentQuestionIndexResultsByuser(
                          {
                            currentIndex: this.props.getCurrentQuestionIndex,
                            isDecrease: false
                          }
                        )}

                      >
                        Next question
                </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            }

            {this.props.showregularQuestions &&
              (
                <React.Fragment>
                  <div className="question-wrapper">
                  <MathJax math={this.props.getQuestions.getIn(['questions', questionsIndex, 'testQuestion', 'questionText'], '')} />
                  <div>
                    <b>Correct Answer: </b>&nbsp;
                  {
                    this.props.getQuestions.getIn(['questions', questionsIndex, 'correctOption'], '')
                      }
                  </div>
                    <div>
                      {
                        this.props.getQuestions.getIn(['questions', questionsIndex, 'testQuestion', 'marksForQuestion'], '')
                      }&nbsp;Points
                    </div>
                  </div>

                  <div className="p-3">
                    {
                      this.props.getQuestions.getIn(
                        [
                          'questions',
                          questionsIndex,
                          'testAnswerOptions'
                        ], List()
                      ).map(
                        (currentQuestion, index) =>
                          <InputGenerator
                            inputType={
                              this.props.getQuestions
                                .getIn(
                                  [
                                    'questions', questionsIndex, 'testQuestion', 'questionCategory'
                                  ], '')
                            }
                            question={
                              currentQuestion.get('answerOption', '')
                            }
                            answer={
                              currentQuestion.get('testAnswerId', '')
                            }
                            index={index}

                            testQuestionnaireId={
                              currentQuestion.get('testQuestionnaireId', '')
                            }
                            getCurrentAnswer={
                              this.props.getCurrentAnswer
                            }

                          />)
                    }
                    <div className="row d-flex justify-content-between">
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => this.props.actions.questionsIndexResultsByuser(
                          {
                            regularQuestionIndex: this.props.getQuestionsIndex - 1
                          }

                        )}
                      >
                        Prev question
                </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => this.props.actions.questionsIndexResultsByuser(
                          {
                            regularQuestionIndex: this.props.getQuestionsIndex + 1
                          }
                        )}
                      >
                        Next question
                </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

const s = ({ state }) => ({
  getQuestions: selectors.questionsOfResultsByuser(state),
  getPassageQuestionIndex: selectors.passageIndexOfResultsByuser(state),
  getCurrentQuestionIndex: selectors.currentQuestionResultsByuserIndex(state),
  showQuestionsRelatedToParaGraph: selectors.showQuestionsForParaGraphResultsByuser(state),
  getQuestionsIndex: selectors.questionsResultsByuserIndex(state),
  showregularQuestions: selectors.showregularQuestionsResultsByuser(state),
  getCurrentAnswer: selectors.getCurrentAnswer(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(ViewResultsByUser);