import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as videoStreamingData from '../redux-videoStreaming/selectors'
import * as appData from '../../appSelector';
import '../BookResoursesManagement.css';
import { videoStreamingConf } from '../redux-videoStreaming/index';
import YourLearningList from '../YourLearningList';
import { Link } from 'react-router-dom'
import SingleVideoCard from './SingleVideoCard';
import VideoPlayerModal from '../../modals/VideoPlayerModal';

class SingleVideoView extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        this.closeSingleVideoPlayer = this.closeSingleVideoPlayer.bind(this);
    }
    closeSingleVideoPlayer() {
        this.props.actions.closeSingleVideoPlayer();
    }

    componentDidMount() {
        this.props.actions.getVideosByBookResourceId({
            bookResourceId: this.props.location.state.eachBookResourceByBookId.bookResourceId,
            jwt: this.props.userData.jwt
        })
    }
    render() {
        console.log("this.props", this.props)
        const { eachBookResourceByBookId } = this.props.location.state
        return (
            <div>
                <div>
                    {this.props.getVideosByBookResourceId.length !== 0 &&
                        this.props.getOpenSingleVideoPlayer &&
                        (
                            <div>
                                <VideoPlayerModal show={this.props.getOpenSingleVideoPlayer}
                                    onClose={this.closeSingleVideoPlayer}
                                    eachBookResourceByBookId={eachBookResourceByBookId}
                                />
                            </div>
                        )}
                </div>
                <div className="container-fluid remove-all-padding-single-video">
                    <div className='top-container-single-video'>
                        <div>
                            {this.props.userData.roleId === "78129" ? (
                                <div className='row p-4'>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={{ pathname: '/AddBooks' }}>Books</Link></li>
                                            <li className="breadcrumb-item"><Link to={{ pathname: '/view-resources', state: { bookId: eachBookResourceByBookId.bookId } }}>Resources</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Course Videos View</li>
                                        </ol>
                                    </nav>
                                </div>) : <i> </i>
                            }
                            {this.props.userData.roleId === "73146" ? (
                                <div className='row p-4'>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><Link to={{ pathname: '/InstOrgAddCourseDetails' }}>Course Details</Link></li>
                                            <li className="breadcrumb-item"><Link to={{ pathname: '/view-resources', state: { bookId: eachBookResourceByBookId.bookId } }}>Resources</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">Course Videos View</li>
                                        </ol>
                                    </nav>
                                </div>) : <i> </i>
                            }
                            <div className='row p-4'>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <h1 className='text-color-white'>{eachBookResourceByBookId.bookResourceName}</h1>
                            </div>
                        </div>
                    </div>
                    <div>
                        <SingleVideoCard imageUrl={eachBookResourceByBookId.imageUrl} />
                    </div>
                    <div className='row p-4'>
                        <YourLearningList resourceId={eachBookResourceByBookId.bookResourceId} />
                    </div>
                </div>
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getVideosByBookResourceId: videoStreamingData.getVideosByBookResourceId(state),
    getOpenSingleVideoPlayer: videoStreamingData.getOpenSingleVideoPlayer(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(SingleVideoView);
