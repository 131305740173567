const INFO_BANNER={

    MESSAGE_INFO_BANNER_INST_STANDARD:'This is Standard page',
    MESSAGE_INFO_BANNER_INST_ADD_COURSE:'This is Add course',
    MESSAGE_INFO_BANNER_ADD_COURSEDETAILS_INST:'Add the Course',
    MESSAGE_INFO_BANNER_ADDBOOKS :'This is Add books page',
    MESSAGE_INFO_BANNER_ADD_STANDARD:'This is Standard page',
    MESSAGE_INFO_BANNER_ADD_COURSE: 'This is Add course',
    MESSAGE_DELETE_TEXT:'Delete the class',
    MESSAGE_CHECKOUT:'Your cart is empty',
    MESSAGE_ORDERHISTORY:'Your order history is empty',
    MESSAGE_VIDEOMANAGEMENT:'Book Resource Management',
}
export default INFO_BANNER;

    

