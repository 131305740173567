import axios from 'axios'
import moment from 'moment'
import APICONF from '.././config/ApiConfig.js';
import { profile } from './index'
import { Map, fromJS } from 'immutable'


export const getUserInfo = action => async (
  { dispatch },
  { userId, jwt }
) => {
  const getUserInfo = APICONF.GET_USER_INFO + "/" + userId;
  const [err, result] = await axios
    .get(getUserInfo, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const updateAddressInfo = action => async (
  { dispatch, getState },
  { jwt }
) => {

  const userId = getState().state.getIn(['userData','id'],'')

  const userInfo = getState().state.getIn(['userInfo','responseObject'], Map());

  const isShippingAddress = getState().state.getIn(['edittingAddress','isShippingAddress'], false)

  const currentAddressIndex = getState().state.getIn(['edittingAddress','currentAddressIndex'],0)

  const isEditingAddress = getState().state.getIn(['edittingAddress','isEditingAddress'], false)
   const fullName = userInfo.get('firstName','') + userInfo.get('lastName','')
   const phoneNumber = Number(userInfo.get('phoneNumber',''))

  const basePath =isEditingAddress 
  ? isShippingAddress
    ? ['addressInfo','shippingAddressDetails', currentAddressIndex]
    :  ['addressInfo','billingAddressDetails', currentAddressIndex]
  : ['newAddress']

  const addressType = getState().state.getIn([...basePath,'addressType'],'')
  const street1 = getState().state.getIn([...basePath,'street1'],'')
  const street2 = getState().state.getIn([...basePath,'street2'],'')
  const cityName = getState().state.getIn([...basePath,'cityName'],'')
  const landmark = getState().state.getIn([...basePath,'landmark'],'')
  const district = getState().state.getIn([...basePath,'district'],'')
  const state = getState().state.getIn([...basePath,'state'],'')
  const stateCode = getState().state.getIn([...basePath,'stateCode'],'')
  const country = getState().state.getIn([...basePath,'country'],'')
  const countryCode = getState().state.getIn([...basePath,'countryCode'],'')
  const zipCode = getState().state.getIn([...basePath,'zipCode'],'')
  const isDefault = getState().state.getIn([...basePath,'isDefault'],false) 
      ? 'Y'
      : 'N'
  const userAddressInfoId = getState().state.getIn([...basePath,'userAddressInfoId'],'') 
  let address;
    if(addressType === 'billing'){
      address = {
        "billingAddress": "Y",
        "billingAddressDefault": "Y",
        "shippingAddress": "N",
        "shippingAddressDefault": "N",
      }
    } else{
      address = {
        "billingAddress": "N",
        "billingAddressDefault": "N",
        "shippingAddress": "Y",
        "shippingAddressDefault": "Y",
      }
    }
  const [err, result] = await axios( {
      method: 'post', 
      url: APICONF.UPDATE_ADDRESS_INFO,
      headers: {
        "authorization": jwt
      },
      data: {
        addressType,
        street1,
        street2,
        cityName,
        landmark,
        district,
        state,
        country,
        countryCode,
        zipCode,
        isDefault,
        ...address,
        userId,
        userAddressInfoId,
        stateCode,
        fullName,
        phoneNumber
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  if(!err){
    new Promise((resolve, reject) => {
      setTimeout( () => {
        resolve(
          dispatch(profile.actions.getAddressInfo({userId,jwt})) &&
          dispatch(profile.actions.deleteNewAddreess()) &&
          dispatch(profile.actions.toggleAddedAddressAlert(true))
        )
      },3000)
    })

    new Promise((resolve, reject) => {
      setTimeout( () => {
        resolve(
          dispatch(profile.actions.toggleAddedAddressAlert(false))
        )
      },5000)
    })
  }
  return dispatch(actionToDispatch) 
}

export const getAddressInfo = action => async (
  { dispatch },
  { userId, jwt }
) => {
  const getAddressURL = APICONF.GET_ADDRESS_INFO + "/" + userId;
  const [err, result] = await axios
    .get(getAddressURL, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  return dispatch(actionToDispatch)
}

export const deleteAddressInfo = action => async (
  { dispatch },
  { userAddressInfoId, userId,jwt }
) => {
  const getAddressURL = `${APICONF.DELETE_ADDRESS_INFO}/${userId}/${userAddressInfoId}`
  const [err, result] = await axios
    .delete(getAddressURL, {
      headers: {
        "authorization": jwt
      }
    })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  if(!err){
    new Promise((resolve, reject) => {
      setTimeout( ()=> {
        resolve(
          dispatch(
            profile.actions.getAddressInfo({userId,jwt})
          )
        )
      },3000)
    })
  }
  return dispatch(actionToDispatch) 
}

export const updateUserInfo = action => async (
  { dispatch, getState },
  { jwt }
) => {
  const personalInfo = getState().state.getIn(['userInfo','responseObject'], Map())
  const userId = getState().state.getIn(['userData','id'], '')
  const  firstName = personalInfo.get("firstName", "")
  const lastName = personalInfo.get("lastName", "")
  const phoneNumber=personalInfo.get("phoneNumber", "")
  const [err, result] = await axios({
    method: 'post',
    url: APICONF.UPDATE_USER_INFO,
    headers:{
      "authorization": jwt
    },
    data: {
      userId,
      firstName,
      lastName,
      phoneNumber
    }
  })
    .then(res => [null, res.data])
    .catch(err => [err, null])
  const actionToDispatch = err ? action.failure(err) : action.success(result)
  if(!err){
    new Promise((resolve, reject) => {
      setTimeout( ()=> {
        resolve(
          dispatch(
            profile.actions.toggleUserInfoAlert(false)
          )
        )
      },3000)
    })
  }
  return (
    dispatch(actionToDispatch) &&
    dispatch(profile.actions.toggleUserInfoAlert(true))
  );
}






export const createEvent = action => async (
  { dispatch, getState },
  { data, eventUsersMap }
) => {

  
  const updatedEventInformation = fromJS(data)
  console.log("updatedEventInformation::::::::::",updatedEventInformation.toJS())
  const createdBy = getState().state.getIn(['userData','id'],'')
  const jwt = getState().state.getIn(['userData','jwt'],'')
  const meetingUrl = getState().state.get('meetingUrl') || ''
  
  const eventDetails = {
    activeStatus: 'active',
    createdBy,
    createdDate: new Date(),
    endTime: updatedEventInformation.getIn([0,'EndDate'],''),
    eventDescription: updatedEventInformation.getIn([0,'Description'],''),
    eventManagementId: updatedEventInformation.getIn([0,'Id'],''),
    eventTitle:  updatedEventInformation.getIn([0,'Subject'],''),
    meetingLink: meetingUrl,
    recurrenceRule: updatedEventInformation.getIn([0,'RecurrenceRule'],''),
    startTime: updatedEventInformation.getIn([0,'StartDate'],''),
    startDate: updatedEventInformation.getIn([0,'StartDate'],''),
    endDate: updatedEventInformation.getIn([0,'EndDate'],''),
    eventReason: updatedEventInformation.getIn([0,'Reason'],''),
    eventType: updatedEventInformation.getIn([0,'EventType'],''),
    userId: createdBy
  }
  const eventUsers = eventUsersMap.map(eventUserMap => ({...eventUserMap,
                                    createdBy: createdBy,
                                    userEventDetailsId: '',
                                    eventManagementId: updatedEventInformation.getIn([0,'Id'],'')}))

  console.log("eventUsers",eventUsers)
  const [err, result] = await axios({
    method: 'post',
    url: APICONF.CREATE_EVENT,
    headers:{
      "authorization": jwt
    },
    data: {
      eventDetails,
      eventUsers

    }
  })
  .then(res => [null, res.data])
  .catch(err => [err, null])
const actionToDispatch = err ? action.failure(err) : action.success(result)
return dispatch(actionToDispatch) 
}
  



export const cancelEvent =
  (action) =>
  async ({ dispatch, getState }, { jwt, id }) => {
    const [err, result] = await axios({
      method: "post",
      url: `${APICONF.CANCEL_EVENT}/${id}`,
      headers: {
        authorization: jwt,
      },
      data: {},
    })
      .then((res) => [null, res.data])
      .catch((err) => [err, null]);
    const actionToDispatch = err ? action.failure(err) : action.success(result);
    return dispatch(actionToDispatch);
  };


  export const getUsers =
    (action) =>
    async ({ dispatch, getState }, { jwt, id }) => {
      const [err, result] = await axios
        .get(`${APICONF.GET_USERS}/${id}`, {
          headers: {
            authorization: jwt,
          },
        })
        .then((res) => [null, res.data])
        .catch((err) => [err, null]);
      const actionToDispatch = err
        ? action.failure(err)
        : action.success(result);
      return dispatch(actionToDispatch);
    };

  export const generateMeetingLink =
    (action) =>
    async ({ dispatch, getState }) => {
      const jwt = getState().state.getIn(["userData", "jwt"], "");
      const id = getState().state.getIn(["userData", "id"], "");
      const [err, result] = await axios
        .get(`${APICONF.MEETING_LINK}/${id}`, {
          headers: {
            authorization: jwt,
          },
        })
        .then((res) => [null, res.data])
        .catch((err) => [err, null]);
      const actionToDispatch = err
        ? action.failure(err)
        : action.success(result);
      return dispatch(actionToDispatch);
    };

    export const updateEvents = action => async (
      { dispatch, getState },
      { data }
    ) => {
    
      
      const updatedEvent = fromJS(data)
    
      const createdBy = getState().state.getIn(['userData','id'],'')
      const jwt = getState().state.getIn(['userData','jwt'],'')
      const meetingUrl = getState().state.get('meetingUrl') || ''
      const eventDetails = {
        activeStatus: 'active',
        createdBy,
        createdDate: new Date(),
        endTime: updatedEvent.getIn(['EndDate'],''),
        id: updatedEvent.getIn(['Id'],''),
        eventDescription: updatedEvent.getIn(['Description'],''),
        eventManagementId: updatedEvent.getIn(['Id'],''),
        eventTitle:  updatedEvent.getIn(['Subject'],''),
        meetingLink: meetingUrl,
        recurrenceRule: updatedEvent.getIn(['RecurrenceRule'],''),
        startTime: updatedEvent.getIn(['StartDate'],''),
        startDate: updatedEvent.getIn(['StartDate'],''),
        endDate: updatedEvent.getIn(['EndDate'],''),
        eventReason: updatedEvent.getIn(['Reason'],''),
        userId: createdBy
      }
      const [err, result] = await axios({
        method: 'post',
        url: APICONF.UPDATE_EVENT,
        headers:{
          "authorization": jwt
        },
        data: {
          eventDetails
        }
      })
        .then(res => [null, res.data])
        .catch(err => [err, null])
      const actionToDispatch = err ? action.failure(err) : action.success(result)
      return dispatch(actionToDispatch) 
    }
    

    export const getUpcomingEventsByUserIdAndDate =
      (action) =>
      async ({ dispatch, getState }, {
        date
      }) => {
        const jwt = getState().state.getIn(["userData", "jwt"], "");
        const id = getState().state.getIn(["userData", "id"], "");
        const currentYear = date.getFullYear()
        const currentMonth = date.getMonth()+1 //as month starts from 0
        const currentDay = date.getDate()
        const dateFormat = `${currentYear}-${currentMonth}-${currentDay}`
        window.sessionStorage.setItem("previousSelectedMonth", currentMonth)
        window.sessionStorage.setItem("previousSelectedYear", currentYear)
        const [err, result] = await axios
          .get(`${APICONF.UPCOMING_EVENTS}/${id}/${dateFormat}`, {
            headers: {
              authorization: jwt,
            },
          })
          .then((res) => [null, res.data])
          .catch((err) => [err, null]);
        const actionToDispatch = err
          ? action.failure(err)
          : action.success(result);
        return dispatch(actionToDispatch)
      };