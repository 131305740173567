import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appData from '../appSelector'
import { Link } from 'react-router-dom';

class GuestHomePanelLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    let eachOrgBooksData = this.props.guestEachOrgBooksData
    console.log(eachOrgBooksData)
    var imageUrl = eachOrgBooksData.imageappLocationOrg;
    return (
      <div key={eachOrgBooksData.bookId} className="books-card-wrapper col-md-12">
        <div className="card" >
          <div className="card-header">
            Standard Name: {eachOrgBooksData.standardName}&nbsp;&nbsp;
            Course Name: {eachOrgBooksData.courseName}&nbsp;&nbsp;
            Book price : <span className="rupee"></span> {eachOrgBooksData.price}
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <img className="bookImageSize" alt={eachOrgBooksData.bookTitle} src={imageUrl} />
              </div>
              <div className="col-6">
                <ul>
                  <li>
                  <Link to={{ pathname: '/PublicOrders', state: { bookId: eachOrgBooksData.bookId, bookData: eachOrgBooksData, role: 'guest' } }}>
                    <button className="mb-3 mt-3 text-white btn btn-warning" >Order</button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {

    },
    d
  )
})
export default connect(s, d)(GuestHomePanelLinks);
