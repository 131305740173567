import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as videoStreamingData from '../redux-videoStreaming/selectors'
import * as appData from '../../appSelector';
import '../BookResoursesManagement.css';
import { videoStreamingConf } from '../redux-videoStreaming/index';
import VideoPlayer from '../video-player/VideoPlayer';

class SingleVideoPreview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoJsOptions: '',
            srcVideoObjectArray: []
        };
        this.createSourceOption = this.createSourceOption.bind(this);
        this.setVideoJsOptions = this.setVideoJsOptions.bind(this);
    }
    componentDidMount() {
        this.createSourceOption();
        this.setVideoJsOptions();
    }

    setVideoJsOptions() {
        console.log("this.state.srcVideoObjectArray", this.state.srcVideoObjectArray)
        const videoJsOptions = {
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            sources: this.state.srcVideoObjectArray
        };
        this.setState({
            videoJsOptions: videoJsOptions
        })
    }
    createSourceOption() {
        this.props.getVideosByBookResourceId.singleVideo.map(videosByBookResource => {
            let srcObject = {
                src: videosByBookResource.courseSingleVidDestinationS3url,
                type: 'application/x-mpegURL'
            };
            this.state.srcVideoObjectArray.push(srcObject);
        })
    }

    render() {
        const videoJsOptions = this.state.videoJsOptions;
        return (
            <div>
                {(this.props.getOpenSingleVideoPlayer && this.state.videoJsOptions) &&
                    (<VideoPlayer {...videoJsOptions} />)}
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getVideosByBookResourceId: videoStreamingData.getVideosByBookResourceId(state),
    getOpenSingleVideoPlayer: videoStreamingData.getOpenSingleVideoPlayer(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(SingleVideoPreview);
