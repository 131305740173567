import React, { Component } from 'react';
import DatePicker from 'rsuite/DatePicker';
import moment from 'moment';
import 'rsuite/dist/rsuite.min.css';
import './weekPicker.css';
import { attendanceConf } from './redux-attendance/index';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as attendanceData from './redux-attendance/selectors';
import * as appData from "../appSelector";
import Select from 'react-select';
import * as modalsData from '../modals/selector';
import { modals } from '../modals/index'
import { common } from '../common/index'
import './attendance.css'
import AlertModal from '../common/AlertModal';
import * as commonData from '../common/selector';

class AttendanceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            objWeek: {
                date: new Date(), // initialize with current date
                startOfWeek: null,
                endOfWeek: null,
                weekNumber: null
            },
            selectedGroupId: null,
            entireColumnDateSelected: false,
            absentedUsersForSelctedDay: [],
            eachSelctedCheckbox: false,
            inputEntireColumnSelectedValue: '',
            selectedCourseId: null
        };
        this.onDateChange = this.onDateChange.bind(this);
        this.handleGroupSelection = this.handleGroupSelection.bind(this);
        this.getGroupNames = this.getGroupNames.bind(this);
        this.handleColumnDateSelected = this.handleColumnDateSelected.bind(this);
        this.handleEachDateAndUser = this.handleEachDateAndUser.bind(this);
        this.handleAttendanceSubmit = this.handleAttendanceSubmit.bind(this);
        this.makeAllCheckboxesFalse = this.makeAllCheckboxesFalse.bind(this);
        this.handleAttendanceModalClose = this.handleAttendanceModalClose.bind(this);
        this.setElementValue = this.setElementValue.bind(this);
        this.waitForElement = this.waitForElement.bind(this);
        this.associatedGroupsByUser = this.associatedGroupsByUser.bind(this);
        this.disableAllInputs = this.disableAllInputs.bind(this);
        this.handleCourseSelection = this.handleCourseSelection.bind(this);
        
        //this.mondayDataUsers = this.mondayDataUsers.bind(this);
    }

    disableAllInputs() {

        var rows = document.getElementsByTagName("tr");
   for (var i = 0; i < rows.length; i++) {
     rows[i].onmouseover= null;
   } 
        // if(document.getElementById("table1")){
        //     document.getElementById("table1").disabled = true;
        // }
       
    }
    onDateChange(date) {
        const weekNumber = moment(date).isoWeek();
        const startOfWeek = moment(date).startOf('isoWeek').toDate();
        const endOfWeek = moment(date).endOf('isoWeek').toDate();
        this.makeAllCheckboxesFalse();
        this.setState({
            objWeek: {
                date,
                startOfWeek,
                endOfWeek,
                weekNumber
            }
        }, () => {
            if (this.state.selectedGroupId != null) {
                this.callgetStudentTimeEntrysData();
            }
        });
    }

    handleAttendanceModalClose(payload) {
        this.props.actions.closeAttendanceAlertModal(payload);
    }
    makeAllCheckboxesFalse() {
        let inputTags = document.querySelectorAll(`*[id^="input_"]`);
        for (let i = 0; i < inputTags.length; i++) {
            if (inputTags[i].type == 'checkbox') {
                inputTags[i].checked = false;
            }
        }
        console.log("inputTags", inputTags)
    }
    handleColumnDateSelected(event) {
        //debugger
        if (this.state.inputEntireColumnSelectedValue !== '' && this.state.inputEntireColumnSelectedValue !== event.target.id) {
            let inputTags = document.querySelectorAll(`*[id^="${this.state.inputEntireColumnSelectedValue}"]`);
            for (let i = 0; i < inputTags.length; i++) {
                if (inputTags[i].type == 'checkbox') {
                    inputTags[i].checked = false;
                }
            }
            console.log("inputTags", inputTags)
        }

        let inputTagsIds = document.querySelectorAll(`*[id^="${event.target.id}"]`);
        console.log("inputTagsIds", inputTagsIds)
        //*[id^="${event.target.id}"] gets the data with starting of the given event.target.id for id 
        for (let i = 0; i < inputTagsIds.length; i++) {
            if (inputTagsIds[i].type == 'checkbox') {
                inputTagsIds[i].checked = event.currentTarget.checked;
            }
        }
        this.setState({
            inputEntireColumnSelectedValue: event.target.id
        })

    }

    handleEachDateAndUser(event, id) {
        let elem = document.getElementById(event.target.id);
        elem.checked = event.currentTarget.checked;
    }
    getGroupNames() {
        this.makeAllCheckboxesFalse()
        this.props.actions.getGroupNames({
            loginUserId: this.props.userData.id,
            jwt: this.props.userData.jwt
        })
    }
    associatedGroupsByUser() {
        let userId = this.props.userData.id;
        if(["79126", "75126"].includes(this.props.userData.roleId)){
            let selectedStudentData = JSON.parse(localStorage.getItem('selectedStudentData'));
            userId = selectedStudentData.studentId
        }
        this.props.actions.getDefaultGroupNames({
            userId: userId,
            jwt: this.props.userData.jwt
        })
    }
    
    getAssociatedCourses() {
        let userId;
        if(["79126", "75126"].includes(this.props.userData.roleId)){
            let selectedStudentData = JSON.parse(localStorage.getItem('selectedStudentData'));
            userId = selectedStudentData.studentId
        }
        this.props.actions.getDefaultSelectedCourses({
            eachUserId: userId,
            jwt: this.props.userData.jwt
        });
    }
    componentDidMount() {
        if (["79146", "72195"].includes(this.props.userData.roleId)) {
            this.getGroupNames();
        }
        if (["72166", "72138","79126", "75126"].includes(this.props.userData.roleId)) {
            this.associatedGroupsByUser();
        }
        if(["79126", "75126"].includes(this.props.userData.roleId)){
            this.getAssociatedCourses();
        }
        const date = new Date();
        const weekNumber = moment(date).isoWeek();
        const startOfWeek = moment(date).startOf('isoWeek').toDate();
        const endOfWeek = moment(date).endOf('isoWeek').toDate();
        this.setState({
            objWeek: {
                date,
                startOfWeek,
                endOfWeek,
                weekNumber
            }
        });
    }
    handleAttendanceSubmit = (event) => {
        let finalInputTagIds = document.querySelectorAll(`*[id^="${this.state.inputEntireColumnSelectedValue}"]`);
        console.log("finalInputTagIds", finalInputTagIds);
        let selectedDateString = this.state.inputEntireColumnSelectedValue.replace('input_', '');
        let selectedWeekNumber = this.state.objWeek.weekNumber
        let selectedGroupId = this.state.selectedGroupId
        let selectedBookId = localStorage.getItem('selectedBookIdRoster')
        let selectedDate = new Date(selectedDateString);
        let selectedYear = selectedDate.getFullYear();
        let studentTimeTableArray = [];

        for (let i = 0; i < finalInputTagIds.length; i++) {
            if (i != 0) {
                if (finalInputTagIds[i].type == 'checkbox') {
                    console.log("finalInputTagIds[i]", finalInputTagIds[i].id)
                    let studentAttended;
                    let studentId = finalInputTagIds[i].id.replace(this.state.inputEntireColumnSelectedValue + '_', '');
                    if (finalInputTagIds[i].checked) {
                        studentAttended = "Y"
                    } else {
                        studentAttended = "N"
                    }
                    let eachStudentTimeTable = {
                        "bookId": selectedBookId,
                        "studentId": studentId,
                        "teacherId": this.props.userData.id,
                        "attendedDate": selectedDate,
                        "attended": studentAttended,
                        "groupId": selectedGroupId,
                        "weekNumber": selectedWeekNumber,
                        "attendedYear": selectedYear
                    }
                    studentTimeTableArray.push(eachStudentTimeTable)
                }

            }
        }
        this.props.actions.saveStudentTimeEntrys({
            jwt: this.props.userData.jwt,
            studentTimeTablesData: studentTimeTableArray
        })
        this.callgetStudentTimeEntrysData()
    };
    handleGroupSelection = event => {
        let groupId = event.value;
        this.setState({ selectedGroupId: groupId }, () => {
            this.callgetStudentTimeEntrysData();
        });
    }

    handleCourseSelection = event => {
        let courseId = event.value;
        this.setState({ selectedCourseId: courseId }, () => {
            this.callgetStudentTimeEntrysData();
        });
    }


    callgetStudentTimeEntrysData() {
        let bookId = localStorage.getItem('selectedBookIdRoster');
        if(["79126", "75126"].includes(this.props.userData.roleId)){
            bookId = this.state.selectedCourseId
        }
        if(bookId !=null && this.state.selectedGroupId != null){
            this.props.actions.getSudentTimeEntrys({
                bookId: bookId,
                groupId: this.state.selectedGroupId,
                userId: this.props.userData.id,
                weekNumber: this.state.objWeek.weekNumber,
                attendedYear: this.state.objWeek.startOfWeek.getFullYear(),
                jwt: this.props.userData.jwt
            });
        }

    }
    waitForElement(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
    setElementValue(inputColoumnIdEach) {
        let elem = document.getElementById(`${inputColoumnIdEach}`)
        console.log("Elementtt", elem)
        if (elem != null) {
            elem.checked = true
        }
    }
    render() {
        let allGroupOptionsData = [];
        if ((["79146", "72195"].includes(this.props.userData.roleId)) && this.props.getGroupNamesData) {
            this.props.getGroupNamesData.map(function (dataChange) {
                var groups = {
                    value: dataChange.groupId,
                    label: dataChange.groupName
                }
                allGroupOptionsData.push(groups)
            })
        }

        if ((["72166", "72138", "79126", "75126"].includes(this.props.userData.roleId)) && this.props.getDefaultGroupNamesData) {
            this.props.getDefaultGroupNamesData.map(function (dataChange) {
                var groups = {
                    value: dataChange.groupId,
                    label: dataChange.groupName
                }
                allGroupOptionsData.push(groups)
            })
        }
        const { objWeek } = this.state;
        const today = new Date();
        const days = [];
        let currDate = moment(objWeek.startOfWeek);
        while (currDate <= moment(objWeek.endOfWeek)) {
            days.push(currDate.toDate());
            currDate = currDate.clone().add(1, 'd');
        }
        localStorage.setItem("startOfWeek", objWeek.startOfWeek)
        localStorage.setItem("endOfWeek", objWeek.endOfWeek)
        return (
            <div>
                {(this.props.getAttendanceSuccessAlert) && (
                    <AlertModal type="success" show={true} title="Success" message={this.props.getAttendanceSuccessLongMsg} onClose={() => this.handleAttendanceModalClose(true)} />
                )}
                {(this.props.getAttendanceErrorAlert) && (
                    <AlertModal type="danger" show={true} title="Error" message={this.props.getAttendanceErrorLongMsg} onClose={() => this.handleAttendanceModalClose(false)} />
                )}
                <div className='WeekPicker  container-fluid p-5'>
                    <div className='row ml-2'>
                        <h4 className='weekinfo-text'>Week Picker</h4>
                        <div className='col-2 ml-5'>
                            <DatePicker
                                placeholder='Week picker'
                                isoWeek
                                value={objWeek.date}
                                onChange={this.onDateChange}

                            />
                        </div>
                        <div className='col-3'>
                            <div className='weekinfo-text'>
                                <div>
                                    <span><b>Start of Week : </b></span>
                                    <b>{objWeek.startOfWeek?.toDateString()}</b>
                                </div>
                                <div>
                                    <span><b>End of Week : </b></span>
                                    <b>{objWeek.endOfWeek?.toDateString()}</b>
                                </div>
                                <div >
                                    <span><b>Week Number : </b></span>
                                    <b>{objWeek.weekNumber}</b>
                                </div>
                            </div>
                        </div>
                        <div className='col-5'>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b><label className='weekinfo-text'>Group Name</label></b>
                            <div className='col-6'>
                                <Select onChange={this.handleGroupSelection} options={allGroupOptionsData} />
                            </div>
                        </div>

                        {(["79126", "75126"].includes(this.props.userData.roleId)) &&
                        <div className='col-5'>
                            &nbsp;&nbsp;&nbsp;&nbsp;<b><label className='weekinfo-text'>Course Name</label></b>
                            <div className='col-6'>
                                <Select onChange={this.handleCourseSelection} options={this.props.getDefaultSelectedCourses} />
                            </div>
                        </div>}
                    </div>
                    
                    <div className='card mt-4' >
                        <div className='weekInfos m-1'>
                            <table id="table1" className="table text">
                                <thead>
                                    <tr>
                                        <th className='align-middle h5 '>Id</th>
                                        <th className='align-middle h5 '>Users</th>
                                        {days.map((day) => {
                                            let date = moment(day).format("YYYY-MM-DD")
                                            console.log("date", date)
                                            return (
                                                <th id={day}
                                                    className={`${moment(day).isSame(today, 'day') ? 'highlight' : ''}
                                                       ${moment(day).day() === 0 ? 'holiday' : ''}  `}>
                                                    {moment(day).format('dddd')}
                                                    <br />
                                                    {moment(day).format('MMM D')}
                                                    <br />
                                                    <br />
                                                    {!(["72166", "72138"].includes(this.props.userData.roleId))
                                                        &&
                                                        <label>
                                                            <input type="checkbox" id={"input" + "_" + date}
                                                                onClick={(e) => this.handleColumnDateSelected(e)}
                                                            // checked={isDateSelectedEachUser ? this.state.entireColumnDateSelected : false}
                                                            />
                                                            Default
                                                        </label>}
                                                </th>)
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.getSudentTimeEntryData.responseObject && this.props.getSudentTimeEntryData.responseObject.map((studentData, index) =>
                                        <tr key={studentData.studentId}>
                                            <td>{index + 1}</td>
                                            <td scope="row">{studentData.studenName}</td>
                                            {days.map((day) =>
                                                <td id={"cell" + "_" + moment(day).format("YYYY-MM-DD") + "_" + studentData.studentId} >
                                                    <label>
                                                        <input type="checkbox" id={"input" + "_" + moment(day).format("YYYY-MM-DD") + "_" + studentData.studentId}
                                                            onClick={(e) => this.handleEachDateAndUser(e, studentData.studentId)}

                                                        />
                                                        Check Me!
                                                    </label>
                                                </td>
                                            )}
                                        </tr>
                                    )}
                                    {this.props.getSudentTimeEntryData.responseObject && this.props.getSudentTimeEntryData.responseObject.map((studentData, index) => {
                                        days && days.map((day) => {
                                            studentData.studentTimeTableDataDto && studentData.studentTimeTableDataDto.map((studentDatesInfo) => {
                                                let dateFromApi = moment(studentDatesInfo.date).format("YYYY-MM-DD")
                                                let date = moment(day).format("YYYY-MM-DD")
                                                if (dateFromApi == date) {
                                                    if (studentDatesInfo.attended == "Y") {
                                                        console.log('Checking is ready');
                                                        let inputColoumnIdEach = "input" + "_" + date + "_" + studentData.studentId;
                                                        //const elm = await waitForElm('.some-class');

                                                        this.waitForElement(`#${inputColoumnIdEach}`).then(element => {
                                                            console.log('The element exists', element);
                                                            this.setElementValue(inputColoumnIdEach)
                                                        });
                                                    }
                                                }
                                                console.log("dateFromApi", typeof dateFromApi, typeof date)
                                            })
                                        }
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    {
                        (["72166", "72138"].includes(this.props.userData.roleId)) &&
                        
                            this.disableAllInputs()
                    }
                    {(["79146", "72195"].includes(this.props.userData.roleId)) &&
                        (<div>
                            <button className="btn btn-primary pull-right mt-4" onClick={(e) => this.handleAttendanceSubmit(e)}> Submit </button>
                        </div>)}

                </div>

            </div>
        );
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getGroupNamesData: modalsData.getGroupNames(state),
    getSudentTimeEntryData: attendanceData.getSudentTimeEntrys(state),
    getAttendanceErrorAlert: attendanceData.getAttendanceErrorAlert(state),
    getAttendanceErrorShortMsg: attendanceData.getAttendanceErrorShortMsg(state),
    getAttendanceErrorLongMsg: attendanceData.getAttendanceErrorLongMsg(state),
    getAttendanceSuccessAlert: attendanceData.getAttendanceSuccessAlert(state),
    getAttendanceSuccessLongMsg: attendanceData.getAttendanceSuccessLongMsg(state),
    getAttendanceSuccessShortMsg: attendanceData.getAttendanceSuccessShortMsg(state),
    getDefaultGroupNamesData: commonData.getDefaultGroupNamesData(state),
    getDefaultSelectedCourses : modalsData.getDefaultSelectedCourses(state),
})

const d = (d) => ({
    actions: bindActionCreators(
        {
            ...attendanceConf.actions,
            ...modals.actions,
            ...common.actions
        },
        d
    )
})
export default connect(s, d)(AttendanceTable);
