import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from "connected-react-router";
import AdminHome from '../../src/admin/AdminHome.js';
import AddStandards from '../admin/adminConfiguration/standards/AddStandardsPage.js';
import AddCourses from '../admin/adminConfiguration/courses/AddCoursesPage.js';
import AddBooks from '../admin/adminConfiguration/books/AddBooksPage.js';
import AddUsers from '../../src/common/AddUsers.js';
import ViewBookResources from '../videoStreaming/ViewBookResources';
import Order from '../../src/order/OrdersPage.js';
import Header from '../layout/Header';
import { createBrowserHistory } from 'history';
import '../admin/AdminHome.css'
import PublicFooter from "../layout/PublicFooter";
import NotFoundPage from '../common/404'
import ExecutiveCartSummary from '../../src/order/cartSummary/ExecutiveCartSummary';
import Review from '../order/cartSummary/review';
import OrderHistory from '../order/orderHistory/OrderHistory';
import ProfileHome from "../profile/ProfileHome";
import CreateInstitute from "../executive/CreateInstitute";
import Calendar from '../calendar';
import BookResoursesManagement from '../videoStreaming/BookResoursesManagement.js';
import SingleVideoView from '../videoStreaming/single-video-view/SingleVideoView.js';
import CourseVideoMainView from '../videoStreaming/course-video-view/CourseVideoMainView.js';

import PartnersPage from '../publicview/PartnersPage.js';
import AboutUsPage from '../publicview/AboutUsPage'
import CareersPage from '../publicview/CareersPage.js';
import ContactUsPage from '../publicview/ContactUsPage.js';



export default class AdminRouter extends Component {
  render() {
    return (
      <div>
        <div className="page-body">
          <div className="page-content-admin height-fix">
            <ConnectedRouter history={createBrowserHistory()}>
              <Header> </Header>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/AdminHome" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/AdminHome" />
                </Route>
                <Route
                  exact
                  path="/AdminHome"
                  render={(props) => <AdminHome {...props} />}
                />
                <Route
                  exact
                  path="/AddStandards"
                  render={(props) => <AddStandards {...props} />}
                />
                <Route
                  exact
                  path="/AddCourses"
                  render={(props) => <AddCourses {...props} />}
                />
                <Route
                  exact
                  path="/AddBooks"
                  render={(props) => <AddBooks {...props} />}
                />
                <Route
                  exact
                  path="/AddUsers"
                  render={(props) => <AddUsers {...props} />}
                />
                <Route
                  exact
                  path="/Orders"
                  render={(props) => <Order {...props} />}
                />
                <Route
                  exact
                  path="/view-resources"
                  render={(props) => <ViewBookResources {...props} />}
                />
                <Route
                  exact
                  path="/CartSummary"
                  render={(props) => <ExecutiveCartSummary {...props} />}
                />
                <Route
                  exact
                  path="/Review"
                  render={(props) => <Review {...props} />}
                />
                <Route
                  exact
                  path="/OrderHistory"
                  render={(props) => <OrderHistory {...props} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => <ProfileHome {...props} />}
                />
                <Route
                  exact
                  path="/AddInstitute"
                  render={(props) => <CreateInstitute {...props} />}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />
                <Route
                  exact
                  path="/book-resourses-management"
                  render={(props) => <BookResoursesManagement {...props} />}
                />
                <Route
                  exact
                  path="/single-video-view"
                  render={(props) => <SingleVideoView {...props} />}
                />
                <Route
                  exact
                  path="/course-video-view"
                  render={(props) => <CourseVideoMainView {...props} />}
                />
                <Route exact path="/Careers" component={CareersPage} />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route exact path="/Partners" component={PartnersPage} />
                <Route component={NotFoundPage} />
              </Switch>
              <PublicFooter />
            </ConnectedRouter>
          </div>
        </div>
      </div>
    );
  }
}
