import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion'

class ViewAllResultsLinks extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.handleViewAnswers = this.handleViewAnswers.bind(this);
    }
    handleViewAnswers() {
        localStorage.setItem('eachTestId', this.props.eachViewEachAssessmentData.testId)
        localStorage.setItem('eachUserId', this.props.eachUserResultsData.userId)
        localStorage.setItem('eachTestData', JSON.stringify(this.props.eachViewEachAssessmentData))
        window.open("/ViewAnswers")
    }
    render() {
        var eachViewEachAssessmentData = this.props.eachViewEachAssessmentData
        var eachUserResultsData = this.props.eachUserResultsData
        const index = this.props.index;
        return (
            <React.Fragment>
                <Card>
                    <Accordion.Toggle className="d-flex align-items-center justify-content-between" as={Card.Header} variant="link" eventKey={index + 1}>
                        <div>
                            <b>First Name:&nbsp;{eachUserResultsData.firstName}</b>&nbsp;&nbsp;
                            <b>Last Name:&nbsp;{eachUserResultsData.lastName}</b>&nbsp;&nbsp;
                            <b>Scored Points:&nbsp;{eachUserResultsData.scoredMarks}</b>&nbsp;&nbsp;
                            <b>Assessment Status:&nbsp;{eachUserResultsData.qualifiedStatus}</b>&nbsp;&nbsp;
                        </div>
                        <i class="fa fa-angle-down"></i>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index + 1}>
                        <Card.Body>
                            <div className="row">
                                <div className="col-12">
                                    <b>Standard Name:&nbsp;{eachViewEachAssessmentData.standardName}</b>&nbsp;&nbsp;
                                    <b>Course Name:&nbsp;{eachViewEachAssessmentData.courseName}</b>&nbsp;&nbsp;
                                    <b>Assessment Name:&nbsp;{eachViewEachAssessmentData.testName}</b>
                                </div>
                                <div className="col-5">
                                    <div>
                                        <div className="mt-1">
                                            <b>Assessments Details:</b>
                                        </div>
                                        <div className="mt-1">
                                            Start Date: {eachViewEachAssessmentData.startDate}&nbsp;&nbsp;
                                            End Date: {eachViewEachAssessmentData.endDate}&nbsp;&nbsp;
                                        </div>
                                        <div className="mt-1">
                                            Total Questions: {eachViewEachAssessmentData.totalQuestions}
                                        </div>
                                        <div className="mt-1">
                                            Assessment Duration: {eachViewEachAssessmentData.testDuration}
                                        </div>
                                        <div className="mt-1">
                                            Total Points: {eachViewEachAssessmentData.totalMarks}
                                        </div>
                                        <div className="mt-1">
                                            Qualification Points: {eachViewEachAssessmentData.qualificationMarks}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div>
                                        <div>
                                            <b>Course Details:</b>
                                        </div>
                                        <div className="mt-1">
                                            Title: {eachViewEachAssessmentData.bookTitle}
                                        </div>
                                        <div className="mt-1">
                                            Description: {eachViewEachAssessmentData.bookDescription}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">



                                    <div className="float-right">
                                        <button className="btn btn-sm btn-warning ml-2" onClick={this.handleViewAnswers}>
                                            <i className="fa fa-external-link" aria-hidden="true" style={{ color: 'black' }} /> View Result
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </Card.Body>
                    </Accordion.Collapse>
                </Card>


            </React.Fragment>

        );
    }
}

const s = ({ state }) => ({

})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...assessmentModule.actions
        },
        d
    )
})
export default connect(s, d)(ViewAllResultsLinks);