import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import * as execData from '../executive/redux-executive/selector';
// import { executive } from '../executive/redux-executive/index'
import * as appData from '../appSelector'
import * as publicBookData from '../publicview/redux-publicView/selector';
import './PublicOrders.css';
import { orders } from './redux-orders';

import * as orderSelectors from './redux-orders/selector'
import { Alert } from 'react-bootstrap';
// import { publicView } from '../order/redux-publicView/index';
import { modals } from '../modals/index'

class PublicOrders extends Component {

  constructor() {
    super();
    this.state = {
      book: null,
      quantity: 1,
      showCartModal: false
    };
  }

  openCartModal = () => {
    this.setState({
      showCartModal: true
    })
  }

  closeCartModal = () => {
    this.setState({ showCartModal: false })
  }

  handleQuantity = (e) => {
    
    if ( e.target.value > 0) {
      this.setState({ quantity: e.target.value })
    }
  }

  addToLocalStorage = () => {
    const { bookData } = this.props.location.state;
    const { quantity } = this.state;
    const tobeAdded = {
      "bookPrice": bookData.price,
      "bookId": bookData.bookId,
      "totalAmount": +quantity * bookData.price,
      "quantity": +quantity
    }
    const cart = JSON.parse(window.sessionStorage.getItem('cart')) || [];
    if (cart.length > 0) {
      const isAlreadyExist = cart.findIndex(cartItem => cartItem.bookId === bookData.bookId);
      if (isAlreadyExist !== -1) {
        cart.splice(isAlreadyExist, 1);
      }
    }
    cart.push(tobeAdded);
    window.sessionStorage.setItem('cart', JSON.stringify(cart));
    window.sessionStorage.setItem('localCart', true);
    if(!this.props.isLoggedIn){
      this.props.history.push('/Signup');
    }
  }

  addItemsToCart = () => {
    console.log('add items to cart');
    const { bookData } = this.props.location.state;
    const { quantity } = this.state;
    if(this.props.userData.roleId === "75812"){
      const data = {
        "addToCart": {
          "cartTotal": +quantity * bookData.price,
          "userId": this.props.userData.id
        },
        "guestCartSummary": [
          {
            "bookPrice": bookData.price,
            "bookId": bookData.bookId,
            "totalAmount": +quantity * bookData.price,
            "quantity": +quantity
          }
        ]
      }
      this.props.actions.addToGuestCart({ data, jwt: this.props.userData.jwt })
    }
    if(this.props.userData.roleId === "76517" || this.props.userData.roleId === "79126"
     || this.props.userData.roleId === "72195" || this.props.userData.roleId === "72138"){
      this.addToLocalStorage();
      let cart = JSON.parse(window.sessionStorage.getItem('cart')) || [];
      let cartTotal = 0;
      cart = cart.map(item => {
        cartTotal += Number(item.totalAmount);
        return { ...item, "instId": Number(Object.keys(this.props.userData.instInfo[0])) };
      })
      console.log(cart);
      const data = {
        "addToCart": {
          "cartTotal": cartTotal,
          "userId": this.props.userData.id
        },
        "cartDetails": cart
      }
      console.log(data);
      this.props.actions.addToCart({ data, jwt: this.props.userData.jwt });
    }
    window.sessionStorage.removeItem('localCart');
    window.sessionStorage.removeItem('cart');
  }

  componentDidMount() {
    this.props.actions.resetAddToCartAlerts()
  }

  render() {
    // const { eachTestId } = this.props.location.state

    const {
      addToCartSuccessAlert,
      addTocartFailureAlert
    } = this.props

    
    const { bookData, role } = this.props.location.state;
    const { quantity } = this.state;
    console.log(bookData);
    var imageFromBucket = bookData && bookData.imageBucketLocationChanged;
    var imageUrl = '';
    if (imageFromBucket) {
      imageUrl = bookData.imageBucketLocationChanged
    }
    else {
      imageUrl = bookData.imageappLocationChanged
    }
    return (
    
    
        <div className="guest-orders info-container">
          <div className="image">
            <img src={imageUrl} alt={bookData.bookTitle} />
          </div>
          <div className="content">
            <h1 className="title"> {bookData.bookTitle} </h1>
            <div className="description">
              <p> {bookData.bookDescription} </p>
            </div>
            <div className="price">
              <p>
                Regular : 
                <del> <span className="rupee"></span> {bookData.price + 100}</del>{" "}
              </p>
              <p>Sale :  <span className="rupee"></span>  {bookData.price}</p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label>Quantity: </label>
              </div>
              <div className="col-md-8">
                <input
                  value={quantity}
                  type="number"
                  min={1}
                  max={5}
                  onChange={this.handleQuantity}
                  placeholder="Enter Quantity"
                  className="css-yk16xz-control"
                  required
                />
              </div>
            </div>

           <br></br>
            

            {
              addToCartSuccessAlert &&  
              <Alert variant='success'>
                {'SuccessfullyAdded'}
              </Alert>

            }

            {
              addTocartFailureAlert && 
               <Alert variant='danger'>
                {'Failed to add cart'}
              </Alert>

            }

            <button
                onClick={
                  (role === "public" && !this.props.isLoggedIn) 
                    ? this.addToLocalStorage
                    : this.addItemsToCart
                }
                className="add-to-cart btn btn-warning w-75 mt-5 mx-auto d-block"
              >
                ADD TO CART
              </button>

          
           
             
          </div>
        </div>
    
    );
  }
}

const s = ({ state }) => ({
  publicBookData: publicBookData.getPublicBooks(state),
  addToCartSuccessAlert: orderSelectors.getAddToCartSuccessAlert(state),
  addTocartFailureAlert: orderSelectors.getAddToCartFailureAlert(state),
  userData: appData.getUserData(state),
  isLoggedIn: appData.getLoginStatus(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
      ...modals.actions
    },
    d
  )
})

export default connect(s, d)(PublicOrders);