export const addGroupName = (payload) => (state) => {
  return state.set('addGroupData', payload.data)
    .set('successAlertBox', true)
    .set('userSuccessMsg', payload.data.msg)
}
export const getGroupNames = (payload) => (state) => {
  return state.set('groupNames', payload.data)
}

export const getUsersByRoleId = (payload) => (state) => {
  const userData = state.get('userData');
  if(userData.roleId==="76517"|| userData.roleId==="73146" || userData.roleId==="72195"|| userData.roleId==="79146" ){
    let allAssignParentOptionsData = [];
    if (payload.data) {
      payload.data.map(function (dataChange) {
            var parents = {
                value: dataChange.id,
                label: dataChange.firstName + "-" + dataChange.lastName
            }
            allAssignParentOptionsData.push(parents)
        })
    }
    return state.set('getUsersByRoleId', allAssignParentOptionsData)
  }
  return state.set('getUsersByRoleId', payload.data)
}

export const deleteAssignParentNames = (payload) => (state) => {
  return state.set('deleteAssignParentNames', payload.data)
  .set('successAlertBox', true)
  .set('userAlertSuccessShortMsg', payload.data.responseMessages[0].shortMessage)
  .set('userSuccessMsg', payload.data.responseMessages[0].longMessage)
 
}

export const assignParentToStudent = (payload) => (state) => {
  return state.set('assignParentToStudent', payload.data)
    .set('successAlertBox', true)
    .set('userSuccessShortMsg', payload.data.responseMessages[0].shortMessage)
    .set('userSuccessMsg', payload.data.responseMessages[0].longMessage)
 
}

export const assignParentToStudentFailure = (payload) => (state) => {
  return state.set('failureAlertBox', true)
  .set('userFailureMsg', payload.error.msg)
  .set('userFailureMsgShortMsg', payload.error.response.data.responseMessages[0].shortMessage)
  .set('userFailureMsg', payload.error.response.data.responseMessages[0].longMessage)
  
}

export const addAssignGroupNames = (payload) => (state) => {
  return state.set('addAssigngroupNames', payload.data)
    .set('successAlertBox', true)
    .set('userSuccessMsg', payload.data.msg)
}

export const deleteAssignGroupNames = (payload) => (state) => {
  return state.set('deleteAssignGroupNames', payload.data)
}

export const deleteAssessment = (payload) => (state) => {
  return state.set('deleteAssessment', payload.data)
}

export const closeDeleteAssessmentModal = (payload) => (state) => {
  return state.set('showDeleteAssessmentModal', false)
}

export const getAllInstitutes = (payload) => (state) => {
  let institutesOptions = [];
  payload.data.map((dataChange) => {
    var institutesObject = {
      value: dataChange.instId,
      label: dataChange.instituteName
    }
    institutesOptions.push(institutesObject)
    if (institutesOptions.length > 0) {
      return institutesOptions;
    }
    return institutesOptions;
  })
  return state.set('allInstitutesData', institutesOptions)
}

export const addInstituteSuccess = (payload) => state => {
  return state.set('successAlertModal', true)
    .set('createInstituteSuccessShortMsg', payload.data.responseMessages[0].shortMessage)
    .set('createInstituteSuccessLongMsg', payload.data.responseMessages[0].longMessage)
}

export const addInstituteError = (payload) => state => {
  console.log("payload::::insti", payload)
  return state.set('failureAlertModal', true)
    .set('createInstituteFailureShortMsg', payload.error.response.data.responseMessages[0].shortMessage)
    .set('createInstituteFailureLongMsg', payload.error.response.data.responseMessages[0].longMessage)
}

export const addToCartSuccess = (payload) => (state) => {
  return state.set('addToCartStatusSuceessExec', true)
    .set('addToCartStatusSuceessExecMsg', payload.data.msg)
}
export const addToCartError = (payload) => (state) => {
  return state.set('addToCartStatusErrorExec', true)
    .set('addToCartStatusErrorExecMsg', payload.error.msg)
}


export const getRoleTypesByUser = (payload) => (state) => {
  let rolesOptions = [];
  payload.data.map((dataChange) => {
    var rolesObject = {
      value: Object.keys(dataChange)[0],
      label: Object.values(dataChange)[0]
    }
    rolesOptions.push(rolesObject)
    if (rolesOptions.length > 0) {
      return rolesOptions;
    }
    return rolesOptions;
  })
  return state.set('rolesData', rolesOptions)
}

export const showUserSuccessAlertBox = (payload) => (state) => {
  return state.set('successAlertBox', true)
    .set('userSuccessMsg', payload.data.msg)
}
export const showUserFailureAlertBox = (payload) => (state) => {
  return state.set('failureAlertBox', true)
    .set('userFailureMsg', payload.error.msg)
}

export const deleteAssignCourseUser = (payload) => (state) => {
  return state.set('deleteAssignCourseUser', '')
}

export const getDefaultSelectedCourses = (payload) => (state) => {
  var defalutSelectedCourse = payload.data.map(data => {
    return {
      value: data.bookId,
      label: data.bookTitle
    }
  })
  return state.set('defaultSelectedCourses', defalutSelectedCourse)
}

export const getAllAssociatedCourses = (payload) => (state) => {
  let booksOptions = [];
  payload.data.responseObject.booksData.map(function (dataChange) {
    var associatedBooksObject = {
      value: dataChange.bookId,
      label: dataChange.bookTitle
    }
    booksOptions.push(associatedBooksObject)
    if (booksOptions.length > 0) {
      return booksOptions;
    }
  })

  return state.set('allAssociatedCourses', booksOptions)
}



export const getAllTeacherAssociatedCourses = (payload) => (state) => {
  let coursesOptions = [];
  let teacherAssocData = payload.data;
  teacherAssocData.map(function (dataChange) {
    var associatedCoursesObject = {
      value: dataChange.bookId,
      label: dataChange.bookTitle
    }
    coursesOptions.push(associatedCoursesObject)
    if (coursesOptions.length > 0) {
      return coursesOptions;
    }
  })

  return state.set('teacherCoursesOptions', coursesOptions)
}

export const closeEndAssessmentModal = (payload) => (state) => {
  return state.set('showEndAssessmentModal', false)
}

export const closeEndAssessmentTimerModal = (payload) => (state) => {
  return state.set('showEndAssessmentTimerModal', false)
}

export const closeBookModal = (payload) => (state) => {
  return state.set('openAddBookModal', false)
}

export const getStandardsUser = (payload) => (state) => {
  let standardsOptions = []
  payload.data.responseObject.standardDetails.map((dataChange) => {
    var standardsObject = {
      value: dataChange.standardId,
      label: dataChange.standardName
    }
    standardsOptions.push(standardsObject)
    if (standardsOptions.length > 0) {
      return standardsOptions;
    }
  })
  return state.set('standardsOptionsData', standardsOptions)
}

export const getCoursesWithIds = (payload) => (state) => {
  return state.set('coursesData', payload.data)
}

export const getCoursesOptions = (payload) => (state) => {
  let standardId = payload.standardId;
  let coursesOptions = [];
  let coursesData = state.get('coursesData')
  coursesData.responseObject.coursesData.map((dataChange) => {
    let standardIdfromCourse = dataChange.standardId;
    var coursemapData = dataChange.courseIdNames;
    for (const [key, value] of Object.entries(coursemapData)) {
      if (standardIdfromCourse === standardId) {
        var courseObject = {
          value: key,
          label: value
        }
        coursesOptions.push(courseObject)
      }
    }
  })
  return state.set('coursesOptionsData', coursesOptions)
}

export const booksPageSuccessAlertBox = (payload) => (state) => {
  return state.set('successAlertBox', true)
    .set('addedBooksSuccessMsg', payload.data.msg)
}
export const booksPageFailureAlertBox = (payload) => (state) => {
  return state.set('failureAlertBox', true)
    .set('addedBooksFailureMsg', payload.error.msg)
}

export const courseSectionSuccessAlertBox = (payload) => (state) => {
  return state.set('successAlertBox', true)
    .set('addcourseSectionSuccessMsg', payload.data.msg)
}
export const courseSectionFailureAlertBox = (payload) => (state) => {
  return state.set('failureAlertBox', true)
    .set('addcourseSectionFailureMsg', payload.error.msg)
}

export const getAssociatedOrgDetailsByUserId = (payload) => (state) => {
  let organizationOptions = [];
  payload.data.map((dataChange) => {
    var organizationsObject = {
      value: dataChange.orgId,
      label: dataChange.organizationName
    }
    organizationOptions.push(organizationsObject)
    if (organizationOptions.length > 0) {
      return organizationOptions;
    }
  })
  return state.set('userAssociatedOrgsData', organizationOptions)
}

export const getOrderStatusConstants = (payload) => (state) => {
  console.log("payload", payload)
  let orderStatusConstantsOptions = [];
  payload.data.map((dataChange) => {
    var orderStatusConstantsObject = {
      value: dataChange.orderStatusKey,
      label: dataChange.orderStatusValue
    }
    orderStatusConstantsOptions.push(orderStatusConstantsObject)
    if (orderStatusConstantsOptions.length > 0) {
      return orderStatusConstantsOptions;
    }
  })
  return state.set('orderStatusConstantsData', orderStatusConstantsOptions)
}
export const getCourierServiceProviders = (payload) => (state) => {
  console.log("payload", payload)
  let courierServiceProvidersOptions = [];
  payload.data.map((dataChange) => {
    var courierServiceProvidersObject = {
      value: dataChange.courierServicesCode,
      label: dataChange.courierServicesName
    }
    courierServiceProvidersOptions.push(courierServiceProvidersObject)
    if (courierServiceProvidersOptions.length > 0) {
      return courierServiceProvidersOptions;
    }
  })
  return state.set('courierServiceProvidersOptions', courierServiceProvidersOptions)
}

export const orderHistoryPageSuccessAlertBox = (payload) => (state) => {
  return state.set('successAlertBox', true)
    .set('orderHistoryPageSuccessMsg', payload.data.msg)
}
export const orderHistoryPageFailureAlertBox = (payload) => (state) => {
  return state.set('failureAlertBox', true)
    .set('orderHistoryPageFailureMsg', payload.error.msg)
}

export const setOrderStatus =
  ({ selectedOption, orderStatusCurrentIndex }) =>
    (state) => {
      return state.setIn(
        [
          "orderHistoryData",
          "ordersData",
          orderStatusCurrentIndex,
          "orderStatus",
        ],
        selectedOption.label
      );
    };





export const setPostalService =
  ({ selectedOption, orderStatusCurrentIndex }) =>
    (state) => {
      return state.setIn(
        [
          "orderHistoryData",
          "ordersData",
          orderStatusCurrentIndex,
          "postalService",
        ],
        selectedOption.label
      );
    };

export const closeAddSectionmodal = (payload) => (state) => {
  return state.set('openAddsectionmodal', false)
}

export const addCourseSectionSuccessAlertBox = (payload) => (state) => {
  console.log("payload.data.responseMessages", payload.data.responseMessages[0].longMessage)
  return state.set('successAlertBox', true)
    .set('manageBookResourcesSuccessShortMsg', payload.data.responseMessages[0].shortMessage)
    .set('manageBookResourcesSuccessLongMsg', payload.data.responseMessages[0].longMessage)
}

export const addCourseSectionFailureAlertBox = (payload) => (state) => {
  return state.set('failureAlertBox', true)
    .set('manageBookResourcesFailureShortMsg', payload.data.responseMessages[0].shortMessage)
    .set('manageBookResourcesFailureLongMsg', payload.data.responseMessages[0].longMessage)
}

export const closeInstituteAlertModal = (payload) => (state) => {
  if (payload) {
    return state.set('successAlertModal', false)
  } else {
    return state.set('failureAlertModal', false)
  }

}