import React, { Component } from "react";
import "./Login.css";
import PublicFooter from "../layout/PublicFooter";
import { Route } from "react-router-dom";
import PartnersPage from "../publicview/PartnersPage";
import PublicBooksPage from "../publicview/PublicBooksPage.js";
import AboutUsPage from "../publicview/AboutUsPage.js";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import { inputValidatorForUserName } from "../lib/inputValidator";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginn } from "./index";
import * as selectors from "./selectors";
import { KEYCLOAK_URL, REALM, APP_CLIENT_ID } from '../config/ApiConfig'

import Keycloak from "keycloak-js";
import { KeycloakProvider } from "@react-keycloak/web";

const keycloak = new Keycloak({
  realm: REALM,
  url: KEYCLOAK_URL,
  clientId: APP_CLIENT_ID,
});

const keycloakProviderInitConfig = {
  onLoad: "login-required",
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      userNameErrorMsg: "",
    };
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.props.actions.login({
      username: this.state.userName,
      password: this.state.password,
    });
  }

  handleUserChange(evt) {
    const errorMsg = inputValidatorForUserName(evt.target.value);
    this.setState({
      userName: evt.target.value,
      userNameErrorMsg: errorMsg || "",
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }
  commonRoutes() {
    return (
      <React.Fragment>
        <Route exact path="/Partners" component={PartnersPage} />
        <Route exact path="/PublicBooks" component={PublicBooksPage} />
        <Route eaxct path="/AboutUs" component={AboutUsPage} />
        <Route exact path="/Careers" component={CareersPage} />
        <Route exact path="/ContactUs" component={ContactUsPage} />
      </React.Fragment>
    );
  }

  onKeycloakEvent = (event, error) => {
    localStorage.setItem('keycloak', JSON.stringify(keycloak))
    if(event==='onAuthRefreshSuccess' ){
      localStorage.setItem('onAuthRefreshSuccess', true)
    }
   
  };

  onKeycloakTokens = (tokens) => {
    console.log("onKeycloakTokens", tokens);
    // localStorage.setItem('isLoggedIn',true)
    this.props.actions.setTokens(tokens)
    this.props.actions.getLoginUserInfo()
    this.props.actions.setLoggedInStatus();
    if(localStorage.getItem('onAuthRefreshSuccess') === 'true'){
      this.props.actions.setRefreshRokenPopup()
    }
  };

  componentDidMount() {
    const isLogout  = localStorage.getItem('isLogout')
    // var loggedIn = localStorage.getItem("isLoggedIn");
    if(isLogout === 'true'){
      console.log('logout')
      keycloak
        .logout()
        localStorage.removeItem('isLogout')
    }

  }

  render() {
    return (
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={keycloakProviderInitConfig}
        onEvent={this.onKeycloakEvent}
        onTokens={this.onKeycloakTokens}
      >
        <div className="login-page">
          <div className="positionLogin" style={{ height: '100%'}}>
          </div>
          {/* {!this.props.isLoggedIn && (
            <div className="login-page">
              <form
                onSubmit={(event) => {
                  this.handleSubmit(event);
                }}
              >
                <div className="positionLogin">
                  {this.props.userNameErrorMsg && (
                    <p className="h4FontColor text-left">
                      {this.props.userNameErrorMsg}
                    </p>
                  )}
                  <h4 className="h4FontColor ">Log into Durchere</h4>
                  <input
                    className="transparentInputs"
                    type="text"
                    onChange={this.handleUserChange}
                    placeholder="Enter Username"
                    required
                  />
                  <br />
                  <input
                    className="transparentInputs"
                    type="password"
                    onChange={this.handlePassChange}
                    placeholder="Enter Password"
                    required
                  />
                  <br />
                  <br />
                  <br />
                  <Button type="submit" className="loginButton">
                    <span className="fontbuttoncolor">Log In</span>
                  </Button>
                  <br />
                  <br />
                  <div className="text-center">
                    <Link to="/forgot-password">Forgot Password</Link>{" "}
                    <span className="h4FontColor"> | </span>
                    <Link to="/forgot-username">Forgot Username</Link>
                  </div>
                </div>
              </form>
            </div>
          )} */}
          <PublicFooter />
        </div>
      </KeycloakProvider>
    );
  }
}

const s = ({ state }) => ({
  userNameErrorMsg: selectors.getUserNameErrorMessage(state),
  isLoggedIn: selectors.getLoggedInStatus(state),
  isLoggedOut: selectors.getLogoutStatus(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions,
    },
    d
  ),
});
export default connect(s, d)(Login);
