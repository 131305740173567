export const getPublicBooks = (payload) => (state)=> {
    return state.set('publicBooksData',payload.data.responseObject.orgBooks)
}
export const contactFormSuccessAlertBox = (payload) => (state)=> {
    return state.set('publicViewSuccessAlertBox',true)
                .set('contactFormSuccessMsg', payload.data.msg)
}
export const contactFormFailureAlertBox = (payload) => (state) => {
        return state.set('publicViewFailureAlertBox', true)
        .set('contactFormFailureMsg', payload.error.msg)
}
