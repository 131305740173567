import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import PublicFooter from "../layout/PublicFooter";
import SchoolAdminHeader from "../layout/schoolAdminHeader/SchoolAdminHeader"
import SchoolAdminHome from "../schoolAdmin/SchoolAdminHome";
import AssignedCoursesFromOrg from "../schoolAdmin/AssignedCoursesFromOrg";
import AddUsers from "../../src/common/AddUsers.js";
import NotFoundPage from "../common/404";
import OrdersPage from "../../src/order/OrdersPage.js";
import CartSummary from "../../src/order/cartSummary/CartSummary";
import Checkout from "../../src/order/cartSummary/Checkout";
import OrderHistory from "../order/orderHistory/OrderHistory";
import Payment from "../../src/order/cartSummary/payment";
import ProfileHome from "../profile/ProfileHome";
import Calendar from "../calendar";
import SingleVideoView from '../videoStreaming/single-video-view/SingleVideoView.js';
import CourseVideoMainView from '../videoStreaming/course-video-view/CourseVideoMainView.js';
import ViewBookResources from '../videoStreaming/ViewBookResources';

import PartnersPage from "../publicview/PartnersPage.js";
import AboutUsPage from "../publicview/AboutUsPage";
import PublicBooksPage from "../publicview/PublicBooksPage.js";
import CareersPage from "../publicview/CareersPage.js";
import ContactUsPage from "../publicview/ContactUsPage.js";
import PublicOrders from "../order/PublicOrders.js";

export default class SchoolAdminRouter extends Component {
  render() {
    return (
      <ConnectedRouter history={createBrowserHistory()}>
        <div>
          <div className="page-body">
            <div className="page-content-admin height-fix">
              {window.location.pathname.indexOf("/Payment") === -1 && (
                <SchoolAdminHeader></SchoolAdminHeader>
              )}
              <Switch>
                <Route exact path="/">
                  <Redirect to="/SchoolAdminHome" />
                </Route>
                <Route exact path="/Login">
                  <Redirect to="/SchoolAdminHome" />
                </Route>
                <Route
                  exact
                  path="/SchoolAdminHome"
                  render={(props) => <SchoolAdminHome {...props} />}
                />
                <Route
                  exact
                  path="/SchoolAdminCourses"
                  render={(props) => <AssignedCoursesFromOrg {...props} />}
                />
                <Route
                  exact
                  path="/SchoolAdminUsers"
                  render={(props) => <AddUsers {...props} />}
                />
                <Route
                  exact
                  path="/Orders"
                  render={(props) => <OrdersPage {...props} />}
                />
                <Route
                  exact
                  path="/CartSummary"
                  render={(props) => <CartSummary {...props} />}
                />
                <Route
                  exact
                  path="/Checkout"
                  render={(props) => <Checkout {...props} />}
                />
                <Route
                  exact
                  path="/OrderHistory"
                  render={(props) => <OrderHistory {...props} />}
                />
                <Route
                  exact
                  path="/Payment/:id"
                  render={(props) => <Payment {...props} />}
                />
                <Route
                  exact
                  path="/profile"
                  render={(props) => (
                    <ProfileHome onlyPersonalInfo={true} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/calendar"
                  render={(props) => <Calendar {...props} />}
                />
                <Route
                  exact
                  path="/view-resources"
                  render={(props) => <ViewBookResources {...props} />}
                />
                <Route
                  exact
                  path="/single-video-view"
                  render={(props) => <SingleVideoView {...props} />}
                />
                <Route
                  exact
                  path="/course-video-view"
                  render={(props) => <CourseVideoMainView {...props} />}
                />

                <Route exact path="/Partners" component={PartnersPage} />
                <Route exact path="/PublicBooks" component={PublicBooksPage} />
                <Route
                  exact
                  path="/PublicOrders"
                  render={(props) => <PublicOrders {...props} />}
                />
                <Route eaxct path="/AboutUs" component={AboutUsPage} />
                <Route exact path="/Careers" component={CareersPage} />
                <Route exact path="/ContactUs" component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </div>
          </div>
          {window.location.pathname.indexOf("/Payment") === -1 && (
            <PublicFooter />
          )}
        </div>
      </ConnectedRouter>
    );
  }
}
