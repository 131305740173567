import React, { Component } from 'react';
import * as appData from '../appSelector'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { parent } from './parent-redux/index'
import * as parents from './parent-redux/selectors'
import { Link } from 'react-router-dom';
class StudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
    this.openAttendance = this.openAttendance.bind(this);
  }
  openAttendance() {
    window.open("/attendance")
  }

  render() {
    let selectedStudentData = JSON.parse(localStorage.getItem('selectedStudentData'));
    console.log("selectedStudentData:::",selectedStudentData)
    return (
      <div className='container m-5 col-12'>

        <div className=" mb-3">
          {this.props.userData.roleId === "79126" ? (
            <Link to={{ pathname: "/ParentHome" }}>
              <button className="btn btn-sm btn-info" >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" style={{ color: 'black' }} /> Go back
              </button>
            </Link>) : <i> </i>}
          {this.props.userData.roleId === "75126" ? (
            <Link to={{ pathname: "/ParentinstorgHome" }}>
              <button className="btn btn-sm btn-info" >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" style={{ color: 'black' }} /> Go back
              </button>
            </Link>) : <i> </i>}
        </div>
        <hr />
        <div className='row mt-4'>
          <div className='col-6'>
            <div className="card" >
              <div className="card-header">
                Student Name: {selectedStudentData.firstName + ' ' + selectedStudentData.lastName}&nbsp;&nbsp;
                Institute Name: {selectedStudentData.instIds}
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <ul>
                      <li>
                        <Link to={{ pathname: '/ViewAssessmentsStudent' }}>
                          <button className="btn btn-sm btn-success ml-2 m-2" >
                            <i className="fa fa-external-link" aria-hidden="true" style={{ color: 'black' }}></i>
                            View Assessments
                          </button>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => this.openAttendance()}>
                          <button className="btn btn-sm btn-success ml-2 m-2" >
                            <i className="fa fa-external-link" aria-hidden="true" style={{ color: 'black' }}></i>
                            Attendance Roster
                          </button>
                          {/* <Link className="btn btn-dark" onClick={() => this.openAttendance(this.props.studentAssocBookData.bookId)}>Attendance Roster</Link> */}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  getSelectedStudent: parents.getSelectedStudent(state),
  getAssignStudentToParent: parents.getAssignStudentToParent(state),
  userData: appData.getUserData(state)
})

const d = (d) => ({
  actions: bindActionCreators(
    {
      ...parent.actions,

    },
    d
  )
})
export default connect(s, d)(StudentDashboard);