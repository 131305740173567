import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import * as appData from '.././appSelector'
import * as profileData from './selectors'
import { bindActionCreators } from 'redux'
import { profile } from './index'
import { connect } from 'react-redux'
import { common } from '../common/index'
import * as commonData from '../common/selector'
import Select from 'react-select';
class AddAddressModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addressType: 'billing'
    };
    this.addressForm = React.createRef();
  }

  componentDidMount() {
    this.props.actions.getStateCodeInfo({
      jwt: this.props.userData.jwt,
    })
    this.props.actions.getCountryCodeInfo({
      jwt: this.props.userData.jwt,
    })
  }
  // closeAddressModal = () => {
  //   this.props.onCloseModal();
  // }

  // submitAddressModal = (e) => {
  //   e.preventDefault();
  //   console.log(this.state);
  //   let address;
  //   if(this.state.addressType === 'billing'){
  //     address = {
  //       "billingAddress": "Y",
  //       "billingAddressDefault": "Y",
  //       "shippingAddress": "N",
  //       "shippingAddressDefault": "N",
  //     }
  //   } else{
  //     address = {
  //       "billingAddress": "N",
  //       "billingAddressDefault": "N",
  //       "shippingAddress": "Y",
  //       "shippingAddressDefault": "Y",
  //     }
  //   }
  //   const data = {
  //     ...this.state,
  //     ...address,
  //     userId: this.props.userData.id
  //   };
  //   this.props.actions.updateAddressInfo({ data, jwt: this.props.userData.jwt });
  //   this.addressForm.current.reset();
  //   this.closeAddressModal();
  // }

  // handleInputChange = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   })
  // }

  render() {
    const {
      baseState,
      actions,
      addressModalStatus,
      isShippingAddress,
      currentAddressIndex,
      isEditingAddress
    } = this.props

    const basePath =isEditingAddress 
    ? isShippingAddress
      ? ['addressInfo','shippingAddressDetails', currentAddressIndex]
      :  ['addressInfo','billingAddressDetails', currentAddressIndex]
    : ['newAddress']
    return (
      <Modal show={addressModalStatus}>
        <div
        // ref={this.addressForm}
        // onSubmit={this.submitAddressModal}
        // action=""
        >
          <Modal.Header>
            <Modal.Title>
              {isEditingAddress ? "Edit Address" : "Add Address"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Type Of address</label>
              </div>
              <div className="col-md-8">
                <select
                  required
                  name="addressType"
                  id="addressType"
                  className="css-yk16xz-control"
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "addressType"],
                      value: e.target.value,
                    })
                  }
                  value={baseState.getIn([...basePath, "addressType"], "")}
                >
                  <option value="billing">Billing Address</option>
                  <option value="shipping">Shipping Address</option>
                </select>
                {/* <Select name="addressType" id="addressType" onChange={this.handleStandard} options={['']} /> */}
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>Use this as default</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="checkbox"
                  name="isDefault"
                  id="isDefault"
                  // value="yes"
                  isChecked={baseState.getIn([...basePath, "isDefault"], false)}
                  onClick={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "isDefault"],
                      value: !baseState.getIn(
                        [...basePath, "isDefault"],
                        false
                      ),
                    })
                  }
                  className={
                    baseState.getIn([...basePath, "isDefault"], false)
                      ? "checked"
                      : "notChecked"
                  }
                  //  need to add style here for showing tick
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>street1</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="text"
                  placeholder="street1"
                  name="street1"
                  id="street1"
                  className="css-yk16xz-control"
                  value={baseState.getIn([...basePath, "street1"], "")}
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "street1"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>street2</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="text"
                  placeholder="street2"
                  name="street2"
                  id="street2"
                  className="css-yk16xz-control"
                  value={baseState.getIn([...basePath, "street2"], "")}
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "street2"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>cityName</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="text"
                  placeholder="cityName"
                  name="cityName"
                  id="cityName"
                  className="css-yk16xz-control"
                  value={baseState.getIn([...basePath, "cityName"], "")}
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "cityName"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>landmark</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="text"
                  placeholder="landmark"
                  name="landmark"
                  id="landmark"
                  className="css-yk16xz-control"
                  value={baseState.getIn([...basePath, "landmark"], "")}
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "landmark"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>district</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="text"
                  placeholder="district"
                  name="district"
                  id="district"
                  className="css-yk16xz-control"
                  value={baseState.getIn([...basePath, "district"], "")}
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "district"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="mt-2 row">
              <div className="col-md-4">
                <label>state</label>
              </div>
              <div className="col-md-8">
                <Select
                  // onChange={this.handleStateInfo}
                  value={
                   {
                    value:baseState.getIn([...basePath, "stateCode"], ""),
                    label:baseState.getIn([...basePath, "state"], "")
                   }
                  }
                  onChange={(option) =>
                    actions.setInputStateValue({
                      path: [...basePath],
                      value: option,
                    })
                  }
                  options={this.props.stateCodeInfoOptions}
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>country</label>
              </div>
              <div className="col-md-8">
                <Select
                  value={
                    {
                     value:baseState.getIn([...basePath, "countryCode"], ""),
                     label:baseState.getIn([...basePath, "country"], "")
                    }
                   }
                   onChange={(option) =>
                    actions.setCountry({
                      path: [...basePath],
                      value: option,
                    })
                  }
                  options={this.props.countryCodeInfoOptions}
                />
              </div>
            </div>

            <div className="mt-2 row">
              <div className="col-md-4">
                <label>zipCode</label>
              </div>
              <div className="col-md-8">
                <input
                  required
                  type="text"
                  placeholder="zipCode"
                  name="zipCode"
                  id="zipCode"
                  className="css-yk16xz-control"
                  value={baseState.getIn([...basePath, "zipCode"], "")}
                  onChange={(e) =>
                    actions.setInputValue({
                      path: [...basePath, "zipCode"],
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              className="btn btn-danger btn-sm btn-responsive"
              onClick={() =>
                actions.toggleAddressModal(false) &&
                actions.resetEditAddress() &&
                actions.deleteNewAddreess()
              }
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-primary btn-sm btn-responsive"
              onClick={() =>
                this.props.actions.updateAddressInfo({
                  jwt: this.props.userData.jwt,
                }) &&
                actions.toggleAddressModal(false) &&
                actions.resetEditAddress()
              }
            >
              Add
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  isShippingAddress: profileData.isShippingAddress(state),
  currentAddressIndex: profileData.getCurrentAddressIndex(state),
  isEditingAddress: profileData.isEditingAddress(state),
  baseState: state,
  stateCodeInfoOptions: commonData.getStateCodeInfo(state),
  countryCodeInfoOptions: commonData.getCountryCodeInfo(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...profile.actions,
      ...common.actions,
    },
    d
  )
})

export default connect(s, d)(AddAddressModal)
