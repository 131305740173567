import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import orderTask from "../redux-orders/orderTask";
import * as selectors from "../redux-orders/selector";
import * as appData from "../../appSelector";
import { List } from "immutable";
import "./CartSummary.css";
import { Modal } from "react-bootstrap";
import AlertModal from '../../common/AlertModal';


class Review extends Component {
  handleReviewModalClose(payload){
    this.props.actions.closeOrderPaymentAlertModal(payload);
    window.location.href = '/OrderHistory';
  }
  componentDidMount() {
    this.props.actions.getCartSummaryByUserId({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });
  }

  subTotal = (cartData) => {
    let total = cartData.get("bookDtls", List()).reduce(
      (sub, book) => {
        sub = sub + Number(book.get('totalAmount', 0))
        return sub
      },0
    )
    return total
  }


  render() {
    const { cartSummary, shippingAdressDetails, actions, getinstShipArray } = this.props;
    return (
      
      <div className="container mt-4">
                      {(this.props.getorderBooksSuccessAlert)&&(
          <AlertModal type="success" show={true} title="Success" message={this.props.getorderBooksSuccessMsg} onClose={() => this.handleReviewModalClose()}/>
        )}
              {(this.props.getorderBooksFailureAlert)&&(
          <AlertModal type="danger" show={true} title="Error" message={this.props.getorderBooksFailureMsg} onClose={() => this.handleReviewModalClose()}/>
        )}
        <div className="checkout-button">
       
          <button
            className="btn btn-primary mb-4"
            disabled={!(getinstShipArray.size===cartSummary.size)}
            onClick={() => {
              this.props.actions.ordersBooks({
                jwt: this.props.userData.jwt,
              });
            }}
          >
            Place Order
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered">
          
            <thead>
              <tr>
                <th>Institute Name</th>
                <th>Book Details</th>
                <th>Sub Total</th>
              </tr>
            </thead>
            <tbody>
            {cartSummary.map((cartData, instituteIndex) => (
              <tr key={instituteIndex}>
                <td>
                  <h2>{`${cartData.get("instName", "")}`}</h2>
                </td>
                <td>
                  {cartData.get("bookDtls", List()).map((book, bookIndex) => (
                    <div key ={bookIndex}>
                      <div className="cart-img-wrapper">
                        <img
                          src={book.get("bookImage", "")}
                          alt={"book"}
                          width={"300"}
                          height={"200"}
                        />
                      </div>
                      <div className="cart-content-wrapper">
                        <p className="price">Price: 
                        <span className="rupee"></span>
                          {` ${book.get("bookPrice", "")}`}
                        </p>
                        <p className="quantity">
                          {`Quantity:  ${book.get("quantity", "")}`}
                        </p>
                        <p className="total-amount">Total Amount: 
                        <span className="rupee"></span>
                          {` ${book.get("totalAmount", "")}`}
                        </p>
                      </div>
                    </div>
                  ))}
                </td>
                <td>Sub Total:
                <span className="rupee"></span>
                  {` ${this.subTotal(cartData)}`}
                  <br/>
                  <br/>
                  <div>
                  { 
                    getinstShipArray.filter(address => 
                    address.get("instituteIndexValue", "") === instituteIndex).map((address) => (
                  <div  className="cart-data-wrap">
                    <div className="cart-content-wrapper">
                      {address.get("street1", "")},
                      {address.get("street2", "")},
                      {address.get("landmark", "")},
                      {address.get("cityName", "")},
                      {address.get("district", "")},
                      {address.get("state", "")},
                      {address.get("country", "")},
                      {address.get("zipCode", "")}
                      
                    </div>      
                  </div>
                    ))
                  }    
              </div>
                  <div className="exec-shipping-button">
                  <button 
                    className="btn btn-warning mb-4"
                    onClick={() => {
                      this.props.actions.getShippingAddressForInstitute({
                        instId: cartData.get('instId'),
                      })
                      this.props.actions.setSelectedInstIdShipping({selectedInstId: cartData.get('instId'),
                    instituteIndexValue: instituteIndex})
                      this.props.actions.toggleShippingAddressModal(true)
                    }}
                  >
                    Select Shipping Address
                  </button></div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      
        <Modal show={this.props.shippingAddressModalStatus} >
          <Modal.Header closeButton onClick={() =>
            actions.toggleShippingAddressModal(false)} >
            <Modal.Title className="text-font">Select Shipping Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {shippingAdressDetails.map((address, index) => (
              <div key={index}>
                <div className="cart-data-wrap">
                  <div className="cart-content-wrapper">
                    {address.get("street1", "")}<br/>
                    {address.get("street2", "")}<br/>
                    {address.get("landmark", "")}<br/>
                    {address.get("cityName", "")}<br/>
                    {address.get("district", "")}<br/>
                    {address.get("state", "")}<br/>
                    {address.get("country", "")}<br/>
                    {address.get("zipCode", "")}<br/><br/>
                    </div>
                    <div className="useAddress-button">
                    <button className="btn btn-warning"
                      onClick={() =>
                        actions.setShippingIdAdmin({
                          selectedAddress: address,
                        }) && actions.toggleShippingAddressModal(false)
                      }
                    >
                      Deliver to this address
                    </button>
                    </div>
                  </div>
                
              </div>
            ))}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  cartSummary: selectors.getCartSummary(state),
  cartId: selectors.cartId(state),
  shippingAddressModalStatus: selectors.getShippingAddressModalStatus(state),
  shippingAdressDetails: selectors.getUserAddressDetails(state),
  getorderBooksSuccessAlert: selectors.getorderBooksSuccessAlert(state),
  getorderBooksSuccessMsg: selectors.getorderBooksSuccessMsg(state),
  getorderBooksFailureAlert: selectors.getorderBooksFailureAlert(state),
  getorderBooksFailureMsg: selectors.getorderBooksFailureMsg(state),
  getinstShipArray: selectors.getinstShipArray(state)
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
      ...orderTask.actions
    },
    d
  ),
});
export default connect(s, d)(Review);
