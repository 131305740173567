import React, { Component } from 'react';
import DatePicker from 'rsuite/DatePicker';
import moment from 'moment';
import 'rsuite/dist/rsuite.min.css';
import './weekPicker.css';
import { attendanceConf } from './redux-attendance/index';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as attendanceData from './redux-attendance/selectors';
import * as appData from "../appSelector";
import * as groupData from '../modals/selector';
import { modals } from '../modals/index'
import './attendance.css'
import AttendanceTable from './AttendanceTable';

class AttendanceHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        return (
            <div>
            <AttendanceTable />
            </div>
        );
    }
}

const s = ({ state }) => ({
    // getUserDetails: selectors.getUserDetails(state),
    // mondayData: selectors.mondayData(state),
    userData: appData.getUserData(state),
    getGroupNamesData: groupData.getGroupNames(state),
    getSudentTimeEntryData: attendanceData.getSudentTimeEntrys(state),
})

const d = (d) => ({
    actions: bindActionCreators(
        {
            ...attendanceConf.actions,
            ...modals.actions
        },
        d
    )
})
export default connect(s, d)(AttendanceHome);
