import React, { Component } from 'react'

class SearchBox extends Component {

  state = {
    searchText: ''
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    this.setState({
      searchText: value
    })
    this.props.onSearch(value);
  }

  render() {
    return (
      <div className="my-3 px-3 d-flex text-right justify-content-end searchBox-wrapper">
        <input className="form-control w-25" type="text" onChange={this.handleInputChange} value={this.state.searchText} placeholder="Search Here" />
      </div>
    )
  }

}

export default SearchBox
