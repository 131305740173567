import React, { Component } from "react"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import orderTask from "../redux-orders/orderTask";
import * as selectors from "../redux-orders/selector";
import * as appData from "../../appSelector";
import AlertModal from '../../common/AlertModal';

class Payment extends Component {

  handlePaymentModalClose(payload){
    this.props.actions.closeOrderPaymentAlertModal(payload);
    window.opener.location.href = '/OrderHistory';
    window.close();
  }
    componentDidMount() {
     console.log("I am in payment")
      const userId = this.props.userData.id
      const jwt = this.props.userData.jwt
      const roleId = this.props.userData.roleId
      const shippingId = this.props.getShippingId
      const actions = this.props.actions
        const  loadScript =  (src) =>  {
            return new Promise((resolve) => {
                const script = document.createElement("script");
                script.src = src;
                script.onload = () => {
          
                    resolve(true);
                    let  options = JSON.parse(localStorage.getItem('options'))
                    let cartId = Number(localStorage.getItem('cartId'))
                    options =
                    {
                      ...options,
                      handler: async  (response) => {
                        const responseData = {
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                        };
                        actions.paymentSucessSaga({
                          userId,
                          cartId,
                          jwt,
                          responseData,
                          roleId,
                          shippingId
                        })
              
                    },
                    }
                    if(options) {
                        const paymentObject = new window.Razorpay(options);
                        paymentObject.open();    
                    }
                };
                script.onerror = () => {
                    resolve(false);
                };
                document.body.appendChild(script);
            });
          }

          loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
          )
    }
    render() {
        return  (<div>

        {(this.props.getpaymentFailureAlert)&&(
          <AlertModal type="danger" show={true} title="Failure" message={this.props.getpaymentFailureMsg} onClose={() => this.handlePaymentModalClose(false)}/>
        )}
        {(this.props.getorderBooksForGuestSuccessAlert)&&(
          <AlertModal type="success" show={true} title="Success" message={this.props.getorderBooksForGuestSuccessMsg} onClose={() => this.handlePaymentModalClose(true)}/>
        )}
        {(this.props.getorderBooksForGuestFailureAlert)&&(
          <AlertModal type="danger" show={true} title="Failure" message={this.props.getorderBooksForGuestFailureMsg} onClose={() => this.handlePaymentModalClose(false)}/>
        )}
        {(this.props.getorderBooksSuccessAlert)&&(
          <AlertModal type="success" show={true} title="Success" message={this.props.getorderBooksSuccessMsg} onClose={() => this.handlePaymentModalClose(true)}/>
        )}
        {(this.props.getorderBooksFailureAlert)&&(
          <AlertModal type="danger" show={true} title="Failure" message={this.props.getorderBooksFailureMsg} onClose={() => this.handlePaymentModalClose(false)}/>
        )}
        </div>)
    }
}

// export default Payment



const s = ({ state }) => ({
    userData: appData.getUserData(state),
    cartSummary: selectors.getCartSummary(state),
    cartId: selectors.cartId(state),
    options: selectors.getPayemntOptions(state),
    getpaymentFailureAlert: selectors.getpaymentFailureAlert(state),
    getpaymentFailureMsg: selectors.getpaymentFailureMsg(state),
    getorderBooksSuccessAlert: selectors.getorderBooksSuccessAlert(state),
    getorderBooksSuccessMsg: selectors.getorderBooksSuccessMsg(state),
    getorderBooksFailureAlert: selectors.getorderBooksFailureAlert(state),
    getorderBooksFailureMsg: selectors.getorderBooksFailureMsg(state),
    getorderBooksForGuestSuccessAlert: selectors.getorderBooksForGuestSuccessAlert(state),
    getorderBooksForGuestSuccessMsg: selectors.getorderBooksForGuestSuccessMsg(state),
    getorderBooksForGuestFailureAlert: selectors.getorderBooksForGuestFailureAlert(state),
    getorderBooksForGuestFailureMsg: selectors.getorderBooksForGuestFailureMsg(state),
    getShippingId: selectors.getShippingId(state)
  });
  const d = (d) => ({
    actions: bindActionCreators(
      {
        ...orders.actions,
        ...orderTask.actions
      },
      d
    ),
  });
  export default connect(s, d)(Payment);