import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from 'react-router-dom'

import durchereLogo from '../../assets/logo/durchere.png';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginn } from "../../login/index";
import * as selectors from "../../login/selectors";
import * as app from '../../appSelector'
import RefreshTokenModal from '../../modals/RefreshTokenModal';
import IdleTimer from 'react-idle-timer';
import Loader from '../../Loader'

class ParentinstorgHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            timeout: 1000 * 10 * 30,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }
        this.clearLocalStorage = this.clearLocalStorage.bind(this);
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)

    }

    _onAction(e) {
        console.log('user did something', e)
        this.setState({ isTimedOut: false })
    }

    _onActive(e) {
        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle(e) {
        console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        this.clearLocalStorage()
    }

    clearLocalStorage() {
        console.log(this.props.history, 'history')
        const keyCloak = JSON.parse(localStorage.getItem('keycloak'))
        console.log(keyCloak, 'keycloak keycloak')
        // keyCloak.logout()
        localStorage.clear();
        this.props.actions.resetLoginStatus()
        this.props.actions.setLogoutStatus()
        this.props.history.push('/Login')
        localStorage.setItem('isLogout', true)
        // return <Redirect to ={'/'} push />
    }
    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout}
                />
                {!this.props.startAssessment && (
                    <RefreshTokenModal
                        show={this.props.refreshToken}
                        no={() =>
                            this.props.actions.setRefreshTokenModalStatus(false) &&
                            this.clearLocalStorage()
                        }
                        onGenerateNewToken={() =>
                            this.props.actions.setRefreshTokenModalStatus(false)
                            // this.props.actions.renewToken({
                            //   jwt: this.props.userData.jwt,
                            //   username: this.props.userData.username,
                            // })
                        }
                    />
                )}

                <Navbar collapseOnSelect expand="lg" bg="black" variant="dark">
                    <Link to="/ParentHome">
                        <Navbar.Brand >
                            <img alt="durchere logo" src={durchereLogo} width="32"
                                height="30" />
                        </Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto contentrev  alignitems">
                            <Link className="nav-link" to="/ParentinstorgHome"><span className="textColor">Home</span></Link>
                        </Nav>
                        <Nav>
                            <NavDropdown title={<span><i className="fa fa-user-circle"></i></span>} id="basic-nav-dropdown">
                                <LinkContainer to="/profile">
                                    <NavDropdown.Item>
                                        Profile <i className="fa fa-user"></i>
                                    </NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Item onClick={this.clearLocalStorage}>
                                    logout <i className="fa fa-sign-out"></i>
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Loader show={this.props.overLayLoader} />
            </div>
        );
    }
}

const s = ({ state }) => ({
    userNameErrorMsg: selectors.getUserNameErrorMessage(state),
    isLoggedOut: selectors.getLogoutStatus(state),
    startAssessment: app.startAssessment(state),
    refreshToken: app.getRefreshTokenModalStatus(state),
});
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...loginn.actions,
        },
        d
    ),
});
export default withRouter(connect(s, d)(ParentinstorgHeader));