import axios from 'axios'

import { List, Map } from 'immutable'

import APICONF from '../../config/ApiConfig.js';

import { instOrgAdminConf } from './index'

import instaOrgAdminTask from './instaOrgAdminTask'

export const getBookByOrgUser = (action) => async (
    { dispatch },
    { jwt, orgId, userId }) => {
    const getBooksUrl = APICONF.GET_BOOKS_ORG_USER + "/" + orgId + "/" + userId;
    const [err, result] = await axios.get(getBooksUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}


export const getStandards = (action) => async (
    { dispatch },
    { jwt, orgIdAdmRteInStdrds, userIdAdmRteInStdrds, updateStandards = false}) => {
    const getStandardsUrl = 
      APICONF.GET_STANDARDS_USER + "/"+orgIdAdmRteInStdrds+"/"+userIdAdmRteInStdrds
    const [err, result] = await axios.get(getStandardsUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    if(updateStandards){
        if(err){
            return dispatch(actionToDispatch) &&
            dispatch(instOrgAdminConf.actions.deleteStandardAlertFailure(true))

        }
        else{
            return  dispatch(actionToDispatch) && 
               dispatch(instOrgAdminConf.actions.deleteStandardAlert(true))  

        }
    }
    return dispatch(actionToDispatch)
} 

export const handleSaveStandards = (action) => async (
    { dispatch },
    { jwt, userId, orgId, standardDetails}) => {
    const addStandardsUrl = APICONF.ADD_STANDARDS
    const [err, result] = await axios({
        method: 'post', 
        url: addStandardsUrl,
        headers: {
            "authorization": jwt
        }, 
        data:{
            "userId": userId,
            "orgId": orgId,
            "standardDetails": standardDetails
    
       } 
    })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 


export const updateStandards = (action) => async (
    { dispatch, getState },
    { jwt, userId, orgId,orgIdAdmRteInStdrds, userIdAdmRteInStdrds}) => {
    const updateStandardsUrl = APICONF.UPDATE_STANDARDS
    const currentDeletedModalStandard = getState().state.get('currentDeletedModalStandard',{})
    const [err, result] = await axios({
        method: 'post', 
        url: updateStandardsUrl,
        headers: {
            "authorization": jwt
        }, 
        data:{
            "userId": userId,
            "orgId": orgId,
            "standardDetails": [{
                ...currentDeletedModalStandard,
                activeFlag: 'N'
            }]
    
       } 
    })
     .then(res => [null,res.data])
     .catch( err => [err, null])
     const actionToDispatch = err ? action.failure(err) : action.success(result)
    if(result){
        return new Promise((resolve, reject) => {
            setTimeout(() =>  {
                resolve(
                    dispatch(actionToDispatch) &&
                    dispatch(instOrgAdminConf.actions.getStandards({
                        jwt,
                        orgIdAdmRteInStdrds, 
                        userIdAdmRteInStdrds,
                        updateStandards: true
        
                    })) 
                )
            } 
            , 3000
            )
        })
    }
} 



export const getStandardsUrlInCrses = (action) => async (
    { dispatch },
    { jwt, orgIdAdmRteInCrses, pathSeparator, userIdAdmRteInCrses}) => {
        const getStandardsUrlInCrses = 
        APICONF.GET_STANDARDS_USER + "/" + orgIdAdmRteInCrses + pathSeparator + userIdAdmRteInCrses
    const [err, result] = await axios({
        method: 'get', 
        url: getStandardsUrlInCrses,
        headers: {
            "authorization": jwt
        },
    })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 


export const getCoursesUrlInCrses = (action) => async (
    { dispatch },
    { 
        jwt, 
        orgIdAdmRteInCrses, 
        pathSeparator,
        userIdAdmRteInCrses
    }) => {
        const getCoursesUrlInCrses = 
        APICONF.GET_COURSES_USER + 
        "/" + 
        orgIdAdmRteInCrses + 
        pathSeparator + 
        userIdAdmRteInCrses + 
        pathSeparator + true
    const [err, result] = await axios({
        method: 'get', 
        url: getCoursesUrlInCrses,
        headers: {
            "authorization": jwt
        },
    })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
} 


export const addCourses = (action) => async (
    { dispatch, getState },
    { 
        jwt,
        userId,
        orgId,
        coursesData 
       
    }) => {


    const allCourses = getState()
      .state.getIn(["standardUrl", "responseObject", "standardDetails"], List())
      .map(
          standard => {
              const courseNames = standard.get('courseNames', List())

              let courses = List()

              courseNames.forEach(
                  course => {
                    courses = courses.concat(List(
                        [course.get('courseName','')]
                    ))
                  }
              )

              return standard.set('courseNames',courses)
          }
      )
        
    const [err, result] = await axios({
        method: 'post', 
        url: APICONF.ADD_COURSES,
        headers: {
            "authorization": jwt
        },
        data: {
            userId,
            orgId,
            coursesData: allCourses.toJS()
        }
    })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}  



export const updateCourses = (action) => async (
    { dispatch, getState },
    {   jwt, 
        userId, 
        orgId, 
        orgIdAdmRteInCrses, 
        pathSeparator, 
        userIdAdmRteInCrses
    }) => {
    const updateCoursesUrl = APICONF.UPDATE_COURSES
    const currentStandardIndex = getState().state.get('currentStandardIndex',0)
    const currentCourseIndex = getState().state.get('currentCourseIndex',0)
    const path = [
        "standardUrl",
        "responseObject",
        "standardDetails",
    ]

    const currentStandards = getState().state.getIn([
        ...path,
        currentStandardIndex
    ], Map()).toJS()

    const currentCourses =  getState().state.getIn([
        ...path,
        currentStandardIndex,
        'courseNames',
        currentCourseIndex
    ], Map()).toJS()
    const [err, result] = await axios({
        method: 'post', 
        url: updateCoursesUrl,
        headers: {
            "authorization": jwt
        }, 
        data:{
            "userId": userId,
            "orgId": orgId,
            "removedCourses": [
                {
                   ...currentStandards,
                   ...currentCourses,
                    activeFlag: 'N'
                }
            ]
    
       } 
    })
     .then(res => [null,res.data])
     .catch( err => [err, null])
     const actionToDispatch = err ? action.failure(err) : action.success(result)
     if(result) {
         return new Promise((resolve) => {
             setTimeout(
                resolve(
                    dispatch(
                        instaOrgAdminTask.actions.getCoursesAndStandardData({
                            jwt, 
                            orgIdAdmRteInCrses, 
                            pathSeparator, 
                            userIdAdmRteInCrses,
                            updateCoursesAndStandards: true
                        })
                    ) 
                ),
                3000 
             )
         })
     }
     return dispatch(actionToDispatch)
}

export const getOrganizationDetails = (action) => async (
    { dispatch },
    { jwt, orgId, userId }) => {
    const getBooksUrl = APICONF.GET_ORGANIZATION_DETAILS + "/" + orgId;
    const [err, result] = await axios.get(getBooksUrl,{
        headers: {
            "authorization": jwt
        }
     })
     .then(res => [null,res.data])
     .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
}


