export const organisationDetails = (payload) => (state) => {
  return state.set("organisationDetails", payload.data);
};

export const getUserDetails = (payload) => (state) => {
  return state.set("getUserDetails", payload.data);
};

export const getSudentTimeEntrys = (payload) => (state) => {
  return state.set("sudentTimeEntrysData", payload.data);
};

export const saveStudentTimeEntrysDataSuccess = (payload) => (state) => {
  return state.set("sudentTimeEntrysData", payload.data)
          .set("attendanceSuccessAlert", true)
          .set('attendanceSuccessShortMsg', payload.data.responseMessages[0].shortMessage)
          .set('attendanceSuccessLongMsg', payload.data.responseMessages[0].longMessage)
};

export const saveStudentTimeEntrysDataFailure = (payload) => (state) => {
  return state.set("sudentTimeEntrysData", payload.data)
          .set("attendanceErrorAlert", true)
          .set('attendanceErrorShortMsg', payload.error.response.data.responseMessages[0].shortMessage)
          .set('attendanceErrorLongMsg', payload.error.response.data.responseMessages[0].longMessage)
};

export const closeAttendanceAlertModal = (payload) => (state) => {
  if(payload){
      return state.set('attendanceSuccessAlert', false)
      .set('attendanceSuccessMsg','')
  }else{
      return state.set('attendanceErrorAlert', false)
           .set('attendanceErrorMsg','')
  }
  
}