import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './viewAssessmentsStudent.css'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import * as viewAssessmentsData from '../../../redux-assessmentModule/selector.js'
import ViewAssessmentLinksStudent from './ViewAssessmentLinksStudent.js'
import * as appData from '../../../../appSelector'

class ViewAssessmentsStudentByCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.getViewAssessmentsByCourse = this.getViewAssessmentsByCourse.bind(this);
  }
  componentDidMount() {
    this.getViewAssessmentsByCourse();
  }
  getViewAssessmentsByCourse() {
    const { eachBookId } = this.props.location.state
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.props.actions.getViewAssessmentsByCourse({
      loginUserId: this.props.userData.id,
      jwt: this.props.userData.jwt,
      bookId: eachBookId,
      clientTimeZone: timeZone
    })
  }
  render() {
    var viewFullAssessmentsData = this.props.viewAssessmentsDataPageByCourse;
    if (viewFullAssessmentsData) {
      var showAssessments = viewFullAssessmentsData.map((viewEachAssessmentData, i) => {
        return (
          <ViewAssessmentLinksStudent key={viewEachAssessmentData.testId} viewEachAssessmentData={viewEachAssessmentData} />
        );
      })
    }
    return (
      <>
        {showAssessments}
      </>
    );
  }
}

const s = ({ state }) => ({
  viewAssessmentsDataPageByCourse: viewAssessmentsData.getViewAssessmentsByCourse(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(ViewAssessmentsStudentByCourse);