import React, { Component } from 'react';
import StudentPanel from './StudentPanelLink.js';
import { student } from './index'
import * as studentData from './selectors';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './StudentHome.css'
import * as appData from '.././appSelector'
import { modals } from '../modals/index'
import { executive } from '../executive/redux-executive/index'
import * as execData from '../executive/redux-executive/selector'
import Calendar from "../calendar";
import WithPagination from '../common/WithPagination.js';

class StudentHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3,


    };

  }
  addItemsToCart() {
    let cart = JSON.parse(window.sessionStorage.getItem('cart')) || [];
    let cartTotal = 0;
    cart = cart.map(item => {
      cartTotal += Number(item.totalAmount);
      return { ...item, "instId": Number(Object.keys(this.props.userData.instInfo[0])) };
    })
    console.log(cart);
    const data = {
      "addToCart": {
        "cartTotal": cartTotal,
        "userId": this.props.userData.id
      },
      "cartDetails": cart
    }
    console.log(data);
    this.props.actions.addToCart({ data, jwt: this.props.userData.jwt });
    window.sessionStorage.removeItem('localCart');
    window.sessionStorage.removeItem('cart');
  }

  componentDidMount() {
    this.props.actions.getStudentAssociatedBooks({
      userIdStudent: this.props.userData.id,
      jwt: this.props.userData.jwt
    })
    const isLocalCart = window.sessionStorage.getItem('localCart');
    if (isLocalCart)
      this.addItemsToCart();
  }

  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }

  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }

  render() {
    var studentBooksData = this.props.studentsAssocData;
    const { currentPage, itemsPerPage, searchText } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    let currentItems = []
    studentBooksData && studentBooksData.map((studentBooksDataEach) => {

      currentItems.push(studentBooksDataEach)


    })
    console.log("currentPage", currentPage, "itemsPerPage", itemsPerPage)
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let itemString = '';
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
          }
        }
        return itemString.match(searchTermRegex);
      })
    }

    if (currentItems) {
      var showBooks = 
      currentItems.slice(indexOfFirst, indexOfLast).map(
        (studentAssocBookData, i) => {
        return (
          <StudentPanel key={studentAssocBookData.bookId} studentAssocBookData={studentAssocBookData} />
        );
      })
    }
    return (
      <div className='mt-4'>
        <div className={`${this.props.isCalendarShown ? 'move-left' : ''}`}>
          <div style={{
            paddingBottom: '5px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer'
          }}
            onClick={() => this.props.actions.toggleCalendar(!this.props.isCalendarShown)}
          >
            <img
              src={'https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png'}
              alt="Calendar"
            />
          </div>
          <div style={{ "margin-bottom": '70px' }}>
          <WithPagination
              itemsPerPage={itemsPerPage}
              totalItems={currentItems.length}
              currentPage={currentPage}
              onSearch={this.onSearch}
              onPaginate={this.onPaginate}

            >
            {showBooks}
            </WithPagination>
          </div>

        </div>
        {
          this.props.isCalendarShown &&
          <div className="calender-wrapper">
            <a className="calendar-external-icon" href="/calendar" target="_blank"><i className="fa-external-link fa" aria-hidden="true" style={{ color: 'black', fontFamily: "FontAwesome" }} /></a>
            <Calendar callApiGetAPI={true} />
          </div>
        }
      </div>
    );

  }
}
const s = ({ state }) => ({
  studentsAssocData: studentData.getStudentsData(state),
  userData: appData.getUserData(state),
  isCalendarShown: execData.isCalendarShown(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...student.actions,
      ...modals.actions,
      ...executive.actions,
    },
    d
  )
})
export default connect(s, d)(StudentHome);