import { createAction } from 'redux-act'
import { createAsyncAction } from '../../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getCartSummaryByUserId = createAsyncAction(
    `GET_CART_SUMMARY_BY_USER_ID`,
    payload => payload
)

const getGuestCartSummaryByUserId = createAsyncAction(
    `GET_GUEST_CART_SUMMARY_BY_USER_ID`,
    payload => payload
)

const changeQuantity = createAction(
    `CHANGE_QUANTITY`,
    payload => payload
)

const changeGuestQuantity = createAction(
    `CHANGE_GUEST_QUANTITY`,
    payload => payload
)

const changeQuantityForGuestSummary = createAction(
    `CHANGE_QUANTITY_FOR_GUEST_SUMMARY`,
    payload => payload
)

const updateCart = createAsyncAction(
    `UPDATE_CART`,
    payload => payload
)

const deleteCart = createAsyncAction(
    `DELETE_CART`,
    payload => payload
)

const setCurrentDataIndex = createAction(
    `SET_CURRENT_DATA_INDEX`,
    payload => payload
)

const ordersBooks = createAsyncAction(
    `ORDER_BOOKS`,
    payload => payload
)

const addToGuestCart = createAsyncAction(
    `ADD_TO_GUEST_CART`,
    payload => payload
  )

const updateGuestCart = createAsyncAction(
    `UPDATE_GUEST_CART`,
    payload => payload
  )
const deleteGuestCart = createAsyncAction(
    `DELETE_GUEST_CART`,
    payload => payload
  )

const loadRazoPayScript = createAsyncAction(
    `LOAD-RAZO_PAY_SCRIPT`,
    payload => payload
)

const paymentOrders = createAsyncAction(
    `PAYMENT_ORDERS`,
    payload => payload
)

const openPaymentUI = createAsyncAction(
    `OPEN_PAYMENT_UI`,
    payload => payload
)

const paymentSuccess = createAsyncAction(
    `PAYMENT_SUCCESS`,
    payload => payload
)

const orderBooksForGuest = createAsyncAction(
    `ORDER_BOOKS_FOR_GUESTS`,
    payload => payload
)

const resetAddToCartAlerts = createAction(
    `RESET_ADD_TO_CART_ALERTS`,
    payload => payload 
)
const getUserAddressDetails = createAsyncAction(
    `USER_ADDRESS_DETAILS`,
    payload => payload
)

const setShippingId = createAction(
    `SET_SHIPPING_ID`,
    payload => payload
)

const setShippingIdAdmin = createAction(
  `SET_SHIPPING_ID_ADMIN`,
  payload => payload
)
const setSelectedInstIdShipping = createAction(
  `SET_SELECTED_INSTID_SHIPPING`,
  payload => payload
)
const toggleShippingAddressModal = createAction(
    `TOGGLE_SHIPPING_ADDRESS_MODAL`,
    payload => payload
)

const getAllOrderDetailsByUserId = createAsyncAction(
    `GET_ALL_ORDER_DETAILS_BY_USER_ID`,
    payload => payload
)

const resestUpdatedCartAlerts = createAction(
    `REST_UPDATED_CART_ALERTS`,
    payload => payload
)

const selectOrderHistoryYear = createAction(
 `SELECT ORDER HISTORY YEAR`,
 payload => payload
)

const resetDeleteCartAlerts = createAction(
    `RESET_DELETE_CARTS_ALERTS`,
    payload => payload
)

const setCurrentOrderIndex = createAction(
  `SET_CURRENT_ORDER_INDEX`,
  payload => payload
)

const getShippingAddressForInstitute = createAsyncAction(
  `GET SHIPPING ADDRESS FOR INSTITUTE`,
  payload => payload
)

const closeOrderPaymentAlertModal = createAction(
  `CLOSE_ORDER_PAYMENT_ALERT_MODAL`,
  payload => payload
)



const reducer = {
  [getCartSummaryByUserId.success]: (state, payload) =>
    mutators.getCartSummaryByUserId(payload)(state),
  [getGuestCartSummaryByUserId.success]: (state, payload) =>
    mutators.getGuestCartSummaryByUserId(payload)(state),
  [addToGuestCart.success]: (state, payload) =>
    mutators.addToGuestCartSuccess(payload)(state),

  [resetAddToCartAlerts]: (state, payload) =>
    mutators.resetAddToCartAlerts(payload)(state),

  [paymentOrders.success]: (state, payload) =>
    mutators.paymentOrders(payload)(state),
  [paymentOrders.failure]: (state, payload) =>
    mutators.paymentOrdersFailure(payload)(state),
  [addToGuestCart.failure]: (state, payload) =>
    mutators.addToGuestCartError(payload)(state),
  [changeQuantity]: (state, payload) => mutators.changeQuantity(payload)(state),
  [changeGuestQuantity]: (state, payload) =>
    mutators.changeGuestQuantity(payload)(state),
  [setCurrentDataIndex]: (state, payload) =>
    mutators.setCurrentDataIndex(payload)(state),
  [updateCart.success]: (state, payload) =>
    mutators.updateCartSucess(payload)(state),

  [updateCart.failure]: (state, payload) =>
    mutators.updateCartFailure(payload)(state),
  [updateGuestCart.success]: (state, payload) =>
    mutators.updateCartSucess(payload)(state),

  [updateGuestCart.failure]: (state, payload) =>
    mutators.updateCartFailure(payload)(state),

  [changeQuantityForGuestSummary]: (state, payload) =>
    mutators.changeQuantityForGuestSummary(payload)(state),

  [getUserAddressDetails.success]: (state, payload) =>
    mutators.setUserAddressDetails(payload)(state),

  [setShippingId]: (state, payload) => mutators.setShippingId(payload)(state),

  [setSelectedInstIdShipping]: (state, payload) => mutators.setSelectedInstIdShipping(payload)(state),
  

  [setShippingIdAdmin]: (state, payload) => mutators.setShippingIdAdmin(payload)(state),

  [toggleShippingAddressModal]: (state, payload) =>
    mutators.toggleShippingAddressModal(payload)(state),

  [getAllOrderDetailsByUserId.success]: (state, payload) =>
    mutators.setAllOrderDetailsByUserId(payload)(state),

  [resestUpdatedCartAlerts]: (state, payload) =>
    mutators.resestUpdatedCartAlerts(payload)(state),

  [deleteGuestCart.success]: (state, payload) =>
    mutators.deleteCartSuccess(payload)(state),

  [deleteGuestCart.failure]: (state, payload) =>
    mutators.deleteCartFailure(payload)(state),

  [deleteCart.success]: (state, payload) =>
    mutators.deleteCartSuccess(payload)(state),

  [deleteCart.failure]: (state, payload) =>
    mutators.deleteCartFailure(payload)(state),

  [resetDeleteCartAlerts]: (state, payload) =>
    mutators.resetDeleteCartAlerts(payload)(state),

  [selectOrderHistoryYear]: (state, payload) => 
    mutators.selectOrderHistoryYear(payload)(state),

  [setCurrentOrderIndex]: (state, payload) =>
    mutators.setCurrentOrderIndex(payload)(state),
  
  [paymentSuccess.success]: (state, payload) =>
    mutators.paymentSuccess(payload)(state),
  [paymentSuccess.failure]: (state, payload) =>
    mutators.paymentSuccess(payload)(state),
  
  [orderBooksForGuest.success]: (state, payload) =>
    mutators.orderBooksForGuestSuccess(payload)(state),
  [orderBooksForGuest.failure]: (state, payload) =>
    mutators.orderBooksForGuestFailure(payload)(state),

  [ordersBooks.success]: (state, payload) =>
    mutators.orderBooksSuccess(payload)(state),
  [ordersBooks.failure]: (state, payload) =>
    mutators.orderBooksFailure(payload)(state),

  [getShippingAddressForInstitute.success]: (state, payload) => 
    mutators.getShippingAddressForInstituteSuceess(payload)(state),

  [getShippingAddressForInstitute.failure]: (state, payload) => 
    mutators.getShippingAddressForInstituteFailure(payload)(state),

  [closeOrderPaymentAlertModal]: (state, payload) =>
    mutators.closeOrderPaymentAlertModal(payload)(state),
   
}

const restApi = {
    [getCartSummaryByUserId]: api.getCartSummaryByUserId(getCartSummaryByUserId),
    [getGuestCartSummaryByUserId]: api.getGuestCartSummaryByUserId(getGuestCartSummaryByUserId),
    [updateCart]: api.updateCart(updateCart),
    [deleteCart]: api.deleteCart(deleteCart),
    [updateGuestCart]: api.updateGuestCart(updateGuestCart),
    [deleteGuestCart]: api.deleteGuestCart(deleteGuestCart),
    [ordersBooks]: api.ordersBooks(ordersBooks),
    [addToGuestCart]: api.addToGuestCart(addToGuestCart),
    [loadRazoPayScript]: api.loadRazoPayScript(loadRazoPayScript),
    [paymentOrders]: api.paymentOrders(paymentOrders),
    [openPaymentUI]: api.openPaymentUI(openPaymentUI),
    [paymentSuccess]: api.paymentSuccess(paymentSuccess),
    [orderBooksForGuest]: api.orderBooksForGuest(orderBooksForGuest),
    [getUserAddressDetails]: api.getUserAddressDetails(getUserAddressDetails),
    [getAllOrderDetailsByUserId]: api.getAllOrderDetailsByUserId(getAllOrderDetailsByUserId),
    [getShippingAddressForInstitute]: api.getShippingAddressForInstitute(getShippingAddressForInstitute)
}

export default {
    actions: {
        getCartSummaryByUserId,
        getGuestCartSummaryByUserId,
        changeQuantity,
        updateCart,
        deleteCart,
        setCurrentDataIndex,
        ordersBooks,
        addToGuestCart,
        updateGuestCart,
        deleteGuestCart,
        changeGuestQuantity,
        changeQuantityForGuestSummary,
        loadRazoPayScript,
        paymentOrders,
        paymentSuccess,
        orderBooksForGuest,
        resetAddToCartAlerts,
        getUserAddressDetails,
        setShippingId,
        setShippingIdAdmin,
        setSelectedInstIdShipping,
        toggleShippingAddressModal,
        getAllOrderDetailsByUserId,
        resestUpdatedCartAlerts,
        resetDeleteCartAlerts,
        selectOrderHistoryYear,
        setCurrentOrderIndex,
        getShippingAddressForInstitute,
        closeOrderPaymentAlertModal
    },
    restApi,
    reducer
}