import { fromJS, Map, List } from 'immutable'

export const createAssessment = (payload) => (state) => {
    return state.set('createAssessmentData', payload.data)
            .set('createAssessmentAlert',true)
            .set('createAssessmentSuccessMsg','Successfully created the Assessment')
}

export const createAssessmentError = (payload) => (state) => {
    return state.set('createAssessmentErrorAlert', true)
    .set('createAssessmentErrorMsg','Failed to create the Assessment')
}

export const getViewAssessments = (payload) => (state) => {
    return state.set('viewAssessmentsData', payload.data)
}

export const addQuestionToAssessment = (payload) => (state) => {
    return state.set('addQuestionToAssessmentData', payload.data)
            .set('createQuestionnaireAlert',true)
            .set('createQuestionnaireSuccessMsg','Successfully added the Question to Questionnaire')
}

export const addQuestionToAssessmentError = (payload) => (state) => {
    return state.set('createQuestionnaireFailAlert', true)
    .set('createQuestionnaireErrorMsg','Failed to add the Question to Questionnaire')
}

export const closeQuestionnaireModal = (payload) => (state) => {
    return state.set('createQuestionnaireAlert', false)
    .set('createQuestionnaireFailAlert', false)
    .set('createQuestionnaireErrorMsg','')
    .set('createQuestionnaireSuccessMsg','')
}

export const getQuestions = payload => state => {
    return state.set('questions', fromJS(payload.data))
        .set('passageIndex', 0)
}

export const getAssmentAssocParagraphs = (payload) => (state) => {
    return state.set('paragraphsData', payload.data)
}

export const addParagraphToAssessment = (payload) => (state) => {
    return state.set('addparagraphData', payload.data)
                .set('createQuestionnaireAlert',true)
                .set('createQuestionnaireSuccessMsg','Successfully added the Paragraph to Questionnaire')
}

export const addParagraphToAssessmentError = (payload) => (state) => {
    return state.set('createQuestionnaireAlert', true)
    .set('createQuestionnaireErrorMsg','Failed to add the Paragraph to Questionnaire')
}


export const setAnswerOptionsData = (payload) => (state) => {
    const answerOptionsData = state.get('answerOptionsData', Map()).merge(fromJS(payload))
    return state.set('answerOptionsData', answerOptionsData);
}

export const getViewAssessmentsByCourse = (payload) => (state) => {
    return state.set('viewAssessmentsDataByCourse', payload.data)
}

export const showQuestionsRelatedToParaGraph = payload => state =>
    state.set('showQuestionsRelatedToParaGraph', true)

export const currentQuestionIndex = ({ currentIndex, isDecrease }) => state => {
    const passageIndexMutator = state.get('passageIndex', 0);
    if (isDecrease) {
        const index = currentIndex - 1 === -1 ? (currentIndex - 1) : Math.abs(currentIndex - 1)
        if ((currentIndex - 1) === -1) {
            return state.set('showQuestionsRelatedToParaGraph', false)
                .set('currentQuestionIndex', 0)
        }
        return state.set('currentQuestionIndex', index)
    } else {
        const currentIndexHasQues = state.getIn(
            [
                'questions',
                'passages',
                passageIndexMutator,
                'passagesQuestions',
                currentIndex + 1,
            ], Map()).size > 0

        if (currentIndexHasQues) {
            return state.set('currentQuestionIndex', (Math.abs(currentIndex + 1)))
        }
        else {
            const currentIndexHasPassagges = state.getIn(
                [
                    'questions',
                    'passages',
                    passageIndexMutator + 1,
                ], List()).count() > 0

            if (currentIndexHasPassagges) {
                return state.set('passageIndex', passageIndexMutator + 1)
                    .set('currentQuestionIndex', 0).set('showregularQuestions', false)
                    .set('showQuestionsRelatedToParaGraph', false)
            }
            else {
                const currentIndexHasQuestions = state.getIn(
                    [
                        'questions',
                        'questions',
                    ],List()).count()>0
                    if(currentIndexHasQuestions){
                        return state.set('showregularQuestions', true).set('showQuestionsRelatedToParaGraph', false)
                        .set("currentQuestionIndex",0)
                        .set("passageIndex",0)
                    }
                else{
                    return state.set('showQuestionsRelatedToParaGraph', false)
                }
            }
        }
    }
}


export const questionsIndex = ({ regularQuestionIndex }) => state => {
    const currentIndexHasQuestions = state.getIn(
        [
            'questions',
            'questions',
            regularQuestionIndex,
        ], List()).count() > regularQuestionIndex

    if (regularQuestionIndex === -1) {
        return state.set('showregularQuestions', false)
            .set('showQuestionsRelatedToParaGraph', true)
    }
    if (currentIndexHasQuestions) {
        return state.set('questionsIndex', regularQuestionIndex)
    }
    else {
        return state.set('showregularQuestions', false)
               .set('questionsIndex',0)
    }
}

export const startAssessment = payload => state => {
    return state.set('questionsOfStartAssessment', fromJS(payload.data))
        .set('passageIndexOfStartAssessment', 0)
}

export const showQuestionsForParaGraphStartAssessment = payload => state =>
    state.set('showQuestionsForParaGraphStartAssessment', true)

export const currentQuestionIndexStartAssessment = ({ currentIndex, isDecrease }) => state => {
    const passageIndexMutator = state.get('passageIndexOfStartAssessment', 0);
    if (isDecrease) {
        const index = currentIndex - 1 === -1 ? (currentIndex - 1) : Math.abs(currentIndex - 1)
        if ((currentIndex - 1) === -1) {
            return state.set('showQuestionsForParaGraphStartAssessment', false)
                .set('currentQuestionStartAssessmentIndex', 0)
        }
        return state.set('currentQuestionStartAssessmentIndex', index)
    } else {
        const currentIndexHasQues = state.getIn(
            [
                'questionsOfStartAssessment',
                'passages',
                passageIndexMutator,
                'passagesQuestions',
                currentIndex + 1,
            ], Map()).size > 0
        if (currentIndexHasQues) {
            return state.set('currentQuestionStartAssessmentIndex', (Math.abs(currentIndex + 1)))
        }
        else {
            const currentIndexHasPassagges = state.getIn(
                [
                    'questionsOfStartAssessment',
                    'passages',
                    passageIndexMutator + 1,
                ], List()).count() > 0
            if (currentIndexHasPassagges) {
                return state.set('passageIndexOfStartAssessment', passageIndexMutator + 1)
                    .set('currentQuestionStartAssessmentIndex', 0).set('showregularQuestionsStartAssessment', false)
                    .set('showQuestionsForParaGraphStartAssessment', false)
            }
            else {
                const currentIndexHasQuestions = state.getIn(
                    [
                        'questionsOfStartAssessment',
                        'questions',
                    ],List()).count()>0
                    if(currentIndexHasQuestions){
                        return state.set('showregularQuestionsStartAssessment', true).set('showQuestionsForParaGraphStartAssessment', false)
                        .set("currentQuestionStartAssessmentIndex",0)
                        .set("passageIndexOfStartAssessment",0)
                    }
                else{
                    return state.set('showQuestionsForParaGraphStartAssessment', false)
                }
            }
        }
    }
}


export const questionsIndexStartAssessment = ({ regularQuestionIndex }) => state => {
    const currentIndexHasQuestions = state.getIn(
        [
            'questionsOfStartAssessment',
            'questions',
        ],List()).count()>regularQuestionIndex
    if (regularQuestionIndex === -1) {
        return state.set('showregularQuestionsStartAssessment', false)
            .set('showQuestionsForParaGraphStartAssessment', true)
    }
    if (currentIndexHasQuestions) {
        return state.set('questionsStartAssessmentIndex', regularQuestionIndex)
    }
    else {
        return state.set('showregularQuestionsStartAssessment', false)
        .set('questionsStartAssessmentIndex',0)
    }
}

export const closeAlertModal = (payload) => (state) => {
    if(payload){
        return state.set('createAssessmentAlert', false)
        .set('createAssessmentErrorMsg','')
        .set('createAssessmentSuccessMsg','')
    }else{
        return state.set('createAssessmentErrorAlert', false)
             .set('createAssessmentErrorMsg','')
    }
    
}

export const showDeleteAssessmentModal = (payload) => (state) => {
    return state.set('showDeleteAssessmentModal', true)
}

export const currentTestId = (payload) => (state) => {
    return state.set('currentTestId', payload)
}

export const getAllResultsByTestId = (payload) => (state) => {
    return state.set('allResultsDataByTestId', payload.data)
}

export const setCureentInputValue = (payload) => state => {
    return state.set('getCurrentAnswer',payload)
}


export const setResultsOfStudent = (
{
    marksForQuestion, 
    testQuestionnaireId, 
    testAnswerId, 
    correctOption,
    answerOption,
    inputType 
}
) => state => {
    if(inputType === 0) {
        const getCurrentAnswer = state.get('getCurrentAnswer','')
        
            return state
                    .setIn(
                            ['resultsOfStudent',testQuestionnaireId ],
                                Map({
                                    marksForQuestion, 
                                    testQuestionnaireId, 
                                    testAnswerId, 
                                    correctOption : answerOption === getCurrentAnswer ? 'Y': 'N' ,
                                    answerOption: getCurrentAnswer
                                })
                    )
    }

    if(inputType === 1) {
        return  state
                 .setIn(
                        ['resultsOfStudent',testQuestionnaireId ],
                            Map({
                                marksForQuestion, 
                                testQuestionnaireId, 
                                testAnswerId, 
                                correctOption,
                                answerOption 
                            })
                    )

    }
    return state

}


export const totalMarks = () => state => {
    const getResultsOfStudent = state.get('resultsOfStudent',Map())
    const totalMarks = getResultsOfStudent.reduce((total,student,index) => {
        if(student.get('correctOption','') === 'Y') {
            total = total + student.get('marksForQuestion',0)
        }
        return total
    },0)
    window.localStorage.setItem('endAssesment', true)
    return state.set('totalMarks',totalMarks).set('endAssessment',true)
}

export const getResultsByuser = payload => state => {

    const questionsData = fromJS(payload.data)
    const passages = questionsData.get("passages",List())
    passages.forEach(passageData => {
        const passageQuestions = passageData.get("passagesQuestions", List());
        passageQuestions.forEach(passageQuestionsData => {
            const chosenOption = passageQuestionsData.get("chosenOption", Map());
            state = state.setIn(['eachAnswer', chosenOption.get("testQuestionnaireId",'')], chosenOption.get("testAnswerValue",''))
        });
    });
    const regularQuestionsData = questionsData.get("questions",List())
    regularQuestionsData.forEach(regularQuestion => {
        const chosenOption = regularQuestion.get("chosenOption", Map());
            state = state.setIn(['eachAnswer', chosenOption.get("testQuestionnaireId",'')]
            , chosenOption.get("testAnswerValue",''))
        
    });
    return state.set('questionsOfResultsByuser', fromJS(payload.data))
        .set('passageIndexOfResultsByuser', 0)
}

export const showQuestionsForParaGraphResultsByuser = payload => state =>
    state.set('showQuestionsForParaGraphResultsByuser', true)

export const currentQuestionIndexResultsByuser = ({ currentIndex, isDecrease }) => state => {
    const passageIndexMutator = state.get('passageIndexOfResultsByuser',0);
    if (isDecrease) {
        const index = currentIndex - 1 === -1 ? (currentIndex - 1) : Math.abs(currentIndex - 1)
        if ((currentIndex - 1) === -1) {
            return state.set('showQuestionsForParaGraphResultsByuser', false)
                .set('currentQuestionResultsByuserIndex', 0)
        }
        return state.set('currentQuestionResultsByuserIndex', index)
    } else {
        const currentIndexHasQues = state.getIn(
            [
                'questionsOfResultsByuser',
                'passages',
                passageIndexMutator,
                'passagesQuestions',
                currentIndex + 1,
            ], Map()).size > 0

        if (currentIndexHasQues) {
            return state.set('currentQuestionResultsByuserIndex', (Math.abs(currentIndex + 1)))
        }
        else {
            const currentIndexHasPassagges = state.getIn(
                [
                    'questionsOfResultsByuser',
                    'passages',
                    passageIndexMutator + 1,
                ], Map()).size > 0

            if (currentIndexHasPassagges) {
                return state.set('passageIndexOfResultsByuser', passageIndexMutator + 1)
                    .set('currentQuestionResultsByuserIndex', 0).set('showregularQuestionsResultsByuser', false)
                    .set('showQuestionsForParaGraphResultsByuser', false)
            }
            else {
                return state.set('showregularQuestionsResultsByuser', true).set('showQuestionsForParaGraphResultsByuser', false)
            }
        }
    }
}


export const questionsIndexResultsByuser = ({ regularQuestionIndex }) => state => {
    const currentIndexHasQuestions = state.getIn(
        [
            'questionsOfResultsByuser',
            'questions',
            regularQuestionIndex,
        ], Map()).size > 0
    if (regularQuestionIndex === -1) {
        return state.set('showregularQuestionsResultsByuser', false)
            .set('showQuestionsForParaGraphResultsByuser', true)
    }
    if (currentIndexHasQuestions) {
        return state.set('questionsResultsByuserIndex', regularQuestionIndex)
    }
    else {
        return state.set('showregularQuestionsResultsByuser', false)
    }
}

export const setCurrentAnswer = ({testQuestionnaireId, value}) => state => {
    return state.setIn(['eachAnswer',testQuestionnaireId], value)
}

export const endAssessmentModal = (payload) => (state) => {
    return state.set('showEndAssessmentModal', true)
}

export const endAssessmentTimerEndModal = (payload) => (state) => {
    return state.set('showEndAssessmentTimerModal', true)
}


export const setStartAssessment = payload => state => {
    return state.set('startAssessment', payload)
}

