import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as videoStreamingData from '../redux-videoStreaming/selectors'
import * as appData from '../../appSelector';
import './CourseVideoView.css';
import { videoStreamingConf } from '../redux-videoStreaming/index';
import YourLearningList from '../YourLearningList';
import { Link } from 'react-router-dom'
import CourseVideoContent from './CourseVideoContent';

class CourseVideoMainView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openLearningList: false
        };
        this.openLearningList = this.openLearningList.bind(this);
    }

    openLearningList() {
        this.setState({
            openLearningList: true
        })
    }
    componentDidMount() {
        this.props.actions.getVideosByBookResourceId({
            bookResourceId: this.props.location.state.eachBookResourceByBookId.bookResourceId,
            jwt: this.props.userData.jwt
        })
    }
    render() {
        console.log("this.props", this.props)
        const { eachBookResourceByBookId } = this.props.location.state
        return (
            <div>
                <div className="container-fluid remove-all-padding-course-video">
                    <div>
                            <CourseVideoContent />                        
                    </div>
                </div>
                <div className="container-fluid remove-all-padding-course-video">
                    <div className='top-container-course-video'>
                        <div>
                            <div className='row p-2'>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={{ pathname: '/AddBooks' }}>Books</Link></li>
                                        <li className="breadcrumb-item"><Link to={{ pathname: '/view-resources', state: { bookId: eachBookResourceByBookId.bookId } }}>Resources</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Course Videos View</li>
                                    </ol>
                                </nav>
                            </div>
                            <div className='row p-2'>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <h1 className='text-color-white'>{eachBookResourceByBookId.bookResourceName}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className='row mt-3'>
                            <div className="btn-group" role="group" aria-label="Learning List">
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-light" onClick={this.openLearningList} >
                                    Your Learning List
                                </button>
                            </div>
                        </div>
                        <hr />
                    </div>
                    {this.state.openLearningList && (
                        <div className='row p-4'>
                            <YourLearningList resourceId={eachBookResourceByBookId.bookResourceId} />
                        </div>
                    )}

                </div>
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getVideosByBookResourceId: videoStreamingData.getVideosByBookResourceId(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(CourseVideoMainView);
