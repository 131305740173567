import { createAction } from 'redux-act'
import { createAsyncAction } from '../lib/utils'

import * as api from './api'
import * as mutators from './mutators'

const getDefaultGroupNames = createAsyncAction(
  `GET_DEFAULT_GROUP_NAMES`,
  payload => payload
)

const getStateCodeInfo = createAsyncAction(
  `GET_STATE_CODE_INFO`,
  payload => payload
)

const getCountryCodeInfo = createAsyncAction(
  `GET_COUNTRY_CODE_INFO`,
  payload => payload
)
const openupdateOrderStatusModal = createAction(
  `OPEN_UPDATE_ORDER_STATUS_MODAL`,
  (payload) => payload
);

const closeupdateOrderStatusModal = createAction(
  `CLOSE_UPDATE_ORDER_STATUS_MODAL`,
  (payload) => payload
);

const getAssignParentToStudentByStudentId = createAsyncAction(
  `GET_ASSIGNED_PARENT_TO_STUDENT_BY_STUDENT_ID`,
  payload => payload
);

const notificationhandler = createAction(
  `NOTIFICATION_HANDLER`,
  (payload) => payload
);

const reducer = {
  [getDefaultGroupNames.success]: (state, payload) =>
    mutators.getDefaultGroupNames(payload)(state),
  [getStateCodeInfo.success]: (state, payload) =>
    mutators.getStateCodeInfo(payload)(state),
  [getCountryCodeInfo.success]: (state, payload) =>
    mutators.getCountryCodeInfo(payload)(state),
  [openupdateOrderStatusModal]: (state, payload) =>
    mutators.openupdateOrderStatusModal(payload)(state),
  [closeupdateOrderStatusModal]: (state, payload) =>
    mutators.closeupdateOrderStatusModal(payload)(state),
  [getAssignParentToStudentByStudentId.success]: (state, payload) =>
    mutators.getAssignParentToStudentByStudentId(payload)(state),
  [notificationhandler]: (state, payload) =>
    mutators.notificationhandler(payload)(state),
}

const restApi = {
  [getDefaultGroupNames]: api.getDefaultGroupNames(getDefaultGroupNames),
  [getStateCodeInfo]: api.getStateCodeInfo(getStateCodeInfo),
  [getCountryCodeInfo]: api.getCountryCodeInfo(getCountryCodeInfo),
  [getAssignParentToStudentByStudentId]: api.getAssignParentToStudentByStudentId(getAssignParentToStudentByStudentId),

}

export default {
  actions: {
    getDefaultGroupNames,
    getStateCodeInfo,
    getCountryCodeInfo,
    openupdateOrderStatusModal,
    closeupdateOrderStatusModal,
    getAssignParentToStudentByStudentId,
    notificationhandler
  },
  restApi,
  reducer
}