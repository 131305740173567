import {Map, List} from 'immutable'
import { createSelector } from 'reselect'
export const getViewAssessments = state => state.get('viewAssessmentsData', '')
export const getAssmentAssocParagraphs = state => state.get('paragraphsData', '')
export const getAnswerOptionsData = state => state.get('answerOptionsData', Map())
export const getViewAssessmentsByCourse = state => state.get('viewAssessmentsDataByCourse', '')
export const getQuestions = state => state.get('questions',Map())
export const getPassageQuestionIndex = state => state.get('passageIndex',0)
export const showQuestionsRelatedToParaGraph = state => state.get('showQuestionsRelatedToParaGraph',false)
export const getCurrentQuestionIndex = state => state.get('currentQuestionIndex',0)
export const getQuestionsIndex = state => state.get('questionsIndex',0)
export const showregularQuestions = state => state.get('showregularQuestions',false)
export const questionsOfStartAssessment = state => state.get('questionsOfStartAssessment',Map())
export const passageIndexOfStartAssessment = state => state.get('passageIndexOfStartAssessment',0)
export const showQuestionsForParaGraphStartAssessment = state => state.get('showQuestionsForParaGraphStartAssessment',false)
export const currentQuestionStartAssessmentIndex = state => state.get('currentQuestionStartAssessmentIndex',0)
export const questionsStartAssessmentIndex = state => state.get('questionsStartAssessmentIndex',0)
export const showregularQuestionsStartAssessment = state => state.get('showregularQuestionsStartAssessment',false)
export const getCreateAssessmentErrorAlert = state => state.get('createAssessmentErrorAlert',false)
export const getCreateAssessmentSuccessAlert = state => state.get('createAssessmentAlert',false)
export const getCreateAssessmentSuccessMsg = state => state.get('createAssessmentSuccessMsg','')
export const getCreateAssessmentErrorMsg = state => state.get('createAssessmentErrorMsg','')
export const showDeleteAssessmentModal = state => state.get('showDeleteAssessmentModal',false)
export const getAllResultsByTestId = state => state.get('allResultsDataByTestId','')

export const questionsOfResultsByuser = state => state.get('questionsOfResultsByuser',Map())
export const passageIndexOfResultsByuser = state => state.get('passageIndexOfResultsByuser',0)
export const showQuestionsForParaGraphResultsByuser = state => state.get('showQuestionsForParaGraphResultsByuser',false)
export const currentQuestionResultsByuserIndex = state => state.get('currentQuestionResultsByuserIndex',0)
export const questionsResultsByuserIndex = state => state.get('questionsResultsByuserIndex',0)
export const showregularQuestionsResultsByuser = state => state.get('showregularQuestionsResultsByuser',false)
export const getEachAnswer = state => state.get('eachAnswer', Map())
export const getCurrentAnswer = createSelector(
    getEachAnswer,
    (getEachAnswer) =>{
        return (currentQuestionnaireId)=>{
                return getEachAnswer.get(currentQuestionnaireId,'')
        }
    }
)


export const getCreateQuestionnaireSuccessAlert = state => state.get('createQuestionnaireAlert',false)
export const getCreateQuestionnaireSuccessMsg = state => state.get('createQuestionnaireSuccessMsg','')
export const getCreateQuestionnaireErrorAlert = state => state.get('createQuestionnaireFailAlert',false)
export const getCreateQuestionnaireErrorMsg = state => state.get('createQuestionnaireErrorMsg','')
export const endAssessmentModal = state => state.get('showEndAssessmentModal',false)
export const endAssessmentTimerEndModal = state => state.get('showEndAssessmentTimerModal',false)
export const showOnlyRegularQuestions = state => {
    return state.getIn(['questionsOfStartAssessment', 'passages'],List()).count() === 0 
}
export const showOnlyRegularQuestionsForQuestionaire = state => {
    return state.getIn(['questions', 'passages'],List()).count() === 0 
}


