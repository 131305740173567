import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List } from "immutable";
import Card from "react-bootstrap/Card";
import * as appData from ".././appSelector";
import * as profileData from "./selectors";
import { profile } from "./index";
import { student } from "../student/index";
import AddAddressModal from "./AddAddressModal";
import { Alert } from 'react-bootstrap';
class ProfileHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      personalInfoData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
      billingAddresses: [],
      shippingAddresses: [],
      showAddAddressModal: false,
    };
  }

  componentDidMount() {
    console.log(this.props.actions);
    this.props.actions.getUserInfo({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });
    this.props.actions.getAddressInfo({
      userId: this.props.userData.id,
      jwt: this.props.userData.jwt,
    });
  }

  render() {
    const {
      actions,
      addressModalStatus,
      addressInfo,
      personalInfo,
      userData,
      addressAlert,
      getUserInfoAlert
    } = this.props;

    const basePath = ["userInfo", "responseObject"];
    console.log("userDat::::", userData);
    const roleId = userData.roleId;

    return (
      <>
        <div className="books-card-wrapper col-md-12 mt-4">
          <div className="card">
            <div className="card-header">Personal Info</div>
          </div>
          <div className="card-body">

            <div>
              {getUserInfoAlert && 'USER INFO UPDATED SUCCESSFULLY'}
              <div className="row">
                <div className="col-6">
                  <label>First Name: </label>
                  <input
                    type="text"
                    name="firstName"
                    // onChange={this.handlePersonalInfoChange}
                    onChange={(e) =>
                      actions.setInputValue({
                        path: [...basePath, "firstName"],
                        value: e.target.value,
                      })
                    }
                    placeholder="First Name"
                    value={personalInfo.get("firstName", "")}
                    className="form-control"
                  />
                </div>
                <div className="col-6">
                  <label>Last Name: </label>
                  <input
                    type="text"
                    name="lastName"
                    onChange={(e) =>
                      actions.setInputValue({
                        path: [...basePath, "lastName"],
                        value: e.target.value,
                      })
                    }
                    value={personalInfo.get("lastName", "")}
                    placeholder="Last Name"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <label>Email: </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    readOnly
                    value={personalInfo.get("email", "")}
                    className="form-control"
                    onChange={(e) =>
                      actions.setInputValue({
                        path: [...basePath, "email"],
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-6">
                  <label>Mobile: </label>
                  <input
                    type="text"
                    name="phoneNumber"
                    placeholder="phoneNumber"
                    value={personalInfo.get("phoneNumber", "")}
                    className="form-control"
                    onChange={(e) =>
                      actions.setInputValue({
                        path: [...basePath, "phoneNumber"],
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() =>
                      actions.updateUserInfo({
                        jwt: this.props.userData.jwt,
                      })
                    }
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!["73146", "79146", "72166", '77138', '78129', '75126'].includes(roleId) && (
          <>

            <div className="books-card-wrapper col-md-12">
              <div className="card">
                {addressAlert && <Alert variant='success' className="p-1 m-1">{'SUCCESFULLY ADDED ADRESS'}</Alert>}
                <div className="card-header d-flex justify-content-between">
                  <span>Shipping Address</span>
                  <button
                    onClick={() =>
                      actions.toggleAddressModal(true) &&
                      actions.setAddressType("shipping")
                    }
                    className="btn btn-primary"
                  >
                    Add New
                  </button>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      {addressInfo
                        .get("shippingAddressDetails", List())
                        .map((addressInfo, index) => (
                          <Card style={{ width: "18rem" }}>
                            <Card.Body>
                              <Card.Text>
                                {addressInfo.get("street1")}
                                {addressInfo.get("street2")}
                                {addressInfo.get("landmark")}
                                {addressInfo.get("district")}
                                {addressInfo.get("state")}
                                {addressInfo.get("country")}
                                {/* Flat no 304 , Mayuri nandana apartments Gujjanagundla
                          GUNTUR, ANDHRA PRADESH 522006 India Phone number:
                          ‪9949270864‬ */}
                              </Card.Text>
                              <button
                                onClick={() =>
                                  actions.currentEditAddress({
                                    index,
                                    isShippingAddress: true,
                                  }) && actions.toggleAddressModal(true)
                                }
                                className="btn btn-warning mr-2"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  this.props.actions.deleteAddressInfo({
                                    userAddressInfoId:
                                      addressInfo.get("userAddressInfoId"),
                                    userId: this.props.userData.id,
                                    jwt: this.props.userData.jwt,
                                  })
                                }
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <span>Billing Address</span>
                  <button
                    onClick={() =>
                      actions.toggleAddressModal(true) &&
                      actions.setAddressType("billing")
                    }
                    className="btn btn-primary"
                  >
                    Add New
                  </button>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      {addressInfo
                        .get("billingAddressDetails", List())
                        .map((addressInfo, index) => (
                          <Card style={{ width: "18rem" }}>
                            <Card.Body>
                              <Card.Text>
                                {addressInfo.get("street1")}
                                {addressInfo.get("street2")}
                                {addressInfo.get("landmark")}
                                {addressInfo.get("district")}
                                {addressInfo.get("state")}
                                {addressInfo.get("country")}
                                {/* Flat no 304 , Mayuri nandana apartments Gujjanagundla
                          GUNTUR, ANDHRA PRADESH 522006 India Phone number:
                          ‪9949270864‬ */}
                              </Card.Text>
                              <button
                                onClick={() =>
                                  actions.currentEditAddress({
                                    index,
                                    isShippingAddress: false,
                                  }) && actions.toggleAddressModal(true)
                                }
                                className="btn btn-warning mr-2"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() =>
                                  this.props.actions.deleteAddressInfo({
                                    userAddressInfoId:
                                      addressInfo.get("userAddressInfoId"),
                                    userId: this.props.userData.id,
                                    jwt: this.props.userData.jwt,
                                  })
                                }
                                className="btn btn-danger"
                              >
                                Delete
                              </button>
                            </Card.Body>
                          </Card>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddAddressModal addressModalStatus={addressModalStatus} />
          </>
        )}

        {/* {this.props.personalInfo &&(
        <div className="books-card-wrapper col-md-12">
          <div className="card">
            <div className="card-header">
              Personal Info
          </div>
            <div className="card-body">
              <form onSubmit={this.handlePersonalInfoSubmit}>
                <div className="row">
                  <div className="col-6">
                    <label>First Name: </label>
                    <input type="text" name="firstName" onChange={this.handlePersonalInfoChange} placeholder="First Name" value={this.props.personalInfo.responseObject.firstName} className="form-control" />
                  </div>
                  <div className="col-6">
                    <label>Last Name: </label>
                    <input type="text" name="lastName" onChange={this.handlePersonalInfoChange} placeholder="Last Name" value={this.props.personalInfo.responseObject.lastName} className="form-control" />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <label>Email: </label>
                    <input type="email" name="email" placeholder="Email" readOnly value={this.props.personalInfo.responseObject.email} className="form-control" />
                  </div>
                  <div className="col-6">
                    <label>Mobile: </label>
                    <input type="text" name="phoneNumber" placeholder="phoneNumber" value={this.props.personalInfo.responseObject.phoneNumber} className="form-control" onChange={this.handlePersonalInfoChange} />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary">Update</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>)}
        {
          !onlyPersonalInfo && (
            <>
              <div className="books-card-wrapper col-md-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <span>Shipping Address</span>
                    <button onClick={this.handleShowAddAddressModal} className="btn btn-primary">Add New</button>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4">
                        <Card style={{ width: '18rem' }}>
                          <Card.Body>
                            <Card.Text>
                              Flat no 304 , Mayuri nandana apartments
                              Gujjanagundla
                              GUNTUR, ANDHRA PRADESH 522006
                              India
                              Phone number: ‪9949270864‬
                      </Card.Text>
                            <button className="btn btn-warning mr-2">Edit</button>
                            <button onClick={() => this.handleDelete(1)} className="btn btn-danger">Delete</button>
                          </Card.Body>
                        </Card>
                        {
                          billingAddresses && billingAddresses.length > 1 && billingAddresses.map(address => (
                            <Card style={{ width: '18rem' }}>
                              <Card.Body>
                                <Card.Text>
                                  {address.street1} {address.street2} {address.landmark} {address.district} {address.state} {address.country} - {address.zipCode}
                                </Card.Text>
                                <button className="btn btn-warning mr-2">Edit</button>
                                <button onClick={() => this.handleDelete(address.AddAddressModaluserAddressInfoId)} className="btn btn-danger">Delete</button>
                              </Card.Body>
                            </Card>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="books-card-wrapper col-md-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between">
                    <span>Billing Address</span>
                    <button onClick={this.handleShowAddAddressModal} className="btn btn-primary">Add New</button>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4">
                        <Card style={{ width: '18rem' }}>
                          <Card.Body>
                            <Card.Text>
                              Flat no 304 , Mayuri nandana apartments
                              Gujjanagundla
                              GUNTUR, ANDHRA PRADESH 522006
                              India
                              Phone number: ‪9949270864‬
                      </Card.Text>
                            <button className="btn btn-warning mr-2">Edit</button>
                            <button className="btn btn-danger">Delete</button>
                          </Card.Body>
                        </Card>
                        {
                          shippingAddresses && shippingAddresses.length > 1 && shippingAddresses.map(address => (
                            <Card style={{ width: '18rem' }}>
                              <Card.Body>
                                <Card.Text>
                                  {address.street1} {address.street2} {address.landmark} {address.city} {address.district} {address.state} {address.country} - {address.zipCode}
                                </Card.Text>
                                <button className="btn btn-warning mr-2">Edit</button>
                                <button onClick={() => this.handleDelete(address.AddAddressModaluserAddressInfoId)} className="btn btn-danger">Delete</button>
                              </Card.Body>
                            </Card>
                          ))
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </>
          )
        }

        <AddAddressModal onCloseModal={this.handleCloseModal} show={showAddAddressModal} /> */}
      </>
    );
  }
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  personalInfo: profileData.getUserInfo(state),
  addressInfo: profileData.getAddressInfo(state),
  addressModalStatus: profileData.getAddressModalStatus(state),
  addressAlert: profileData.getAddressAlert(state),
  userInfoAlert: profileData.getUserInfoAlert(state)
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...profile.actions,
      ...student.actions,
    },
    d
  ),
});

export default connect(s, d)(ProfileHome);
