import {createAsyncAction} from '../lib/utils'

import { put, delay, takeLatest } from 'redux-saga/effects'

import { push } from 'react-router-redux';

import login from './loginn'

const redirectToLoginAfterSucccessfulSignUp = createAsyncAction(
    `REDIRECT_TO_LOGIN_AFTER_SUCCCESSFUL_SIGN_UP`
)

const cancelRefreshToken = createAsyncAction(
    `CANCEL_REFRESH_TOKEN`,
    payload => payload
)

function* cancelRefreshTokenTask({ payload }) {
    yield delay(5*60*1000)
    window.localStorage.clear()
    yield put(login.actions.removeUserData())
    window.location.href = '/Login'
    
}

function* redirectToLoginTaskAfterSuccessfulSignUpTask({ payload }) {

    yield put(login.actions.setSignUpSuccessMsg())

    yield put(push('/Login'))

}

function* listener() {
  yield takeLatest(cancelRefreshToken, cancelRefreshTokenTask);
  yield takeLatest(
    redirectToLoginAfterSucccessfulSignUp,
    redirectToLoginTaskAfterSuccessfulSignUpTask
  );
}

export default {
    actions: {
        cancelRefreshToken,
        redirectToLoginAfterSucccessfulSignUp
    },
    listener
}