import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import './InstOrgAddCourseDetailsModal.css';
import * as appData from '.././appSelector'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { modals } from './index'
import * as modalData from './selector'
class instOrgAddCourseDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standardsForBooks: [],
            showAddUser: false,
            coursesForBooks: [],
            coursesForBooksOptions: [],
            selectedStandard: null,
            selectedCourseId: null,
            enteredBookTitle: '',
            enteredPrice: null,
            enteredISBN: '',
            enteredBookDescription: '',
            selectedFile: null,
            addedCourse: '',
            userId: '',
            orgId: '',
        };
        this.handleStandard = this.handleStandard.bind(this);
        this.handleCourseId = this.handleCourseId.bind(this);
        this.handleBookTitle = this.handleBookTitle.bind(this);
        this.handleBookDescription = this.handleBookDescription.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onFileUpload = this.onFileUpload.bind(this);
        this.callBookCloseModal = this.callBookCloseModal.bind(this);
        this.closeCoursesModal = this.closeCoursesModal.bind(this);
    }
    closeCoursesModal() {
        this.props.actions.closeBookModal();
        this.props.onClose();
    }
    callBookCloseModal(e) {
        e.preventDefault();
        this.onFileUpload();
        this.closeCoursesModal()
    }
    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };
    onFileUpload = () => {
        const formDatatest = new FormData();
        var booksData = {
            "userId": this.props.userData.id,
            "orgId": Number(Object.keys(this.props.userData.orgInfo[0])),
            "booksDetails": [
                {
                    "standardId": this.state.selectedStandard,
                    "courseId": this.state.selectedCourseId,
                    "bookTitle": this.state.enteredBookTitle,
                    "isbnNumber": this.state.enteredISBN,
                    "price": this.state.enteredPrice,
                    "bookDescription": this.state.enteredBookDescription
                }
            ]
        }
        var postData = JSON.stringify(booksData);
        formDatatest.append('file', this.state.selectedFile);
        formDatatest.append('booksRequest', postData);
        this.props.actions.addBooks({
            formData: formDatatest,
            jwt: this.props.userData.jwt
        })
    }
    handleStandard = event => {
        let standardId = event.value;
        this.setState({
            selectedStandard: standardId,
        })
        this.props.actions.getCoursesOptions({
            standardId
        })
    }

    handleCourseId = event => {
        let courseId = event.value;
        this.setState({
            selectedCourseId: courseId,
        })
    }

    handleBookTitle = event => {
        let booktitle = event.target.value;
        this.setState({
            enteredBookTitle: booktitle,
        })
    }
    handleBookDescription = event => {
        let bookdescription = event.target.value;
        this.setState({
            enteredBookDescription: bookdescription,
        })
    }
    componentDidMount() {
        this.props.actions.getStandardsUser({
            userId: this.props.userData.id,
            orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
            jwt: this.props.userData.jwt
        })
        this.props.actions.getCoursesWithIds({
            userId: this.props.userData.id,
            orgId: Number(Object.keys(this.props.userData.orgInfo[0])),
            jwt: this.props.userData.jwt
        })
    }
    render() {
        let standardsOptionsFinal = this.props.standardsOptions;
        let courseOptionsFinal = this.props.coursesOptions;
        return (
            <Modal show={this.props.show}>
                <form onSubmit={this.callBookCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Add Book</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-4">
                                <label>Standard Name:  </label>
                            </div>
                            <div className="col-md-8">
                                <Select required onChange={this.handleStandard} options={standardsOptionsFinal} />
                            </div>
                        </div>
                        <div className="mt-2 row">
                            <div className="col-md-4">
                                <label>Course Name: </label>
                            </div>
                            <div className="col-md-8">
                                <Select required onChange={this.handleCourseId} options={courseOptionsFinal} />
                            </div>
                        </div>
                        <div className="mt-2 row">
                            <div className="col-md-4">
                                <label>Course Title: </label>
                            </div>
                            <div className="col-md-8">
                                <input required type="text" onChange={this.handleBookTitle} placeholder="Enter Course Title" className="css-yk16xz-control" />
                            </div>
                        </div>
                        <div className="mt-2 row">
                            <div className="col-md-4">
                                <label>Course Description: </label>
                            </div>
                            <div className="col-md-8">
                                <input required type="text" onChange={this.handleBookDescription} placeholder="Enter Course Description" className="css-yk16xz-control" />
                            </div>
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-danger btn-sm btn-responsive" onClick={this.closeCoursesModal}>Close</button>
                        <button type="submit" className="btn btn-primary btn-sm btn-responsive">Add</button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
const s = ({ state }) => ({
    userData: appData.getUserData(state),
    standardsOptions: modalData.getStandardsOptionsData(state),
    coursesOptions: modalData.getCoursesOptions(state)
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...modals.actions
        },
        d
    )
})
export default connect(s, d)(instOrgAddCourseDetailsModal);
