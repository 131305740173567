import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appData from '../../appSelector';
import '../BookResoursesManagement.css';
import { videoStreamingConf } from '../redux-videoStreaming/index';

class SingleVideoCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
        this.openSingleVideoPlayer = this.openSingleVideoPlayer.bind(this);
    }
    openSingleVideoPlayer(){
        this.props.actions.openSingleVideoPlayer();
    }
    render() {

        return (
            <div>
                <div className="book-card float-right mt-4">
                    <button onClick={this.openSingleVideoPlayer} className=" shadow-none border-0">
                        <div className="book-card-image-holder">
                            <i className="fa fa-play-circle" aria-hidden="true"></i>
                            <div className="book-card-image" style={{ backgroundImage: `url(${this.props.imageUrl})` }} />
                        </div>
                    </button>
                </div>
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(SingleVideoCard);
