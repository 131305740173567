import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { modals } from './index'
import * as modalsData from './selector'
import * as appData from '../appSelector'
import CreatableSelect from 'react-select/creatable';

class CartModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      quantity: '',
      instituteName: [],
      isLoading: false,
      options: []
    };
  }

  static getDerivedStateFromProps(nextProps,) {
    return {
      options: nextProps.allInstitutes
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show === true && prevProps.show !== this.props.show) {
      this.callGetAllInstitutes();
    }
  }

  onCartSubmit = (e) => {
    e.preventDefault();
    const { bookData } = this.props;
    const totalAmount = this.state.quantity * bookData.price;
    const institutes = this.state.instituteName;
    const cartDetails = institutes.map(institute => {
      return {
        bookId: bookData.bookId,
        bookPrice: bookData.price,
        instId: institute.value,
        quantity: parseInt(this.state.quantity),
        totalAmount 
      }
    })

    // {
    //   "cartSummaries": {
    //     "bookPrice": 0,
    //     "orgId": 0,
    //     "quantity": 0,
    //     "totalAmount": 0,
    //     "userId": 0
    //   },
    //   "instList": [
    //     0
    //   ]
    // }
    const cartData = {
      "addToCart": {
        "cartTotal": totalAmount * institutes.length,
        "userId": this.props.userData.id
      },
      cartDetails
      
    }
    this.props.actions.addToCart({ data: cartData, jwt: this.props.userData.jwt });
    setTimeout(() => {
      this.props.onClose();
    })
  }

  callGetAllInstitutes() {
    this.props.actions.getAllInstitutes({
      jwt: this.props.userData.jwt
    })
  }

  handleQuantity = (e) => {
    this.setState({
      quantity: e.target.value
    })
  }

  handleInstitute = (newValue, actionMeta) => {
    this.setState({ instituteName: newValue });
  }

  createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  handleCreate = (inputValue) => {
    this.props.onClose();
    setTimeout(() => {
      window.open('/AddInstitute');
    }, 100)
  }

  componentDidMount() {
    this.callGetAllInstitutes();
  }

  render() {
    const { quantity, isLoading, instituteName, options: institutesOptionsFinal } = this.state;
    return <Modal size='lg' show={this.props.show}>
      <form onSubmit={this.onCartSubmit}>
        <Modal.Header>
          <Modal.Title>Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <label>Quantity:  </label>
            </div>
            <div className="col-md-8">
              <input
                value={quantity}
                type="number"
                onChange={this.handleQuantity}
                placeholder="Enter Quantity"
                className="css-yk16xz-control"
                required
              />
            </div>
          </div>
          <div className="mt-2 row">
            <div className="col-md-4">
              <label>Institute Name: </label>
            </div>
            <div className="col-md-5">
              <CreatableSelect
                isClearable
                isMulti
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={this.handleInstitute}
                onCreateOption={this.handleCreate}
                options={institutesOptionsFinal}
                value={instituteName}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="submit" className="btn btn-primary btn-sm btn-responsive">Submit</button>
          <button className="btn btn-danger btn-sm btn-responsive"
            onClick={(e) => {
              this.props.onClose()
            }}>Close</button>
        </Modal.Footer>
      </form>
    </Modal>
  }
}

const s = ({ state }) => ({
  allInstitutes: modalsData.getAllInstitutesData(state),
  userData: appData.getUserData(state),
  addToCartStatus: modalsData.getAddToCartStatus(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions
    },
    d
  )
})
export default connect(s, d)(CartModal);