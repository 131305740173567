import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './viewAssessments.css'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import * as viewAssessmentsData from '../../../redux-assessmentModule/selector.js'
import ViewAssessmentLinks from './ViewAssessmentLinks.js'
import DeleteAssessmentModal from '../../../../modals/DeleteAssessmentModal'
import * as appData from '../../../../appSelector'
import WithPagination from '../../../../common/WithPagination';

class ViewAssessments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 3
    }
    this.getViewAssessments = this.getViewAssessments.bind(this);
  }
  componentDidMount() {
    this.getViewAssessments();
  }
  getViewAssessments() {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    this.props.actions.getViewAssessments({
      loginUserId: this.props.userData.id,
      jwt: this.props.userData.jwt,
      clientTimeZone: timeZone
    })
  }

  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }

  onSearch = (input) => {
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }

  render() {

    const { currentPage, itemsPerPage, searchText } = this.state;
    const { viewAssessmentsDataPage } = this.props;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;

    let currentItems = [...viewAssessmentsDataPage];
    console.log(viewAssessmentsDataPage)
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(searchText, 'gim')


      currentItems = currentItems.filter(item => {
        let itemString = '';
        console.log(item)
        for (let key in item) {
          if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
            itemString += item[key].toString().toLowerCase().trim() + ' ';
            console.log(key)
          }
        }
        return itemString.match(searchTermRegex);
      })
    }

    // var viewFullAssessmentsData = this.props.viewAssessmentsDataPage;
    if (currentItems) {

      var showAssessments = currentItems.slice(indexOfFirst, indexOfLast).map((viewEachAssessmentData, i) => {
        return <ViewAssessmentLinks key={viewEachAssessmentData.testId} viewEachAssessmentData={viewEachAssessmentData} />
      })

      return (
        <div className='p-1 mt-4'>
          <div style={{ "margin-bottom": '70px' }} >
            <WithPagination
              itemsPerPage={itemsPerPage}
              totalItems={currentItems.length}
              currentPage={currentPage}
              onSearch={this.onSearch}
              onPaginate={this.onPaginate}
            >
              {showAssessments}
            </WithPagination>
          </div>
        </div>
      );
    }
    return (
      <>
        {
          (this.props.showDeleteAssessment) && (
            <DeleteAssessmentModal show={this.props.showDeleteAssessment} />
          )
        }
        {showAssessments}
      </>
    );
  }
}

const s = ({ state }) => ({
  viewAssessmentsDataPage: viewAssessmentsData.getViewAssessments(state),
  showDeleteAssessment: viewAssessmentsData.showDeleteAssessmentModal(state),
  userData: appData.getUserData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions,
      ...assessmentModule.getViewAssessments
    },
    d
  )
})
export default connect(s, d)(ViewAssessments);