import React, { Component } from 'react';
import PublicFooter from "../layout/PublicFooter";
import APICONF from '../config/ApiConfig';
import './Partners.css'
import linkIcon from '../assets/icons/external-link.svg';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as  appSelector from '../appSelector'
import { loginn } from '../login/index'
import loginTask from '../login/loginTask'

class PartnersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {}
    };
  }

  componentDidMount() {
    this.getPartnerDetails();
  }

  getPartnerDetails = () => {
    const getPartnersURL = APICONF.GET_PARTNER_DETAILS
    fetch(getPartnersURL)
      .then(response => response.json())
      .then(data => {
        this.setState({ response: data });
      })
      .catch(error => { });
  }

  render() {
    const { topPartner, partners, topClient, clients } = this.state.response;
    return (
      <div className="partners-wrapper">
        <div className="page-body">
          <div className="page-content">
            {
              topPartner && <div className="banner">
                <video autoPlay loop muted>
                  <source src={topPartner.videoUrl} type="video/mp4" />
                </video>
                <div className="layer">
                </div>
                <div className="content">
                  <a target="_blank" href={topPartner.partnerWebsiteUrl}>{topPartner.partnerName} <img className="link-icon" src={linkIcon} alt="link-icon" /> </a>
                </div>
              </div>
            }

            {partners && <h2 className="text-center mt-3 text-white">Partners</h2>}

            <div className="partners">
              {
                partners && partners.map(partner => {
                  return (
                    <div key={partner.partnerName} className="partner">
                      <img src={partner.imageUrl} alt="" />
                      <a target="_blank" href={partner.partnerWebsiteUrl}>{partner.partnerName} <img className="link-icon" src={linkIcon} alt="link-icon" /></a>
                    </div>
                  )
                })
              }
            </div>

            {
              topClient && <div className="banner">
                <video autoPlay loop muted>
                  <source src={topClient.videoUrl} type="video/mp4" />
                </video>
                <div className="layer">
                </div>
                <div className="content">
                  <a target="_blank" href={topClient.clientWebsiteUrl}>{topClient.clientName} <img className="link-icon" src={linkIcon} alt="link-icon" /> </a>
                </div>
              </div>
            }

            {clients && <h2 className="text-center mt-3 text-white">Clients</h2>}

            <div className="clients">
              {
                clients && clients.map(client => {
                  return (
                    <div key={client.clientName} className="client">
                      <img src={client.imageUrl} alt="" />
                      <a target="_blank" href={client.clientWebsiteUrl} >{client.clientName} <img className="link-icon" src={linkIcon} alt="link-icon" /></a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        {!this.props.isLoggedIn &&  <PublicFooter isPublicPath={this.props.match.path === '/PublicBooks'} />}

      </div>
    );
  }
}
const s = ({ state }) => ({
  isLoggedIn: appSelector.getLoginStatus(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...loginn.actions,
      ...loginTask.actions
    },
    d
  )
})
export default connect(s, d)(PartnersPage);
