import * as React from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { profile } from './profile/index'
import { modals } from './modals'
import profileTask from "./profile/profileTask";
import * as appData from "./appSelector";
import * as modal_seletors from './modals/selector';
import { L10n } from '@syncfusion/ej2-base';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  RecurrenceEditorComponent
} from "@syncfusion/ej2-react-schedule";
import { extend, isNullOrUndefined } from "@syncfusion/ej2-base";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { DropDownListComponent, MultiSelectComponent, CheckBoxSelection } from "@syncfusion/ej2-react-dropdowns";
import { enableRipple } from '@syncfusion/ej2-base';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import * as profile_selectors from './profile/selectors'
import './calendar.css'
enableRipple(true);

var eventUserDeatils = [];
class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.recObject = React.createRef();
    this.fields = { text: 'OwnerText', value: 'Id' };
    this.groupFields = { text: 'GroupName', value: 'Id' }
    this.minDate = new Date(new Date().setDate(new Date().getDate()))
    this.flag = true
    let maxD = new Date(new Date().setDate(new Date().getDate()))
    this.maxDate = new Date(maxD.setDate(maxD.getDate() + 67))
    L10n.load({
      'en-US': {
        'schedule': {
          'saveButton': 'Add',
          'closeButton': 'Close',
          'deleteButton': 'Remove',
          'newEvent': 'Add Event'
        }
      }
    });
  }


  meetingUrl = (e) => {
    this.props.actions.generateMeetingLink()
  }

  dateHandleChange(event) {
    var maiz = event.target.value
  }
  onChangeGroupUser(event) {
    let eventuserDetail = {
      createdDate: new Date(),
      group: event.itemData.hasOwnProperty('GroupName') ? true : false,
      groupId: event.itemData.hasOwnProperty('GroupName') ? event.itemData.Id : null,
      userEventDetailsId: '',
      userId: event.itemData.hasOwnProperty('OwnerText') ? event.itemData.Id : null,
    }
    eventUserDeatils.push(eventuserDetail)
  }
  onActionFailure() {
    alert("Failed to load Calender")
  }
  editorTemplate(props) {
    //dont delete below variables
    let recRule = ['FREQ=DAILY;INTERVAL=2;COUNT=8', 'FREQ=DAILY;BYMONTHDAY=10,20,30', 'FREQ=WEEKLY;BYDAY=TU,TH;INTERVAL=2;WKST=TH',
      'FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-2', 'FREQ=MONTHLY;BYMONTHDAY=29;INTERVAL=2;COUNT=8;', 'FREQ=YEARLY;BYMONTH=9', 'FREQ=YEARLY;BYWEEKNO=36',
      'FREQ=YEARLY;BYYEARDAY=252',];
    let frequencies = ['none', 'daily', 'weekly', 'monthly', 'yearly'];
    return (props !== undefined) ? (
      <table
        className="custom-event-editor"
        style={{ width: "100%", cellpadding: "5" }}
      >
        <tbody>
          <tr>
            <td className="e-textlabel">Summary</td>
            <td colSpan={4}>
              <input
                id="Summary"
                className="e-input"
                type="text"
                name="subject"
                style={{ width: "100%" }}
                data-name="Summary"
                value={props.EventDescription}
                defaultValue={props.EventDescription}
                autoComplete="off"
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Add Users</td>
            <td colSpan={4}>
              <MultiSelectComponent
                className="e-field"
                placeholder="Select a user"
                data-name="OwnerId"
                disablePreSelectedValues={false}
                dataSource={this.props.userProfiles}
                fields={this.fields}
                allowCustomValue={false}
                select={this.onChangeGroupUser}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Groups</td>
            <td colSpan={4}>
              <MultiSelectComponent
                className="e-field"
                placeholder="Select a Group"
                data-name="GroupId"
                disablePreSelectedValues={true}
                select={this.onChangeGroupUser}
                dataSource={this.props.groupNames}
                fields={this.groupFields}
                mode="CheckBox" selectAllText="Select All" unSelectAllText="unSelect All" showSelectAll={true}
                allowCustomValue={false} >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Status</td>
            <td colSpan={4}>
              <DropDownListComponent
                id="EventType"
                placeholder="Choose status"
                data-name="owners"
                style={{ width: "100%" }}
                dataSource={["New", "Requested", "Confirmed"]}
                value={props.EventType || "New"}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">From</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="StartDate"
                data-name="StartDate"
                min={this.minDate}
                strictMode={true}
                ref={(date) => {
                  this.startObj = date;
                }}
                value={new Date(props.startDate || props.StartDate)}
                className="e-field"
                onChange={this.dateHandleChange}
              ></DateTimePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">To</td>
            <td colSpan={4}>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="EndDate"
                data-name="EndDate"
                min={this.minDate}
                strictMode={true}
                ref={(date) => {
                  this.endObj = date;
                }}
                value={new Date(this.endDate || this.EndDate)}
                className="e-field"

              ></DateTimePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Reason</td>
            <td colSpan={4}>
              <input
                id="Reason"
                className="e-input"
                name="Reason"
                data-source={props.EventReason}
                value={props.EventReason}
                defaultValue={props.EventReason}
                autoComplete="off"
                rows={3}
                cols={50}
                style={{
                  width: "100%",
                  height: "60px !important",
                  resize: "vertical",
                }}
              />
            </td>
          </tr>

          {
          !(this.scheduleObj.currentAction === "EditOccurrence" ||
          this.scheduleObj.currentAction === "EditSeries" ||
          this.scheduleObj.currentAction === "Save") &&
          (!props.generateMeetingLink) && (
            <tr>
              <td colSpan={4}>
                <CheckBoxComponent
                  id="generateMeetingLink"
                  className="e-control"
                  name="generateMeetingLink"
                  data-name="generateMeetingLink"
                  label="want to generate meeting"
                  value={props.generateMeetingLink}
                />
              </td>
            </tr>
          )}
          {(this.scheduleObj.currentAction === "EditOccurrence" ||
          this.scheduleObj.currentAction === "EditSeries" ||
          this.scheduleObj.currentAction === "Save"
          ) &&
            (
              <tr>
              <td className="e-textlabel">
                Join Meeting
              </td>
              <td colSpan={4}>
              <a href={props.MeetingLink} target="_blank">{props.MeetingLink}</a>
              </td>
            </tr>
            )
           
          }
          <tr>
            <td className="e-textlabel">RecurrenceRule</td>
            <td colSpan={4}>
              <RecurrenceEditorComponent id='RecurrenceEditor' delayUpdate={true}
                frequencies={this.frequencies} value={this.recRule}
                ref={(t) => { this.recObject = t }} ></RecurrenceEditorComponent>
            </td>
          </tr>
          {this.props.meetingURL && (
            <tr>
              <td colSpan={4}>
                <a href={this.props.meetingURL} target="_blank">
                  Join Meeting
                </a>
              </td>
              <td colSpan={4}>{this.props.meetingURL}</td>
            </tr>
          )}
        </tbody>
      </table>
    ) : (
      <div></div>
    );
  }
  onPopupOpen(args) {
    if (args.type === "Editor") {
      let subjectElement = args.element.querySelector("#Summary");

      if (subjectElement) {
        subjectElement.value = args.data.Subject || "";
      }
      let statusElement = args.element.querySelector("#EventType");
      statusElement.setAttribute("name", "EventType");
      let descriptionElement = args.element.querySelector("#Reason");
      if (descriptionElement) {
        descriptionElement.value = args.data.Description || "";
        if (args.type === "Editor") {
          args.duration = 160;
          if (this.flag) {
            let dialogObj = args.element.ej2_instances[0];
            let buttons = dialogObj.buttons;
            buttons.push({
              buttonModel: { content: "Generate meeting", isPrimary: true },
              click: this.meetingUrl.bind(this)
            });
            dialogObj.setProperties({ buttons: buttons });
            this.flag = false;
          }
        }
      }
    }
    if (args.type === "QuickInfo" && !args.target.classList.contains('e-appointment')) {
     if(args.data.MeetingLink !=='' && args.data.MeetingLink !== null
           && args.data.MeetingLink !== undefined) {
            let quickInfoPopupContent = document.querySelector('.e-popup-content');
            let html = `<div class="join-meeting-quickinfo">
                          <div class="e-control e-btn e-lib e-flat">
                            <a href=//${args.data.MeetingLink} target="_blank">Join meeting </a>
                          </div>
                        </div>`
            let div = document.createElement('div');
            div.innerHTML = html;
            quickInfoPopupContent.appendChild(div);
     }
    }

  }
  onPopupClose(args) {
    if(args.data !== undefined && args.data !== null && args.data.MeetingLink !=='' && args.data.MeetingLink !== null
           && args.data.MeetingLink !== undefined) {
            let joinMeeting = document.getElementsByClassName('join-meeting-quickinfo')[0];
            if(joinMeeting !=='' && joinMeeting !== undefined){
              joinMeeting.remove();
            }
            
           }
    if (args.type === "Editor") {
      this.scheduleObj.eventWindow.recurrenceEditor = null;
    }
    if (args.type === "Editor" && !isNullOrUndefined(args.data)) {
      let subjectElement = args.element.querySelector("#Summary");
      if (subjectElement) {
        args.data.Subject = subjectElement.value;
      }
      let statusElement = args.element.querySelector("#EventType");
      if (statusElement) {
        args.data.EventType = statusElement.value;
      }
      args.data.StartTime = this.startObj.value;
      args.data.EndTime = this.endObj.value;
      let descriptionElement = args.element.querySelector("#Reason");
      if (descriptionElement) {
        args.data.Reason = descriptionElement.value;
      }
    }

  }

  onActionBegin = (args) => {
    if (args.requestType === 'eventCreate') {
      this.props.actions.generateEvent({
        data: args.data,
        eventUsersMap: eventUserDeatils
      })
      eventUserDeatils = []
    }

    if (args.requestType === 'eventRemove') {
      this.props.actions.cancelEvent({
        jwt: this.props.userData.jwt,
        id: args.data[0].Id
      })
      eventUserDeatils = []
    }

    if (args.requestType === 'eventChange') {
      this.props.actions.updateEvents({
        data: args.data,
        eventUsersMap: eventUserDeatils
      })
      eventUserDeatils = []
    }
  }

  onActionComplete = (args) => {
    if (args.requestType === "viewNavigate" || args.requestType === "dateNavigate") {
      var currentViewDates = this.scheduleObj.getCurrentViewDates();
      var startDate = currentViewDates[0];
      const currentYear = startDate.getFullYear()
      const currentMonth = startDate.getMonth() + 1
      const previousSelectedMonth = window.sessionStorage.getItem("previousSelectedMonth");
      const previousSelectedYear = window.sessionStorage.getItem("previousSelectedYear");
      if (parseInt(previousSelectedMonth) !== currentMonth || parseInt(previousSelectedYear) !== currentYear) {
        this.props.actions.getUpcomingEventsByUserIdAndDate({
          date: startDate
        })
      }

    }
  }

  componentDidMount() {
    if (this.props.callApiGetAPI) {
      this.props.actions.setCurrentDate()
      this.props.actions.getGroupNames({
        loginUserId: this.props.userData.id,
        jwt: this.props.userData.jwt,
      })
      this.props.actions.getUsers({
        id: this.props.userData.id,
        jwt: this.props.userData.jwt,
      })
      this.props.actions.getUpcomingEventsByUserIdAndDate({
        date: new Date()
      })
    }

  }

  render() {
    return (
      <ScheduleComponent
        width="100%"
        height="550px"
        ref={(schedule) => (this.scheduleObj = schedule)}
        eventSettings={{
          dataSource: extend([], this.props.events, null, true),
          fields: {
            id: 'Id',
            subject: { name: 'Subject' },
            isAllDay: { name: 'IsAllDay' },
            startTime: { name: 'StartDate' },
            endTime: { name: 'EndDate' },
            eventDescription: { name: 'EventDescription' },
            eventReason: { name: 'EventReason' },
            UserrId: { name: 'UserId' },
            GroupIdd: { name: 'GroupId' }
          },
        }}
        editorTemplate={this.editorTemplate.bind(this)}
        showQuickInfo={true}
        popupOpen={this.onPopupOpen.bind(this)}
        popupClose={this.onPopupClose.bind(this)}
        currentView="Day"
        actionBegin={this.onActionBegin}
        actionComplete={this.onActionComplete}
        actionFailure={this.onActionFailure.bind(this)}
      // quickInfoTemplates={{ content: this.content.bind(this)}}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="WorkWeek" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
 
        <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
      </ScheduleComponent>
    );

  }

}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  userProfiles: profile_selectors.getUserProfiles(state),
  groupNames: modal_seletors.getGroupNamesForCalendar(state),
  events: profile_selectors.getEvents(state),
  overLayLoader: appData.getOverLayLoader(state),
  meetingURL: profile_selectors.getMeetingUrl(state),
  eventAlert: profile_selectors.getEventData(state)
});

const d = (d) => ({
  actions: bindActionCreators(
    {
      ...profile.actions,
      ...modals.actions,
      ...profileTask.actions
    },
    d
  ),
});

export default connect(s, d)(Calendar);
