import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appData from '.././appSelector'
import { modals } from './index'
import * as modalsData from './selector'

class DeleteBookModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.removeBookData = this.removeBookData.bind(this);
    this.callRemoveBookCloseModal = this.callRemoveBookCloseModal.bind(this);
  }
  callRemoveBookCloseModal() {
    this.removeBookData();
    this.props.onClose();
  }
  removeBookData() {
    this.props.actions.deleteBook({
      orgId : Number(Object.keys(this.props.userData.orgInfo[0])),
      bookId : this.props.bookModalData.bookId,
      userId : this.props.userData.id,
      jwt : this.props.userData.jwt,
    })
  }
  render() {
    return (
      <div>
        <Modal show={this.props.show} centered>
          <Modal.Header>
            <Modal.Title>Delete {this.props.showCoursesModal && this.props.showCoursesModal ?(
              <>Course</>
            ):(<>Book</>)}  {this.props.bookModalData.bookTitle}?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Deleting  {this.props.showCoursesModal && this.props.showCoursesModal ?(
              <>Course</>
            ):(<>Book</>)} removes all the information associated</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose} >
              Close
          </Button>
            <Button variant="primary" onClick={this.callRemoveBookCloseModal}>
              Save
          </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  showCoursesModal: modalsData.openDeleteBookModal(state),
  bookModalData: modalsData.openDeleteBookModalBookData(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...modals.actions
    },
    d
  )
})
export default connect(s,d)(DeleteBookModal);