import { createAsyncAction } from "../../lib/utils";

import { put, takeLatest, take } from 'redux-saga/effects'

import orders from './orders'


const displayRazorpay = createAsyncAction(
    `DISPLAY_RAZO_PAY`,
    payload => payload
)

const paymentSucessSaga = createAsyncAction(
    `PAYMENT_SUCESS_SAGA`,
    payload => payload 
)


function *displayRazorpayTask({payload}){
    const {
        userId,
        jwt,
        cartId,
        username
    } = payload
    yield put(orders.actions.paymentOrders({
        userId,
        jwt,
        cartId,
        username
    }))

}

function *paymentSucessSagaTask({payload}) {
    const {
        userId,
        cartId,
        jwt,
        responseData,
        roleId, 
        shippingId
    } = payload 

    yield put(orders.actions.paymentSuccess({
        userId,
        cartId,
        jwt,
        responseData,
        shippingId
      }))


    yield take(orders.actions.paymentSuccess.success)

      if(roleId === '75812') {
        yield put (
            orders.actions.getGuestCartSummaryByUserId({
                userId,
                jwt
              })
        );
       yield take(orders.actions.getGuestCartSummaryByUserId.success)

       

       
        yield put(
            orders.actions.orderBooksForGuest({
                jwt
            })
        )

        yield take(orders.actions.orderBooksForGuest.success)

      }else{

        yield put(
            orders.actions.getCartSummaryByUserId({
                userId,
                jwt
              })
        )
        yield take(orders.actions.getCartSummaryByUserId.success)

        yield put(
            orders.actions.ordersBooks({
                jwt
              })
        )

        yield take(orders.actions.ordersBooks.success)
       
      }
}


function *listener() {
    yield takeLatest(displayRazorpay, displayRazorpayTask)
    yield takeLatest(paymentSucessSaga, paymentSucessSagaTask)
}



export default  {
    actions: {
        displayRazorpay,
        paymentSucessSaga 
    },
    listener
}