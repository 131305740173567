import React, {Component} from 'react';
import 'react-data-grid/dist/react-data-grid.css';
import StandardsTables from '../standards/StandardsTables';

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { adminConf } from '../../redux-adminconf/index'

import  * as selectors from '../../redux-adminconf/selector'
import DeleteStandard from '../../../instOrgAdmin/instOrgAdminConfiguration/standardInstOrg/deleteStandard'

import * as appSelector from '../../../appSelector'
import InformationBanner from '../../../common/InformationBanner'
import INFO_BANNER from '../../../common/InfoBanner';
class AddStandardsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    standards:[],
    userId:'',
    orgId:'',
    changedStandards:[]
  };
  this.StandardsTables = this.StandardsTables.bind(this);
  this.handleAddEvent = this.handleAddEvent.bind(this);
  this.handleRowDel = this.handleRowDel.bind(this);
  }
  componentDidMount() {
    let orgIdAdmRteInStdrds = Number(Object.keys(this.props.userProps.orgInfo[0]))
    let userIdAdmRteInStdrds = this.props.userProps.id

    this.props.actions.getStandards({
      orgIdAdmRteInStdrds,
      userIdAdmRteInStdrds,
      jwt: this.props.userProps.jwt
    })

    this.props.actions.deleteStandardAlert(false)
    this.props.actions.deleteStandardAlertFailure(false)
  }
  handleRowDel(standard) {
    var index = this.state.standards.indexOf(standard);
    this.props.actions.changedStandards(this.props.standards.splice(index, 1))
  };

  handleAddEvent(evt) {
    this.props.actions.handleAddEvent()
  }

  StandardsTables(evt) {
    var stdsFromInputTag = {
      id: evt.target.id,
      standardName: evt.target.name,
      value: evt.target.value
    };
  var standards = this.props.standards.slice();
  var newStandards = standards.map(function(standard) {
    for (var key in standard) {
      if (
         key === stdsFromInputTag.standardName && 
         standard.standardId.toString() === stdsFromInputTag.id
         ) {
        standard[key] = stdsFromInputTag.value;
      }
    }
    return standard;
  });
    this.props.actions.changedStandards(newStandards)
  };

  render() {
    var userId = this.props.userId;
    var orgId = this.props.orgId;
    var changedStandardsData = this.props.changedStandards;
    var userProps = this.props.userProps;
    return (
      <div>
        <div className='container-fluid'>
          <div className='row p-2 flex-column-reverse flex-md-row'>
            <div className='col-lg-9'>

        {
          this.props.isDeletedModalShow && 
          <DeleteStandard 
            isDeletedModalShow = {
              this.props.isDeletedModalShow
            }
            hideDeleteStandardModal = {
              this.props.actions.hideDeleteStandardModal
            }
            orgId={orgId} 
            userId={userId} 
            jwt = {this.props.userProps.jwt}
            updateStandards={this.props.actions.updateStandards}
            orgIdAdmRteInStdrds={Number(Object.keys(this.props.userProps.orgInfo[0]))}
            userIdAdmRteInStdrds={this.props.userProps.id}
            currentDeletedStandard={this.props.currentDeletedStandard} 
          />
        }
        <StandardsTables 
          history={this.props.history} 
          userProps={userProps} 
          orgId={orgId} 
          userId={userId} 
          changedStandardsData={changedStandardsData} 
          onStandardsTablesUpdate={this.StandardsTables} 
          onRowAdd={this.handleAddEvent} 
          onRowDel={this.handleRowDel} 
          standards={this.props.standards}
          /></div>
      <div className='col-lg-3 p-5'><InformationBanner
      isHorizontal
      message = {INFO_BANNER.MESSAGE_INFO_BANNER_ADD_STANDARD}
      /></div>
      </div>
          </div>
        </div>
     
    );
  }
}

const s = ({ state }) => ({
  standards: selectors.getStandards(state),
  userId: selectors.getUserId(state),
  orgId: selectors.getOrgId(state),
  changedStandards: selectors.getChangedStandards(state),
  isDeletedModalShow: selectors.isDeletedModalShow(state),
  userProps: appSelector.getUserData(state),
  currentDeletedStandard: selectors.getCurrentDeletedStandard(state)
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...adminConf.actions
    },
    d
  )
})


export default connect(s, d)(AddStandardsPage);