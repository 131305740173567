import React, { Component } from "react";
import StanadardTableRow from "../standards/StanadardTableRow";
import AlertModal from "../../../common/AlertModal";
import AlertBox from '../../../common/AlertBox'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { adminConf } from "../../redux-adminconf/index";

import * as selectors from "../../redux-adminconf/selector";

import ApiMsg from '../../../common/ApiMsgs'

class StandardsTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedStandards: [],
      successAlert: false,
      errorAlert: false,
      successMsg: "",
      errorMsg: "",
    };
    this.handleSaveStandards = this.handleSaveStandards.bind(this);
  }
  handleSaveStandards() {
    var saveStandardsData = this.props.changedStandardsData;
    this.props.actions.handleSaveStandards({
      userId: this.props.userId,
      orgId: this.props.orgId,
      standardDetails: saveStandardsData,
      jwt: this.props.userProps.jwt,
    });
  }
  handleSuccessAlertModalClose() {
    this.props.actions.handleSuccessAlertModalClose();
    this.props.history.push("/AddCourses");
  }
  handleErrorAlertModalClose() {
    this.props.actions.handleErrorAlertModalClose();
  }
  render() {
    var onStandardsTablesUpdate = this.props.onStandardsTablesUpdate;
    var rowDel = this.props.onRowDel;
    var deleteStandard = this.props.actions.deleteStandard;
    var successAlert = this.props.successAlert;
    var errorAlert = this.props.errorAlert;
    var successMsg = this.props.successMsg;
    var errorMsg = this.props.errorMsg;
    var standard = this.props.standards.map(function (standard) {
      return (
        <StanadardTableRow
          onStandardsTablesUpdate={onStandardsTablesUpdate}
          standard={standard}
          onDelEvent={rowDel.bind(this)}
          key={standard.standardId}
          deleteStandard={deleteStandard}
        />
      );
    });
    return (
      <div>
        <AlertModal
          type="success"
          show={successAlert}
          title="Success"
          message={successMsg}
          onClose={() => this.handleSuccessAlertModalClose()}
        ></AlertModal>
        <AlertModal
          type="danger"
          show={errorAlert}
          title="Error"
          message={errorMsg}
          onClose={() => this.handleErrorAlertModalClose()}
        ></AlertModal>
        <AlertBox 
          successAlert={this.props.deleteStandardAlert} 
          errorAlert={this.props.deleteStandardFailureAlertBox} 
          successMsg={ApiMsg.STANDARD_DELETE_SUCCESS}
          errorMsg={ApiMsg.STANDARD_DELETE_FAILURE} 
        >
        </AlertBox>
        <div className="col-lg-6 col-md-4 col-sm-9 col-xs-12  table-responsive">
          <br />
          <br />
          <table className="table table-bordered table-striped">
            <thead className="thead-dark">
              <tr>
                <th colSpan="2" className="center">
                  Standard Name
                </th>
              </tr>
            </thead>
            <tbody>{standard}</tbody>
          </table>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <button
              onClick={this.props.onRowAdd}
              className="no-btn-padding col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-primary btn-responsive pull-left mb-3  "
            >
              Add
            </button>
            <span className="col-lg-6 col-md-6 col-sm-6 col-xs-6"></span>
            <button
              className="no-btn-padding col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-success btn-responsive pull-right mb-3"
              onClick={this.handleSaveStandards}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const s = ({ state }) => ({
  savedStandards: selectors.savedStandards(state),
  successMsg: selectors.successMsg(state),
  successAlert: selectors.successAlert(state),
  errorAlert: selectors.getErrorAlert(state),
  errorMsg: selectors.getErrorMessage(state),
  deleteStandardAlert: selectors.deleteStandardAlert(state),
  deleteStandardFailureAlertBox: selectors.deleteStandardFailureAlertBox(state) 
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...adminConf.actions,
    },
    d
  ),
});

export default connect(s, d)(StandardsTables);
