import React, { Component } from "react";

import { List } from "immutable";

class CourseInputTagInstOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: this.props.stdCourse.courseNames,
      currentStandardId: null,
    };
  }

  inputKeyDown = (e) => {
    this.props.addingCourses({
      value: e.target.value,
      e: e,
      standardId: this.props.stdCourse.get("standardId", ""),
    });
    var standardId = e.target.attributes.id.value;
    const val = e.target.value;
    this.setState({
      currentStandardId: standardId,
    });
    if (e.key === "Enter" && val) {
      this.tagInput.value = null;
    } else if (e.key === "Backspace" && !val) {
      if (this.state.tags !== undefined) {
        // this.removeTag(this.state.tags.length - 1, e);
      }
    }
  };

  render() {
    return (
      <tr>
        <td className="fixed-col center">
          {this.props.stdCourse.get("standardName", "")}
        </td>
        <td className="input-tag input-tag__tags">
          {this.props.stdCourse.get("courseNames", List()) !== null &&
            this.props.stdCourse.get("courseNames", List()).map((tag, i) => (
              <li key={tag.get("courseId", "")}>
                {tag.get("courseName", "")}
                <button
                  id={this.props.stdCourse.get("standardId", "")}
                  type="button"
                  onClick={(e) => {
                    this.props.storingIndexesOfCurrentDeletedCourses({
                      currentStandardIndex: this.props.currentStdCourseIndex,
                      currentCourseIndex: i,
                    });
                  }}
                >
                  +
                </button>
              </li>
            ))}
          <li className="input-tag__tags__input">
            <input
              type="text"
              onKeyDown={this.inputKeyDown}
              name={this.props.stdCourse.get("standardName", "")}
              id={this.props.stdCourse.get("standardId", "")}
              coursenamestag={
                this.state.tags !== undefined ? this.state.tags : ""
              }
              ref={(c) => {
                this.tagInput = c;
              }}
              placeholder="Enter Course Name"
            />
          </li>
        </td>
      </tr>
    );
  }
}

export default CourseInputTagInstOrg;
