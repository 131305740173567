export const  inputValidatorForUserName = (value) => {
    const regex = /^(^$|[a-zA-Z();0-9$.!'@"<>/\s])+$/
    //const regexMail = /^(^$|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/
    //const regexMailTest = regexMail.test(value)
    const testRegExp = regex.test(value)
    const errorMsg =  testRegExp
     ? ''
     : 'Please enter valid userName'
     return errorMsg

  }

 export const  inputNameValidator = (value,name) => {
    const regexName = /^(^$|[a-zA-Z();'@"<>/\s])+$/
    const regexNameTest = regexName.test(value)
    const errorMsg = regexNameTest
     ? ''
     : `Please enter valid ${name}`
     return errorMsg
  }

  export const inputPasswordValidator = (value) => {
    const regexPassword = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[!$'@"<>/\s])([a-zA-Z0-9!$'@"<>/\s]{8,})$/i
    const regexPasswordTest = regexPassword.test(value)
    const errorMsg = regexPasswordTest
     ? ''
     : 'Please use atleast one number, special character !,$,@ and minimum eight letters'
     return errorMsg
  }

  export const inputEmailValidator = (value) => {
    const regexMail = /^(^$|(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))$/
    const regexMailTest = regexMail.test(value)
    const errorMsg = regexMailTest
     ? ''
     : 'Please enter valid email'
    return errorMsg

  }

  export const checkNumbers = (value) => {
     if(value ==="1234567890"){
      return 'Please enter Valid digit mobile number'
     }
     const regexNumber = /^(^$|[0-9]{10})$/
     const regexNumberTest = regexNumber.test(value)
     const errorMsg = regexNumberTest
      ? ''
      : 'Please enter 10 digit mobile number'
     return errorMsg
  }


