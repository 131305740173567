import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import { Link } from 'react-router-dom'


class ViewAssessmentLinks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDeleteAssessment : false
    }
    this.handleWindowopenQuestionaire = this.handleWindowopenQuestionaire.bind(this);
    this.showDeleteAssessmentModal = this.showDeleteAssessmentModal.bind(this);
  }

  showDeleteAssessmentModal(){
    this.props.actions.showDeleteAssessmentModal()
    this.props.actions.currentTestId(this.props.viewEachAssessmentData.testId)
  }

  handleWindowopenQuestionaire(viewQuestionnaireRoute) {
    localStorage.setItem('eachTestId', this.props.viewEachAssessmentData.testId)
    localStorage.setItem('eachTestData', JSON.stringify(this.props.viewEachAssessmentData));
    var width = window.screen.width;
    var height = window.screen.height;
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height},left=0,top=0`;
    window.open(`${viewQuestionnaireRoute}`, "ViewQuestionnaireTeacherWindow", params)
  }
  render() {
    var eachViewEachAssessmentData = this.props.viewEachAssessmentData;
    var testType = this.props.viewEachAssessmentData.testType;
    var viewQuestionnaireRoute;
    if (testType === 'paper') {
      viewQuestionnaireRoute = "ViewQuestionnairePaper"
    }
    if (testType === 'online') {
      viewQuestionnaireRoute = "ViewQuestionnaireOnline"
    }
    return (
      <React.Fragment>
      <div className="books-card-wrapper col-md-12">
        <div key={eachViewEachAssessmentData.testId} className="card">
          <div className="card-header">
            <b>Standard Name:&nbsp;{eachViewEachAssessmentData.standardName}</b>&nbsp;&nbsp;
            <b>Course Name:&nbsp;{eachViewEachAssessmentData.courseName}</b>&nbsp;&nbsp;
            </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <b>Assessment Name:&nbsp;{eachViewEachAssessmentData.testName}</b>
              </div>
              <div className="col-5">
                <div>
                  <div className="mt-1">
                    <b>Assessments Details:</b>
                  </div>
                  <div className="mt-1">
                    Start Date: {eachViewEachAssessmentData.startDate}&nbsp;&nbsp;
                    End Date: {eachViewEachAssessmentData.endDate}&nbsp;&nbsp;
                    </div>
                  <div className="mt-1">
                    Total Questions: {eachViewEachAssessmentData.totalQuestions}
                  </div>
                  <div className="mt-1">
                    Assessment Duration: {eachViewEachAssessmentData.testDuration}
                  </div>
                  <div className="mt-1">
                    Total Points: {eachViewEachAssessmentData.totalMarks}
                  </div>
                  <div className="mt-1">
                    Qualification Points: {eachViewEachAssessmentData.qualificationMarks}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div>
                  <div>
                    <b>Course Details:</b>
                  </div>
                  <div className="mt-1">
                    Title: {eachViewEachAssessmentData.bookTitle}
                  </div>
                  <div className="mt-1">
                    Description: {eachViewEachAssessmentData.bookDescription}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12 d-flex mt-2">
                <ul>
                  {(eachViewEachAssessmentData.allowAddQuestions) && (
                    <li>
                      <Link className="btn btn-success mb-3" to={{ pathname: '/Questionnaire', state: { eachTestId: this.props.viewEachAssessmentData.testId, eachTestData: this.props.viewEachAssessmentData } }}>Create Questionnaire</Link>
                    </li>
                  )}
                  <li>
                    {
                      testType === 'paper' ?
                        <div>
                          <button className="btn btn-warning mb-3 min-width" onClick={() => this.handleWindowopenQuestionaire(viewQuestionnaireRoute)}>View Questionnaire</button>
                        </div>
                        : <Link className="btn btn-warning mb-3" to={{ pathname: `/${viewQuestionnaireRoute}`, state: { eachTestId: this.props.viewEachAssessmentData.testId, eachTestData: this.props.viewEachAssessmentData } }}>View Questionnaire</Link>
                    }
                  </li>
                  {(eachViewEachAssessmentData.allowDeleteAssessment) && (
                    <li>
                      <button className="btn btn-danger mb-3 min-width" onClick = {this.showDeleteAssessmentModal}>Delete Assessment</button>
                    </li>
                    )}
                    { (
                    <li>
                      <Link className="btn btn-warning mb-3"  to={{ pathname: "/ViewAllResults", state: { eachTestId: this.props.viewEachAssessmentData.testId, eachViewEachAssessmentData: this.props.viewEachAssessmentData } }}>View Results</Link>
                    </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

const s = ({ state }) => ({

})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(ViewAssessmentLinks);