import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { orders } from "../redux-orders/index";
import Select from 'react-select';
import moment from 'moment'
import * as appData from "../../appSelector";
import * as ordersData from "../redux-orders/selector";
import OrderInfo from './OrderInfo'
import './OrderHistory.css'
import * as commonData from '../../common/selector'
import OrderStatus from '../../modals/OrderStatus.js'
import { common } from '../../common/index'
import * as modalsData from '../../modals/selector'
import AlertBox from '../../common/AlertBox';
import WithPagination from '../../common/WithPagination';
import INFO_BANNER from "../../common/InfoBanner";
import InformationBanner from "../../common/InformationBanner";
import { fromJS } from "immutable";
import { toEditorSettings } from "typescript";
class OrderHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: '',
      itemsPerPage: 1
    }
    this.closeupdateOrderStatusModalMain = this.closeupdateOrderStatusModalMain.bind(this);
  }
  closeupdateOrderStatusModalMain() {
    this.props.actions.closeupdateOrderStatusModal();
  }
  onPaginate = (number) => {
    this.setState({
      currentPage: number
    });
  }
  onSearch = (input) => {
    console.log("input:::", input,)
    this.setState({ searchText: input, currentPage: 1 })
  }

  componentDidUpdate() {
    document.body.scrollTo(0, 0);
  }
  generateString(item) {
    let finalString = '';
    finalString += this.generateStringForNested(item)
    return finalString
  }

  generateStringForNested(item) {
    let itemString = '';
    for (let key in item) {
      if (item.hasOwnProperty(key) && item[key] && item[key] !== '') {
        if (Array.isArray(item[key])) {
          item[key].forEach(element => {
            itemString += this.generateStringForNested(element)
          });
        }
        itemString += item[key].toString().toLowerCase().trim() + ' ';
      }
    }
    return itemString;
  }
  render() {
    const { orderHistoryData, actions, selectOrderHistoryYear } = this.props;
    const initialDate = moment(new Date('2019'))
    const currentDate = moment(new Date())
    const years = currentDate.diff(initialDate, 'years')
    const { currentPage, itemsPerPage, searchText } = this.state;
    const indexOfLast = currentPage * itemsPerPage;
    const indexOfFirst = indexOfLast - itemsPerPage;
    let orderHistoryDataFromJs = this.props.orderHistoryData.toJS();
    let currentItems = this.props.orderHistoryData.toJS();
    console.log("currentItems--->", currentItems)
    const selectYearOptions = []

    selectYearOptions.push({
      value: 0,
      label: '3 months'
    })
    if (currentItems && searchText) {
      var searchTermRegex = new RegExp(this.state.searchText, 'gim')
      currentItems = currentItems.filter(item => {
        let finalStringhere = '';
        finalStringhere += this.generateString(item)
        console.log("finalStringhere", finalStringhere)
        return finalStringhere.match(searchTermRegex);
      })
    }


    for (let i = years; i >= 0; i--) {
      const upComingDate = moment(initialDate).add(moment.duration({ Y: i })).toDate()
      selectYearOptions.push({
        value: upComingDate.getFullYear(),
        label: upComingDate.getFullYear()
      })

    }
    if (currentItems) {

      var showOrders = currentItems.slice(indexOfFirst, indexOfLast).map((ordersInfo, orderIndex) => {
        return <OrderInfo key={orderIndex} orderIndex={orderIndex} ordersInfo={fromJS(ordersInfo)} />
      })

      return (
        <div className="mt-4">
          <div className="duration-selector">
            <Select
              onChange={(selectedOption) =>
                actions.selectOrderHistoryYear(selectedOption) &&
                actions.getAllOrderDetailsByUserId()
              }
              options={selectYearOptions}
              value={selectOrderHistoryYear}
            />
          </div>
          <div hidden={!this.props.orderHistoryData.size > 0} style={{ "margin-bottom": '70px' }}>
            <WithPagination
              itemsPerPage={itemsPerPage}
              totalItems={currentItems.length}
              currentPage={currentPage}
              onSearch={this.onSearch}
              onPaginate={this.onPaginate}
            >
              {
                (this.props.successAlertBox || this.props.failureAlertBox) && (
                  <AlertBox successAlert={this.props.successAlertBox} successMsg={this.props.orderHistoryPageSuccessAlertBox} errorAlert={this.props.failureAlertBox} errorMsg={this.props.orderHistoryPageFailureMsg} />
                )
              }
              {this.props.userData.roleId === "78129" &&
                (this.props.openupdateOrderStatusModal) && (
                  <OrderStatus onClose={this.closeupdateOrderStatusModalMain}
                    show={this.props.openupdateOrderStatusModal} />
                )
              }
              {showOrders}
            </WithPagination>
          </div>
          <div hidden={this.props.orderHistoryData.size > 0} className="empty-content">
            <InformationBanner isHorizontal
              message={INFO_BANNER.MESSAGE_ORDERHISTORY} />
          </div>
        </div>
      );
    }
  }
}

const s = ({ state }) => ({
  userData: appData.getUserData(state),
  orderHistoryData: ordersData.getAllOrderDetailsByUserId(state),
  selectOrderHistoryYear: ordersData.getSelectedOrderHistoryYear(state),
  openupdateOrderStatusModal: commonData.openupdateOrderStatusModal(state),
  successAlertBox: modalsData.successAlertBox(state),
  failureAlertBox: modalsData.failureAlertBox(state),
  orderHistoryPageFailureMsg: modalsData.orderHistoryPageFailureMsg(state),
  orderHistoryPageSuccessAlertBox: modalsData.orderHistoryPageSuccessMsg(state)
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...orders.actions,
      ...common.actions
    },
    d
  ),
});
export default connect(s, d)(OrderHistory);
