import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as videoStreamingData from './redux-videoStreaming/selectors'
import * as appData from '../appSelector';
import './BookResoursesManagement.css';
import { videoStreamingConf } from './redux-videoStreaming/index';
import AlertModal from '../common/AlertModal';


class YourLearningList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showTextBox: false,
            learningListTemp: [],
            singleLearnList: '',
            reloadComponent: false,
            learningListDelete: [],
            successAlert: false,
            errorAlert: false,
        };
        this.handleAddLearningList = this.handleAddLearningList.bind(this);
        this.handleLearningListValue = this.handleLearningListValue.bind(this);
        this.handleSavingLearningList = this.handleSavingLearningList.bind(this);
        this.getExistingLearningData = this.getExistingLearningData.bind(this);
        this.reRenderComponent = this.reRenderComponent.bind(this);
        this.handleDeleteLearningList = this.handleDeleteLearningList.bind(this);
        this.callDeleteLearningList = this.callDeleteLearningList.bind(this);
    }

    handleDeleteLearningList = (event, resourceLearningListId, learningData) => {
        document.getElementById(resourceLearningListId).remove()
        console.log("this.state.learningListDelete", this.state.learningListDelete)
        event.preventDefault();
        let deleteLearningList = {
            resourceLearningListId: resourceLearningListId,
            learningData: learningData
        }
        console.log("deleteLearningList",deleteLearningList)

        var learningListDeleteConcat = this.state.learningListDelete.concat(deleteLearningList);

        this.setState({ learningListDelete: learningListDeleteConcat })
        //this.setState({ learningListDelete: [...this.state.learningListDelete, deleteLearningList] })
    }

    reRenderComponent() {
        setTimeout(() => {
            this.getExistingLearningData()
            this.forceUpdate()
        }, 3000);
    }

    getExistingLearningData() {
        this.props.actions.getLearningList({
            bookResourceId: this.props.resourceId,
            jwt: this.props.userData.jwt
        })
    }
    componentDidMount() {
        this.getExistingLearningData()
    }


    handleLearningListValue = (event) => {
        this.setState({
            singleLearnList: event.target.value
        });
    };

    handleAddLearningList = (event) => {
        this.setState({ learningListTemp: [...this.state.learningListTemp, this.state.singleLearnList] })
    }

    handleSavingLearningList() {
        let learningList = {
            "learningList": this.state.learningListTemp,
            "resourceId": this.props.resourceId
        }
        this.props.actions.addLearningList({
            learningListData: learningList,
            jwt: this.props.userData.jwt,
        })
        this.setState({
            learningListTemp: []
        });
        this.reRenderComponent()
    }

    callDeleteLearningList(){
        console.log("this.state.learningListDelete",this.state.learningListDelete)
        let deleteLearningList = {
            "resourceLearningObjectList": this.state.learningListDelete,
            "resourceId": this.props.resourceId,
            "learningList":null
        }
        this.props.actions.deleteLearningList({
            deleteLearningListData: deleteLearningList,
            jwt: this.props.userData.jwt,
        })

    }
    handleSuccessAlertModalClose() {
        this.props.actions.handleSuccessAlertModalClose();

    }
    handleErrorAlertModalClose() {
        this.props.actions.handleErrorAlertModalClose();
    }


    render() {
        var successAlert = this.props.successAlert;
        var errorAlert = this.props.errorAlert;
        var successMsg = this.props.successMsg;
        var errorMsg = this.props.errorMsg;
        const secondColumnStart = Math.floor(this.props.getExistingLearningList.length / 2);
        console.log("this.state.learningListTemp", typeof this.state.learningListTemp.length, typeof 0)
        return (
            <div className='container'>
                   <AlertModal
                    type="success"
                    show={successAlert}
                    title="Success"
                    message={successMsg}
                    onClose={() => this.handleSuccessAlertModalClose()}
                ></AlertModal>
                <AlertModal
                    type="danger"
                    show={errorAlert}
                    title="Error"
                    message={errorMsg}
                    onClose={() => this.handleErrorAlertModalClose()}
                ></AlertModal>
                <div className="cart-data-wrap ">
            
                    <div className="mt-3">
                        <h2 className="your-learing-list-title font-weight-bold text-uppercase">Your Learning List</h2>
                    </div>
                   
                    {this.props.userData.roleId === "78129" && (
                        <div className='row p-4'>
                            <div className='col border-right'>
                                <div>
                                    <textarea className="form-control cart-data-wrap" rows="3" placeholder='Enter what you Learn'
                                        onChange={(e) =>
                                            this.handleLearningListValue(e)
                                        }
                                    >
                                    </textarea>
                                </div>
                                &nbsp;&nbsp;
                                <div>
                                    <button
                                        onClick=
                                        {(e) => this.handleAddLearningList(e)}
                                        className="btn  w-25 btn-primary btn-responsive p-1 float-right">
                                        Add
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <div className="col">
                                    {this.state.learningListTemp.map((eachTempLearningList, index) => <p>{index + 1}.{eachTempLearningList}</p>)}
                                </div>
                                <div>
                                    {
                                        (this.state.learningListTemp.length !== 0) && (
                                            <button
                                                onClick=
                                                {this.handleSavingLearningList}
                                                className="btn w-25 btn-success btn-responsive p-1 float-right">
                                                Save
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    }

                    <div className="row p-4">
                        <div className="col">
                            {this.props.getExistingLearningList.slice(0, secondColumnStart).map((eachExistingLearningList, index) => {
                                return <div key={eachExistingLearningList.resourceLearningListId} id={eachExistingLearningList.resourceLearningListId} className="row p-4 mt-n5">
                                    <i className="fa fa-check"></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <p className="mt-n1">{eachExistingLearningList.learningData}</p>
                                    &nbsp;&nbsp;
                                    {this.props.userData.roleId === "78129" && (
                                        <div id={eachExistingLearningList.resourceLearningListId} className="mt-n1 text-color-red">
                                            <span role="button">
                                                <i className="fa fa-times-circle" onClick={(event) => this.handleDeleteLearningList(event, eachExistingLearningList.resourceLearningListId,
                                                    eachExistingLearningList.learningData)}></i>
                                            </span>
                                        </div>
                            )}
                                </div>
                            })}
                        </div>
                        <div className="col">
                            {this.props.getExistingLearningList.slice(secondColumnStart).map((eachExistingLearningList, index) => {
                                return <div key={eachExistingLearningList.resourceLearningListId} id={eachExistingLearningList.resourceLearningListId} className="row p-4 mt-n5">
                                    <i className="fa fa-check"></i>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <p className="mt-n1">{eachExistingLearningList.learningData}</p>
                                    &nbsp;&nbsp;
                                    {this.props.userData.roleId === "78129" && (
                                        <div id={eachExistingLearningList.resourceLearningListId} className="mt-n1 text-color-red">
                                            <span role="button">
                                                <i className="fa fa-times-circle" onClick={(event) => this.handleDeleteLearningList(event, eachExistingLearningList.resourceLearningListId,
                                                    eachExistingLearningList.learningData)}></i>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            })}
                        </div>
                        <div>
                                    {
                                        (this.state.learningListDelete.length !== 0) && (
                                            <button
                                                onClick=
                                                {this.callDeleteLearningList}
                                                className="btn btn-success btn-responsive p-1 float-right">
                                                Save
                                            </button>
                                        )
                                    }
                                </div>
                    </div>
                </div>
            </div>
        )
    }
}

const s = ({ state }) => ({
    userData: appData.getUserData(state),
    getExistingLearningList: videoStreamingData.getExistingLearningList(state),
    successAlertBox: videoStreamingData.successAlertBox(state),
    failureAlertBox: videoStreamingData.failureAlertBox(state),
    successAlert: videoStreamingData.successAlert(state),
    errorAlert: videoStreamingData.getErrorAlert(state),
    successMsg: videoStreamingData.successMsg(state),
    errorMsg: videoStreamingData.getErrorMessage(state),
})
const d = (d) => ({
    actions: bindActionCreators(
        {
            ...videoStreamingConf.actions
        },
        d
    )
})
export default connect(s, d)(YourLearningList);
