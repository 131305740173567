import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as appData from "../appSelector";
import "./SchoolAdminHome.css";
import { schoolAdmin } from "./redux-schoolAdmin/index";
import * as schoolAdminData from "./redux-schoolAdmin/selector";
import { modals } from "../modals/index";
import { executive } from "../executive/redux-executive/index";
import * as execData from "../executive/redux-executive/selector";
import Calendar from "../calendar";
import { profile } from '../profile/index'

class SchoolAdminHome extends Component {
  addItemsToCart() {
    let cart = JSON.parse(window.sessionStorage.getItem("cart")) || [];
    let cartTotal = 0;
    cart = cart.map((item) => {
      cartTotal += Number(item.totalAmount);
      return {
        ...item,
        instId: Number(Object.keys(this.props.userData.instInfo[0])),
      };
    });
    console.log(cart);
    const data = {
      addToCart: {
        cartTotal: cartTotal,
        userId: this.props.userData.id,
      },
      cartDetails: cart,
    };
    console.log(data);
    this.props.actions.addToCart({ data, jwt: this.props.userData.jwt });
    window.sessionStorage.removeItem("localCart");
    window.sessionStorage.removeItem("cart");
  }

  componentDidMount() {
    this.props.userData.instInfo.map((_, index) => {
      this.props.actions.getEachInstituteDetails({
        jwt: this.props.userData.jwt,
        instId: Number(Object.keys(this.props.userData.instInfo[index])),
        index,
      });
    });
    this.props.userData.orgInfo.map((_, index) => {
      this.props.actions.getEachOrganizationDetails({
        jwt: this.props.userData.jwt,
        orgId: Number(Object.keys(this.props.userData.orgInfo[index])),
        index,
      });
    });
    const isLocalCart = window.sessionStorage.getItem("localCart");
    if (isLocalCart) this.addItemsToCart();
  }

  render() {
    return (
      <div className="mt-4">
        <p>
          <p className={`${this.props.isCalendarShown ? "move-left" : ""}`}>
            <div
              style={{
                paddingBottom: "5px",
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
              onClick={() =>
                this.props.actions.toggleCalendar(!this.props.isCalendarShown)
              }
            >
              <img
                src={
                  "https://www.gstatic.com/companion/icon_assets/calendar_2020q4_2x.png"
                }
                alt="Calendar"
              />
            </div>
          </p>
          {this.props.isCalendarShown && (
            <div className="calender-wrapper">
              <a
                className="calendar-external-icon"
                href="/calendar"
                target="_blank"
              >
                <i
                  className="fa-external-link fa"
                  aria-hidden="true"
                  style={{ color: "black", fontFamily: "FontAwesome" }}
                />
              </a>
              <Calendar callApiGetAPI={true}/>
            </div>
          )}
        </p>
        <div className="container">
          {this.props.getinstituteDetailsData.map((institute) => (
            <div className="card mb-4">
              <div className="card-header">
                <b>
                  {`Institution Name: ${institute.get("instituteName")}`}
                  &nbsp;&nbsp;
                </b>
              </div>
              <div className="card-body">
                <p>
                  <b>Institution Details</b>
                </p>
                <hr></hr>
                <br />
                <div className="org-info">
                  <p>
                    <b>Street1:</b> {institute.get("street1")}
                  </p>
                  <p>
                    <b>Street2:</b> {institute.get("street2")}
                  </p>
                  <p>
                    <b>District:</b> {institute.get("district")}
                  </p>
                  <p>
                    <b>State:</b> {institute.get("state")}
                  </p>
                  <p>
                    <b>Country:</b> {institute.get("country")}
                  </p>
                  <p>
                    <b>Zip Code:</b> {institute.get("zipCode")}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {this.props.getOrganizationDetailsData.map((organization) => (
            <div className="card mb-4">
              <div className="card-header">
                <b>
                  {`Organization Name: ${organization.get("organizationName")}`}
                  &nbsp;&nbsp;
                </b>
              </div>
              <div className="card-body">
                <p>
                  <b>Organization Details</b>
                </p>
                <hr></hr>
                <br />
                <div className="org-info">
                  <p>
                    <b>Street1:</b> {organization.get("street1")}
                  </p>
                  <p>
                    <b>Street2:</b> {organization.get("street2")}
                  </p>
                  <p>
                    <b>District:</b> {organization.get("district")}
                  </p>
                  <p>
                    <b>State:</b> {organization.get("state")}
                  </p>
                  <p>
                    <b>Country:</b> {organization.get("country")}
                  </p>
                  <p>
                    <b>Zip Code:</b> {organization.get("zipCode")}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const s = ({ state }) => ({
  userData: appData.getUserData(state),
  getOrganizationDetailsData: schoolAdminData.getEachOrganizationDetails(state),
  getinstituteDetailsData: schoolAdminData.getEachInstituteDetails(state),
  isCalendarShown: execData.isCalendarShown(state),
});
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...schoolAdmin.actions,
      ...modals.actions,
      ...executive.actions,
      ...profile.actions,
    },
    d
  ),
});
export default connect(s, d)(SchoolAdminHome);
