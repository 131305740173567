import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
class DeleteStandard extends Component {
  render() {
    return (
      <Modal
        show={this.props.isDeletedModalShow}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Do you really want to delete the Standard?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deleting standard will remove all the data associated with standard{" "}
          <b>{this.props.currentDeletedStandard.standardName}</b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.hideDeleteStandardModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              this.props.hideDeleteStandardModal() &&
              this.props.updateStandards({
                orgId: this.props.orgId,
                userId: this.props.userId,
                jwt: this.props.jwt,
                orgIdAdmRteInStdrds: this.props.orgIdAdmRteInStdrds,
                userIdAdmRteInStdrds: this.props.userIdAdmRteInStdrds,
              })
            }
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteStandard;
