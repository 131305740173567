import axios from 'axios'
import APICONF from '.././config/ApiConfig.js';

export const  getTeacherAssociatedBooks = (action) => async (
    { dispatch } ,
    { userIdTeacher,jwt }
    ) => {
    const getBooksUrl = APICONF.GET_USER_ASSOCIATED_BOOKS  + "/" + userIdTeacher;
    const [err, result] = await axios 
         .get(getBooksUrl, {
            headers: {
                "authorization": jwt
            }
         })
         .then(res => [null,res.data])
         .catch( err => [err, null])
    const actionToDispatch = err ? action.failure(err) : action.success(result)
    return dispatch(actionToDispatch)
    }