import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { assessmentModule } from '../../../redux-assessmentModule/index'
import './viewQuestionnaire.css'
import * as selectors from '../../../redux-assessmentModule/selector'
import { List } from 'immutable'
import TestHeaderView from '../../viewAssessment/teacher/TestHeaderView'
import { Navbar } from "react-bootstrap";
import durchereLogoText from '../../../../assets/logo/durchere_text_logo.png';
import MathJax from 'react-mathjax-preview'
import * as appData from '../../../../appSelector'

const generateInputBaseOnType = (inputType, props) => {
  const inputTypes = {
    1: props => {
      const { question, answer } = props
      return (
        <div className="options-wrapper" key={question}>
          <label className="checkbox-inline"><input name={inputType} id={answer} value={answer} type="radio" /> {question}</label>
        </div>
      )
    },
    0: props => <div className="options-wrapper"><input type='text' placeholder='Answer'></input></div>
  }
  return inputTypes[inputType](props)
}
const InputGenerator = props => {
  const { inputType, ...restProps } = props
  return generateInputBaseOnType(inputType, restProps)
}
class ViewQuestionnaire extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    var globalTestId;
    if (this.props.location.state) {
      const { eachTestId } = this.props.location.state
      globalTestId = eachTestId;
    }
    if (!globalTestId) {
      globalTestId = localStorage.getItem('eachTestId');
    }

    this.props.actions.getQuestions({
      jwt: this.props.userData.jwt,
      testId: globalTestId
    })
  }

  render() {
    const passageIndex = this.props.getPassageQuestionIndex
    const questionsIndex = this.props.getQuestionsIndex
    var globalEachTestData;
    if (this.props.location.state) {
      const { eachTestData } = this.props.location.state
      globalEachTestData = eachTestData;
    }
    if (!globalEachTestData) {
      globalEachTestData = JSON.parse(localStorage.getItem('eachTestData'))
    }
    return (
      <>
        {!this.props.location.state
          &&
          <Navbar expand="lg" bg="dark" className="navbar navbar-expand-md navbar-dark fixed-top">
            <Navbar.Brand href="/">
              <img alt="durchere logo" src={durchereLogoText} className="durchere-logo" />
            </Navbar.Brand>
          </Navbar>}

        <div className={!this.props.location.state ? "container top-margin-window-view-questonnaire-online" : "container top-margin"}>
          <TestHeaderView eachTestData={globalEachTestData} />
          <div className="view-questionnaire-wrapper col-md-12">
            {
              (!this.props.showQuestionsRelatedToParaGraph)
               && (!this.props.showregularQuestions)
               && (this.props.getQuestions.get('passages', List()).count() > 0)
               && (
                <div>
                  <div>
                    <b>Passage Title :</b>{this.props.getQuestions.getIn(['passages', passageIndex, 'testPassage', 'passageTitle'], '')}
                  </div>
                  <div className="top-margin p-3">
                  <MathJax math={this.props.getQuestions.getIn(['passages', passageIndex, 'testPassage', 'passageText'], '')} />
                  </div>
                  <div className="top-margin">
                    <button
                      className="col-md-12 no-btn-padding btn btn-success"
                      type="button"
                      onClick={this.props.actions.showQuestionsRelatedToParaGraph}
                    >
                      Next
                </button>
                  </div>
                </div>
              )
            }
            {
              (this.props.showQuestionsRelatedToParaGraph) && (!this.props.showregularQuestions) && (
                <React.Fragment>
                  <div className="question-wrapper">
                  <MathJax math={this.props.getQuestions
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionIndex,
                              'testQuestion',
                              'questionText',
                            ], '')} />

                    <div>
                      {
                        this.props.getQuestions
                          .getIn(
                            [
                              'passages',
                              passageIndex,
                              'passagesQuestions',
                              this.props.getCurrentQuestionIndex,
                              'testQuestion',
                              'marksForQuestion',
                            ], '')
                      }&nbsp;
                      Points
                    </div>
                  </div>

                  <div className="p-3">
                    {this.props.getQuestions
                      .getIn(
                        [
                          'passages',
                          passageIndex,
                          'passagesQuestions',
                          this.props.getCurrentQuestionIndex,
                          'testAnswerOptions'
                        ], List()
                      ).map(currentAnswer =>
                        <InputGenerator
                          inputType={
                            this.props.getQuestions
                              .getIn(
                                [
                                  'passages',
                                  passageIndex,
                                  'passagesQuestions',
                                  this.props.getCurrentQuestionIndex,
                                  'testQuestion',
                                  'questionCategory'
                                ], '')
                          }

                          question={
                            currentAnswer.get('answerOption', '')
                          }
                          answer={
                            currentAnswer.get('testAnswerId', '')
                          }

                        />)
                    }

                    <div className="row d-flex justify-content-between">
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => this.props.actions.currentQuestionIndex(
                          {
                            currentIndex: this.props.getCurrentQuestionIndex,
                            isDecrease: true
                          }
                        )}
                      >
                        Prev question
                </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => this.props.actions.currentQuestionIndex(
                          {
                            currentIndex: this.props.getCurrentQuestionIndex,
                            isDecrease: false
                          }
                        )}

                      >
                        Next question
                </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            }

            {(this.props.showOnlyRegularQuestionsForQuestionaire || this.props.showregularQuestions)
            && (this.props.getQuestions.get('questions', List()).count() > 0)
            && (
                <React.Fragment>
                  <div className="question-wrapper">
                  <MathJax math={this.props.getQuestions.getIn(['questions', questionsIndex, 'testQuestion', 'questionText'], '')} />
                    <div>
                      {this.props.getQuestions
                      .getIn(['questions', questionsIndex, 'testQuestion', 'marksForQuestion'], '')}
                      &nbsp;Points
                    </div>
                  </div>

                  <div className="p-3">
                    {
                      this.props.getQuestions.getIn(
                        [
                          'questions',
                          questionsIndex,
                          'testAnswerOptions'
                        ], List()
                      ).map(
                        (currentQuestion, index) =>
                          <InputGenerator
                            inputType={
                              this.props.getQuestions
                                .getIn(
                                  [
                                    'questions', questionsIndex, 'testQuestion', 'questionCategory'
                                  ], '')
                            }
                            question={
                              currentQuestion.get('answerOption', '')
                            }
                            answer={
                              currentQuestion.get('testAnswerId', '')
                            }
                            index={index}

                          />)
                    }
                    <div className="row d-flex justify-content-between">
                      <button
                        className="btn btn-warning"
                        type="button"
                        onClick={() => this.props.actions.questionsIndex(
                          {
                            regularQuestionIndex: this.props.getQuestionsIndex - 1
                          }

                        )}
                      >
                        Prev question
                </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => this.props.actions.questionsIndex(
                          {
                            regularQuestionIndex: this.props.getQuestionsIndex + 1
                          }
                        )}
                      >
                        Next question
                </button>
                    </div>
                  </div>
                </React.Fragment>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

const s = ({ state }) => ({
  getQuestions: selectors.getQuestions(state),
  getPassageQuestionIndex: selectors.getPassageQuestionIndex(state),
  getCurrentQuestionIndex: selectors.getCurrentQuestionIndex(state),
  showQuestionsRelatedToParaGraph: selectors.showQuestionsRelatedToParaGraph(state),
  getQuestionsIndex: selectors.getQuestionsIndex(state),
  showregularQuestions: selectors.showregularQuestions(state),
  userData: appData.getUserData(state),
  showOnlyRegularQuestionsForQuestionaire: selectors.showOnlyRegularQuestionsForQuestionaire(state),
})
const d = (d) => ({
  actions: bindActionCreators(
    {
      ...assessmentModule.actions
    },
    d
  )
})
export default connect(s, d)(ViewQuestionnaire);